import React from 'react';
import './BackDrop.css';
import classNames from 'classnames';



const backdrop = props => {
    let backdropClass = classNames('back-drawer', 'mobile-only')
    return (
        <div  className={backdropClass} onClick={props.click}/>
    );
  };

export default backdrop;

// const backdrop = props => (
    
//     <div  className="back-drawer" onClick={props.click}/>
// );