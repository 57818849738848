import React, {useEffect} from "react";
import { Layout, Button, Space, Row, Col, message } from "antd";
import styled from "styled-components";

import styles from "./AuthLayout.scss";
import BaseLayout from "../BaseLayout/BaseLayout";
// import LogoImage from "../../../assets/images/logo.png";
import LogoImage from "../../../assets/images/nr-logo-hor.png";
import {useRouteMatch, useHistory, useLocation, Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
// import Spinner from "components/UI/Spinner/Spinner";
import queryString from "query-string";
import { actions as loginActions} from "../../../views/Auth/Login/redux";
import { getFromLS, deleteFromLS, setToLS, openUrl, checkIPAD } from "../../../lib/utils/helperMethods";
// import { appTypeSelector, sebConfigFileSelector, sebConfigFileLoadingSelector } from "App/OfflineApp/offlineAppSelectors";
// import {actions as offlineAppActions} from "App/OfflineApp/offlineAppRedux.js"
// import { CheckAndSetAppType } from "App/OfflineApp/CheckAndSetAppType";

const { Header, Content, Footer } = Layout;
const ContentTop = styled.div`
  display: block;
  text-align: center;
  box-sizing: border-box;
  ::before,
  ::after {
    box-sizing: border-box;
  }
`;
const ContentMain = styled.div`
  display: block;
  width: 100%;
  max-width: 500px;
  padding: 10px 0px 0px 0px;
  margin: 0 auto;
  ::before,
  ::after {
    box-sizing: border-box;
  }

  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  // width: 550px;
  border-radius: 10px;
`;

const StyledLogo = styled.img`
  src: url(${props => props.src});
  // width: 100px;
  // width: 50px;
  height: 50px;
`;

const styledContentTop = {};
const redirectUrlKey = "ap-redirect-url";
const AuthLayout = props => {
  console.log('props in auth==>', props);

  const {path, url} = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()
  const {isAuthenticated, tokenPresent} = props;
  // const appType = useSelector(appTypeSelector())
  // const sebConfigFileUrl = useSelector(sebConfigFileSelector())
  // const sebConfigFileLoading = useSelector(sebConfigFileLoadingSelector())

  useEffect(() => {
    if(tokenPresent){
      dispatch(loginActions.checkAuthState());
    }
    const locationQueryParams = queryString.parse(location.search);
    console.log( "locationQueryParams", locationQueryParams );
    if(locationQueryParams.error){
      const errorEmail = locationQueryParams.error.split("user_not_found_")[1];
      console.log( "errorEmail", errorEmail );
      console.log( "loginActions", loginActions );
      dispatch(loginActions.createFail({error: `User not found with email ${errorEmail}`}));
      history.replace({
        search: ""
      });
    }
    if(location && location.state){
      fromLocation = location.state.from;
      console.log( "fromLocation 103", fromLocation, location );
      let storeUrlFlag = false;
      if(fromLocation.pathname){
        let finalRedirectUrlToStore = fromLocation.pathname
        setToLS(redirectUrlKey, `${finalRedirectUrlToStore}${fromLocation.search}`)
      }
      fromQueryParams = queryString.parse(fromLocation.search);
      if(fromQueryParams.token){
        // localStorage.setItem("token", fromQueryParams.token);
        setToLS("token", fromQueryParams.token);
        dispatch(loginActions.checkAuthState());
      }
    } else {
      // let locationQueryParams = queryString.parse(location.search);
      if(locationQueryParams.token){
        // localStorage.setItem("token", fromQueryParams.token);
        setToLS("token", locationQueryParams.token);
        dispatch(loginActions.checkAuthState());
      }
  
    }
    console.log( "location 60", location );


    // let appType = "web";
    // // let appTypeFromLS = localStorage.getItem("appType")
    // // if((location && location.search && location.search.indexOf("appType=seb") > -1) || (appTypeFromLS && appTypeFromLS == "seb")){
    // if((location && location.search && location.search.indexOf("appType=seb") > -1)){
    //   appType = "seb"
    //   // dispatch(loginActions.initAppSuccess({appType}))
    //   // localStorage.setItem("appType", appType)
    //   // using LS here in case user refreshes inside SEB
    // }
    // // moved here also from checkAuthState
    // if(window.downloadHelper){
    //   appType = "win";
    // } else if(window.webkit){
    //   appType = "mac";
    // }
    // dispatch(loginActions.initAppSuccess({appType}))


  }, []);

  // const openSEB = (sebConfigFileUrl) => {
  //   // console.log("sebConfigFileUrl", sebConfigFileUrl)
  //   if(sebConfigFileUrl){
  //     window.location.href = sebConfigFileUrl
  //   }
  // }

  const homePath = "/"
  let fromLocation, fromQueryParams;
  // console.log("tokenPresent isAuthenticated", tokenPresent, isAuthenticated)
  console.log("location 63", location)
  
  // moved here also from checkAuthState - this checks on first load login page so url checker here
  


  fromQueryParams = {};
  if(tokenPresent){
    if(isAuthenticated){
      let redirectPath = homePath;
      if(location && location.state){
        console.log("location 69", location)
        fromLocation = location.state.from;
        // redirectPath = fromLocation.pathname;
        redirectPath = fromLocation.pathname.concat(fromLocation.search);
      } else {
        let tempRedirectPath = getFromLS(redirectUrlKey);
        console.log( "tempRedirectPath", tempRedirectPath );
        if(tempRedirectPath){
          redirectPath = tempRedirectPath; 
        }
      }
      deleteFromLS(redirectUrlKey)
      return <Redirect to={redirectPath}/>
    } else {
      // return <Spinner />
    }
  }
  

  // let toRender;
  // console.log("1", props);
  // if(!props.children){
  //   console.log("2", props);
  //   toRender = <div>HI {props.main}</div>
  // }
  // return <div>
  //   Auth Layout
  //   <div>{props.children}</div>
  //   {/* {toRender} */}
  // </div>

  // log s

  return (
    <BaseLayout>
      <ContentTop>
        <StyledLogo src={LogoImage} />
        {/* <h1 className="sign-in-text">Sign in to AssessPrep</h1> */}
      </ContentTop>
      {/* <ContentMain className="auth-content-main">{props.children}</ContentMain> */}
      <ContentMain className="auth-content-main">{props.children}</ContentMain>
      {/* <Row className="login-page-extra text-center m-t-15">
        <Col span={24}>
          {(process.env.REACT_APP_MODE == 'development' || process.env.REACT_APP_MODE == 'staging') && appType == "web" && <Button type="primary" ghost={true} size="large" disabled={sebConfigFileLoading} loading={sebConfigFileLoading} onClick={() => {
            if(sebConfigFileLoading){
              return
            }
            dispatch(loginActions.sebConfigFile({}, {successCallback: openUrl}))
          }}>Take test using SEB</Button>}

          {appType == "seb" && <Button key="quit" type="danger" href={`${process.env.REACT_APP_WEB_URL}/seb_quit.html`}>Quit</Button>}
      
          {appType == "mac" && (process.env.REACT_APP_MODE == 'development' || process.env.REACT_APP_MODE == 'staging') && <Button type="danger" onClick={
      ()=>{dispatch(offlineAppActions.executeMethod({key: "quit", value: true}))}}>Quit</Button>}

        </Col>
      </Row> */}
    </BaseLayout>
  );
};
export default AuthLayout;
