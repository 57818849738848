import React from "react";
import { List, Card, Table, Col, Button, Space, Tag } from "antd";
import styled from "styled-components";
import reduxCrud from "../redux";
import ActionBtns from "../../../components/ActionBtns/ActionBtns";
//import { getMaxListeners } from "cluster";
import { MrRow } from "../../../components/UI/MrTable/MrTable";

import { MrColumn } from "../../../components/UI/MrTable/MrTable";
import Mrcard from "../../../components/UI/MrTable/Mrcard";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { currentUserSelector } from "../../Auth/Login/selector";

export const DisordersList = (props) => {
  const { item, dataSource, edited, deleted } = props;
  const currentUser = useSelector(currentUserSelector());
  const permission = currentUser.permission;
  console.log("props disorders---------->", props);
  var disordersId = `${"/u/disorders/" + props.item.id}`;
  const DisordersColumns = [
    {
      title: "Form Name",
      dataIndex: "form_name",
      key: "form_name",
    },
    {
      title: "Form code",
      dataIndex: "form_code",
      key: "form_code",
    },
    {
      title: "Disorder type",
      dataIndex: "disorder_type",
      key: "disorder_type",
    },
    {
      title: "Form type",
      dataIndex: "form_type",
      key: "form_type",
    },
    {
      title: "Color",
      dataIndex: "form_color",
      key: "form_color",
    },
    {
      title: "Last updated",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: props.currentUser.role === "data_entry" ? "Print" : "Actions",
      dataIndex: "actionBtns",
      key: "actionBtns",
    },
  ];
  const dataObject = {
    Name: item.form_name,
    abbreviation: item.abbreviation,
    symptoms: item.symptoms,
    Remarks: item.remarks,
    ActionBtns: props.actionBtns,
    disordersId,
  };
  console.log("dataobject in disorder", dataObject);
  const tableDataSource = [];
  for (let index = 0; index < dataSource.length; index++) {
    let objToPush = {};
    objToPush.id = dataSource[index].id;
    objToPush.form_name = (
      <Link to={currentUser.role === "data_entry" ? "#" : `${"/u/disorders/" + dataSource[index].id}`}>
        {dataSource[index].form_name}{" "}
      </Link>
    );
    objToPush.form_code = dataSource[index].form_code;
    objToPush.disorder_type = dataSource[index].disorder_type;
    objToPush.form_type = dataSource[index].form_type;
    objToPush.form_color = (
      <Space>
        <Tag color={dataSource[index].form_color}>
          {dataSource[index].form_color}
        </Tag>
      </Space>
    );
    objToPush.updated_at = dayjs(dataSource[index].updated_at).format(
      "DD-MM-YYYY h:mm:ss"
    );
    objToPush.actionBtns = (
      <div style={{ display: "flex" }}>
        {permission.disorder && permission.disorder.update && (
          <ActionBtns
            edit={true}
            delete={permission.disorder.delete}
            edited={edited}
            deleted={deleted}
            item={dataSource[index]}
            id={dataSource[index].id}
            path="disorders"
          />
        )}
        <Button
          style={{
            left: "-15px",
            top: "-5px",
          }}
          type="link"
          onClick={() => {
            window.open(`/print/disorders/${dataSource[index].id}`, "_blank");
          }}
          // type="primary"
          // icon={<PrinterOutlined />}
        >
          Print
        </Button>
      </div>
    );
    tableDataSource.push(objToPush);
  }
  return (
    <Table
      dataSource={tableDataSource}
      columns={DisordersColumns}
      pagination={true}
    />
    // <Col span={24}>
    //   <List.Item>
    //     <MrRow className="desktop-only">
    //       <MrColumn style={{ flex: 2 }}>
    //         {" "}
    //         <Link to={disordersId}>{item.name} </Link>{" "}
    //       </MrColumn>
    //       <MrColumn style={{ flex: 2 }}> {item.abbreviation} </MrColumn>
    //       <MrColumn style={{ flex: 2 }}> {item.symptoms} </MrColumn>
    //       <MrColumn style={{ flex: 2 }}> {item.remarks} </MrColumn>
    //       <MrColumn style={{ flex: 2 }}> {props.actionBtns} </MrColumn>
    //     </MrRow>
    //     <Mrcard
    //       dataObject={dataObject}
    //       disordersId={disordersId}
    //       actionBtns={props.actionBtns}
    //     />
    //   </List.Item>
    // </Col>
  );
};
// export default DisordersList;
