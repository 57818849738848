import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  withRouter
} from "react-router-dom";
import { Menu, Icon } from "antd";
import AppConstants from "../../../views/AppConstants/AppConstants";
import Dashboard from "../../../views/Dashboard/Dashboard";
import { FormattedMessage, useIntl, injectIntl } from "react-intl";

const { SubMenu } = Menu;

const MrMenu = props => {
  const initialState = {
    current: "1"
  };

  const finalClass = class extends Component {
    state = initialState;

    static getDerivedStateFromProps(props, state) {
      console.log("props.items", props.items);
      if (props.items) {
        let j = 1;
        let finalIdx = j;
        props.items.forEach(currentItem => {
          console.log("currentItem.url", currentItem.url);

          console.log("props.location.pathname", props.location.pathname);
          if (currentItem.url === props.location.pathname) {
            console.log("currentItem.url === props.location.pathname");
            finalIdx = j;
            return false;
          }
          j += 1;
        });
        return { current: finalIdx.toString() };
      }
      // if (props.location && props.location.pathname === ) {
      // }
      return null;
    }
    handleMenuChange = e => {
      this.setState({
        current: e.key
      });
      console.log("e.key", e.key);
      console.log("e.key", typeof e.key);
    };
    // componentDidMount() {}
    render() {
      let content = [];
      let menuItems = this.props.items;
      // content = menuItems;
      content = menuItems.map((item, i) => {
        return (
          <Menu.Item key={i + 1}>
            <NavLink to={item.url} key={item.title}>
              {/* <Icon type={item.icon} /> */}
              <span>
                {this.props.intl.formatMessage({
                  id: `module.${item.singleResourceName}`,
                  defaultMessage: item.title
                })}
              </span>
            </NavLink>
          </Menu.Item>
        );
      });
      let toRender = (
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[this.state.current]}
          onClick={this.handleMenuChange}
        >
          {content}
        </Menu>
      );
      return toRender;
    }
  };
  return withRouter(injectIntl(finalClass));
};

export default MrMenu;
