import React from "react";
import {Route, useRouteMatch} from "react-router-dom";
import {Tabs, Button} from "antd";
import { List, Typography, Card, Row, Col } from "antd";
import _ from "lodash";
import { adhdForm } from "../../PatientDisorderForms/adhd";
import { MrRow } from '../../../components/UI/MrTable/MrTable';
import { MrColumn } from '../../../components/UI/MrTable/MrTable';

const getSectionFields = (props, element) => {
  const { item } = props;
  if (!item.id) return null;
  console.log("props ->> ", props);
  console.log("props.item", props.item);
  const hospitalDetails = props.item;
  console.log("hospitalDetails", hospitalDetails);

  let properties = element.fields.properties;

  let sectionFields = [];
  for (const key in properties) {
    if (properties.hasOwnProperty(key)) {
      console.log("key", key);
      console.log("value", properties[key].is_sub_heading);
      console.log("value", hospitalDetails.disorder_information[key]);
      if(properties[key].is_sub_heading === true){
        sectionFields.push(
          <List.Item>
            <MrRow className="section-field-row">
              <MrColumn style={{flex: 4}} className="sub-heading">{properties[key].sub_heading_title}</MrColumn>
            </MrRow>
          </List.Item>
        );
      }
      else{
        sectionFields.push(
          <List.Item>
            <MrRow className="section-field-row">
              <MrColumn style={{flex: 4}}>{properties[key].title}</MrColumn>
              <MrColumn style={{flex: 20}} className="field-value"> {hospitalDetails.disorder_information[key]}</MrColumn>
            </MrRow>
          </List.Item>
          // <p><span>{key}</span> - <span>{element.fields.properties[key].title}</span>: <span>{hospitalDetails.disorder_information[key]}</span></p>
        );
      }
    }
  }

  return sectionFields;
}


const AccountItemDetail = (props) => {
  const { item } = props;
  if (!item.id) return null;
  console.log("props hospital ->> ", props);
  console.log("props.item", props.item);

  const hospitalDetails = props.item;

  console.log("hospitalDetails", hospitalDetails);

  let finalRender = [];
  // for (const key in adhdForm) {
  //   if (adhdForm.hasOwnProperty(key)) {
  //     const element = adhdForm[key];
  //     console.log("key", key);
  //     console.log("element", element);
  //     finalRender.push(
  //       <React.Fragment key={`showSection__${key}`}>
  //         <Card title={element.title} className="section-card section-card-show">
  //           {getSectionFields(props, element)}
  //         </Card>
  //       </React.Fragment>
  //     );
  //   }
  // }

  return (
    <div>
      {/* <StyledHeading>
        Patient: {hospitalDetails.first_name.toUpperCase()} {hospitalDetails.middle_name.toUpperCase()}{" "}
        {hospitalDetails.last_name.toUpperCase()}
      </StyledHeading> */}
      <div class="section-card-show">
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{flex: 4}}>Hospital name</MrColumn>
            <MrColumn style={{flex: 20}} className="field-value"> 
              <p class="patient-name">{hospitalDetails.first_name} {hospitalDetails.middle_name} {hospitalDetails.last_name}</p>
            </MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{flex: 4}}>Phone</MrColumn>
            <MrColumn style={{flex: 20}} className="field-value">{hospitalDetails.phone}</MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{flex: 4}}>Email</MrColumn>
            <MrColumn style={{flex: 20}} className="field-value">{hospitalDetails.email}</MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{flex: 4}}>Address</MrColumn>
            <MrColumn style={{flex: 20}} className="field-value">{hospitalDetails.address}</MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{flex: 4}}>Hospital ID</MrColumn>
            <MrColumn style={{flex: 20}} className="field-value">{hospitalDetails.hospital_id}</MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{flex: 4}}>Disorder</MrColumn>
            {hospitalDetails.disorder_information && <MrColumn style={{flex: 20}} className="field-value">{hospitalDetails.disorder_information.disorder_type}</MrColumn>}
          </MrRow>
        </List.Item>
        {/* <p class="patient-name">{hospitalDetails.first_name} {hospitalDetails.middle_name} {hospitalDetails.last_name}</p> */}
        {/* <p>{hospitalDetails.phone}</p>
        <p>{hospitalDetails.email}</p>
        <p>{hospitalDetails.address}</p>
        <p>{hospitalDetails.hospital_id}</p>
        <p>{hospitalDetails.disorder_information.disorder_type}</p> */}
      </div>
      
      {finalRender}
      
    </div>
  );
}


AccountItemDetail.defaultProps = {};

AccountItemDetail.propTypes = {};

export default AccountItemDetail;
