import React, { Component } from "react";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import "./Login.scss";
import { MrFormComponent } from "mr_react_framework";
import AuthLayout from "../../../components/Layouts/AuthLayout/AuthLayout";
import { GoogleLogin } from "react-google-login";
import { connect } from "react-redux";
import {
  loadingSelector,
  errorSelector,
  isAuthenticatedSelector
} from "./selector";
import {
  GoogleOutlined,
  ArrowRightOutlined
} from "@ant-design/icons";
import { actions } from "./redux";
// import styles from "./Login.css";
const getLoginForm = props => {
  return {
    schema: {
      type: "object",
      properties: {
        email: {
          // title: "Email",
          type: "string",
          format: "email",
          defaultValue: "sample@email.com",
          placeholder: "Email",
          className: "login-email"
        },
        password: {
          // title: "Password",
          type: "string",
          placeholder: "Password",
          widget: Input.Password,
          className: "login-password"
        }, 
        
      }
    },
    
    uiSchema: {
        password: {
        classNames: "hiddenpass",
        "ui:widget": "password"
      }
    },
    submitBtn: {
        text: "Login",
        // className: "login-button", please allow this
        style: {
          // classes: "primary",
          "margin-left": "318px",
        }
      },
    // email: {
    //   value: "sample@email.com",
    //   elementType: "input",
    //   group: 1,
    //   validationRules: [
    //     { required: true },
    //     { type: "email", message: "Not a valid email." }
    //   ],
    //   placeholder: "email",

    //   prefix: {
    //     type: "element",
    //     element: <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
    //   }
    // },
    // password: {
    //   value: "password",
    //   validationRules: [{ required: true }],
    //   type: "password",
    //   elementType: "input",
    //   group: 1,
    //   placeholder: "Passowrd",
    //   prefix: {
    //     type: "element",
    //     element: <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
    //   }
    // }
    // gender: {
    //   validationRules: [{ required: true }],
    //   type: "text",
    //   elementType: "select",
    //   group: 2,
    //   placeholder: "gender"
    // }
  };
};

const LoginText = () => {
  let loginText = []
  loginText.push("Login")
  loginText.push(<ArrowRightOutlined style={{"marginLeft":"10px"}}/>)
  return loginText
}

class Login extends Component {
  responseGoogle = (response) => {
    this.props.onGoogleLogin(response);
    console.log("response", response);
  };

  handleSubmit = (params, opts = {}) => {
    this.props.onSubmitHandler(params);
  };
  render() {
    const styleProps = {
      submitBtn: {
        width: "100%",
      },
    };
    let mainContent = [];
    const TempForm = MrFormComponent;
    const jsonForm = getLoginForm(this.props);
    const mainForm = <TempForm
      key={"mrForm"}
      {...this.props}
      visible={true}
      resourceForm={{form: {...jsonForm}, config: {isModal: false, visible: true}}}
      
      item={{}}
      // formConfig={{actions: {submitText: "Login", showCancelBtn: false}}}
      formConfig={{actions: {submitText: <LoginText />, showCancelBtn: false}}}
      formProps={{}}
      // jsonForm={jsonForm}
      // schema={jsonForm.schema}
      handleOk={this.handleSubmit}
      handleSubmit={this.handleSubmit}
      styleProps={styleProps}
    />
    mainContent.push(
      <TempForm
        key={"mrForm"}
        {...this.props}
        visible={true}
        resourceForm={{form: {...jsonForm}, config: {isModal: false, visible: true}}}
        
        item={{}}
        formConfig={{actions: {submitText: "Login"}}}
        formProps={{}}
        // jsonForm={jsonForm}
        // schema={jsonForm.schema}
        handleOk={this.handleSubmit}
        handleSubmit={this.handleSubmit}
        styleProps={styleProps}
      />
    );

    // mainContent.push(
    //   <GoogleLogin
    //     key="googleLogin"
    //     clientId="758735174332-clv9t2m6qkf1g55vpnm1ibpakit8derg.apps.googleusercontent.com"
    //     buttonText="Login"
    //     onSuccess={this.responseGoogle}
    //     onFailure={this.responseGoogle}
    //     redirectUri="http://localhost:3005/omniauth/google/callback"
    //     // isSignedIn="true"
    //   />
    // );
    // return <AuthLayout top={"Hello"} main={mainContent} />;
    return <>
      {mainForm}
    </>
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    return {
      loading: loadingSelector()(state, props),
      error: errorSelector()(state, props),
      //  bar: getBarState(state, props)
      isAuthenticated: isAuthenticatedSelector()(state, props)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitHandler: params => {
      let finalParams = {
        user: {
          ...params
        }
      };

      dispatch(actions.create(finalParams));
    },
    onGoogleLogin: googleOauthObj => {
      const googleLoginData = {
        googleLoginObj: googleOauthObj
      };
      dispatch(actions.createGoogle(googleLoginData));
    }
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Login);

// export default Login;

// class Login extends React.Component {
//   handleSubmit = e => {
//     e.preventDefault();
//     this.props.form.validateFields((err, values) => {
//       if (!err) {
//         console.log("Received values of form: ", values);
//       }
//     });
//   };

//   render() {
//     const { getFieldDecorator, setFieldsValue } = this.props.form;
//     return (
//       <div id="components-form-demo-normal-login">
//         <Form onSubmit={this.handleSubmit} className="login-form">
//           <Form.Item>
//             {getFieldDecorator("userName", {
//               initialValue: "myfirstUsername",
//               rules: [
//                 { required: true, message: "Please input your username!" }
//               ]
//             })(
//               <Input
//                 prefix={
//                   <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
//                 }
//                 placeholder="Username"
//               />
//             )}
//           </Form.Item>
//           <Form.Item>
//             {getFieldDecorator("password", {
//               initialValue: "hellopassword",
//               rules: [
//                 { required: true, message: "Please input your Password!" }
//               ]
//             })(
//               <Input
//                 prefix={
//                   <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
//                 }
//                 type="password"
//                 placeholder="Password"
//               />
//             )}
//           </Form.Item>
//           <Form.Item>
//             {getFieldDecorator("remember", {
//               valuePropName: "checked",
//               initialValue: true
//             })(<Checkbox>Remember me</Checkbox>)}
//             <a className="login-form-forgot" href="">
//               Forgot password
//             </a>
//             <Button
//               type="primary"
//               htmlType="submit"
//               className="login-form-button"
//             >
//               Log in
//             </Button>
//             Or <a href="">register now!</a>
//           </Form.Item>
//         </Form>
//       </div>
//     );
//   }
// }

// const LoginForm = Form.create({
//   name: "login",
//   values: { userName: "hello123", password: "hellohellohello" }
// })(Login);

// export default LoginForm;
