import React, { useState, useEffect } from "react";
import { Upload, Modal, Image, message, List, Avatar, Card } from "antd";
import {
  InboxOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import "./SelectUpload.scss";
import MediaShow from "../UI/MediaShow/MediaShow";

const url =
  (process.env.REACT_APP_API_URL || "/backend/api/v1/") + "attachments";

const token = localStorage.getItem("token");

function getBase64(file) {
  console.log("file==>", file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const { Dragger } = Upload;

const SelectUpload = (props) => {
  const [previewFile, setPreviewFile] = useState({
    previewImage: "",
    previewVisible: false,
    previewTitle: "",
  });
  const { hideWhenFileUploaded } = props;

  const { files } = props || props.values;
  const showUploadList =
    props.showUploadList !== undefined ? props.showUploadList : true;
  const defaultList =
    (props.value && props.value.files) || (files && files.files) || [];
  // console.log("select upload props==>", defaultList);
  // const [fileList, setFileList] = useState(defaultList);
  const [fileList, setFileList] = useState({
    files: defaultList,
  });

  let shouldHide = hideWhenFileUploaded && fileList.files.length > 0;

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    console.log("upload props==>", file);
    setPreviewFile({
      ...previewFile,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      file: file,
    });
    console.log("previewing file==>", file);
  };
  const onChange = (file) => {
    if (file.file.status === "done") {
      const fileResponse = file.file.response;
      setFileList({
        ...fileList,
        files: [...fileList.files, fileResponse],
      });
      // setFileList([...fileList, fileResponse]);
    }
  };
  const onRemoveFile = (file) => {
    console.log("on change file==>", file, fileList);
    const newFileList = [...fileList.files];
    const finalList = newFileList.filter((item) => item.id !== file.id);
    setFileList({
      ...fileList,
      files: finalList,
    });
  };

  useEffect(() => {
    console.log("file after==>", fileList);
    props.onChange(fileList);
  }, [fileList]);
  // ----------- NGINX file size limit is 100mb for now -------------
  return (
    !shouldHide && (
      <>
        <Dragger
          action={url}
          name="file"
          listType="picture"
          multiple="true"
          defaultFileList={fileList.files}
          // fileList={fileList}
          headers={{ token: token }}
          onChange={onChange}
          onPreview={handlePreview}
          showUploadList={showUploadList}
          onRemove={onRemoveFile}
          // itemRender={(originNode, file, currFileList) => {
          //   console.log("file props==>", file, fileList);
          //   const { name, status, uid, response } = file;
          //   const index = currFileList.indexOf(file);
          //   return (
          //     <>
          //       <Card>
          //         <List.Item actions={[<DeleteOutlined />]}>
          //           <List.Item.Meta
          //             avatar={
          //               status !== "done" ? (
          //                 <LoadingOutlined />
          //               ) : (
          //                 <Image alt={name} src={response.url} />
          //               )
          //             }
          //             title={<a href="#">{name}</a>}
          //             description={`${
          //               response
          //                 ? dayjs(response.updated_at).format(
          //                     "DD-MM-YYYY h:mm:ss"
          //                   )
          //                 : ""
          //             }`}
          //           />
          //         </List.Item>
          //       </Card>
          //     </>
          //   );
          // }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload.
          </p>
        </Dragger>
        <Modal
          visible={previewFile.previewVisible}
          title={previewFile.previewTitle}
          footer={null}
          onCancel={() =>
            setPreviewFile({
              ...previewFile,
              previewVisible: false,
            })
          }
        >
          <MediaShow file={previewFile.file} />
        </Modal>
      </>
    )
  );
};

export default SelectUpload;
