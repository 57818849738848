import React from "react";
import { MrCrudView, MrSelect, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
import commonSelectors from "../../../App/commonSelectors";
import PatientDisorderList from "./PatientDisorderList";
import PatientDisorderItemDetail from "./PatientDisorderItemDetail";
import { actions as genericActions } from "../../../App/genericRedux";
import { Row, Card, Space, Switch } from "antd";
import { useSelector } from "react-redux";

import { adhdForm } from "../../PatientDisorderForms/adhd";
import { asdForm } from "../../PatientDisorderForms/asd";
import { encephalitisForm } from "../../PatientDisorderForms/encephalitis";
import { movementDisorderForm } from "../../PatientDisorderForms/movementDisorder";
import { neurodegenerativeForm } from "../../PatientDisorderForms/neurodegenerative";
import { cerebralForm } from "../../PatientDisorderForms/cerebralPalsy";
import { neuromuscularForm } from "../../PatientDisorderForms/neuromuscular";
import { epilepsyForm } from "../../PatientDisorderForms/epilepsy";
import { gddForm } from "../../PatientDisorderForms/gdd";
import { ldForm } from "../../PatientDisorderForms/ld";
import { strokeForm } from "../../PatientDisorderForms/stroke";
import { demyelinatingForm } from "../../PatientDisorderForms/demyelinating";
import { merge, filter, defaultsDeep, remove, isObject } from "lodash";
import { requestDisorderSelector } from "../../Disorders/selector";
import CustomInput from "../../Disorders/CustomInput";
import DisorderItemView from "./DisorderItemView";
import deepmerge from "deepmerge";
import SelectUpload from "../../../components/SelectUpload/SelectUpload";
import { mergeDisorders } from "../../../lib/utils/helperMethods";

const GenericWidgetCreator = (hocProps, Component) => {
  return (props) => {
    let finalHocProps = {};
    if (typeof hocProps === "function") {
      finalHocProps = hocProps(props);
    }
    return <Component {...props} {...finalHocProps} />;
  };
};

const disorderFormsObj = {
  adhdForm: adhdForm,
  asdForm: asdForm,
  encephalitisForm: encephalitisForm,
  movementDisorderForm: movementDisorderForm,
  neurodegenerativeForm: neurodegenerativeForm,
  cerebralForm: cerebralForm,
  neuromuscularForm: neuromuscularForm,
  epilepsyForm: epilepsyForm,
  gddForm: gddForm,
  ldForm: ldForm,
  strokeForm: strokeForm,
  demyelinatingForm: demyelinatingForm,
};

const SelectDisorderType = MrSelect({
  actions: genericActions,
  resourceName: "disorders",
  config: {
    url: "/disorders",
    processData: (data, props) => {
      const disorderNames = props.disorderArr;
      const visitType = props.visitType;
      console.log("props disorderArr==>", data);
      const updatedData = [];
      data.map((item) => {
        disorderNames &&
          disorderNames.map((disorderName) => {
            if ((item.disorder_type || item.name) === disorderName) {
              if (item.form_type === visitType) {
                updatedData.push(item);
              }
            }
          });
      });
      console.log("disorder name==>", updatedData);
      return updatedData.map((item) => {
        return { label: item.form_name, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Select Disorder",
      // mode: "multiple",
      style: { width: "250px" },
      allowClear: true,
      // value: [13],
    },
  },
});

const SelectDisorderNames = MrSelect({
  config: {
    processData: (data, props) => {
      console.log("select dropdown==>", data, props);
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "ADHD", value: "adhd_form" },
        { label: "ASD", value: "asd_form" },
        { label: "CEREBRAL PALSY", value: "cerebral_form" },
        { label: "DEMYELINATING", value: "demyelinating_form" },
        { label: "ENCEPHALITIS", value: "encephalitis_form" },
        { label: "EPILEPSY", value: "epilepsy_form" },
        { label: "GLOBAL DEVELOPMENTAL DELAY", value: "gdd_form" },
        { label: "LEARNING DISABILITY", value: "ld_form" },
        { label: "MOVEMENT DISORDER", value: "movement_disorder_form" },
        { label: "NEURODEGENERATIVE", value: "neurodegenerative_form" },
        { label: "NEUROMUSCULAR", value: "neuromuscular_form" },
        { label: "STROKE", value: "stroke_form" },
      ],
      showSearch: false,
      // filterOption: true,
      optionFilterProp: "label",
      placeholder: "Value",
      // style: { width: "120px" },
      allowClear: true,
    },
  },
});

const SelectVisitType = MrSelect({
  config: {
    processData: (data, props) => {
      console.log("select dropdown==>", data, props);
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "First visit", value: "add_on" },
        { label: "Follow-up visit", value: "follow_up" },
      ],
      showSearch: false,
      // filterOption: true,
      optionFilterProp: "label",
      placeholder: "Value",
      // style: { width: "120px" },
      allowClear: true,
    },
  },
});
const getDisorderViewField = (
  sourceValues,
  disorderArr,
  useMasterForm,
  disorderNames,
  scannedDocument,
  visitType
) => {
  console.log("disorder id==>", disorderArr);
  return {
    type: "object",
    properties: {
      disorder_dependents: {
        placeholder: "Enter Disorder Info",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: DisorderItemView,
        widgetConfig: {
          disorderArr: disorderArr,
          useMasterForm: useMasterForm,
          disorderNames: disorderNames,
          scannedDocument: scannedDocument,
          visitType: visitType,
        },
      },
    },
  };
};

const getSelectDisorderField = (sourceValues, disorderArr, visitType) => {
  console.log("inside drop==>", sourceValues, visitType);
  return {
    type: "object",
    properties: {
      disorder_ids: {
        title: "Select form",
        // placeholder: "Disorder",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectDisorderType,
        widgetConfig: {
          disorderArr: disorderArr,
          visitType: visitType,
          mode: "multiple",
          disabled: sourceValues.id ? true : false,
        },
        rules: [
          {
            required: true,
            message: "Please select",
          },
        ],
      },
    },
  };
};

const SelectDisorderTypeComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
  editDisabled,
}) => {
  const disorderArr = getFieldValue(["response_json", "disorder_type"]);
  const visitType = getFieldValue(["response_json", "visit_type"]);
  console.log("disorder in form==>", disorderArr);
  let finalRender = null;
  finalRender = (
    <FormRenderer
      formObj={getSelectDisorderField(sourceValues, disorderArr, visitType)}
      sourceValues={sourceValues}
      formInstance={formInstance}
      propertyKey={"response_json"}
    />
  );
  return finalRender;
};

const DisorderMergeComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  var disorderArr = getFieldValue(["response_json", "disorder_ids"]);
  var useMasterForm = getFieldValue(["response_json", "use_master_form"]);
  var disorderNames = getFieldValue(["response_json", "disorder_type"]);
  var scannedDocument = getFieldValue(["response_json", "scanned_document"]);
  var visitType = getFieldValue(["response_json", "visit_type"]);
  console.log("getting field value==>", scannedDocument);

  if (!disorderArr) {
    disorderArr = [];
  }
  let finalRender = null;
  finalRender = (
    <FormRenderer
      formObj={getDisorderViewField(
        sourceValues,
        disorderArr,
        useMasterForm,
        disorderNames,
        scannedDocument,
        visitType
      )}
      sourceValues={sourceValues}
      formInstance={formInstance}
      propertyKey={"response_json"}
    />
  );
  return finalRender;
};

// export const processInitialValues = (data) => {

// }
const processSettingsFormInitialValues = (data) => {
  let newData = { ...data };
  console.log("initial values==> final", newData);

  if (
    data &&
    data.attachments_json &&
    data.attachments_json.scanned_document &&
    data.attachments_json.scanned_document.files &&
    data.attachments_json.scanned_document.files.length > 0
  ) {
    // let children = formatJsonApiData(data.children)
    let newFiles = data.attachments_json.scanned_document;
    // modifiedSettings.start_at_datetime = moment(
    //   data.settings.start_at_datetime
    // );
    newData.response_json.scanned_document = newFiles;
  }

  // console.log("newData", newData);
  return newData;
};

export const returnDisorderInfoForm = (props) => {
  const { intl, currentUser, permission, disorders } = props;
  const editDisabled = props.formState.values.id ? true : false;

  console.log("returnDisorderInfoForm==>", props.formState.values);
  let patientsForm = {
    processFormData: (dataProps, opts) => {
      console.log("opts values==>", opts.values);
      const newOptsValues = { ...opts.values };

      const tempData = mergeDisorders(
        props.disorders,
        opts.values.response_json.disorder_ids,
        opts.values.response_json.disorder_type,
        opts.values.response_json.use_master_form
      );
      if (!opts.values.response_json.disorder_dependents) {
        opts.values.response_json.disorder_dependents = tempData;
      }
      if (
        opts.values &&
        opts.values.response_json &&
        opts.values.response_json.scanned_document &&
        opts.values.response_json.scanned_document.files
      ) {
        const newAttachmentsJson = {
          scanned_document: opts.values.response_json.scanned_document,
        };
        opts.values.attachments_json = newAttachmentsJson;
        delete opts.values.response_json.scanned_document;
      }

      // opts.values.disorder_id = opts.values.response_json.disorder_ids[0]
      // console.log("opts", opts);
      console.log("opts values==> final", opts.values);

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        // disorder_id: {
        //   type: "string",
        //   title: "Disorder Id",
        //   span: 8,
        //   // widgetConfig: {
        //   //   width: "200px",
        //   // },
        // },
        response_json: {
          title: "Disorder Type(Multi Select)",
          type: "object",
          span: 24,
          properties: {
            //---------- Need to remove this after AIIMS done with old forms ---------------------
            // disorder_dependents2: {
            //   type: "string",
            //   className: "m-b-0",
            //   dependsOn: ["response_json.disorder_type"],
            //   setInitialValue: true,
            //   // children: DisorderMergeComponent,
            //   children: ({ getFieldValue, sourceValues }) => {
            //     // console.log('children props=>', props)
            //     var disorderArr = getFieldValue([
            //       "response_json",
            //       "disorder_type",
            //     ]);
            //     let finalObj = disorderFormsObj[disorderArr];

            //     var i = 1;
            //     for (var k in finalObj) {
            //       const c = finalObj[k];
            //       for (var key in c.fields.properties) {
            //         const current = c.fields.properties[key];
            //         if (current.hasOwnProperty("title")) {
            //           current.title = current.title.concat(` #${i}`);
            //         } else {
            //           current.sub_heading_title =
            //             current.sub_heading_title.concat(` #${i}`);
            //         }
            //         i++;
            //       }
            //     }

            //     let finalRender = [];
            //     for (const key in finalObj) {
            //       if (finalObj.hasOwnProperty(key)) {
            //         const element = finalObj[key];
            //         finalRender.push(
            //           <React.Fragment key={`formSection__${key}`}>
            //             <Card title={element.title} className="section-card">
            //               <Row gutter={[16, 16]} className="section-row m-0">
            //                 <FormRenderer
            //                   formObj={element.fields}
            //                   propertyKey={"response_json"}
            //                   sourceValues={sourceValues}
            //                 />
            //               </Row>
            //             </Card>
            //           </React.Fragment>
            //         );
            //       }
            //     }
            //     // }
            //     return finalRender;
            //     // return null
            //   },
            // },
            //----------- Need to remove till here after AIIMS are done with old forms -------------

            use_master_form: {
              title: "Use Master Form",
              type: "string",
              span: 4,
              valuePropName: "checked",
              // widget: CustomSwitchWidget,
              widget: "SwitchWidget",
              wrapClassName: "hidden",
              widgetConfig: {
                defaultChecked: true,
                disabled: editDisabled,
                // checkedChildren: "1",
                // unCheckedChildren: "0",
              },
            },
            visit_type: {
              title: "Visit type",
              type: "string",
              widget: SelectVisitType,
              widgetConfig: {
                disabled: editDisabled,
              },
              span: 4,
              rules: [
                {
                  required: true,
                  message: "Please select",
                },
              ],
            },

            disorder_type: {
              title: "Disorder type",
              type: "string",
              // placeholder: "Disorder Names",
              widget: SelectDisorderNames,
              widgetConfig: {
                mode: "multiple",
                width: "200px",
                disabled: editDisabled,
              },
              span: 6,
              rules: [
                {
                  required: true,
                  message: "Please select",
                },
              ],
            },
            disorder_ids: {
              // widget: SelectDisorderTypeComponent,
              span: 6,
              type: "string",
              className: "m-b-0",
              dependsOn: [
                "response_json.disorder_ids",
                "response_json.use_master_form",
                "response_json.disorder_type",
                "response_json.visit_type",
              ],
              setInitialValue: true,
              widgetConfig: {
                disabled: editDisabled,
              },
              children: SelectDisorderTypeComponent,
            },
            // attachments_json: {
            //   type: "object",
            //   properties: {
            //     scanned_document: {
            //       title: "Upload scanned form",
            //       type: "string",
            //       span: 8,
            //       widget: SelectUpload,
            //       setInitialValue: true,
            //       widgetConfig: {
            //         showUploadList: false,
            //         multiple: false,
            //       },
            //
            //     },
            //   },
            // },
            scanned_document: {
              title: "Upload scanned form",
              type: "string",
              span: 8,
              widget: SelectUpload,
              setInitialValue: true,
              widgetConfig: {
                showUploadList: false,
                multiple: false,
                hideWhenFileUploaded: true,
              },
              // wrapClassName:
              //   props.formState.values &&
              //   props.formState.values.response_json &&
              //   props.formState.values.response_json.scanned_document &&
              //   props.formState.values.response_json.scanned_document.files
              //     .length > 0 &&
              //   "hidden",
            },
            disorder_dependents: {
              title: "Choose from",
              type: "string",
              className: "m-b-0",
              dependsOn: [
                "response_json.disorder_ids",
                "response_json.use_master_form",
                "response_json.disorder_type",
                "response_json.scanned_document",
                "response_json.visit_type",
              ],
              setInitialValue: true,
              children: DisorderMergeComponent,
              // children: (props) => {
              //   console.log('children props=>', props)
              //   // var disorderArr = getFieldValue([
              //   //   "response_json",
              //   //   "disorder_ids",
              //   // ]);

              //   // let finalRender = [];
              //   // const selectedDisorderList = [];
              //   // if (disorderArr && disorderArr.length !== 0) {
              //   //   for (var i = 0; i < disorderArr.length; i++) {
              //   //     let current = disorderArr[i];
              //   //     const finalData =
              //   //       disorders.length !== 0 &&
              //   //       filter(disorders, (item) => item.id === current);
              //   //     selectedDisorderList.push(finalData[0]);
              //   //   }
              //   // }

              //   // const finalFormJson = [];
              //   // selectedDisorderList.map((item) => {
              //   //   let currentDisorder = item.form_json.disorders;
              //   //   // finalFormJson.push(currentDisorder);

              //   //   currentDisorder.map((innerItem) => {
              //   //     finalFormJson.push(innerItem);
              //   //   });
              //   // });

              //   // var finalList = [];
              //   // if (finalFormJson.length > 1) {
              //   //   for (let item = 0; item < finalFormJson.length; item++) {
              //   //     let current = finalFormJson[item];
              //   //     // remainingList = remove(finalFormJson, (r) => {
              //   //     //   return r.id == item;
              //   //     // });
              //   //     var remainingList = remove(finalFormJson, (f) => {
              //   //       return (
              //   //         f.title.toLowerCase() === current.title.toLowerCase()
              //   //       );
              //   //     });

              //   //     // if (remainingList.length !== 0) {
              //   //     remainingList.map((finalItem) => {
              //   //       current = deepmerge(current, finalItem);
              //   //     });
              //   //     // } else {
              //   //     //   finalList.push(current);
              //   //     // }
              //   //     finalList.push(current);
              //   //   }
              //   // }
              //   // finalFormJson.map((rest) => {
              //   //   finalList.push(rest);
              //   // });

              //   // console.log("disorderArr==>", finalList);

              //   // if (finalList.length !== 0) {
              //   //   finalRender.push(<DisorderItemView data={finalList} />);
              //   // }

              //   // // if (finalObj) {
              //   // // console.log("disorderArr==>", finalObj);
              //   // // finalRender.push(<CustomInput value={finalObj} />);
              //   // // finalRender.push(<DisorderItemView data={finalList} />);
              //   // // for (const key in finalObj) {
              //   // //   if (finalObj.hasOwnProperty(key)) {
              //   // //     const element = finalObj[key];
              //   // //     finalRender.push(
              //   // //       <React.Fragment key={`formSection__${key}`}>
              //   // //         {/* <Card title={element.title} className="section-card">
              //   // //           <Row gutter={[16, 16]} className="section-row m-0">
              //   // //             <FormRenderer
              //   // //               formObj={element.fields}
              //   // //               propertyKey={"response_json"}
              //   // //               sourceValues={sourceValues}
              //   // //             />
              //   // //           </Row>
              //   // //         </Card> */}
              //   // //
              //   // //       </React.Fragment>
              //   // //     );
              //   // //   }
              //   // // }
              //   // // }
              //   // return finalRender;
              //   return null
              // },
            },
          },
        },
        // scanned_document: {
        //   title: "Upload scanned form",
        //   type: "string",
        //   span: 2,
        //   widget: SelectUpload,
        //   setInitialValue: true,
        //   widgetConfig: {
        //     showUploadList: false,
        //     multiple: false,
        //     hideWhenFileUploaded: true,
        //   },
        //   // wrapClassName:
        //   //   props.formState.values &&
        //   //   props.formState.values.response_json &&
        //   //   props.formState.values.response_json.scanned_document &&
        //   //   props.formState.values.response_json.scanned_document.files
        //   //     .length > 0 &&
        //   //   "hidden",
        // },
      },
    },
  };
  return patientsForm;
};

const PatientDisorders = MrCrudView({
  resourceName: "patientDisorders",
  singleResourceName: "patient_disorder",
  displayName: "Create/Edit Patient",
  resourceUrl: "/patient_disorders",
  // layout: NewBasicCRUDLayout,
  isRoute: true,
  // formLayout: NewBasicFormLayout,
  // tableColumns: PatientColumns,
  //   filter: PatientsFilter,
  // listItem: PatientsList,
  forms: [
    {
      name: "patientDisorders",
      form: returnDisorderInfoForm,
      config: {
        processInitialValues: processSettingsFormInitialValues,
        title: { show: true, value: "patient visit" },
        submitConfig: {
          successCallback: (data) => {
            console.log(
              "patient disorder creating =======>",
              data,
              window.location
            );
            setTimeout(() => {
              window.location.hash = `#id=${data.data.patient_disorder.id}`;
            }, 1000);
          },
        },
      },
    },
  ],
  selectorsObj: {
    ...commonSelectors,
    disorders: requestDisorderSelector,
  },
  actions,
  // listHeader: <PatientsHeader />,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: PatientDisorderList,
      // widget: () => {
      //   return <span>Item Detail </span>;
      // },
      // use if we want cards
    },
    itemDetail: {
      // widget: () => {
      //   return <span>Item Detail</span>;
      // },
      widget: PatientDisorderItemDetail,
    },
    pagination: {
      show: false,
    },
    filter: {
      show: true,
      config: {
        create: { show: true },
      },
    },
    core: {
      setQueryParams: false,
    },
    list: {
      // use if we want custom table
      show: true,
      type: "table/list/custom",
      // widget: () => {
      //   return <span>Item Detail</span>;
      // },
      widget: PatientDisorderList,
      forceReload: true,
    },
  },
});

export default PatientDisorders;
