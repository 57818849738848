import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Image, Popconfirm, Modal, Button } from "antd";
// import { AiOutlineClose } from "react-icons/ai";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";

// import { getEmbeddedSelector } from "views/Auth/Login/selector";
// import { appTypeSelector } from "App/OfflineApp/offlineAppSelectors";
// import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
// import { actions as segmentsActions } from "views/Segments/redux";
// import loadable from "@loadable/component";
// import {
//   checkIfURLOfType,
//   checkURLExist,
//   getUniqueId,
// } from "lib/utils/helperMethods";
// const ReactPlayer = loadable(() =>
//   import(/* webpackChunkName: "react-player" */ "react-player")
// );

const MediaShow = (props) => {
  console.log("Media Show Props ==>", props);

  const {
    noCache,
    file = {},
    mediaShowSetting = {},
    setMediaShowSetting,
    style,
  } = props;

  const supportedContentTypes = ["image", "audio", "pdf", "video"];
  var dispatch = useDispatch();

  let fileType;
  supportedContentTypes.map((ct) => {
    if (
      (file.type && file.type.indexOf(ct) >= 0) ||
      (file.content_type && file.content_type.indexOf(ct) >= 0)
    ) {
      fileType = ct;
    }
  });

  const contentType = fileType;

  console.log("content type==>", contentType);

  // let url = null
  // if (contentType == 'pdf') {
  //   if (appType != 'seb') {
  //     url = `/pdfjs/web/viewer.html?file=${file.url}`
  //   } else {
  //     url = `${file.url}#toolbar=0`
  //   }
  // }

  // const fileURL = noCache && file.s3_url ? file.s3_url + `?${Date.now()}` : file.url
  const fileURL = file.url;
  // const fileURL = file.s3_url
  //   const [mediaStateSetting, setMediaStateSetting] = useState({
  //     custom_player: true,
  //   });
  //   const [activeMediaPlaying, setActiveMediaPlaying] = useState(false);
  //   const [fullScreenModal , setFullScreenModal] = useState(false);

  const [reload, setReload] = useState(1);
  const [s3URLExist, setS3URLExist] = useState(false);
  //   useEffect(() => {
  //     if (!embedded && contentType == "video" && file.convert_status == "processing") {
  //       checkURLExist({
  //         url: file.s3_url,
  //         interval: 30000,
  //         successCallback: (value) => {
  //           setS3URLExist(true);
  //         },
  //         errorCallback: (error) => {
  //           setS3URLExist(false);
  //         },
  //       });
  //     }
  //   }, []);

  //   useEffect(() => {
  //     if (activeMediaId && file.id != activeMediaId) {
  //       setActiveMediaPlaying(false);
  //     }
  //   }, [activeMediaId])

  const mediaPlayer = useRef(null);

  const pdfToRender = (
    <>
      {/* <Button
        className= {!fullScreenModal  ? "pdf-full-screen-button" : "pdf-full-screen-exit-button" }
        onClick={() => setFullScreenModal(!fullScreenModal)}
        icon={
          fullScreenModal ? <FullscreenExitOutlined /> : <FullscreenOutlined />
        }
      ></Button> */}
      <iframe
        src={`/pdfjs/web/viewer.html?file=${fileURL}`}
        width="100%"
        // height="500px"
        height="100%"
      ></iframe>
    </>
  );

  //   const handlePlayPause = () => {
  //     const activeMediaId = file.id
  //     dispatch(segmentsActions.setActiveMediaIdSuccess({ activeMediaId }))
  //     setActiveMediaPlaying(!activeMediaPlaying)
  //   }

  //React player having  some issue to get latest value of playing so setting playing = true in onPlay
  //Reference: https://github.com/CookPete/react-player/blob/master/src/demo/App.js

  //   const handleOnPlay = () => {
  //     setActiveMediaPlaying(true)
  //   }

  //   console.log("mediaStateSetting before render ==>", mediaStateSetting)
  return (
    <div key={`media-show-${file.id}-${reload}`} className="media-show">
      {contentType == "image" && (
        <span>
          <Image
            src={fileURL}
            className={"antd-zoom-image"}
            placeholder={false}
            preview={{ mask: false }}
            {...style}
          ></Image>
          {/* {embedded ? (
            <img src={fileURL} />
          ) : (
            <Image
              src={fileURL}
              className={"antd-zoom-image"}
              placeholder={false}
            ></Image>
          )} */}
          {props.removeFile && !props.hideRemoveIcon && (
            <Popconfirm
              title="Confirm delete?"
              onConfirm={(e) => props.removeFile()}
              okText="Yes"
              cancelText="No"
            >
              <CloseCircleOutlined
                // onClick={(e) => props.removeFile()}
                className="remove-image"
              />
            </Popconfirm>
          )}
        </span>
      )}

      {contentType == "pdf" && (
        <span>
          {/* <Modal
          visible={fullScreenModal}
          className="pdf-full-screen-modal"
          footer={false}
          closable={false}
          centered={true}
          >
          {pdfToRender}
          </Modal> */}
          {pdfToRender && pdfToRender}
          {props.removeFile && !props.hideRemoveIcon && (
            <Popconfirm
              title="Confirm delete?"
              onConfirm={(e) => props.removeFile()}
              okText="Yes"
              cancelText="No"
            >
              <CloseCircleOutlined
                // onClick={(e) => props.removeFile()}
                className="remove-pdf"
              />
            </Popconfirm>
          )}
        </span>
      )}
      {/* 
      {contentType == "audio" && (
        <span>
          <ReactPlayer
            url={fileURL}
            controls={true}
            // width={'250px'}
            height={"50px"}
            className={"audio-player"}
            playing={activeMediaPlaying}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  preload: "metadata",
                },
                forceAudio: true,
              },
            }}
            onPlay={(e) => handlePlayPause(e)}
            onDuration={(duration) => {
              console.log("Audio Player duration is ==>", duration);
            }}
            onReady={(e) => {
              console.log("This audio tag is ready now", e, e.getDuration());
            }}
          />
          {props.removeFile && !props.hideRemoveIcon && (
            <Popconfirm
              title="Confirm delete?"
              onConfirm={(e) => props.removeFile()}
              okText="Yes"
              cancelText="No"
            >
              <AiOutlineClose
                // onClick={(e) => props.removeFile()}
                className="remove-audio"
              />
            </Popconfirm>
          )}
        </span>
      )} */}
    </div>
  );
};
export default MediaShow;
