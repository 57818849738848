import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux";
import { actions as patientDisorderActions } from "./redux";
import { actions as disorderActions } from "../../Disorders/redux";
import { patientDisorderSelector } from "./selector";
import PatientDisorderItemDetail from "./PatientDisorderItemDetail";
import DisorderItemView from "./DisorderItemView";

const PrintForm = (props) => {
  const { match } = props;

  let patientId = match.params && match.params.id;
  let patientDisorderId = match.params && match.params.form_id;

  //   let formDisorderId = patientDisorderId.match(/\,/g);

  // //   if (formDisorderId !== null) {
  // //     var array = JSON.parse("[" + patientDisorderId + "]");
  // //     // formId = [array];
  // //     patientDisorderId = [array];
  // //   } else {
  // //     patientDisorderId = patientDisorderId;
  // //   }

  const dispatch = useDispatch();
  useEffect(() => {
    if (patientId) {
      dispatch(actions.show({ id: patientId }));
      dispatch(patientDisorderActions.show({ id: patientDisorderId }));
    }
    setTimeout(() => {
      window.print();
    }, 5000);
  }, []);
  const disorders = useSelector(patientDisorderSelector());
  let finalDisorder =
    disorders[0] &&
    disorders[0].response_json &&
    disorders[0].response_json.disorder_dependents[0] &&
    disorders[0].response_json.disorder_dependents[0].subSectionsList &&
    disorders[0].response_json.disorder_dependents[0].subSectionsList.length >
      0 &&
    disorders[0].response_json.disorder_dependents[0].subSectionsList.map(
      (item) => {
        if (item.widget === "string") {
          item.response = "";
        } else if (item.widget === "RadioGroupWidget") {
          item.options.map((optionItem) => (optionItem.checked = false));
        } else if (item.widget === "CheckboxGroupWidget") {
          item.options.map((optionItem) => (optionItem.checked = false));
        } else if (item.widget === "UploadWidget") {
          item.fileObject = {};
        }
      }
    );

  return (
    <>
      {disorders && (
        <DisorderItemView
          formData={
            disorders[0] && disorders[0].response_json.disorder_dependents
          }
          disorderArr={patientDisorderId[0]}
          print={true}
        />
      )}
    </>
  );
};

export default PrintForm;
