import React from "react";
import { MrCrudView } from "mr_react_framework";
// import MrCrudView from "../../lib/utils/MrCrudView/MrCrudView";
// import { HospitalList, HospitalColumns } from "./HospitalList/HospitalList";
import NewBasicCRUDLayout from "../../components/Layouts/BasicCRUDLayout/NewBasicCRUDLayout";
import NewBasicFormLayout from "../../components/Layouts/BasicCRUDLayout/BasicCRUDLayout";
import HospitalHeader from "../Hospital/HospitalHeader";
import SelectSettings from "../AppConstants/SelectSettings/SelectSettings";
// import HospitalFilter from './HospitalFilter/HospitalFilter'
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import SelectOrg from "../../components/AppSpecific/SelectOrg/SelectOrg";
import commonSelectors from "../../App/commonSelectors";
import { actions } from "./redux";

import  HospitalList  from "../Hospital/HospitalList/HospitalList";
import  HospitalItemDetail  from "../Hospital/HospitalItemDetail/HospitalItemDetail";




defineMessages({
  keyName: {
    id: "hospitalForm.keyName",
    defaultMessage: "Key Name"
  },
  desc: {
    id: "hospitalForm.desc",
    defaultMessage: "Desc"
  }
});

const returnHospitalsForm = (props = {}) => {
  // const keyName = intl.formatMessage({
  //   id: "appConstantsForm.keyName"
  // });
  // const desc = intl.formatMessage({
  //   id: "appConstantsForm.desc"
  // });
  // console.log("intl", intl);
   
  let hospitalForm = {
    schema: {
      type: "object",
      properties: {        
        title : {
          type: "string",
          // default: "",
          title: "Hospital Name"
          // span: 8,
        },
        contact_name: {
          type: "string",
          default: " ",
          title: "Contact Name",
          // span: 8
        },
        contact_email: {
          type: "string",
          default: " ",
          title: "Email",
          // span: 8
        },
        contact_no: {
          type: "string",
          default: " ",
          title: "Contact No.",
          span: 8
        },
        // contact_no: {
        //   type: "string",
        //   // defaultValue: "",
        //   // elementType: "input",
        //   title: "Contact No.",
        //   // span: 8
        // },
        address: {
          type: "string",
          default: " ",
          title: "Address",
          // span: 8
        },
        city: {
          type: "string",
          default: " ",
          title: "City",
          // span: 8
        },
        state: {
          type: "string",
          default: " ",
          title: "State",
          // span: 8
        }, 
        // settings: {
        //   type: "string",
        //   title: "Settings",
        //   mrComponent: SelectSettings,
        //   display: false,
        // },
      }
    },
    uiSchema: {
      // settings: {
      //   "ui:widget": "mrComponent",
      //   "ui:options": {
      //     valueKey: "text_value",
      //     textKey: "text_value",
      //     isFormItem: false,
      //   }
      // }
    }
  };

  return hospitalForm;
};

const Hospitals = MrCrudView({
  resourceName: "hospitals",
  singleResourceName: "hospital",
  displayName: "Create/Edit Hospital",
  resourceUrl: "/hospitals",
  // layout: NewBasicCRUDLayout,
  isRoute: true,
  // formLayout: NewBasicFormLayout,
  /// layout: BlankLayout,
  //filter: HospitalFilter,
  // tableColumns:HospitalColumns,
  // listItem: HospitalList,
  forms: [{ name: "hospitals", form: returnHospitalsForm, config: {isModal: true, modalConfig: {width: "1000px", wrapClassName: "patient-modal"}} }],
  selectorsObj: {
    ...commonSelectors
  },
  actions,
  // listHeader: <HospitalHeader />,
  actionNames: ["fetch", "create", "update", "delete", "show"],
  config: {
    itemPreview: {
      widget: HospitalList
      // use if we want cards
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: HospitalItemDetail
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
    },
    core: {
      setQueryParams: false,
    },
    list: {
      // use if we want custom table
      show: true,
      type: "table/list/custom",
      widget: HospitalList,
      forceReload: true

    }
  },

  // firstTimeFetchParams: { with_users: true }
});

export default Hospitals;
