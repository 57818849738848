import React from "react";
import { MrCrudView } from "mr_react_framework";

import { actions } from "./redux";

import OrgListItem from "./OrgListItem/OrgListItem";
import OrgsFilter from "./OrgsFilter/OrgsFilter";
import WrappedMrForm from "../../lib/utils/MrForm/MrForm";

// import MrReduxCrud from '../../lib/utils/MrReduxCrud/MrReduxCrud';
const returnForm = () => {
  let orgsForm = {
    name: {
      defaultValue: "",
      elementType: "input",
      group: 1,
      label: "Name",
      validationRules: [{ required: true }],
      placeholder: "Name"

      // prefix: {
      //   type: "element",
      //   element: <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
      // }
    },
    lastName: {
      defaultValue: "",
      elementType: "input",
      group: 1,
      label: "Last Name",
      validationRules: [{ required: true }],
      placeholder: "Last Name"

      // prefix: {
      //   type: "element",
      //   element: <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
      // }
    },
    settings: {
      // defaultValue: {},
      elementType: "json",
      group: 1,
      label: "Settings",
      validationRules: [{ required: true }],
      placeholder: "Settings"

      // prefix: {
      //   type: "element",
      //   element: <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
      // }
    }
  };
  return orgsForm;
};


const Orgs = MrCrudView({
  resourceName: "orgs",
  singleResourceName: "org",
  displayName: "Organizations",
  resourceUrl: "/orgs",
  /// layout: BlankLayout,
  // filter: OrgsFilter,
  listItem: OrgListItem,
  forms: [{ name: "orgs", form: returnForm }],
  actions,
  actionNames: ["fetch", "create", "update", "delete"]
  // firstTimeFetchParams: { with_users: true }
});
export default Orgs;
