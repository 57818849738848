import { createSelector, } from "reselect";

const hospitalsState = (state, props) => {
    return state.hospitals.get("hospitals");
};

export const hospitalsSelector = () => createSelector([hospitalsState,], hospitals => hospitals);

const loadingState = (state, props) => {
    return state.hospitals.get("loading");
};

export const loadingSelector = () =>
    createSelector([loadingState,], loading => loading);

const errorState = (state, props) => {
    return state.hospitals.get("error") !== null;
};

export const errorSelector = () => createSelector([errorState,], error => error);