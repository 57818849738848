import React, { Component, PureComponent } from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import "./CustomInput.scss";
import CardsList from "./Components/CardsList";
import FloatingButtons from "./Components/FloatingButtons";
import { getUniqueId, getIndexofObject } from "../../lib/utils/helperMethods";

class CustomInput extends PureComponent {
  static whyDidYouRender = true;

  constructor(props) {
    super(props);
    this.state = {
      sections: { disorders: [] },
      sectionsComponentList: [],
      addButtonVisible: true,
      mainAddButton: true,
      dragToggle: false,
    };
    this.inputsWrapperRef = React.createRef();
    this.inputsInnerWrapperRef = React.createRef();
    this.dragIndex = 0;
    this.dropIndex = 0;
    this.dragInnerIndex = 0;
    this.dropInnerIndex = 0;
  }

  componentDidMount() {
    if (this.props.value !== undefined) {
      console.log("this.props==>", this.props);
      this.setState({
        sections: this.props.value,
      });
    } else {
      this.addSection();
    }
  }

  addButtonStateChange = (value, id, childId) => {
    //console.log("add button ==>",value, id, childId);
    if (childId !== undefined) {
      const stateObj = [...this.state.sections.disorders];

      // stateObj[id]["subSectionsList"][childId]["addButtonVisible"] = value;

      stateObj[id] = {
        ...stateObj[id],
        subSectionsList: [...stateObj[id]["subSectionsList"]],
      };

      stateObj[id]["subSectionsList"][childId] = {
        ...stateObj[id]["subSectionsList"][childId],
        addButtonVisible: value,
      };

      this.setState({
        sections: { disorders: stateObj },
      });
    } else {
      const stateObj = [...this.state.sections.disorders];
      //stateObj[id]["addButtonVisible"] = value;

      stateObj[id] = {
        ...stateObj[id],
        addButtonVisible: value,
      };

      this.setState({
        sections: { disorders: stateObj },
      });
    }
  };

  addOption = (id, childId) => {
    const uniquekey = getUniqueId(); //`${Math.floor(Math.random() * 10000) + 1}`;
    const stateObj = [...this.state.sections.disorders];
    const currentItem = stateObj[id].subSectionsList[childId].options;
    const objToPush = {
      id: currentItem.length,
      uniqueId: uniquekey,
      title: "",
    };
    stateObj[id].subSectionsList[childId].options.push(objToPush);
    this.setState({
      sections: { disorders: stateObj },
    });
  };

  renderSelectedItem = (value, id, nestedId) => {
    if (value.length !== 0) {
      if (
        value === "RadioGroupWidget" ||
        value === "CheckboxGroupWidget" ||
        value === "SelectWidget"
      ) {
        const uniquekey = getUniqueId(); //`${Math.floor(Math.random() * 10000) + 1}`;
        const stateObj = [...this.state.sections.disorders];
        stateObj[id].subSectionsList[nestedId].options = [];
        const currentItem = stateObj[id].subSectionsList[nestedId].options;
        const objToPush = {
          id: currentItem.length,
          title: "",
          uniqueId: uniquekey,
        };
        stateObj[id].subSectionsList[nestedId].options.push(objToPush);
        this.setState({
          sections: { disorders: stateObj },
        });
      } else if (value === "string") {
        const stateObj = [...this.state.sections.disorders];
        stateObj[id].subSectionsList[nestedId].options = [];
        this.setState({
          sections: { disorders: stateObj },
        });
        return "string";
      } else if (value === "InputWithTags") {
        const stateObj = [...this.state.sections.disorders];
        stateObj[id].subSectionsList[nestedId].options = [];
        this.setState({
          sections: { disorders: stateObj },
        });
        return "InputWithTags";
      } else if (value === "number") {
        const stateObj = [...this.state.sections.disorders];
        stateObj[id].subSectionsList[nestedId].max = "";
        stateObj[id].subSectionsList[nestedId].min = "";
        this.setState({
          sections: { disorders: stateObj },
        });
        console.log("final==>", this.state.sections);
      }
    } else {
    }
  };

  onInputChange = _.debounce(
    (element, parentId, childId, nestedChildId, isCheckbox) => {
      if (isCheckbox) {
        this.onCheckBoxChecked(
          element,
          parentId,
          childId,
          nestedChildId,
          isCheckbox
        );
      } else if (nestedChildId !== undefined) {
        const stateObj = [...this.state.sections.disorders];
        stateObj[parentId] = {
          ...stateObj[parentId],
          subSectionsList: [...stateObj[parentId]["subSectionsList"]],
        };
        stateObj[parentId]["subSectionsList"][childId] = {
          ...stateObj[parentId]["subSectionsList"][childId],
          options: [...stateObj[parentId]["subSectionsList"][childId].options],
        };
        stateObj[parentId]["subSectionsList"][childId].options[
          nestedChildId
        ] = {
          ...stateObj[parentId]["subSectionsList"][childId].options[
          nestedChildId
          ],
          [element.name]: element.value,
        };
        this.setState(
          {
            sections: { disorders: stateObj },
          },
          () => {
            this.props.onChange(this.state.sections);
          }
        );

        // );
      } else if (childId !== undefined) {
        //============= selection change ==================

        console.log("selection change=====>");

        this.onSelectBoxChange(
          element,
          parentId,
          childId,
          nestedChildId,
          isCheckbox
        );
      } else {
        console.log("false=======>");

        const stateObj = [...this.state.sections.disorders];
        stateObj[parentId] = {
          ...stateObj[parentId],
        };
        stateObj[parentId] = {
          ...stateObj[parentId],
          [element.name]: element.value,
        };
        this.setState(
          {
            sections: { disorders: stateObj },
          },
          () => {
            this.props.onChange(this.state.sections);
          }
        );
      }
    },
    750
  );

  onSelectBoxChange = _.debounce(
    (element, parentId, childId, nestedChildId, isCheckbox) => {
      if (childId !== undefined) {
        console.log("on selection change------>", element.name, element.value);
        const stateObj = [...this.state.sections.disorders];
        const currentUserIndex = _.findIndex(
          stateObj[parentId].subSectionsList,
          (item) => item.id === childId
        );
        stateObj[parentId] = {
          ...stateObj[parentId],
          subSectionsList: [...stateObj[parentId]["subSectionsList"]],
        };
        stateObj[parentId]["subSectionsList"][currentUserIndex] = {
          ...stateObj[parentId]["subSectionsList"][currentUserIndex],
          [element.name]: element.value,
        };

        this.setState(
          {
            sections: { disorders: stateObj },
          },
          () => {
            console.log(
              "section title ==>",
              this.state.sections.disorders[parentId]
            );
            this.props.onChange(this.state.sections);
          }
        );
      }
    },
    500
  );

  onCheckBoxChecked = (
    element,
    parentId,
    childId,
    nestedChildId,
    isCheckbox
  ) => {
    const stateObj = [...this.state.sections.disorders];
    stateObj[parentId] = {
      ...stateObj[parentId],
      subSectionsList: [...stateObj[parentId]["subSectionsList"]],
    };
    stateObj[parentId]["subSectionsList"][childId] = {
      ...stateObj[parentId]["subSectionsList"][childId],
      [element.target.name]: element.target.checked,
    };
    this.setState(
      {
        sections: { disorders: stateObj },
      },
      () => {
        this.props.onChange(this.state.sections);
      }
    );
  };

  addSubSection = (isHeading, id, position) => {
    const current = _.findIndex(this.state.sections.disorders, function (user) {
      return user.id === id;
    });
    const currentItem = this.state.sections.disorders[current];
    let newId;
    if (currentItem.subSectionsList.length === 0) {
      newId = 0;
    } else {
      newId = currentItem.subSectionsList.length;
    }

    let uniquekey = getUniqueId();
    console.log("uniquekey==>", uniquekey);

    if (isHeading) {
      const headingObj = {
        id: newId,
        uniqueId: uniquekey,
        type: "string",
        span: 24,
        is_sub_heading: true,
        sub_heading_title: "",
        addButtonVisible: true,
        className: "m-b-0 sub-heading",
        dependsOnIndex: "",
        dependsOnValue: "",
        dependsOnCondition: "",
      };
      const stateObj = [...this.state.sections.disorders];
      stateObj[current].subSectionsList.splice(position, 0, headingObj);

      stateObj[current].subSectionsList.map(
        (item, index) => (item["id"] = index)
      );

      stateObj.map((item) => {
        item.addButtonVisible = true;
        item.subSectionsList.map(
          (nestedItem) => (nestedItem.addButtonVisible = true)
        );
      });
      stateObj.map((item) => {
        item.subSectionsList.map(
          (nestedItem) => (nestedItem.addButtonVisible = true)
        );
      });
      //currentItem.subSectionsList.push(headingObj);
      this.setState({
        sections: { disorders: stateObj },
      });
    } else {
      const subSectionObj = {
        id: newId,
        uniqueId: uniquekey,
        widget: "",
        title: "",
        type: "string",
        required: false,
        minMaxEnabled: false,
        span: "",
        setInitialValue: true,
        addButtonVisible: true,
        options: [],
        dependsOnIndex: "",
        dependsOnValue: "",
        dependsOnCondition: "",
      };

      //==========================

      const stateObj = [...this.state.sections.disorders];

      stateObj[current] = {
        ...stateObj[current],
      };

      stateObj[current].subSectionsList.splice(position, 0, subSectionObj);

      //=============================================

      stateObj[current].subSectionsList.map(
        (item, index) => (item["id"] = index)
      );

      stateObj.map((item) => {
        item.addButtonVisible = true;
        item.subSectionsList.map(
          (nestedItem) => (nestedItem.addButtonVisible = true)
        );
      });

      stateObj.map((item) => {
        item.subSectionsList.map(
          (nestedItem) => (nestedItem.addButtonVisible = true)
        );
      });

      // currentItem.subSectionsList.push(subSectionObj);
      this.setState({
        sections: { disorders: stateObj },
      });
    }
  };

  addAfter = (array, index, newItem) => {
    return [...array.slice(0, index), newItem, ...array.slice(index)];
  };

  addCondition = (
    currentSectionId,
    childrenId,
    value,
    condition,
    dependsOnIndex
  ) => {
    const stateObj = [...this.state.sections.disorders];
    const parentIndex = _.findIndex(stateObj, function (item) {
      return item.id === currentSectionId;
    });
    const childIndex = _.findIndex(
      stateObj[parentIndex].subSectionsList,
      (item) => item.id === childrenId
    );
    console.log("addCondition==>",dependsOnIndex);
    stateObj[parentIndex]["subSectionsList"][childIndex][
      "dependsOnIndex"
    ] = dependsOnIndex;
    stateObj[parentIndex]["subSectionsList"][childIndex][
      "dependsOnValue"
    ] = value;
    stateObj[parentIndex]["subSectionsList"][childIndex][
      "dependsOnCondition"
    ] = condition;
    this.setState(
      {
        sections: { disorders: stateObj },
      },
      () => {
        this.props.onChange(this.state.sections);
      }
    );
  };

  addSection = () => {
    this.scrollToBottom();
    let newId;

    if (this.state.sections.disorders.length === 0) {
      newId = 0;
    } else {
      newId = this.state.sections.disorders.length;
    }

    const uniquekey = getUniqueId(); //`${Math.floor(Math.random() * 10000) + 1}`;

    const newSection = {
      id: newId,
      uniqueId: uniquekey,
      title: ``,
      subSectionsList: [],
      addButtonVisible: true,
    };

    const stateObj = [...this.state.sections.disorders, newSection];

    this.setState(
      {
        sections: { disorders: [...this.state.sections.disorders, newSection] }, //[...this.state.sections.disorders, newSection],
      },
      () => {
        this.props.onChange(this.state.sections);
      }
    );
  };
  deleteSection = (id, childId, nestedChildId) => {
    if (nestedChildId !== undefined) {
      const stateObj = this.state.sections.disorders;
      stateObj[id].subSectionsList[childId].options.splice(nestedChildId, 1);
      stateObj[id].subSectionsList[childId].options.map(
        (item, index) => (item["id"] = index)
      );
      this.setState({
        sections: { disorders: stateObj },
      });
    } else if (childId !== undefined) {
      //============= its for heading delete and sub section =============//

      const stateObj = this.state.sections.disorders;
      stateObj[id].subSectionsList.splice(childId, 1);

      stateObj[id].subSectionsList.map((item, index) => (item["id"] = index));

      this.setState({
        sections: { disorders: stateObj },
      });
    } else {
      //===================== its for section ======================//

      const stateObj = [...this.state.sections.disorders];
      const newStateObj = stateObj.filter((item) => item.id !== id);

      console.log("newStateObj==>", newStateObj);

      newStateObj.map((item, index) => (item["id"] = index));

      this.setState(
        {
          sections: { disorders: newStateObj },
        },
        () => {
          this.props.onChange(this.state.sections);
        }
      );
    }
  };

  swapItems(arr, dragIndex, dropIndex) {
    let temp = arr[dragIndex];
    arr[dragIndex] = arr[dropIndex];
    arr[dropIndex] = temp;

    return arr;
  }

  changeBorderColor(Ref, borderColor) {
    const inputWrapperNode = document.getElementById(`${Ref}`);
    if (inputWrapperNode) {
      inputWrapperNode.style.border = borderColor;
      inputWrapperNode.style.opacity = 1.0;
    }
  }

  drag = (e) => {
    e.dataTransfer.setData("sectionId", e.target.id);
    this.changeBorderColor(e.target.id, "2px dashed #999");
  };

  drop = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("sectionId");

    const currentInnerBoxId = data.split("-")[1];

    if (data === e.currentTarget.id) return;

    if (currentInnerBoxId === "inner") {
      const dragSectionIndex = e.dataTransfer
        .getData("sectionId")
        .split("-")[3];
      const dropSectionIndex = e.currentTarget.id.split("-")[1];
      const dragInnerSectionIndex = e.dataTransfer
        .getData("sectionId")
        .split("-")[4];

      const stateObj = [...this.state.sections.disorders];
      if (stateObj[dropSectionIndex].subSectionsList.length > 0) return;

      const dragInnerSectionIndexItem =
        stateObj[dragSectionIndex].subSectionsList[dragInnerSectionIndex];

      stateObj[dropSectionIndex].subSectionsList.splice(
        0,
        0,
        dragInnerSectionIndexItem
      ); //### push the subSectionList item to diff section of subSectionList

      stateObj[dragSectionIndex].subSectionsList.splice(
        dragInnerSectionIndex,
        1
      ); //### remove the subSectionList drag item

      stateObj[dropSectionIndex].subSectionsList.map(
        (item, index) => (item["id"] = index)
      );

      stateObj[dragSectionIndex].subSectionsList.map(
        (item, index) => (item["id"] = index)
      );

      this.setState({
        sections: { disorders: stateObj },
      });
      return;
    }

    const inputWrapperNode =
      this.inputsWrapperRef && this.inputsWrapperRef.current;
    if (inputWrapperNode && inputWrapperNode.childNodes) {
      const nodeList = inputWrapperNode.childNodes;
      for (let i = 0; i < nodeList.length; i++) {
        if (nodeList[i].id === data) {
          this.dragIndex = i;
        }
        if (nodeList[i].id === e.currentTarget.id) {
          this.dropIndex = i;
        }
      }

      //setFieldValue(name, questions);
      const stateObj = this.swapItems(
        this.state.sections.disorders,
        this.dragIndex,
        this.dropIndex
      );

      stateObj.map((item, index) => (item["id"] = index));

      this.setState(
        {
          sections: { disorders: stateObj },
        },
        () => { }
      );
    }
  };

  allowDrop = (e) => {
    e.preventDefault();
  };

  dragInnerItem = (e) => {
    e.dataTransfer.setData("inner_id", e.target.id);
    this.changeBorderColor(e.target.id, "2px dashed #999");
  };

  dropInnerItem = (dropResult) => {
    if (
      dropResult.addedIndex == null ||
      dropResult.removedIndex == null ||
      dropResult.addedIndex === dropResult.removedIndex
    )
      return;

    console.log("drop inner==>", dropResult);

    const stateObj = [...this.state.sections.disorders];

    const currentInnerBoxId = getIndexofObject(stateObj, dropResult.payload);
    console.log("find index is ", currentInnerBoxId);

    const swapStateObj = this.swapItems(
      stateObj[currentInnerBoxId].subSectionsList,
      dropResult.removedIndex,
      dropResult.addedIndex
    );

    swapStateObj.map((item, index) => (item["id"] = index));
    stateObj[currentInnerBoxId].subSectionsList = swapStateObj;

    this.setState(
      {
        sections: { disorders: stateObj },
      },
      () => { }
    );

    // const stateObj = [...this.state.sections.disorders];

    // const currentUserIndex = _.findIndex(
    //   stateObj[parentId].subSectionsList,
    //   (item) => item.id === childId
    // );

    // e.preventDefault();
    // const data = e.dataTransfer.getData("inner_id");

    // if (data === e.currentTarget.id) return;

    // const dragSectionIndex = e.dataTransfer.getData("sectionId").split("-")[3];
    // const dropSectionIndex = e.currentTarget.id.split("-")[3];

    // const currentInnerBoxId = data.split("-")[3];
    // const inputWrapperNode = document.getElementById(
    //   `box-inner-${currentInnerBoxId}`
    // );

    // if (
    //   inputWrapperNode &&
    //   inputWrapperNode.childNodes &&
    //   dragSectionIndex == dropSectionIndex
    // ) {
    //   //############# if same section , drag and drop inner section ######///

    //   const nodeList = inputWrapperNode.childNodes;
    //   for (let i = 0; i < nodeList.length; i++) {
    //     if (nodeList[i].id === data) {
    //       this.dragInnerIndex = i;
    //     }
    //     if (nodeList[i].id === e.currentTarget.id) {
    //       this.dropInnerIndex = i;
    //     }
    //   }

    //   const stateObj = this.swapItems(
    //     this.state.sections.disorders[currentInnerBoxId].subSectionsList,
    //     this.dragInnerIndex,
    //     this.dropInnerIndex
    //   );

    //   stateObj.map((item, index) => (item["id"] = index));

    //   const sectionObj = this.state.sections.disorders;
    //   sectionObj[currentInnerBoxId].subSectionsList = stateObj;

    //   this.setState(
    //     {
    //       sections: { disorders: sectionObj },
    //     },
    //     () => {}
    //   );
    // } else if (
    //   inputWrapperNode &&
    //   inputWrapperNode.childNodes &&
    //   dragSectionIndex != dropSectionIndex
    // ) {
    //   //############# if DIFFERENT section , drag and drop inner section ######///

    //   const dragInnerSectionIndex = e.dataTransfer
    //     .getData("sectionId")
    //     .split("-")[4];
    //   const dropInnerSectionIndex = e.currentTarget.id.split("-")[4];

    //   const stateObj = [...this.state.sections.disorders];
    //   const dragInnerSectionIndexItem =
    //     stateObj[dragSectionIndex].subSectionsList[dragInnerSectionIndex];

    //   stateObj[dropSectionIndex].subSectionsList.splice(
    //     dropInnerSectionIndex,
    //     0,
    //     dragInnerSectionIndexItem
    //   ); //### push the subSectionList item to diff section of subSectionList

    //   stateObj[dragSectionIndex].subSectionsList.splice(
    //     dragInnerSectionIndex,
    //     1
    //   ); //### remove the subSectionList drag item

    //   stateObj[dropSectionIndex].subSectionsList.map(
    //     (item, index) => (item["id"] = index)
    //   );

    //   stateObj[dragSectionIndex].subSectionsList.map(
    //     (item, index) => (item["id"] = index)
    //   );

    //   this.setState({
    //     sections: { disorders: stateObj },
    //   });
    // }
  };

  allowDropInnerItem = (e) => {
    e.preventDefault();
    //this.changeBorderColor(e.target.id,"2px dashed #999");
  };

  leaveDrop = (e) => {
    this.changeBorderColor(e.target.id, "1px solid #F0F0F0");
  };
  leaveDropInner = (e) => {
    this.changeBorderColor(e.target.id, "1px solid #F0F0F0");
  };

  onDragEnter = (e) => {
    //this.changeBorderColor(e.target.id,"2px dashed #228B22");
  };

  onOuterDrop = (dropResult) => {
    const { removedIndex, addedIndex, payload, element } = dropResult;
    console.log("my name is the best==>", dropResult);

    const stateObj = this.swapItems(
      this.state.sections.disorders,
      removedIndex,
      addedIndex
    );

    stateObj.map((item, index) => (item["id"] = index));

    this.setState(
      {
        sections: { disorders: stateObj },
      },
      () => {
        console.log("swapped==>", this.state.sections.disorders);
      }
    );
  };

  enableDrag = () => {
    this.setState({
      dragToggle: !this.state.dragToggle,
    });
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    return (
      <>
        {/* {JSON.stringify(this.state.sections.disorders)} */}
        <FloatingButtons
          addSection={this.addSection}
          enableDrag={this.enableDrag}
          dragToggle={this.state.dragToggle}
        />
        <Row>
          <Col span={24}>
            <div ref={this.inputsWrapperRef}>
              {this.state.sections.disorders && (
                <CardsList
                  data={this.state.sections.disorders}
                  onInputChange={this.onInputChange}
                  dragToggle={this.state.dragToggle}
                  deleteSection={this.deleteSection}
                  addButtonStateChange={this.addButtonStateChange}
                  onOuterDrop={this.onOuterDrop}
                  onDragStart={this.drag}
                  onDrop={this.drop}
                  onDragOver={this.allowDrop}
                  onDragEnd={this.leaveDrop}
                  onDragEnter={this.onDragEnter}
                  inputsInnerWrapperRef={this.inputsInnerWrapperRef}
                  dragInnerItem={this.dragInnerItem}
                  dropInnerItem={this.dropInnerItem}
                  allowDropInnerItem={this.allowDropInnerItem}
                  leaveDropInner={this.leaveDropInner}
                  renderSelectedItem={this.renderSelectedItem}
                  addOption={this.addOption}
                  addSubSection={this.addSubSection}
                  addCondition={this.addCondition}
                />
              )}
            </div>
          </Col>
        </Row>
        <div
          ref={(el) => {
            this.messagesEnd = el;
          }}
        ></div>
      </>
    );
  }
}

export default CustomInput;
