import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import update from "immutability-helper";
import { makeAxiosInstance } from "../../api/apiModule";

export const axiosInstance = makeAxiosInstance();
const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "app_constant",
  actionNames: ["FETCH", "CREATE", "UPDATE", "DELETE"]
});

const initialState = fromJS({
  app_constants: [],
  page: 1,
  total_pages: 10,
  total_count: 10,
  page_size: 10,
  error: null,
  loading: false,
});

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
// export const reducer = reduxCrud.getReducer(initialState, {
//   UPDATE_LEAD_ACTIONS: updateChildCreator("action"),
//   UPDATE_LEAD_LOGS: updateChildCreator("log")
// });
export const reducer = reduxCrud.getReducer(initialState);
export const watchAppConstants = reduxCrud.generateWatchSaga();

export default reduxCrud;
