import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import { buildOptionsArr, buildSchemaObject } from "../../lib/utils/helperMethods";

export const encephalitisForm = {
  history: {
    title: "History",
    fields: {
      type: "object",
      properties: {
        
        gender: {
          title: "Gender",
          type: "string",
          widget: "RadioGroupWidget",
          span: 24,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Male','Female','Other'])
          },
        },
        age_radio: {
          title: "Age greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        age_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_radio"],
          children: ({ getFieldValue, sourceValues }) => {
            // console.log("chilren, line 42");
            // console.log("sourceValues", sourceValues);
            const nameVal = getFieldValue(["disorder_information", "age_radio"]);
            // console.log("nameVal", nameVal);

            let finalRender = null;
            
            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_options", title: "Age in years", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />

              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in years" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in years" />
              //   </Form.Item>
              // );
            } else if (nameVal === "No" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "No")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_options", title: "Age in months", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in months" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in months" />
              //   </Form.Item>
              // );
            }
            return finalRender;
            // return <React.Fragment>{finalRender}</React.Fragment>;
          },
        },
        

        onset: {
          title: "Onset",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Acute', 'Sub-acute', 'Chronic'])
          },
        },


        fever: {
          title: "Fever",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        prodrome_illness: {
          title: "Prodrome illness",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        headache: {
          title: "Headache",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        raised_ipc: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "raised_ipc", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Raised ICP</p>
        },


        vomiting: {
          title: "Vomiting",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        blurring_of_vision: {
          title: "Blurring of vision",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        diplopia: {
          title: "Diplopia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        altered_sensorium: {
          title: "Altered sensorium",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        seizures: {
          title: "Seizures",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        seizure_semiology: {
          title: "Seizure semiology",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Generalised', 'Focal', 'Unknown'])
          },
        },
        course: {
          title: "Course",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Progressive', 'Non-progressive'])
          },
        },
        immunisation_history: {
          title: "Immunisation history",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Complete', 'Incomplete'])
          },
        },
        
        // Recent immunisation

        recent_immunisation: {
          title: "Recent immunisation",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        history_of_recent_travel: {
          title: "History of recent travel",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        history_of_viral_ecephalits_epidemics: {
          title: "History of viral ecephalits epidemics",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        Head_trauma: {
          title: "Head trauma",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        contact_with_tb_patients: {
          title: "Contact with TB patients",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        history_to_suggest_immunosuppressed_state: {
          title: "History to suggest immunosuppressed state",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cranial_nerve_dysfunction: {
          title: "Cranial nerve dysfunction",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cranial_nerve_dysfunction_options: {
          title: "Others",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.cranial_nerve_dysfunction"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "cranial_nerve_dysfunction"]);
            let finalRender = null;
            
            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.cranial_nerve_dysfunction === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "cranial_nerve_dysfunction_options", title: "Others", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            } 
            return finalRender;
          },
        },

        
       
        motor_disturbances: {
          title: "Motor disturbances",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        history_to_suggest_movement_disorder: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "history_to_suggest_movement_disorder", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>History to suggest movement disorder</p>
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor: {
          title: "Tremor",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        Bladder_bowel_involvement: {
          title: "Bladder/bowel involvement",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        sensory_disturbances: {
          title: "Sensory disturbances",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        // Past history of similar illness

        past_history_of_similar_illness: {
          title: "Past history of similar illness",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        gcs: {
          type: "string",
          title: "GCS",
          span: 12,
          setInitialValue: true,
        },


        other_history: {
          type: "string",
          title: "Other history",
          span: 24,
          setInitialValue: true,
        },
  
      },
    },
  },

  general_examination: {
    title: "General Examinations",
    fields: {
      type: "object",
      properties: {
     
        neurocutaneous_markers: {
          title: "Neurocutaneous markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cyanosis: {
          title: "Cyanosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        lymphadenopathy: {
          title: "Lymphadenopathy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        other_ge: {
          title: "Others",
          type: "string",
          setInitialValue: true,
          // span: 8,
        },

        vitals: {
          title: "Vitals",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Stable','Unstable'])
          },
        },

        //anthropometry subheading....

        anthropometry: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Anthropometry", //using in show 
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Anthropometry</p>
        },
       
        weight: {
          title: "Weight",
          type: "number",
          span: 8,
          setInitialValue: true,
          className: "p-l-10",
        },
        height: {
          title: "Height",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        head_circumference: {
          title: "Head circumference",
          type: "number",
          span: 8,
          setInitialValue: true,
        },

        other_history_ge: {
          type: "string",
          title: "Other history",
          span: 24,
          setInitialValue: true,
        },

        //ends_anthropometry

      },
    },
  },

  neurologicalexamination: {
    title: "Neurological Examination",
    fields: {
      type: "object",
      properties: {

        //HMF subheading
        HMF: {
          // title: "HMF",
          is_sub_heading: true, //using in show view
          sub_heading_title: "HMF", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>HMF</p>
        },

        hyperactive: {
          title: "Hyperactive",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_attention: {
          title: "Poor attention",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        speech: {
          title: "Speech",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_eye_contact: {
          title: "Poor eye contact",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        // Cranial nerves deficit

        cranial_nerves_deficit: {
          title: "Cranial nerves deficit",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        
        //Motor system (sub heading)
        motor_system: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Motor System", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Motor System</p>
        },        

        bulk: {
          title: "Bulk",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Wasting', 'Hypertrophy'])
          },
        },

        tone_normal: {
          title: "Tone normal",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Hypotonia','Hypertonia', 'Variable'])
          },
        },

        power_charting: {
          title: "Power charting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['1','2','3','4','5'])
          },
        },

        deep_tendon_reflexes: {
          title: "Deep tendon reflexes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Areflexia', 'Hyporeflexia', 'Hyperreflexia'])
          },
        },

        plantar_responses: {
          title: "Plantar responses",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Flexor','Extensor', 'No response'])
          },
        },
        // motor system ends here


        //Extrapyramidal signs sub-heading
        extrapyramidal_signs: {
          // title: "Extrapyramidal signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Extrapyramidal Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Extrapyramidal Signs</p>
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_es: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Extrapyramidal signs ends (sub section)

        //Cerebellar signs starts (sub section)
        cerebellar_signs: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Cerebellar Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cerebellar signs</p>
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        titubation: {
          title: "Titubation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_cs: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        dysmetria: {
          title: "Dysmetria",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Cerebellar signs ends (sub section)

        // Spine/cranium
        spine_cranium: {
          title: "Spine/cranium",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        other_findings: {
          title: "Other findings",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        hearing_deficit: {
          title: "Hearing Deficit",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        Meningeal_signs: {
          title: "Meningeal signs",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        spinal_dysraphism: {
          title: "Spinal dysraphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        Neural_tube_defects: {
          title: "Neural tube defects",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        other_history_ne: {
          type: "string",
          title: "Other history",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },

  Investigations_section: {
    title: "Investigations",
    fields: {
      type: "object",
      properties: {


        cbc: {
          // title: "Neuroimaging",
          is_sub_heading: true, //using in show view
          sub_heading_title: "cbc", //using in show 
          type: "number",
          span: 24,
          setInitialValue: true,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>CBC</p>
        },


        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        polycythaemia: {
          title: "Polycythaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        leucopenia: {
          title: "Leucopenia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        leucocytosis: {
          title: "Leucocytosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        thrombocytopenia: {
          title: "Thrombocytopenia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        thrombocytosis: {
          title: "Thrombocytosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


       

        lft: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "lft", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>LFT</p>
        },

        // Elevated liver enzymes

        elevated_liver_enzymes: {
          title: "elevated liver enzymes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        hypoalbuminemia: {
          title: "Hypoalbuminemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        



        rft: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "rft", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>RFT</p>
        },


        creatinine: {
          title: "Creatinine",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Elevated', 'Normal'])
          },
        },

        dengue_serology: {
          title: "Dengue serology",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

        Dengue_ns1_antigen: {
          title: "Dengue NS1 antigen",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

        Ricketssial_work_up: {
          title: "Ricketssial work-up",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },
        Ricketssial_work_up_options: {
          title: "Others",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.Ricketssial_work_up"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "Ricketssial_work_up"]);
            let finalRender = null;
            
            if (nameVal === "Positive" || (!nameVal && sourceValues.id && sourceValues.disorder_information.Ricketssial_work_up === "Positive")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "Ricketssial_work_up_options", title: "Others", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            } 
            return finalRender;
          },
        },

        //add dependency

        hiv_screening: {
          title: "HIV screening",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

        cxr: {
          title: "CXR",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Abnormal'])
          },
        },


        mantoux_test: {
          title: "Mantoux test",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

        egg_findings: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "EEG Findings", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>EEG Findings</p>
        },



        state: {
          title: "State",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Sleep', 'Awake'])
          },
        },




        background_sleep_markers: {
          title: "Background/sleep markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Abnormal'])
          },
        },



        epileptiform_discharges: {
          title: "Epileptiform discharges",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },



        pleds: {
          title: "Pleds",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },


        ncse: {
          title: "NCSE",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },


        neuroimaging_findings: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "neuroimaging_findings", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neuroimaging Findings</p>
        },



        ct_brain: {
          title: "CT brain",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Plain', 'Contrast'])
          },
        },



        mri_brain: {
          title: "MRI Brain",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Plain', 'Contrast'])
          },
        },


     


        ct_findings: {
          title: "CT findings",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        mri_findings: {
          title: "MRI findings",
          type: "string",
          span: 12,
          setInitialValue: true,
        },


        mri_spine: {
          title: "MRI spine",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        csf_finding: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "csf_indings", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>CSF Findings</p>
        },


        cell_count: {
          title: "Cell count",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        sugar: {
          title: "Sugar",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        protein: {
          title: "Protein",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        c_s: {
          title: "C/S",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        viral_pcr: {
          title: "Viral PCR",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        tb_pcr: {
          title: "TB PCR",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        mgit_c_s: {
          title: "MGIT C/S",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        fungal_c_s: {
          title: "Fungal C/S",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        //AE panel sub-heading
        ae_panel: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "ae_panel", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>AE panel</p>
        },

        blood: {
          title: "Blood",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

        csf: {
          title: "CSF",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

        type_of_antibodies: {
          title: "Type of antibodies",
          type: "number",
          span: 12,
          setInitialValue: true,
        },



        //ae panel subheading ends

        // Immunodeficiency work-up

        immunodeficiency_work_up: {
          title: "Immunodeficiency work-up",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Primary', 'Secondary'])
          },
        },





        immunoglobulin_level: {
          title: "Immunoglobul in level",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        Lymphocyte_subset_analysis: {
          title: "Lymphocyte subset analysis",
          type: "number",
          span: 12,
          setInitialValue: true,
        },


        blood_C_S: {
          title: "Blood C/S",
          type: "number",
          span: 12,
          setInitialValue: true,
        },



        ps_for_mp: {
          title: "PS for MP",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        // Tumor surveillance

        tumor_surveillance: {
          title: "Tumor surveillance",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },

        tumor_surveillance_options: {
          title: "Others",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.tumor_surveillance"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "tumor_surveillance"]);
            let finalRender = null;
            
            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.tumor_surveillance === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "tumor_surveillance_options", title: "Others", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            } 
            return finalRender;
          },
        },

        //add dependency


        others_investigations: {
          title: "Others",
          type: "number",
          span: 24,
          setInitialValue: true,
        },
       
     
      },
    },
  },
 


  management: {
    title: "Management",
    fields: {
      type: "object",
      properties: {

        antibiotic_therapy: {
          type: "string",
          title: "Antibiotic therapy (duration in days)",
          span: 12,
          setInitialValue: true,
        },
        antibiotic_dose: {
          type: "string",
          title: "Antibiotic dose (mg/kg/day)",
          span: 12,
          setInitialValue: true,
        },
        anti_viral_therapy: {
          type: "string",
          title: "  Anti-viral therapy (Duration in days)",
          span: 12,
          setInitialValue: true,
        },

        anti_viral_therapy_dose: {
          type: "string",
          title: " Anti viral therapy dose (mg/kg/day)",
          span: 12,
          setInitialValue: true,
        },

        requirement_of_mechanical_ventilation: {
          title: "Requirement of mechanical ventilation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },


        immunosuppressive_therapy: {
          title: "Immunosuppressive therapy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['IVMP', 'IVIG', 'Rituximab', 'Tocilizumab', 'Cyclophosphamide', 'Azathioprine', 'Mycophenolate'])
          },
        },


        plasmapheresis: {
          title: "Plasmapheresis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },


        other_drugs: {
          type: "string",
          title: " Other drugs",
          span: 24,
          setInitialValue: true,
        }


        


      
     
      },
    },
  },

  outcome_section: {
    title: "Outcome",
    fields: {
      type: "object",
      properties: {

        duration_of_follow_up: {
          type: "string",
          title: "Duration of follow up (in months)",
          span: 12,
          setInitialValue: true,
        },

        mrs: {
          type: "string",
          title: "MRS",
          span: 12,
          setInitialValue: true,
        },

       

        cognitive_outcome: {
          title: "Cognitive outcome",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Learning disbilities', 'Behavioural symptoms'])
          },
        },

        

        vision_deficits: {
          title: "Vision deficits",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },

        hearing_defiicits: {
          title: "Hearing defiicits",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },

        sleep_problems: {
          title: "Sleep problems",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },
        behavioural_symptoms: {
          title: "Behavioural symptoms",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },

        epilepsy: {
          title: "Epilepsy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes', 'No'])
          },
        },
      },
    },
  },



  



};