import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import {
  buildOptionsArr,
  buildSchemaObject,
} from "../../lib/utils/helperMethods";

export const neuromuscularForm = {
  history: {
    title: "History",
    fields: {
      type: "object",
      properties: {
        uhid: {
          title: "UHID",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        address: {
          title: "Address",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        contact_no: {
          title: "Contact Number",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        gender: {
          title: "Gender",
          type: "string",
          widget: "RadioGroupWidget",
          span: 24,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Male", "Female", "Other"]),
          },
        },
        age_radio: {
          title: "Age greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        age_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_radio"],
          children: ({ getFieldValue, sourceValues }) => {
            // console.log("chilren, line 42");
            // console.log("sourceValues", sourceValues);
            const nameVal = getFieldValue([
              "disorder_information",
              "age_radio",
            ]);
            // console.log("nameVal", nameVal);

            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_radio === "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_options",
                    title: "Age in years",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );

              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in years" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in years" />
              //   </Form.Item>
              // );
            } else if (
              nameVal === "No" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_radio === "No")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_options",
                    title: "Age in months",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in months" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in months" />
              //   </Form.Item>
              // );
            }
            return finalRender;
            // return <React.Fragment>{finalRender}</React.Fragment>;
          },
        },

        age_at_symptom_onset: {
          title: "Age at symptom onset (in months)",
          type: "string",
          widget: "InputNumberWidget",
          span: 6,
          setInitialValue: true,
        },

        Presenting_symptom: {
          type: "string",
          default: "",
          title: "presenting symptom",
          span: 24,
          setInitialValue: true,
        },

        difficulty_in_gripping_chappals: {
          title: "Difficulty in gripping chappals",
          type: "string",
          widget: "RadioGroupWidget",
          span: 8,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_kicking_ball: {
          title: "Difficulty in kicking ball",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_squatting: {
          title: "Difficulty in squatting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_rising_from_squatting_position: {
          title: "Difficulty in rising from squatting position",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_rolling_over_in_bed: {
          title: "Difficulty in rolling over in bed",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_rising_from_supine_position: {
          title: "Difficulty in rising from supine position",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_gripping_objects: {
          title: "Difficulty in gripping objects",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_taking_food_to_mouth: {
          title: "Difficulty in taking food to mouth",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_rising_arms: {
          title: "Difficulty in rising arms",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_closing_eyes: {
          title: "Difficulty in closing eyes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        squint: {
          title: "Squint",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        restriction_of_eye_movements: {
          title: "Restriction of eye movements",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_chewing: {
          title: "Difficulty in chewing",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_drinking_with_straw: {
          title: "Difficulty in drinking with straw",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_swallowing: {
          title: "Difficulty in swallowing",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        nasal_twang: {
          title: "Nasal twang",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        nasal_regurgitation: {
          title: "Nasal regurgitation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        easy_fatiguability: {
          title: "Easy fatiguability",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        diurnal_variation: {
          title: "Diurnal variation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        flailness_of_limbs: {
          title: "Flailness of limbs",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        wasting_of_limbs: {
          title: "Wasting of limbs",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        breathing_difficulty: {
          title: "Breathing difficulty",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        snoring: {
          title: "Snoring",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        excessive_daytime_sleepiness: {
          title: "Excessive daytime sleepiness",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        recurrent_aspiration: {
          title: "Recurrent aspiration",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        recurrent_respiratory_tract_infection: {
          title: "Recurrent respiratory tract infection",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        orthopnoea: {
          title: "Orthopnoea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        vision_disturbances: {
          title: "Vision disturbances",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hearing_disturbances: {
          title: "Hearing disturbances",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        pattern_of_involvment: {
          title: "Pattern of involvment",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr([
              "Proximal > Distal",
              "Distal = Proximal",
              "Distal > Proximal",
            ]),
          },
        },
        muscle_groups_involved: {
          title: "Muscle groups involved",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr([
              "Ocular ",
              "Bulbar",
              "Limb girdle",
              "Respiratory",
            ]),
          },
        },

        other_muscle_groups_involved: {
          title: "Other muscle groups involved",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        course: {
          title: "Course",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Static", "Progressive"]),
          },
        },

        seizures: {
          type: "string",
          title: "Seizures",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Focal", "Generalised", "Unknown"]),
          },
        },

        description_of_seizure: {
          title: "Description of seizure",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.seizures"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "seizures"]);
            let finalRender = null;

            if (
              nameVal === "Focal" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.seizures === "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "description_of_seizure",
                    title: "Description of seizure",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            } else if (
              nameVal === "Generalised" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.seizures === "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "description_of_seizure",
                    title: "Description of seizure",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        behavioural_symptoms: {
          type: "string",
          title: "Behavioural symptoms",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        behavioural_symptoms_details: {
          title: "Behavioural symptoms details",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.behavioural_symptoms"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "behavioural_symptoms",
            ]);
            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.behavioural_symptoms ===
                  "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "behavioural_symptoms_details",
                    title: "Autistic traits",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        sleep_disturbances: {
          type: "string",
          title: "Sleep disturbances",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        antenatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "antenatal_risk_factors", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Antenatal risk factors</p>,
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        hypothyroidism: {
          title: "Hypothyroidism",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        pih: {
          title: "PIH",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        gdm: {
          title: "GDM",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        fever: {
          title: "Fever",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        skin_rash: {
          title: "Skin rash",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        drug_exposure: {
          title: "Drug exposure",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        fetal_hypokinesia: {
          title: "Fetal hypokinesia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        polyhydramnios: {
          title: "Polyhydramnios",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        abnormal_presentation: {
          title: "Abnormal presentation",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        others_antenatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 8,
          setInitialValue: true,
        },

        perinatal_history: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "perinatal_history", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Perinatal history</p>,
        },

        gestational_age: {
          title: "Gestational age",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Preterm", "Term", "Post term"]),
          },
        },

        birth_weight: {
          title: "Birth weight",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["SGA", "AGA", "LGA"]),
          },
        },

        neonatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "neonatal_risk_factors", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neonatal risk factors</p>,
        },

        birth_asphyxia: {
          title: "Birth asphyxia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hypoglycaemia: {
          title: "Hypoglycaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        sepsis: {
          title: "Sepsis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        trauma: {
          title: "Trauma",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        cogulopathy: {
          title: "Cogulopathy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hypotonia: {
          title: "Hypotonia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        feeding_problems: {
          title: "Feeding problems",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        undescended_testes: {
          title: "Undescended testes",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hip_dislocation: {
          title: "Hip dislocation",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        others_neonatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 12,
          setInitialValue: true,
        },

        // development_history subheading
        development_history: {
          type: "string",
          span: 24,
          // setInitialValue: true,
          is_sub_heading: true, //using in show view
          sub_heading_title: "Development History", //using in show view
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Development History</p>,
        },

        gross_motor: {
          type: "string",
          title: "Gross motor",
          span: 12,
          setInitialValue: true,
          className: "p-l-10",
        },

        fine_motor: {
          type: "string",
          title: "Fine motor",
          span: 12,
          setInitialValue: true,
        },

        socio_adaptive: {
          type: "string",
          title: "Socio-adaptive",
          span: 12,
          setInitialValue: true,
          className: "p-l-10",
        },

        language: {
          type: "string",
          title: "Language",
          span: 12,
          setInitialValue: true,
        },

        vision: {
          type: "string",
          title: "Vision",
          span: 12,
          setInitialValue: true,
          className: "p-l-10",
        },

        hearing: {
          type: "string",
          title: "Hearing",
          span: 12,
          setInitialValue: true,
        },

        gdd: {
          title: "GDD/ID",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        // End development_history

        family_history: {
          title: "Family history",
          type: "string",
          span: 4,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        scholastic_performance: {
          title: "Scholastic performance",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Above average", "Average", "Poor"]),
          },
        },

        functional_status: {
          type: "string",
          title: "Functional status",
          span: 12,
          setInitialValue: true,
        },

        adls: {
          type: "string",
          title: "ADLS",
          span: 12,
          setInitialValue: true,
        },

        treatment_history: {
          type: "string",
          title: "Treatment history",
          span: 12,
          setInitialValue: true,
        },

        immunization: {
          type: "string",
          title: "Immunization",
          span: 24,
          setInitialValue: true,
        },

        other_history: {
          type: "string",
          title: "Other history",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },

  general_examination: {
    title: "General Examinations",
    fields: {
      type: "object",
      properties: {
        neurocutaneous_markers: {
          title: "Neurocutaneous markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        cyanosis: {
          title: "Cyanosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        lymphadenopathy: {
          title: "Lymphadenopathy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        myopathic_facies: {
          title: "Myopathic facies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        cataract: {
          title: "Cataract",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hip_dislocation: {
          title: "Hip dislocation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        velvetty_soles_and_palms: {
          title: "Velvetty soles and palms",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        undescended_testes: {
          title: "Undescended testes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        other_ge: {
          title: "Others",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        vitals: {
          title: "Vitals",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Stable", "Unstable"]),
          },
        },

        //anthropometry subheading....

        anthropometry: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Anthropometry", //using in show
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Anthropometry</p>,
        },

        weight: {
          title: "Weight",
          type: "number",
          span: 8,
          setInitialValue: true,
          className: "p-l-10",
        },
        height: {
          title: "Height",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        head_circumference: {
          title: "Head circumference",
          type: "number",
          span: 8,
          setInitialValue: true,
        },

        //ends_anthropometry
      },
    },
  },

  neurologicalexamination: {
    title: "Neurological Examination",
    fields: {
      type: "object",
      properties: {
        //HMF subheading
        HMF: {
          // title: "HMF",
          is_sub_heading: true, //using in show view
          sub_heading_title: "HMF", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>HMF</p>,
        },

        hyperactive: {
          title: "Hyperactive",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        poor_attention: {
          title: "Poor attention",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        speech: {
          title: "Speech",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        poor_eye_contact: {
          title: "Poor eye contact",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        // Cranial nerves deficit

        cranial_nerves_deficit: {
          title: "Cranial nerves deficit",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        //Motor system (sub heading)
        motor_system: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Motor System", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Motor System</p>,
        },

        bulk: {
          title: "Bulk",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Normal", "Wasting", "Hypertrophy"]),
          },
        },

        tone_normal: {
          title: "Tone normal",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Hypotonia", "Hypertonia", "Variable"]),
          },
        },

        power_charting: {
          title: "Power charting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["1", "2", "3", "4", "5"]),
          },
        },

        deep_tendon_reflexes: {
          title: "Deep tendon reflexes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr([
              "Normal",
              "Areflexia",
              "Hyporeflexia",
              "Hyperreflexia",
            ]),
          },
        },

        plantar_responses: {
          title: "Plantar responses",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Flexor", "Extensor", "No response"]),
          },
        },
        // motor system ends here

        //Extrapyramidal signs sub-heading
        extrapyramidal_signs: {
          // title: "Extrapyramidal signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Extrapyramidal Signs", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Extrapyramidal Signs</p>,
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        tremor_es: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        //Extrapyramidal signs ends (sub section)

        //Cerebellar signs starts (sub section)
        cerebellar_signs: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Cerebellar Signs", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cerebellar signs</p>,
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        titubation: {
          title: "Titubation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        tremor_cs: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        dysmetria: {
          title: "Dysmetria",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        //Cerebellar signs ends (sub section)

        // Spine/cranium
        spine_cranium: {
          title: "Spine/cranium",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        other_findings: {
          title: "Other findings",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        hearing_deficit: {
          title: "Hearing Deficit",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        contractures: {
          title: "Contractures",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        fasciculations: {
          title: "Fasciculations",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        polyminimyoclonus: {
          title: "Polyminimyoclonus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        scapular_winging: {
          title: "Scapular winging",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        myotonia: {
          title: "Myotonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        forward_arm_abduction_test: {
          title: "Forward arm abduction test",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        calf_hypertrophy: {
          title: "Calf hypertrophy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hyperlaxity_of_joints: {
          title: "Hyperlaxity of joints",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        gower_sign: {
          title: "Gower sign",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 12,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        rating_scale: {
          title: "Rating scale",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },

  systemic_xamination: {
    title: "Systemic Examination",
    fields: {
      type: "object",
      properties: {
        cardiac: {
          title: "Cardiac",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        respiratory: {
          title: "Respiratory",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        abdominal: {
          title: "Abdominal",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },

  investigations: {
    title: "Investigations",
    fields: {
      type: "object",
      properties: {
        cpk: {
          title: "CPK",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        ldh: {
          title: "LDH",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        sgpt: {
          title: "SGPT",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        sgot: {
          title: "SGOT",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        arterial_lactate: {
          title: "Aarterial lactate",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        abg: {
          title: "ABG",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        neostigmine_challenge_test: {
          title: "Neostigmine challenge test",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        musk_antibodies: {
          title: "Musk antibodies ",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        ncs_findings: {
          title: "NCS findings",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        rnst: {
          title: "RNST",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        emg_finding: {
          title: "EMG finding",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        psg_findings: {
          title: "PSG findings",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        nerve_biopsy: {
          title: "Nerve biopsy ",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        muscle_biopsy_finding: {
          title: "Muscle biopsy finding (if done)",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        ecg: {
          title: "ECG",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        echo: {
          title: "Echo",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        pft: {
          title: "PFT",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        neuro_imaging_finding: {
          title: "Neuro-imaging finding",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        genetic_test_report: {
          title: "Genetic test report",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        other_investigations: {
          title: "Other Investigations",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },

  diagnosis: {
    title: "Diagnosis",
    fields: {
      type: "object",
      properties: {
        diagnosis: {
          title: "Diagnosis",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },
  treatment: {
    title: "Treatment",
    fields: {
      type: "object",
      properties: {
        prednisolone: {
          title: "Prednisolone ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        deflazacort: {
          title: "Deflazacort ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        calcium: {
          title: "Calcium ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        pyridostigmine: {
          title: "Pyridostigmine ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        neostigmine: {
          title: "Neostigmine ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        ivig: {
          title: "IVIG ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        iv_methyl_prednisolone: {
          title: "IV methyl prednisolone ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        mycophenolate: {
          title: "Mycophenolate ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        azathioprine: {
          title: "Azathioprine ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        physiotherapy: {
          title: "Physiotherapy ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        mechanical_ventilation: {
          title: "Mechanical ventilation ",
          type: "string",
          setInitialValue: true,
          span: 8,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        others: {
          title: "Others treatment",
          type: "string",
          span: 8,
          setInitialValue: true,
        },
      },
    },
  },
  outcome: {
    title: "Outcome",
    fields: {
      type: "object",
      properties: {
        date_of_last_follow_up: {
          title: "Date of last follow up",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "DatePickerWidget",
        },

        functional_status: {
          title: "Functional status",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        Complications: {
          title: "Functional status",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },
};
