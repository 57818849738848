import React from "react";
import { List, Avatar, Space, Button, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "../Patients.scss";

export const downloadFile = (url, filename) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
};

const getName = (item) =>
  item.match(/[^\/]*$/gim) && item.match(/[^\/]*$/gim)[0];

const CustomUploadList = ({ data }) => {
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            onClick={(e) => e.stopPropagation()}
            className="custom-list-item"
            actions={[
              <Tooltip title="Download">
                <Button
                  key="download-export"
                  icon={<DownloadOutlined />}
                  onClick={() => downloadFile(item.url, getName(item.name))}
                >
                  {/* <a href={item.url} target="_blank" download={item.name}>
                    Download
                  </a> */}
                </Button>
              </Tooltip>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar shape="square" size={64} src={item.url} />}
              title={
                <Space>{item.name && item.name.replace(/.*\//g, "")}</Space>
              }
              description={`Uploaded at:- ${dayjs(item.updated_at).format("DD-MM-YYYY, hh:mm a")}`}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default CustomUploadList;
