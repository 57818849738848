import React from "react";
import { Button, Dropdown, Input } from "antd";
import { MrInput } from "mr_react_framework";
import SelectHospital from "../../Hospital/SelectHospital/SelectHospital";

// props
// changed = setParams
const AccountsFilter = props => {
  console.log("props in Leadfilter accounts", props);

  let { currentUser } = props;
  let extraFilters = [];

  if  ( currentUser && currentUser.role  == "superadmin") {
    extraFilters.push(
      <MrInput
        key="by_role"
        elementType="select"
        options={[
          { role: "superadmin", text: "Super Admin" },
          { role: "hosptial_admin", text: "Hospital Admin" },
          { role: "data_entry", text: "Data Entry" }
        ]}
        valueKey="role"
        textKey="text"
        placeholder="Filter by Role"
        extraOptions={{ clearOptions: true}}
        defaultValue={props.by_role}
        changed={props.changed}
        fieldInfo={{ key: "by_role" }}
      />
    );

    extraFilters.push(
      <SelectHospital
        // className="desktop-only"
        // style={{width: "120px"}}
        key="by_hospital_id"
        elementType="select"
        valueKey="id"
        textKey="title"
        extraOptions={{ clearOptions: true, clearOptionsText: "All Hospitals" }}
        placeholder="Filter by Hospital"
        defaultValue={props.by_hospital_id}
        changed={props.changed}
        fieldInfo={{ key: "by_hospital_id" }}
      />
    );
  }

  // extraFilters.push(
  //   <MrInput
  //     key="by_role"
  //     elementType="select"
  //     options={[{role: '', text: ''}, {role: '', text: ''}, {role: '', text: ''}]}
  //     valueKey="role"
  //     textKey="text"
  //     placeholder="Filter by Role"
  //     defaultValue={props.by_role}
  //     changed={props.changed}
  //     fieldInfo={{ key: "by_role" }}
  //   />
  // );

  return extraFilters;
};
export default AccountsFilter;
