import axios from "axios";
// import { localStorage, } from "../shared/testUtil";
import { MrAxiosInstanceMethodBuilder } from "mr_react_framework";
import { setLocalStorage } from "../lib/testUtils/testUtil";

// import { axiosInstance } from "../views/Auth/Login/redux";

try {
  localStorage.getItem("token");
} catch (error) {
  setLocalStorage();
}
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};
// export const axiosInstance = axios.create({
//   // baseURL: "https://react-burger.firebaseio.com/",
//   // baseURL: "http://172.17.0.1:3003/api/v1/",
//   baseURL: "http://http://172.168.43.218:3003/api/v1/",
//   // baseURL: "http://0.0.0.0:3003/api/v1/",
//   // baseURL: "https://test.assessprep.com/api/v1/",
//   // headers: { token: localStorage.getItem("token") , },
//   timeout: 10000,
// });

export const makeAxiosInstance = MrAxiosInstanceMethodBuilder(axios, {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000
});

export const axiosInstance = makeAxiosInstance();
