import React from "react";

// import styles from "./ActionBtns.scss";
// import { Button, Menu, Icon, } from "semantic-ui-react";
import { Button, Icon, Popconfirm, message } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ButtonGroup = Button.Group;
const StyledButtonGroup = styled(ButtonGroup)`
  ${
    "" /* position: absolute;
  right: 10px;
  top: 10px; */
  }
`;
const ActionBtns = (props) => {
  console.log("props action btns", props);
  let toRender;
  let actionsArr = [];
  // add permissions verification aswell

  if (props.edit) {
    actionsArr.push(<Button key="edit" icon="edit" onClick={props.edited} />);
  }
  if (props.delete) {
    actionsArr.push(
      <Button key="delete" icon="delete" onClick={props.deleted} />
    );
  }
  const confirm = (e) => {
    props.deleted(props.item);
    // message.success("Deleted successfully");
  };
  const cancel = (e) => {
    // message.error("Delete cancelled");
  };
  let menuItem;
  let menuItems = [];
  if (props.edit) {
    // menuItem = (
    //   <Button
    //     key="edit"
    //     onClick={() => props.edited({ name: props.name, item: props.item })}
    //     icon={"edit"}
    //   />
    // );
    if (props.path === "patient_disorders") {
      menuItem = (
        <Link to={`/u/${props.path}/${props.id}/edit`}>
          {" "}
          <Button type="primary">Edit</Button>{" "}
        </Link>
      );
    } else {
      menuItem = <Link to={`/u/${props.path}/${props.id}/edit`}> Edit </Link>;
    }
  }
  menuItems.push(menuItem);
  if (props.delete) {
    menuItem = (
      <Popconfirm
        key="delete"
        title="Confirm archive?"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        {/* {props.path === "disorders" ? ( */}
        <Link style={{ margin: "0px 10px" }}>Delete</Link>
        {/* ) : ( */}
        {/* <Button
            // onClick={() => props.deleted(props.item)}
            icon={"delete"}
          /> */}
        {/* )} */}
      </Popconfirm>
    );
    menuItems.push(menuItem);
  }
  // <Button name='gamepad' active={activeItem === 'gamepad'} onClick={this.handleItemClick}>
  //   <Icon name='gamepad' />
  //   Games
  //       </Button>
  return (
    <StyledButtonGroup className={"action-btns"}>{menuItems}</StyledButtonGroup>
  );
  // return <Button.Group>{actionsArr}</Button.Group>;
};

export default ActionBtns;
