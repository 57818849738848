import React from "react";
import { Space, Tooltip, Button, Divider } from "antd";
import { PlusCircleOutlined, DragOutlined } from "@ant-design/icons";

const FloatingButton = ({ addSection, enableDrag, dragToggle }) => {
  return (
    <>
      <Space className="floating-section">
        <Tooltip title="Add new section">
          <Button
            onClick={addSection}
            type="primary"
            icon={<PlusCircleOutlined />}
          ></Button>
        </Tooltip>
        <Tooltip title={`${!dragToggle ? "Enable " : "Disable "}Drag/Drop`}>
          <Button
            type={!dragToggle ? "primary" : "danger"}
            icon={<DragOutlined />}
            onClick={enableDrag}
          ></Button>
        </Tooltip>
        <Divider dashed />
      </Space>
    </>
  );
};

export default FloatingButton;
