import React from "react";
import classes from "./MrInput.scss";
// import { Dropdown, Form, Checkbox, } from "semantic-ui-react";

import _ from "lodash";
import moment from "moment";
import {
  TimePicker,
  Button,
  Select,
  Transfer,
  Slider,
  Switch,
  Radio,
  Mention,
  Input,
  InputNumber,
  Form,
  DatePicker,
  Cascader,
  AutoComplete,
  Checkbox,
  Spin
} from "antd";

import ReactJson from "react-json-view";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

import styled from "styled-components";
// import { DatetimePicker, DatetimePickerTrigger } from "rc-datetime-picker";

// import {
//   DateInput,
//   TimeInput,
//   DateTimeInput,
//   DatesRangeInput
// } from "semantic-ui-calendar-react";

import { getNested, updateNested } from "../../../lib/utils/validationHelper";
import MrSelect from "../MrSelect/MrSelect";
import { returnFinalValue } from "../../../lib/utils/helperMethods";

const Option = Select.Option;
const Search = Input.Search;
const ErrorDiv = styled.span`
  div.srv-validation-message {
    color: #9f3a38;
    font-size: 12px;
  }
`;
export const buildOptions = (options, valueKey, textKey, extraOptions = {}) => {
  if (!options) {
    return [];
  }

  let newOptions = [];
  let optionObj, text, value;

  options.forEach(element => {
    if (typeof element === "string") {
      text = value = element;
    } else if (typeof element === "object") {
      if (valueKey === "object") {
        value = element;
      } else {
        value = element[valueKey];
      }
      text = element[textKey];
    }
    text = _.capitalize(text);

    if (value === "" && text === "") {
      text = "All";
    }
    optionObj = {
      key: text,
      text: text,
      value: value
    };
    newOptions.push(optionObj);
  });
  let finalOptions = [];
  if (extraOptions.clearOptions) {
    // return [{ key: "All", text: "All", value: "" }, ...newOptions];
    finalOptions = [{ key: "All", text: "All", value: "" }, ...newOptions];
  } else {
    finalOptions = newOptions;
  }
  return finalOptions;
  // const optionsToRender = finalOptions.map(item => {
  //   // debugger;
  //   return (
  //     <Option key={item.key} value={item.value}>
  //       {item.text}
  //     </Option>
  //   );
  // });
  // console.log("optionsToRender", optionsToRender);
  // return optionsToRender;
};
const MrInput = props => {
  let toRender;
  let {
    controlName,
    type,
    placeholder,
    prefix,
    style,
    elementType,
    label,
    noLabel,
    disabled,
    isFormItem,
    mainForm,
    defaultValue,
    valueKey,
    textKey,
    validationRules,
    fieldInfo,
    enterButton,
    loading
  } = props;

console.log("propsmrinput", props)

  if (!type) {
    type = "text";
  }
  if (!fieldInfo) {
    fieldInfo = {};
  }
  if (!elementType) {
    elementType = "input";
  }
  if (!loading) {
    loading = false;
  }
  if (!disabled) {
    disabled = false;
  }
  if (!isFormItem) {
    isFormItem = false;
  }
  if (!style) {
    style = {};
  }

  if (!placeholder) {
    placeholder = controlName;
  }
  if (!label) {
    if (!noLabel) {
      label = controlName;
    }
  }

  let prefixElement;

  if (!prefix) {
    prefix = null;
  } else {
    if (prefix.type === "element") {
      prefixElement = prefix.element;
    }
  }
  let onJsonChange = (key, isFormItem) => {
    return obj => {
      console.log("obj", obj);
      // obj.updated_src = updateNested(obj.namespace.join("."), obj.new_value);
      // return obj;
      // debugger;
      if (isFormItem) {
        if (mainForm) {
          // debugger;
          mainForm.setFieldsValue({
            [key]: JSON.stringify(obj)
          });
          // setTimeout(() => {
          // }, 1000);
        }
      }

      if (props.hasOwnProperty("changed")) {
        let extraProps = {};
        if (mainForm) {
          extraProps.mainForm = mainForm;
        }
        if (props.hasOwnProperty("fieldInfo")) {
          // extraProps.fieldInfo = { ...props.fieldInfo };
          extraProps = { ...props.fieldInfo };
          // console.log("fieldInfo", fieldInfo);
        }
        console.log("extraProps", extraProps);
        // debugger;
        props.changed(JSON.stringify(obj.updated_src), extraProps);
      }
      return obj;
    };
  };
  let onChange = event => {
    // debugger;
    let finalValue = returnFinalValue(event);
    console.log("finalValue", finalValue);
    // console.log("event.target.value", event.target.value);
    if (props.hasOwnProperty("changed")) {
      console.log("executing changed method");

      let extraProps = {};
      if (mainForm) {
        extraProps.mainForm = mainForm;
      }
      if (props.hasOwnProperty("fieldInfo")) {
        // extraProps.fieldInfo = { ...props.fieldInfo };
        extraProps = { ...props.fieldInfo };
        // console.log("fieldInfo", fieldInfo);
      }
      console.log("extraProps", extraProps);
      props.changed(event, extraProps);
    }
  };
  let elementProps = {
    type,
    placeholder,
    disabled,
    prefix: prefixElement,
    // initialValue: defaultValue,

    onChange
  };

  let searchProps = {
    type,
    placeholder,
    disabled,
    // initialValue: defaultValue,
    defaultValue,
    onSearch: onChange,
    enterButton: true
  };

  let selectProps = {
    style,
    placeholder,
    // initialValue: defaultValue,
    defaultValue,
    onChange,
    loading
    // options: props.options,
  };
  // if (!props.defaultActiveFirstOption) {
  // }
  if (!isFormItem) {
    if (props.value) {
      selectProps.value = props.value;
    }
    // if (props.labelInValue) {
    //   selectProps.labelInValue = true;
    // }
    // selectProps.showSearch = true;
    // selectProps.search = true;
    // selectProps.showArrow = false;
    // selectProps.defaultValue = false;
    // selectProps.defaultActiveFirstOption = false;
    selectProps.notFoundContent = loading ? <Spin size="small" /> : null;
  }
  console.log("style", style);
  if (elementType === "select" && !style.hasOwnProperty("width")) {
    selectProps.style = { width: 200 };
  }

  const options = buildOptions(props.options, valueKey, textKey);

  let finalControlName = controlName;

  let InputElement;
  console.log("elementType", elementType);
  switch (elementType) {
    case "select":
      let finalSelectOptions = options.map(item => {
        // debugger;
        return <Option key={item.value}>{item.text}</Option>;
      });
      InputElement = <Select {...selectProps}>{finalSelectOptions}</Select>;
      break;
    case "search":
      InputElement = <Search {...searchProps} />;
      break;
    case "datetime":
      InputElement = <Search {...searchProps} />;
      break;

    case "json":
      // let jsonProps = {
      //   src: JSON.parse(defaultValue),
      //   // src: getExampleJson(),
      //   onEdit: onJsonChange(finalControlName, isFormItem),
      //   collapseStringsAfterLength: 15
      //   // theme: "monokai"
      // };
      // isFormItem = false;
      let jsonInitialValue = {};
      if (defaultValue) {
        jsonInitialValue = JSON.parse(defaultValue);
      }
      let jsonProps = {
        value: jsonInitialValue,
        onChange: onJsonChange(finalControlName, isFormItem)
      };
      // console.log("jsonProps", jsonProps);
      InputElement = <Editor {...jsonProps} />;

      // InputElement = <ReactJson {...jsonProps} />;
      // InputElement = (
      //   <InputElement {...mainForm.getFieldProps(finalControlName)} />
      // );
      break;
    default:
      InputElement = <Input {...elementProps} />;
      break;
  }
  toRender = InputElement;
  // if (elementType === "json") {
  //   debugger;
  // }
  // console.log("toRender", toRender);
  if (isFormItem) {
    let formItemLayout = {
      layout: "horizontal"
      // label,
    };
    let finalInputElement;
    let initialValue = defaultValue;
    if (!initialValue) {
      if (props.default) {
        initialValue = props.default;
      }
    }

    if (elementType === "json") {
      console.log("JSON JSON elementType", elementType);
      finalInputElement = [];
      finalInputElement.push(InputElement);
      let tempInputElem = <input hidden={true} />;

      finalInputElement.push(
        mainForm.getFieldDecorator(finalControlName, {
          initialValue,
          onChange
          // hidden: true
          // onChange: onJsonChange(finalControlName, isFormItem)
          // rules: validationRules
        })(tempInputElem)
      );
      // finalInputElement.push(
      //   mainForm.getFieldDecorator(finalControlName, {
      //     initialValue,
      //     hidden: true
      //     // onChange
      //     // onChange: onJsonChange(finalControlName, isFormItem)
      //     // rules: validationRules
      //   })(InputElement)
      // );
      // finalInputElement.push(
      //   <input
      //     {...mainForm.getFieldProps(finalControlName, { hidden: true })}
      //   />
      // );
      // console.log("finalInputElement", finalInputElement);
      // mainForm.setFields({
      //   [finalControlName]: {
      //     value: initialValue
      //   }
      // });
      // toRender =
    } else {
      try {
        finalInputElement = mainForm.getFieldDecorator(finalControlName, {
          initialValue,
          onChange,
          rules: validationRules
        })(InputElement);
      } catch (e) {
        // debugger;
        console.log("e", e);
      }
    }
    toRender = <Form.Item {...formItemLayout}>{finalInputElement}</Form.Item>;
    // debugger;
  }

  return toRender;
  // text input

  // password

  // datepicker

  // datetimepicker

  // timepicker

  // select/dropdown

  // is a different component altogether
};

// const input = props => {
//   let toRender;
//   let containerClasses = ["ui", "input"];
//   let elementClasses = [classes.InputElement];
//   let fieldClasses = ["field"];
//   let InputElement;
//   // if (props.invalid && props.shouldValidate && props.touched) {
//   //   elementClasses.push(classes.Invalid);
//   //   fieldClasses.push("error");
//   // }
//   let hasError = false;

//   if (props.invalid && (props.touched || props.showInvalid)) {
//     hasError = true;
//     // elementClasses.push(classes.Invalid);
//   } else {
//     hasError = false;
//   }
//   if (hasError) {
//     fieldClasses = ["field", "error"];
//   } else {
//     fieldClasses = ["field"];
//   }
//   let onChange;
//   if (
//     props.elementType === "dropdown" ||
//     props.elementType === "datetime" ||
//     props.elementType === "date"
//   ) {
//     onChange = (event, { value }) => {
//       let extraOpts = { ...props.extraOpts };
//       // extraOpts.itemObj = _.where(props.options, { id: value, })[0];
//       if (props.hasOwnProperty("options")) {
//         extraOpts.itemObj = props.options.find(element => {
//           if (element.id === parseInt(value)) {
//             return element;
//           }
//           return null;
//         });
//       }

//       props.changed(value, props.controlName, extraOpts);
//     };
//   } else if (props.elementType === "checkbox") {
//     onChange = () => {};
//   } else {
//     onChange = event => {
//       // debugger;
//       props.changed(event, props.controlName, props.extraOpts);
//     };
//   }
//   // const onChange = props.changed;

//   let elementProps = {
//     type: props.type,
//     placeholder: props.placeholder,
//     value: props.value,
//     onChange: onChange,
//     disabled: props.disabled || false
//   };
//   let selectProps = {
//     value: props.value,
//     onChange: onChange,
//     options: props.options
//   };
//   let finalOptions = [];
//   if (
//     props.options &&
//     typeof props.options[0] === "object" &&
//     props.options[0].hasOwnProperty("value")
//   ) {
//     finalOptions = props.options;
//   } else {
//     if (props.clearOptions) {
//       finalOptions = buildOptions(
//         props.options,
//         props.valueKey,
//         props.textKey,
//         { clearOptions: true }
//       );
//     } else {
//       finalOptions = buildOptions(props.options, props.valueKey, props.textKey);
//     }
//   }
//   let value = props.value;
//   // console.log("props.validator", props.validator);
//   // if (props.validator) {
//   //   if (props.validator.valueFields.hasOwnProperty(props.controlName)) {
//   //     value = getNested(props.controlName, props.validator.valueFields);
//   //   }
//   // }
//   // let value = getNested(props.controlName, props.validator.valueFields);
//   // if (props.hasOwnProperty("valueKeyMap")) {
//   //   if (Array.isArray(value)) {
//   //     let newValue = [];
//   //     value.map((item, i) => {
//   //       newValue.push(item[props.valueKeyMap]);
//   //     });
//   //     value = newValue;
//   //   }
//   // }
//   let dropdownSpecificProps = {
//     item: props.item || false,
//     search: props.search || false,
//     selection: props.selection || false,
//     options: finalOptions,
//     placeholder: props.placeholder,
//     value,
//     multiple: props.multiple || false,
//     onChange: onChange
//   };

//   let checkboxSpecificProps = {
//     label: props.placeholder,
//     value,
//     onChange: onChange
//   };

//   // let datetimePickerSpecificProps = {
//   //   showTimePicker: props.showTimePicker || true,
//   //   isOpen: props.isOpen || false,
//   //   moment: props.value || moment(),
//   //   maxDate: props.maxDate,
//   //   minDate: props.minDate,
//   //   onChange: onChange,
//   // };
//   let datetimePickerSpecificProps = {
//     name: props.name || "dateTime",
//     placeholder: "Date Time",
//     value: props.value,
//     iconPosition: "left",
//     onChange: onChange,
//     label: props.label
//   };
//   let dropdownProps = {
//     form: props.form || false,
//     onChange: onChange
//   };
//   let checkboxProps = {
//     form: props.form || true
//   };

//   let inputClassesString = elementClasses.join(" ");
//   let completeOverride = false;
//   const message = <ErrorDiv>{props.message}</ErrorDiv>;
//   switch (props.elementType) {
//     case "input":
//       InputElement = <input className={inputClassesString} {...elementProps} />;
//       break;
//     case "textarea":
//       InputElement = (
//         <textarea className={inputClassesString} {...elementProps} />
//       );
//       break;
//     case "select":
//       InputElement = (
//         <Dropdown style={{ paddingTop: "30px" }} {...dropdownProps}>
//           {message}
//         </Dropdown>
//       );
//       break;
//     case "dropdown":
//       completeOverride = true;
//       if (dropdownProps.form) {
//         InputElement = (
//           <Form.Dropdown
//             error={hasError}
//             label={props.label}
//             {...dropdownSpecificProps}
//           />
//         );
//       } else {
//         InputElement = <Dropdown {...dropdownSpecificProps} />;
//       }
//       break;
//     case "date":
//       completeOverride = true;
//       InputElement = (
//         <DateInput error={hasError} {...datetimePickerSpecificProps} />
//       );

//       break;
//     case "datetime":
//       completeOverride = true;
//       const shortcuts = {
//         Today: moment(),
//         Yesterday: moment().subtract(1, "days"),
//         Clear: ""
//       };
//       if (true) {
//         InputElement = (
//           <DateTimeInput error={hasError} {...datetimePickerSpecificProps} />
//         );
//       } else {
//         InputElement = <DatetimePicker {...datetimePickerSpecificProps} />;
//       }
//       break;
//     case "select":
//       InputElement = (
//         <select className={inputClassesString} {...selectProps}>
//           {props.options.map(option => (
//             <option key={option.value} value={option.value}>
//               {option.displayValue}
//             </option>
//           ))}
//         </select>
//       );
//       break;
//     case "checkbox":
//       if (checkboxProps.form) {
//         InputElement = (
//           <Form.Checkbox
//             error={hasError}
//             label={props.label}
//             {...checkboxSpecificProps}
//           />
//         );
//       } else {
//         InputElement = <Checkbox {...checkboxSpecificProps} />;
//       }

//       break;
//     default:
//       InputElement = <input className={inputClassesString} {...elementProps} />;
//       break;
//   }
//   let label;
//   if (props.label) {
//     label = <label className={classes.Label}>{props.label}</label>;
//   }
//   if (props.icon) {
//     containerClasses.push("left");
//     containerClasses.push("icon");
//     const iconClasses = ["icon", props.icon];
//     label = <i className={iconClasses.join(" ")} />;
//   }
//   if (props.width) {
//     fieldClasses.push(props.width);
//     fieldClasses.push("wide");
//   }

//   if (completeOverride) {
//     // toRender = InputElement;
//     toRender = [];
//     toRender.push(InputElement);
//     // toRender.push(message);
//   } else {
//     toRender = (
//       <div className={fieldClasses.join(" ")}>
//         {label}
//         <div className={containerClasses.join(" ")}>{InputElement}</div>
//         {message}
//       </div>
//     );
//   }
//   return toRender;
// };

export default MrInput;
