import React, { useState } from "react";
import { Button, Modal, Space, Col, Row, Tabs, Empty } from "antd";
import { upperFirst } from "lodash";
import CustomUploadList from "./CustomUploadList";
import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { DownloadOutlined } from "@ant-design/icons";

const zip = new JSZip();

const { TabPane } = Tabs;

const getCorrectTitle = (str) => str && upperFirst(str.replace(/_/g, " "));

const download = (item) => {
  //download single file as blob and add it to zip archive
  return axios.get(item.url, { responseType: "blob" }).then((resp) => {
    zip.file(item.name, resp.data);
  });
};

//call this function to download all files as ZIP archive
const downloadAll = (fileArr) => {
  const arrOfFiles = fileArr.map((item) => download(item)); //create array of promises
  Promise.all(arrOfFiles)
    .then(() => {
      //when all promises resolved - save zip file
      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, "files.zip");
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ViewUploadsModal = ({ uploads }) => {
  console.log("view uploads==>", uploads);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const allFiles = [];
  const onDownloadAll = () => {
    Object.entries(uploads).forEach(([key, item]) => {
      item.files.length > 0 && item.files.map((file) => allFiles.push(file));
    });
    console.log("allfiles==>", allFiles);
    downloadAll(allFiles);
  };

  const showModal = (e) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setIsModalVisible(false);
  };
  const onChange = (key) => {
    console.log(key);
  };
  let tabsList = [];
  Object.entries(uploads).forEach(([key, value]) => {
    tabsList.push(
      <TabPane tab={getCorrectTitle(key)} key={key}>
        {value.files.length > 0 ? (
          <CustomUploadList data={value.files} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </TabPane>
    );
  });
  let finalRender = [];
  // Open modal
  finalRender.push(
    <>
      <Button
        type="primary"
        onClick={(e) => {
          e.stopPropagation();
          showModal(e);
        }}
      >
        View Files
      </Button>
      <Modal
        wrapClassName="view-uploads-modal"
        title={
          <>
            Patient Uploads
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => onDownloadAll()}
              style={{float: 'right', right: '20px'}}
            >
              Download all
            </Button>
          </>
        }
        visible={isModalVisible}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        onCancel={handleCancel}
        cancelText="Close"
      >
        <Tabs defaultActiveKey={Object.keys(uploads)[0]} onChange={onChange}>
          {tabsList}
        </Tabs>
      </Modal>
    </>
  );
  return finalRender;
};

export default ViewUploadsModal;
