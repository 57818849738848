import { createSelector } from "reselect";

const loadingState = (state, props) => {
  // debugger;
  state.appConstants.get("loading");
};
export const loadingSelector = () =>
  createSelector(
    [loadingState],
    loading => loading
  );

const errorState = (state, props) => state.appConstants.get("error");

export const errorSelector = () =>
  createSelector(
    [errorState],
    error => error
  );

const appConstantsState = (state, props) =>
  state.appConstants.get("app_constants");

export const appConstantsSelector = () =>
  createSelector(
    [appConstantsState],
    appConstants => appConstants
  );

export const rolesAppConstantsSelector = () =>
  createSelector(
    [appConstantsState],
    rolesAppConstants => {
      return rolesAppConstants.filter(item => item.key == "roles")
    }
  );

const paginationState = (state, props) => {
  const pageObj = {};
  pageObj.page = state.appConstants.get("page");
  pageObj.totalPages = state.appConstants.get("total_pages");

  return pageObj;
};

export const paginationSelector = () =>
  createSelector(
    [paginationState],
    pageObj => pageObj
  );
