import React, { Component } from "react";

import {
  errorSelector,
  loadingSelector,
  orgsSelector
} from "../../../views/Orgs/selector";
import { actions } from "../../../views/Orgs/redux";
import { MrConnectField } from "mr_react_framework";

const initialState = {
  value: null
};
const SelectOrg = MrConnectField({
  initialState,
  itemName: "org",
  config: {
    placeholder: "Select Org",
    label: "Select Org",
    elementType: "select"
  },
  fetch: true,
  actions,
  selectorsObj: {
    loading: loadingSelector,
    error: errorSelector,
    orgs: orgsSelector
  },
  elementType: "select",
  options: "props.orgs"
});

export default SelectOrg;
