import React, { useState, useEffect, memo } from "react";
import {
  findIndex,
  filter,
  remove,
  isObject,
  find,
  isEmpty,
  isArray,
} from "lodash";
import { useSelector } from "react-redux";
import deepmerge from "deepmerge";
import { checkMob } from "../../../lib/utils/helperMethods";
import {
  Row,
  Col,
  Card,
  Radio,
  Space,
  Checkbox,
  Upload,
  message,
  Input,
  Button,
  InputNumber,
  Image,
  Popconfirm,
  Affix,
} from "antd";
import { InboxOutlined, PrinterOutlined } from "@ant-design/icons";
import InputWithTags from "../../../components/UI/Input/InputWithTags";
import { requestDisorderSelector } from "../../Disorders/selector";
import DisorderItemSections from "../../Disorders/DisorderItemSections";
import SelectUpload from "../../../components/SelectUpload/SelectUpload";
import MediaShow from "../../../components/UI/MediaShow/MediaShow";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { actions } from "../../Patients/PatientDisorder/redux";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

const token = localStorage.getItem("token");

const { Dragger } = Upload;

const DisorderItemView = (props) => {
  const { scannedDocument, visitType } = props;
  const [disorderData, setDisorderData] = useState([]);
  // const [scannedDocument, setScannedDocument] = useState(props.scannedDocument);
  const [disorderListData, setdisorderListData] = useState([]);
  var disorders = useSelector(requestDisorderSelector()) || [];
  var disorderArr = props.disorderArr || [];
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();

  console.log("DisorderItemView props==>", props);

  useEffect(() => {
    if (props.print) {
      setDisorderData(props.formData);
    } else if (props.value) {
      setDisorderData(props.value);
    } else {
      // if (props.useMasterForm === undefined || props.useMasterForm === true) {
      //   console.log("disorderArr==>", disorders);
      //   const masterFormId =
      //     disorders.length !== 0 &&
      //     disorders.filter(
      //       (item) =>
      //         // Need to change this after BE deploy - had to remove item.name
      //         item.disorder_type === "master_form" ||
      //         item.name === "master_form"
      //     );
      //   if (masterFormId[0]) {
      //     disorderArr = [masterFormId[0].id, ...disorderArr];
      //   }
      // } else {
      //   var index = disorderArr.indexOf(13);
      //   if (index > -1) {
      //     //if found
      //     disorderArr.splice(index, 1);
      //   }
      // }
      var finalDisorders = [];
      disorders &&
        props.disorderNames &&
        disorders.map((item, index) => {
          props.disorderNames &&
            props.disorderNames.map((disorderName, i) => {
              // Need to change this after BE deploy - had to remove item.name
              if (disorderName === item.disorder_type || item.name) {
                finalDisorders.push(item.id);
              }
            });
        });

      let selectedDisorderList = [];
      if (disorders) {
        if (disorderArr && disorderArr.length !== 0) {
          console.log("disorder name!!==>", finalDisorders, disorderArr);
          for (var i = 0; i < disorderArr.length; i++) {
            let current = disorderArr[i];
            const finalData =
              disorders.length && disorders.length !== 0
                ? filter(disorders, (item) => item.id === current)
                : [];
            selectedDisorderList.push(finalData[0]);
          }
          if (visitType === "add_on") {
            const masterForm =
              disorders.length !== 0 &&
              disorders.filter(
                (item) =>
                  // Need to change this after BE deploy - had to remove item.name
                  item.disorder_type === "master_form" ||
                  item.name === "master_form"
              );
            selectedDisorderList.unshift(masterForm[0]);
          }
        } else if (disorderArr.length === 0) {
          selectedDisorderList = [];
        }
        console.log(
          "merge component==> selectedDisorderList",
          selectedDisorderList
        );
        const finalFormJson = [];
        selectedDisorderList.map((item) => {
          let currentDisorder = item && item.form_json.disorders;
          // finalFormJson.push(currentDisorder);

          currentDisorder &&
            currentDisorder.map((innerItem) => {
              finalFormJson.push(innerItem);
            });
        });
        console.log("merge component==> finalFormJson", finalFormJson);

        var finalList = [];
        if (disorderArr && disorderArr.length > 1) {
          for (let item = 0; item < finalFormJson.length; item++) {
            let current = finalFormJson[item];
            // remainingList = remove(finalFormJson, (r) => {
            //   return r.id == item;
            // });

            var remainingList = remove(finalFormJson, (f) => {
              return f.title.toLowerCase() === current.title.toLowerCase();
            });
            // if (remainingList.length !== 0) {
            remainingList.map((finalItem, index) => {
              if (index !== 0) {
                current = deepmerge(current, finalItem);
              }
            });
            // } else {
            //   finalList.push(current);
            // }
            finalList.push(current);
          }
        }
        finalFormJson.map((rest) => {
          finalList.push(rest);
        });
        console.log("merge component==> finalList", finalList);

        finalList &&
          finalList.map((item) => {
            item.subSectionsList.map((innerItem) => {
              // if (!innerItem.is_sub_heading) {
              //   if (innerItem.dependsOnIndex) {
              //     innerItem.visible = false;
              //   } else {
              //     innerItem.visible = true;
              //   }
              // }
              if (innerItem.dependsOnIndex) {
                innerItem.visible = false;
              } else {
                innerItem.visible = true;
              }
            });
          });

        setDisorderData(finalList);
        // props.onChange(finalList);
      }
    }
  }, [props.disorderArr, props.formData, props.disorderNames]);

  const uploadProps = {
    name: "file",
    multiple: true,
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    action:
      (process.env.REACT_APP_API_URL || "/backend/api/v1/") + "attachments",
    headers: { token: token },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log("status==>", info.file.response);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onFileChange = (file, widget, parentId, childId) => {
    !props.print && onValueChange(widget, file, parentId, childId);
  };

  const onValueChange = (widget, value, parentId, childId) => {
    const stateObj = [...disorderData];
    if (widget === "UploadWidget") {
      stateObj[parentId] = {
        ...stateObj[parentId],
        subSectionsList: [...stateObj[parentId]["subSectionsList"]],
      };
      stateObj[parentId]["subSectionsList"][childId] = {
        ...stateObj[parentId]["subSectionsList"][childId],
        ["fileObject"]: value,
      };
    } else if (widget === "PedigreeChart") {
      stateObj[parentId] = {
        ...stateObj[parentId],
        subSectionsList: [...stateObj[parentId]["subSectionsList"]],
      };
      stateObj[parentId]["subSectionsList"][childId] = {
        ...stateObj[parentId]["subSectionsList"][childId],
        ["fileObject"]: value,
      };
    } else if (
      widget === "string" ||
      widget === "number" ||
      widget === "MinMaxWidget"
    ) {
      stateObj[parentId] = {
        ...stateObj[parentId],
        subSectionsList: [...stateObj[parentId]["subSectionsList"]],
      };
      stateObj[parentId]["subSectionsList"][childId] = {
        ...stateObj[parentId]["subSectionsList"][childId],
        ["response"]: value,
      };
    } else if (widget === "RadioGroupWidget") {
      const current = findIndex(
        stateObj[parentId]["subSectionsList"][childId]["options"],
        (item) => item.title == value
      );
      stateObj[parentId] = {
        ...stateObj[parentId],
        subSectionsList: [...stateObj[parentId]["subSectionsList"]],
      };
      stateObj[parentId]["subSectionsList"][childId] = {
        ...stateObj[parentId]["subSectionsList"][childId],
        options: [...stateObj[parentId]["subSectionsList"][childId].options],
      };
      stateObj[parentId]["subSectionsList"][childId].options.map(
        (option) => (option.checked = false)
      );
      stateObj[parentId]["subSectionsList"][childId].options[current] = {
        ...stateObj[parentId]["subSectionsList"][childId].options[current],
        ["checked"]: true,
      };
    } else if (widget === "CheckboxGroupWidget") {
      const checkedOptions =
        stateObj[parentId]["subSectionsList"][childId]["options"];

      checkedOptions.map((currentItem) => {
        var current = currentItem;
        currentItem.checked = false;
        value.map((v) => {
          if (current.title === v) {
            currentItem.checked = true;
          }
        });
      });

      stateObj[parentId] = {
        ...stateObj[parentId],
        subSectionsList: [...stateObj[parentId]["subSectionsList"]],
      };
      stateObj[parentId]["subSectionsList"][childId] = {
        ...stateObj[parentId]["subSectionsList"][childId],
        options: checkedOptions,
      };
    }
    setDisorderData(stateObj);
    props.onChange(stateObj);
  };

  const onChange = (value, parentId, childId, widget) => {
    const stateObj = [...disorderData];
    stateObj[parentId]["subSectionsList"][childId].options.map(
      (option) => (option.checked = false)
    );
    if (widget === "CheckboxGroupWidget") {
      stateObj[parentId]["subSectionsList"][childId].options.map(
        (checkboxItem) => {
          value.map((item) => {
            if (item === checkboxItem.title) {
              checkboxItem.checked = true;
            }
          });
        }
      );
    } else {
      var current = findIndex(
        stateObj[parentId]["subSectionsList"][childId]["options"],
        (item) => item.title == value
      );
      stateObj[parentId]["subSectionsList"][childId]["options"][
        current
      ].checked = true;
    }

    stateObj[parentId]["subSectionsList"].map((item) => {
      // if (!item.is_sub_heading) {
      //   if (item.dependsOnIndex) {
      //     const dependsOnArrayIndex = findIndex(
      //       stateObj[parentId]["subSectionsList"],
      //       (filterItem) => filterItem.uniqueId == item.dependsOnIndex
      //     );
      //     stateObj[parentId]["subSectionsList"][dependsOnArrayIndex][
      //       "options"
      //     ].map((option) => {
      //       if (
      //         option.title.toLowerCase() === item.dependsOnValue.toLowerCase()
      //       ) {
      //         if (option.checked) {
      //           item.visible = true;
      //         } else {
      //           item.visible = false;
      //         }
      //       } else {
      //         // item.visible = false;
      //       }
      //     });
      //   }
      // }

      if (item.dependsOnIndex) {
        const dependsOnArrayIndex = findIndex(
          stateObj[parentId]["subSectionsList"],
          (filterItem) => filterItem.uniqueId == item.dependsOnIndex
        );
        console.log(
          "checking onchange==>",
          item,
          dependsOnArrayIndex,
          stateObj[parentId]["subSectionsList"][dependsOnArrayIndex]
        );
        stateObj[parentId]["subSectionsList"][dependsOnArrayIndex]["options"] &&
          stateObj[parentId]["subSectionsList"][dependsOnArrayIndex]["options"]
            .length > 0 &&
          stateObj[parentId]["subSectionsList"][dependsOnArrayIndex][
            "options"
          ].map((option) => {
            if (
              option.title.toLowerCase() === item.dependsOnValue.toLowerCase()
            ) {
              if (option.checked) {
                item.visible = true;
              } else {
                item.visible = false;
              }
            } else {
              // item.visible = false;
            }
          });
      }
    });
    // stateObj[parentId]["subSectionsList"].map((item) => {
    //   !item.is_sub_heading && item.dependsOnIndex !== ""
    //     ? (item.visible = false)
    //     : (item.visible = true);
    // });
    // stateObj[parentId]["subSectionsList"].visible = true;
    console.log("after changing==>", stateObj);
    setDisorderData(stateObj);
    props.onChange(stateObj);
  };

  console.log("disorderData in item view", disorderData);
  const disorderDataNew = {
    item: {
      form_json: {
        disorders: [...disorderListData],
      },
    },
  };

  console.log("disorderDataNew", disorderDataNew);

  const gettingCorrect = (array, type) => {
    if (type === "CheckboxGroupWidget") {
      const correct = array.length !== 0 && find(array, (item) => item.checked);
      const newItems = [];
      array.map((item) => {
        item.checked && newItems.push(item.title);
      });
      console.log("checkbox array ==>", newItems);
      return newItems;
    }
    const correct = array.length !== 0 && find(array, (item) => item.checked);
    return correct ? correct.title : "";
  };
  let spanValue =
    scannedDocument &&
    scannedDocument.files &&
    scannedDocument.files.length > 0;
  // const [spanValue, setSpanValue] = useState(false);
  // if (scannedDocument) {
  //   if (scannedDocument.files.length > 0) {
  //     setSpanValue(true);
  //   } else {
  //     setSpanValue(false);
  //   }
  // }

  const removeFile = () => {
    // setScannedDocument([]);
    const scannedDocument = { files: [] };
    props.formInstance.setFieldValue(
      ["response_json", "scanned_document"],
      scannedDocument
    );
    // props.onChange(scannedDocument);
    // message.success(`file uploaded deleted.`);
  };

  let sectionRender = (
    <div>
      {props.print && (
        <Row gutter={16}>
          <Col span={24}>
            <Space
              direction="horizontal"
              style={{
                width: "100%",
                justifyContent: "center",
                margin: "20px",
              }}
              size="medium"
            >
              <h3>Patient name:-</h3> <Input />
            </Space>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={spanValue ? 10 : 24}>
          {disorderData &&
            disorderData.length !== 0 &&
            disorderData.map((disorder, index) => (
              <Card
                type="inner"
                title={disorder.title}
                key={disorder.id}
                className="main-card"
                // style={{ width: "1200px" }}
              >
                <Row gutter={16}>
                  {disorder.subSectionsList.length !== 0 &&
                    disorder.subSectionsList.map(
                      (disorderDetail, nestedIndex) =>
                        disorderDetail.hasOwnProperty("is_sub_heading") ? (
                          <Col span={disorderDetail.span} className="heading">
                            {disorderDetail.visible && (
                              <Space>
                                <p>{disorderDetail.sub_heading_title}</p>
                              </Space>
                            )}
                          </Col>
                        ) : (
                          <>
                            {disorderDetail.visible && (
                              <Col
                                span={
                                  (!props.print &&
                                    !scannedDocument.files.length &&
                                    disorderDetail.span) ||
                                  ((props.print ||
                                    scannedDocument.files.length) &&
                                    24)
                                }
                                className="subsection"
                              >
                                <Space className="subsection-title">
                                  {disorderDetail.title}
                                </Space>
                                <br />
                                {disorderDetail.widget === "RadioGroupWidget" &&
                                  disorderDetail.options.length !== 0 && (
                                    <Radio.Group
                                      value={gettingCorrect(
                                        disorderDetail.options
                                      )}
                                      onChange={(value) => {
                                        onChange(
                                          value.target.value,
                                          index,
                                          nestedIndex
                                        );
                                        onValueChange(
                                          disorderDetail.widget,
                                          value.target.value,
                                          index,
                                          nestedIndex
                                        );
                                      }}
                                    >
                                      {disorderDetail.options.map((option) => (
                                        <Space
                                          className="options"
                                          style={{ flex: 1 }}
                                        >
                                          <Radio
                                            value={option.title}
                                            defaultChecked={option.checked}
                                          >
                                            {option.title}
                                          </Radio>
                                        </Space>
                                      ))}
                                    </Radio.Group>
                                  )}
                                {disorderDetail.widget ===
                                  "CheckboxGroupWidget" &&
                                  disorderDetail.options.length !== 0 && (
                                    <Checkbox.Group
                                      defaultValue={gettingCorrect(
                                        disorderDetail.options,
                                        "CheckboxGroupWidget"
                                      )}
                                      onChange={(value) => {
                                        onValueChange(
                                          disorderDetail.widget,
                                          value,
                                          index,
                                          nestedIndex
                                        );
                                        onChange(
                                          value,
                                          index,
                                          nestedIndex,
                                          disorderDetail.widget
                                        );
                                      }}
                                    >
                                      {disorderDetail.options.map((option) => (
                                        <Space
                                          className="options"
                                          style={{ flex: 1 }}
                                        >
                                          <Checkbox
                                            value={option.title}
                                            checked={option.checked}
                                          >
                                            {option.title}
                                          </Checkbox>
                                        </Space>
                                      ))}
                                    </Checkbox.Group>
                                  )}

                                {disorderDetail.visible &&
                                  disorderDetail.widget === "string" && (
                                    <Input
                                      // placeholder="Text"
                                      placeholder={
                                        !props.print && disorderDetail.title
                                      }
                                      value={disorderDetail.response}
                                      onChange={(value) =>
                                        onValueChange(
                                          disorderDetail.widget,
                                          value.target.value,
                                          index,
                                          nestedIndex
                                        )
                                      }
                                    />
                                  )}
                                {disorderDetail.visible &&
                                  disorderDetail.widget === "number" && (
                                    <>
                                      {disorderDetail.minMaxEnabled ? (
                                        <InputNumber
                                          placeholder={
                                            !props.print &&
                                            `Choose between ${disorderDetail.min} to ${disorderDetail.max}`
                                          }
                                          min={disorderDetail.min}
                                          max={disorderDetail.max}
                                          style={{ width: "200px" }}
                                        />
                                      ) : (
                                        <InputNumber
                                          // placeholder="Text"
                                          placeholder={
                                            !props.print && disorderDetail.title
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                {disorderDetail.widget === "InputWithTags" && (
                                  <InputWithTags
                                    placeholderValue={
                                      !props.print && disorderDetail.title
                                    }
                                  />
                                )}
                                {disorderDetail.widget === "UploadWidget" && (
                                  <>
                                    <SelectUpload
                                      files={disorderDetail.fileObject}
                                      onChange={(file) => {
                                        onFileChange(
                                          file,
                                          disorderDetail.widget,
                                          index,
                                          nestedIndex
                                        );
                                      }}
                                    />
                                  </>
                                )}
                                {disorderDetail.widget === "PedigreeChart" && (
                                  <>
                                    <Row gutter={[16, 16]}>
                                      <Col span={12}>
                                        <b>
                                          {" "}
                                          You can create a Pedigree Chart on
                                          this website and can upload it here
                                        </b>
                                        :
                                        <br />
                                        <Button type="link">
                                          <a
                                            href="https://creately.com"
                                            target="_blank"
                                          >
                                            https://creately.com
                                          </a>
                                        </Button>
                                      </Col>
                                      <Col span={12}>
                                        <SelectUpload
                                          files={disorderDetail.fileObject}
                                          onChange={(file) => {
                                            onFileChange(
                                              file,
                                              disorderDetail.widget,
                                              index,
                                              nestedIndex
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Col>
                            )}
                          </>
                        )
                    )}
                </Row>
              </Card>
            ))}
        </Col>
        <Col span={spanValue ? 14 : 0} style={{ position: "relative" }}>
          <Affix
            offsetTop={30}
            onChange={(affixed) => console.log("affixed", affixed)}
          >
            <div className="scanned-document-container">
              <Popconfirm
                title="Confirm delete?"
                onConfirm={(e) => {
                  removeFile();
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
              {scannedDocument &&
                scannedDocument.files &&
                scannedDocument.files.length > 0 &&
                scannedDocument.files.map((item) => <MediaShow file={item} />)}
            </div>
          </Affix>
        </Col>
        {/* <Col span={spanValue ? 1 : 0}>
          <Popconfirm
            title="Confirm delete?"
            onConfirm={(e) => {
              removeFile();
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </Col> */}
      </Row>
    </div>
  );

  let mobileRender = (
    <div>
      {console.log("disorderListDatain mobile", disorderListData)}
      {disorderListData.length > 0 && (
        <DisorderItemSections disorderData={disorderListData} />
      )}
    </div>
  );

  return (
    // <>{mobileRender}</>
    <>{checkMob() ? mobileRender : sectionRender}</>
  );
};

export default DisorderItemView;
