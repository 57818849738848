import React from "react";
import { Button, Dropdown, Input } from "antd";
import MrSelect from "../../../components/UI/MrSelect/MrSelect";
import MrInput from "../../../components/UI/MrInput/MrInput";
import { MrConnectField } from "mr_react_framework";
// import OrderStatus from "../../Settings/OrderStatus/OrderStatus";
import { MrFilter } from "mr_react_framework";
// import Edit from "instructure-qi/lib/components/essay/Edit";

// props
// changed = setParams
const AppConstantFilter = props => {
  let toRender;
  // debugger;
  // const Filter = MrFilter({ name: "AppConstants", ...props });
  console.log("props in Leadfilter app_constant", props);
  let extraFilters = [];
  extraFilters.push(
    <MrInput
      key="helloFilter"
      elementType="select"
      options={["hello", "you are awesome"]}
      placeholder="Select a filter"
      defaultValue={props.helloFilter}
      changed={props.changed}
      fieldInfo={{ key: "helloFilter" }}
    />
  );
  extraFilters.push(
    <MrInput
      key="helloFilter2"
      elementType="select"
      options={[{ value: "hello", label: "Hello" }]}
      valueKey="value"
      textKey="label"
      placeholder="Select a filter"
      defaultValue={props.helloFilter2}
      changed={props.changed}
      fieldInfo={{ key: "helloFilter2" }}
      style={{ width: 200 }}
    />
  );

  return extraFilters;
};
export default AppConstantFilter;
