import React from "react";
import "./SideDrawer.css";
import classNames from 'classnames';
import AppLayoutSiderMobile from "../Layouts/AppLayout/AppLayout";

const SideDrawer = props => {
  let btnClass = classNames('side-drawer', 'mobile-only')
  return (
    <div className={btnClass}>
      {props.children}
    </div>
  );
};

export default SideDrawer;
