import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import { buildOptionsArr, buildSchemaObject } from "../../lib/utils/helperMethods";

const InputWithTags = () => {
  return (
    <>
      <Select
        mode="tags"
        style={{ width: "100%" }}
        // placeholder={placeholderText}
      ></Select>
    </>
  )
}

export const movementDisorderForm = {
//   personal_info: {
//     title: "Personal Info",
//     fields: {
//       type: "object",
//       properties: {
//         aadhar_card: {
//           title: "Aadhar Number",
//           type: "string",
//           span: 8,
//           setInitialValue: true
//         },
//         ethnic_background: {
//           title: "Ethnic Background",
//           type: "string",
//           span: 8,
//           setInitialValue: true,
//           widget: InputWithTags
//         },
//         socio_economic_status: {
//           title: "Socio Economic Status",
//           type: "string",
//           span: 8,
//           setInitialValue: true,
//           // widget: InputWithTags
//         },
//         gender: {
//           title: "Gender",
//           type: "string",
//           widget: "RadioGroupWidget",
//           span: 8,
//           setInitialValue: true,
//           widgetConfig: {
//             options: buildOptionsArr(['Male','Female','Other'])
//           },
//         },
//         dob: {
//           title: "Date Of Birth",
//           type: "string",
//           format: "date-time",
//           span: 12,
//           setInitialValue: true,
//           widget: "DatePickerWidget",
//           widgetConfig: {
//             style : {
//               width: "150px"
//             }
//           }
//         },
//         upload_consent_form: {
//           title: "Upload Consent Form",
//           type: "string",
//           span: 12,
//           widget: "UploadWidget",
//           widgetConfig: {
//             listType: "picture-card",
//             // type: "base64",
//             dragger: {
//               text: "Click or drag file to this area to upload",
//               hint:
//                 "Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files",
//             },
//             buttonText: "Upload files",
//             // listType: "picture-card",
//           }
//         },
//       }
//     }
//   },
  history: {
    title: "History",
    fields: {
      type: "object",
      properties: {
        // uhid:{
        //   title: "UHID",
        //   type: "number",
        //   span: 12,
        //   setInitialValue: true,
        // },

        age_radio: {
          title: "Age at presentation than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        general_history: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "general_history", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>general history</p>
        },

        age_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_radio"],
          children: ({ getFieldValue, sourceValues }) => {
            // console.log("chilren, line 42");
            // console.log("sourceValues", sourceValues);
            const nameVal = getFieldValue(["disorder_information", "age_radio"]);
            // console.log("nameVal", nameVal);

            let finalRender = null;

            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_options", title: "Age in years", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />

              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in years" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in years" />
              //   </Form.Item>
              // );
            } else if (nameVal === "No" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "No")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_options", title: "Age in months", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in months" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in months" />
              //   </Form.Item>
              // );
            }
            return finalRender;
            // return <React.Fragment>{finalRender}</React.Fragment>;
          },
        },

        age_at_symptom_onset: {
          title: "Age at symptom onset greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        age_at_symptom_onset_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_at_symptom_onset"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "age_at_symptom_onset"]);
            let finalRender = null;

            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_at_symptom_onset === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_at_symptom_onset_options", title: "Age in years", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            } else if (nameVal === "No" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_at_symptom_onset === "No")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_at_symptom_onset_options", title: "Age in months", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            }
            return finalRender;
          },
        },

        presenting_symptoms: {
          type: "string",
          default: "",
          title: "Presenting symptoms",
          span: 12,
          setInitialValue: true,
          widget: InputWithTags
        },

        onset: {
          title: "Onset",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Acute', 'Sub acute', 'Chronic'])
          },
        },
        course: {
          title: "Course",
          type: "string",
          widget: "RadioGroupWidget",
          span: 8,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Improving', 'Static', 'Progressive'])
          },
        },

        antenatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "antenatal_risk_factors", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Antenatal risk factors</p>
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        hypothyroidism: {
          title: "Hypothyroidism",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        pih: {
          title: "PIH",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        gdm: {
          title: "GDM",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        fever: {
          title: "Fever",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        skin_rash: {
          title: "Skin rash",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        drug_exposure: {
          title: "Drug exposure",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        others_antenatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 8,
          setInitialValue: true,
          widget: InputWithTags
        },

        // Perinatal history

        perinatal_history: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "perinatal_history", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Perinatal history</p>
        },

        gestational_age_input: {
          title: "Gestational age",
          type: "string",
          span: 8,
          // setInitialValue: true,
          // widget: "RadioGroupWidget",
          // widgetConfig: {
          //   options: buildOptionsArr(['Preterm', 'Term', 'Post term'])
          // },
        },

        gestational_age: {
          title: "Gestational age",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Preterm', 'Term', 'Post term'])
          },
        },

        birth_weight_gram: {
          title: "Birth weight (grams)",
          type: "string",
          span: 8,
          // setInitialValue: true,
          // widget: "RadioGroupWidget",
          // widgetConfig: {
          //   options: buildOptionsArr(['SGA', 'AGA', 'LGA'])
          // },
        },
        birth_weight: {
          title: "Birth weight",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['SGA', 'AGA', 'LGA'])
          },
        },

        // Neonatal risk factors
        neonatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "neonatal_risk_factors", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neonatal risk factors</p>
        },

        seizures: {
          title: "Seizures",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        mechanical_ventilation: {
          title: "Mechanical Ventilation",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        surfactant_administration: {
          title: "Surfactant Administration",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        phototherapy: {
          title: "Phototherapy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        exchange_transfusion: {
          title: "Exchange Transfusion",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        birth_asphyxia: {
          title: "Birth asphyxia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        hypoglycaemia: {
          title: "Hypoglycaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        sepsis: {
          title: "Sepsis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        trauma: {
          title: "Trauma",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        cogulopathy: {
          title: "Cogulopathy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        others_neonatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 12,
          setInitialValue: true,
          widget: InputWithTags
        },

        // Neonatal risk factors ends

        // Developmental Domain subheading
        developmental_domain: {
          type: "string",
          span: 24,
          // setInitialValue: true,
          is_sub_heading: true, //using in show view
          sub_heading_title: "Developmental domain", //using in show view
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Developmental domain</p>
        },

        developmental_delay:{
          title: "Developmental delay (pre-morbid)",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        gdd_id:{
          title: "GDD/ID",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        gross_motor: {
          title: "Gross motor",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        fine_motor: {
          title: "Fine motor",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        socio_personal: {
          title: "Socio-personal",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        language: {
          type: "string",
          title: "Language",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        vision: {
          type: "string",
          title: "Vision",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        hearing: {
          type: "string",
          title: "Hearing",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        // End development domain

        regression_of_milestones: {
          title: "Regression of milestones",
          type: "string",
          span: 4,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        history_of_present_illness: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "History of Present Illness", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>History of Present Illness</p>
        },

        type_of_movement_disorder: {
          title: "Type of movement disorder",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Hypokinetic', 'Hyperkinetic'])
          },
        },

        dystonia: {
          type: "string",
          title: "Dystonia",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        chorea: {
          type: "string",
          title: "Chorea",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        athetosis: {
          type: "string",
          title: "Athetosis",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        tremors: {
          type: "string",
          title: "Tremors",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        stereotypies: {
          type: "string",
          title: "Stereotypies",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        ballismus: {
          type: "string",
          title: "Ballismus",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        tics: {
          type: "string",
          title: "Tics",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        slowing_of_activities: {
          type: "string",
          title: "Slowing of activities",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        decreased_facial_expression: {
          type: "string",
          title: "Decreased facial expression",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        any_setting_for_conversion: {
          type: "string",
          title: "Any setting for conversion",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        precipitating_factor: {
          type: "string",
          title: "Precipitating Factor",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        precipitating_factor_details: {
          title: "Precipitating Factor details",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.precipitating_factor"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "precipitating_factor"]);
            let finalRender = null;

            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.precipitating_factor === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "precipitating_factor_details", title: "Details", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            }
            return finalRender;
          },
        },

        // distribution: {
        //   is_sub_heading: true,
        //   sub_heading_title: "Distribution",
        //   type: "number",
        //   span: 24,
        //   className: "m-b-0",
        //   widget: (props) => <p>Distribution</p>
        // },

        segmental: {
          type: "string",
          title: "Segmental",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        focal: {
          type: "string",
          title: "Focal",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        multifocal: {
          type: "string",
          title: "Multifocal",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        generalised: {
          type: "string",
          title: "Generalised",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        hemidystonia: {
          type: "string",
          title: "Hemidystonia",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        others_distribution: {
          type: "string",
          title: "Others",
          span: 12,
          setInitialValue: true,
        },

        stiffness_of_limbs: {
          type: "string",
          title: "Stiffness of limbs",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        diurnal_variation: {
          type: "string",
          title: "Diurnal variation",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        aggravating_factors: {
          type: "string",
          title: "Aggravating factors",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        relieving_factors: {
          type: "string",
          title: "Relieving factors",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        cognitive_decline: {
          type: "string",
          title: "Cognitive decline",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        deterioration_of_speech: {
          type: "string",
          title: "Deterioration of speech",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        difficulty_in_chewing: {
          type: "string",
          title: "Difficulty in chewing",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        difficulty_in_swallowing: {
          type: "string",
          title: "Difficulty in swallowing",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        nasal_regurgitation: {
          type: "string",
          title: "Nasal regurgitation",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        unsteadiness_of_gait: {
          type: "string",
          title: "Unsteadiness of gait",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        truncal_unsteadiness: {
          type: "string",
          title: "Truncal unsteadiness",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        difficulty_in_buttoning_shirts: {
          type: "string",
          title: "Difficulty in buttoning or unbuttoning shirts",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        deterioration_of_hand_writing: {
          type: "string",
          title: "Deterioration of handwriting",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        roving_eye_movements: {
          type: "string",
          title: "Roving eye movements",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        history_of_preceding_fever: {
          type: "string",
          title: "History of preceding fever",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        night_blindness: {
          type: "string",
          title: "Night-blindness",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        photosensitivity: {
          type: "string",
          title: "Photosensitivity",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        recurrent_sino_pulmonary_infections: {
          type: "string",
          title: "Recurrent sino-pulmonary infections",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        preceeding_rash: {
          type: "string",
          title: "Preceeding rash/varicella in last 1 month",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        trauma_2: {
          type: "string",
          title: "Trauma",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        drug_ingestion: {
          type: "string",
          title: "Drug ingestion",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        exposure_to_toxins: {
          type: "string",
          title: "Exposure to toxins",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        altered_sensorium: {
          type: "string",
          title: "Altered sensorium",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        seizures: {
          type: "string",
          title: "Seizures",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Focal', 'Generalised', 'Unknown'])
          },
        },

        // doubt in "Description of seizure",

        // description_of_seizure: {
        //   title: "Description of seizure",
        //   type: "string",
        //   span: 12,
        //   setInitialValue: true,
        //   // className: "m-b-0",
        //   dependsOn: ["disorder_information.seizures"],
        //   children: ({ getFieldValue, sourceValues }) => {
        //     const nameVal = getFieldValue(["disorder_information", "seizures"]);
        //     let finalRender = null;

        //     if (nameVal === "Focal" || (!nameVal && sourceValues.id && sourceValues.disorder_information.seizures === "Focal")) {
        //       finalRender = <FormRenderer
        //         formObj={buildSchemaObject({key: "description_of_seizure", title: "Description of seizure", inputType: "string"})}
        //         propertyKey={"disorder_information"}
        //         sourceValues={sourceValues}
        //       />
        //     }else if (nameVal === "Generalised" || (!nameVal && sourceValues.id && sourceValues.disorder_information.seizures === "Generalised")) {
        //       finalRender = <FormRenderer
        //         formObj={buildSchemaObject({key: "description_of_seizure", title: "Description of seizure", inputType: "string"})}
        //         propertyKey={"disorder_information"}
        //         sourceValues={sourceValues}
        //       />
        //     }
        //     return finalRender;
        //   },
        // },

       headache: {
          type: "string",
          title: "Headache",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        vomiting: {
          type: "string",
          title: "Vomiting",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        visual_disturbances: {
          type: "string",
          title: "Visual disturbances",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        history_to_suggest_migraine: {
          type: "string",
          title: "History to suggest migraine",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        jaundice: {
          type: "string",
          title: "Jaundice",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        behavioural_symptoms: {
          type: "string",
          title: "Behavioural symptoms",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        behavioural_symptoms_details: {
          title: "Behavioural symptoms details",
          type: "string",
          span: 8,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.behavioural_symptoms"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "behavioural_symptoms"]);
            let finalRender = null;

            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.behavioural_symptoms === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "behavioural_symptoms_details", title: "Autistic traits", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />

            }
            return finalRender;
          },
        },

        family_history: {
          type: "string",
          title: "Family history",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        sleep_disturbances: {
          type: "string",
          title: "Sleep disturbances",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

      },
    },
  },

  general_examination: {
    title: "General Examination",
    fields: {
      type: "object",
      properties: {

        neurocutaneous_markers: {
          title: "Neurocutaneous markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        cyanosis: {
          title: "Cyanosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        kf_ring: {
          title: "KF ring",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        cataract: {
          title: "Cataract",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        progeroid_facies: {
          title: "Progeroid facies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        photosensitivity: {
          title: "Photosensitivity",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        telangiectasia: {
          title: "Telangiectasia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        signs_of_liver_cell_failure: {
          title: "Signs of liver cell failure",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        rickets: {
          title: "Rickets",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        other_ge: {
          title: "Others",
          type: "string",
          setInitialValue: true,
          span: 12,
          widget: InputWithTags
        },

        vitals: {
          title: "Vitals",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Stable','Unstable'])
          },
        },

        //anthropometry subheading....

        anthropometry: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Anthropometry", //using in show
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Anthropometry</p>
        },

        weight: {
          title: "Weight",
          type: "number",
          span: 8,
          setInitialValue: true,
          className: "p-l-10",
        },
        height: {
          title: "Height",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        bmi: {
          title: "BMI",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        head_circumference: {
          title: "Head circumference",
          type: "number",
          span: 8,
          setInitialValue: true,
        },

        //ends_anthropometry

      },
    },
  },

  neurologicalexamination: {
    title: "Neurological Examination",
    fields: {
      type: "object",
      properties: {

        //HMF subheading
        HMF: {
          // title: "HMF",
          is_sub_heading: true, //using in show view
          sub_heading_title: "HMF", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>HMF</p>
        },

        mmse_score: {
          title: "MMSE Score",
          type: "string",
          span: 6,
          // setInitialValue: true,
          // widget: "RadioGroupWidget",
          // widgetConfig: {
          //   options: buildOptionsArr(['Yes','No', 'N/A'])
          // },
        },
        gcs: {
          title: "GCS",
          type: "string",
          span: 6,
          // setInitialValue: true,
          // widget: "RadioGroupWidget",
          // widgetConfig: {
          //   options: buildOptionsArr(['Yes','No', 'N/A'])
          // },
        },

        hyperactive: {
          title: "Hyperactive",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        poor_attention: {
          title: "Poor attention",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        speech: {
          title: "Speech",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        poor_eye_contact: {
          title: "Poor eye contact",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        cranial_nerves_examination: {
          is_sub_heading: true,
          sub_heading_title: "Cranial nerves examination",
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cranial nerves examination</p>
        },

        // Cranial nerves deficit

        cranial_nerves_deficit: {
          title: "Cranial nerves deficit",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        ptosis: {
          title: "Ptosis",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        strabismus: {
          title: "Strabismus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        fundus: {
          title: "Fundus",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        jaw_muscles_involvement: {
          title: "Jaw muscles involvement",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        gag_reflex: {
          title: "Gag reflex",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Diminshed', 'Exaggerated'])
          },
        },

        bulbar_weakness: {
          title: "Bulbar weakness",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        tongue_movements: {
          title: "Tongue movements",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Decreased'])
          },
        },

        Other_cranial_nerves: {
          title: "Other cranial nerves",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        //Motor system (sub heading)
        motor_system: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Motor System", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Motor System</p>
        },

        posture: {
          title: "Posture",
          type: "string",
          setInitialValue: true,
          widget: InputWithTags,
          span: 8,
        },

        bulk: {
          title: "Bulk",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Wasting', 'Hypertrophy'])
          },
        },

        tone_normal: {
          title: "Tone normal",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Hypotonia','Hypertonia', 'Variable'])
          },
        },

        power_charting: {
          title: "Power charting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['1','2','3','4','5'])
          },
        },

        deep_tendon_reflexes: {
          title: "Deep tendon reflexes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Areflexia', 'Hyporeflexia', 'Hyperreflexia'])
          },
        },

        plantar_responses: {
          title: "Plantar responses",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Flexor','Extensor', 'No response'])
          },
        },
        // motor system ends here

        //Extrapyramidal signs sub-heading
        extrapyramidal_signs: {
          // title: "Extrapyramidal signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Extrapyramidal Signs", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Extrapyramidal Signs</p>
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        tremor_es: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },
        //Extrapyramidal signs ends (sub section)

        distribution_of_movement_disorder: {
          title: "Distribution of movement disorder",
          type: "string",
          setInitialValue: true,
          span:12,
        },
        //Cerebellar signs starts (sub section)
        cerebellar_signs: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Cerebellar Signs", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cerebellar signs</p>
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        titubation: {
          title: "Titubation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        tremor_cs: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        dysmetria: {
          title: "Dysmetria",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        //Cerebellar signs ends (sub section)

        // Spine/cranium

        gait:{
          title: "Gait",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        spine_cranium: {
          title: "Spine/cranium",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        other_findings: {
          title: "Other findings",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        hearing_deficit: {
          title: "Hearing Deficit",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        rating_scales: {
          title: "Rating scales",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        functional_status: {
          title: "Functional status",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        Course: {
          title: "Course",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Improving', 'Progressive', 'Static'])
          },
        },

        sensory_system_heading: {
          is_sub_heading: true,
          sub_heading_title: "Sensory system",
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Sensory system</p>
        },

        sensory_system: {
          title: "Sensory system",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Touch', 'Pain', 'Temperature', 'position', 'vibration', 'cortical sensation'])
          },
        },
        sensory_system_description: {
          title: "Sensory system description",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        autonomic_nervous_system_heading: {
          is_sub_heading: true,
          sub_heading_title: "Autonomic nervous system",
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Autonomic nervous system</p>
        },
        autonomic_nervous_system:{
          title: " Autonomic nervous system",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        // worst_gcs: {
        //   title: "Worst GCS",
        //   type: "string",
        //   setInitialValue: true,
        //   widget: "RadioGroupWidget",
        //   span: 24,
        //   widgetConfig: {
        //     options: buildOptionsArr(['Yes','No', 'N/A'])
        //   },
        // },

      },
    },
  },

  systemic_examination: {
    title: "Systemic Examination",
    fields: {
      type: "object",
      properties: {

        cardiac: {
          title: "Cardiac",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        respiratory: {
          title: "Respiratory",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        abdominal: {
          title: "Abdominal",
          type: "string",
          setInitialValue: true,
          span: 12,
        },
        // Comorbid_conditions subheading

      },
    },
  },

  investigations: {
    title: "Investigations",
    fields: {
      type: "object",
      properties: {

        cbc: {
          title: "CBC",
          type: "string",
          setInitialValue: true,
          span: 6,
        },

        serum_albumin: {
          title: "Serum albumin",
          type: "string",
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal',])
          },
        },

        serum_albumin_input: {
          title: "Serum albumin",
          type: "string",
          span: 6,
        },

        lipid_profile: {
          title: "Lipid profile",
          type: "string",
          setInitialValue: true,
          span: 6,
        },

        serumafp: {
          title: "Serum AFP",
          type: "string",
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Elevated', 'Normal'])
          },
        },
        serumafp_input: {
          title: "Serum AFP",
          type: "string",
          span: 6,
        },

        liver_enzymes: {
          title: "Liver enzymes",
          type: "string",
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Elevated', 'Normal'])
          },
        },
        liver_enzymes_input: {
          title: "Liver enzymes",
          type: "string",
          span: 6,
        },
        uric_acid: {
          title: "Uric acid",
          type: "string",
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal', 'High'])
          },
        },
        uric_acid_input: {
          title: "Uric acid",
          type: "string",
          span: 6,
        },
        serum_prolactin: {
          title: "Serum prolactin",
          type: "string",
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['High', 'Normal'])
          },
        },
        serum_prolactin_input: {
          title: "Serum prolactin",
          type: "string",
          span: 6,
        },
        neurotransmitters: {
          title: "Neurotransmitters",
          type: "string",
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal', 'High'])
          },
        },
        neurotransmitters_input: {
          title: "Neurotransmitters",
          type: "string",
          span: 6,
        },

        bood_lactate: {
          title: "Bood lactate",
          type: "string",
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['High', 'Normal'])
          },
        },
        bood_lactate_input: {
          title: "Bood lactate",
          type: "string",
          span: 6,
        },
        ammonia: {
          title: "Ammonia",
          type: "string",
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal', 'High'])
          },
        },
        ammonia_input: {
          title: "Ammonia",
          type: "string",
          span: 6,
        },
        vitamin_e: {
          title: "Vitamin E",
          type: "string",
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal'])
          },
        },
        vitamin_e_input: {
          title: "Vitamin E",
          type: "string",
          span: 6,
        },
        vitamin_b12: {
          title: "Vitamin B12",
          type: "string",
          span: 12,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal'])
          },
        },
        vitamin_b12_input: {
          title: "Vitamin B12",
          type: "string",
          span: 12,
        },
        copper: {
          title: "Copper",
          type: "string",
          span: 12,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal', 'High'])
          },
        },
        copper_input: {
          title: "Copper",
          type: "string",
          span: 12,
        },

        Ceruloplasmin: {
          title: "Ceruloplasmin",
          type: "string",
          span: 12,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal'])
          },
        },
        Ceruloplasmin_input: {
          title: "Ceruloplasmin",
          type: "string",
          span: 12,
        },
        hours_urine_copper: {
          title: "24 hours urine copper",
          type: "string",
          span: 12,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr([ 'Normal', 'High'])
          },
        },
        hours_urine_copper_input: {
          title: "24 hours urine copper",
          type: "string",
          span: 12,
        },

        tms: {
          title: "TMS",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        urine_gcms: {
          title: "Urine GCMS",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

       csf_sugar: {
          title: "CSF sugar",
          type: "string",
          span: 12,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal'])
          },
        },
        csf_sugar_input: {
          title: "CSF sugar",
          type: "string",
          span: 12,
        },

        csf_lactate: {
          title: "CSF lactate",
          type: "string",
          span: 12,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['High', 'Normal'])
          },
        },
        csf_lactate_input: {
          title: "CSF lactate",
          type: "string",
          span: 12,
        },

        csf_protein: {
          title: "CSF protein",
          type: "string",
          span: 12,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['High', 'Normal'])
          },
        },
        csf_protein_input: {
          title: "CSF protein",
          type: "string",
          span: 12,
        },

        csf_viral_markers: {
          title: "CSF viral markers",
          type: "string",
          span: 12,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },
        csf_viral_markers_input: {
          title: "CSF viral markers",
          type: "string",
          span: 12,
        },

        csf_neurotransmitters: {
          title: "CSF neurotransmitters",
          type: "string",
          span: 12,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Low', 'Normal','High'])
          },
        },
        csf_neurotransmitters_input: {
          title: "CSF neurotransmitters",
          type: "string",
          span: 12,
        },

        ecg: {
          title: "ECG",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        echo: {
          title: "ECHO",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        //EEG findings sub-heading
        egg_findings: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "EEG Findings", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>EEG Findings</p>
        },

        type_of_record: {
          title: "Type of record",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Sleep','Awake'])
          },
        },

        background: {
          title: "Background",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Abnormal'])
          },
        },

        sleep_markers: {
          title: "Sleep markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        epileptiform_discharges: {
          title: "Epileptiform discharges",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No', 'N/A'])
          },
        },

        morphology_of_epileptiform_discharges: {
          title: "Morphology of epileptiform discharges",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        frequency_of_epileptiform_discharges: {
          title: "Frequency of epileptiform discharges",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        amplitude_of_epileptiform_discharges: {
          title: "Amplitude of epileptiform discharges",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
        //egg finding ends

        ncs: {
          title: "NCS",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        vep: {
          title: "VEP",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

       bera: {
          title: "BERA",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        ssep_tibial: {
          title: "SSEP tibial",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        muscle_biopsy: {
          title: "Muscle biopsy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        skin_biopsy: {
          title: "Skin biopsy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        genetic_tests: {
          title: "Genetic tests",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

       mri_brain: {
          title: "MRI brain",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        t2_axial: {
          title: "T2 axial",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        t1_axial: {
          title: "T1 axial",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        dwi: {
          title: "DWI",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        adc: {
          title: "ADC",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        mrs: {
          title: "MRS",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        others: {
          title: "Others",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        vasculitis_screening: {
          title: "Vasculitis screening",
          type: "string",
          span: 18,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

        vasculitis_screening_details: {
          type: "string",
          title: "Vasculitis screening details",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.vasculitis_screening"],
          children: ({ getFieldValue, sourceValues }) => {
            console.log("chilren, line 42");
            const nameVal = getFieldValue(["disorder_information", "vasculitis_screening"]);
            let finalRender = null;

            if (nameVal === "Positive" || (!nameVal && sourceValues.id && sourceValues.disorder_information.vasculitis_screening === "Positive")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "vasculitis_screening_details", title: "Details", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            }

            return finalRender;
          },
        },

        ae_panel: {
          title: "AE panel",
          type: "string",
          span: 18,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

        ae_panel_details: {
          type: "string",
          title: "AE panel details",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.ae_panel"],
          children: ({ getFieldValue, sourceValues }) => {
            console.log("chilren, line 42");
            const nameVal = getFieldValue(["disorder_information", "ae_panel"]);
            let finalRender = null;

            if (nameVal === "Positive" || (!nameVal && sourceValues.id && sourceValues.disorder_information.ae_panel === "Positive")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "ae_panel_details", title: "Details", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            }

            return finalRender;
          },
        },

        aso: {
          title: "ASO",
          type: "string",
          span: 18,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'High'])
          },
        },

        adnb: {
          title: "ADNB",
          type: "string",
          span: 18,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'High'])
          },
        },

        other_investigations: {
          title: "Other investigations",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },

  diagnosis: {
    title: "Diagnosis",
    fields: {
      type: "object",
      properties: {

        diagnosis: {
          title: "Diagnosis",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        etiology: {
          title: "Etiology",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

      },
    },
  },

  treatment: {
    title: "Treatment",
    fields: {
      type: "object",
      properties: {
        Antibiotic_herapy: {
          title: "Antibiotic Therapy (duration) " ,
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        antiviral_herapy: {
          title: "Anti-viral Therapy (duration) " ,
          type: "string",
          span: 6,
          setInitialValue: true,
        },

        antiepiletic_medications: {
          title: "Antiepiletic medications",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "SelectWidget",
            widgetConfig: {
              options: buildOptionsArr(["Phenytoin", "Phenobarbital", "Carbamazepine", "Oxcarbazepine", "Gabapentin", "Lacosamide", "Vigabartin", "Valproic Acid", "Lamotrigine", "Topiramate", "Zonisamide", "Levetiracetam", "Clonazepam"]),
            },
            // span: 8,
            setInitialValue: true,
            rules: [
              {
                required: true,
                message: 'Required',
              },
            ]
        },

        movement_disorder_drugs: {
          title: "Movement disorder drugs",
          type: "string",
          span: 6,
          setInitialValue: true,
        },

        immunosuppressive_herapy : {
          title: "Immunosuppressive therapy ",
          type: "string",
          span: 6,
          setInitialValue: true,
        },

        Plasmapheresis: {
          title: "Plasmapheresis",
          type: "string",
          span: 6,
          setInitialValue: true,
        },

        specific_diet: {
          title: "Specific diet",
          type: "string",
          span: 6,
          setInitialValue: true,
        },

        chelation_therapy: {
          title: "Chelation therapy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        physiotherapy: {
          title: "Physiotherapy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        speech_therapy: {
          title: "Speech therapy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        occupational_therapy: {
          title: "Occupational therapy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        deep_brain_stimulation: {
          title: "Deep brain Stimulation",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        non_invasive_neurostimulation: {
          title: "Non-invasive Neurostimulation",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

      },
    },
  },

  outcome: {
    title: "Outcome",
    fields: {
      type: "object",
      properties: {

        mrs: {
          title: "MRS",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        duration_of_follow_up: {
          title: "Duration of follow-up",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        functional_status_at_follow_up: {
          title: "Functional status at follow-up",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

      },
    },
  },

};
