import React, { useEffect, useState } from "react";
import {
  Tabs,
  Button,
  Tooltip,
  Row,
  Col,
  Space,
  Popconfirm,
  message,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, useRouteMatch } from "react-router-dom";
import PatientDisorderItemDetail from "./PatientDisorderItemDetail";
import ActionBtns from "../../../components/ActionBtns/ActionBtns";
import { useDispatch } from "react-redux";
import { actions } from "./redux";
import dayjs from "dayjs";
const { TabPane } = Tabs;

const PatientDisorderList = (props) => {
  const { dataSource } = props;
  const { path, url } = useRouteMatch();

  console.log("12345 - PatientDisorderList ==>", dataSource);
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState(null);
  useEffect(() => {
    if (dataSource && dataSource.length !== 0) {
      setActiveKey(dataSource[0].id);
      window.location.hash = `#id=${dataSource[0].id}`;
    }
  }, []);
  useEffect(() => {
    console.log("12345 - window.location.hash ==>", window.location.hash);

    if (window.location.hash) {
      console.log(
        "12345 - window.location.hash true ==>",
        window.location.hash
      );
      if (window.location.hash.match(/\d.+/g)) {
        console.log(
          "12345 - setting active key 38 ==>",
          window.location.hash.match(/\d.+/g)[0]
        );
        setActiveKey(window.location.hash.match(/\d.+/g)[0]);
      }
    } else if (dataSource && dataSource.length !== 0) {
      console.log(
        "12345 - dataSource.length ==>",
        dataSource.length,
        dataSource[0].id
      );
      window.location.hash = `#id=${dataSource[0].id}`;
      console.log("12345 - setting active key 43 ==>", dataSource[0].id);
      setTimeout(() => {
        setActiveKey(dataSource[0].id);
      }, 100);
      // setActiveKey(dataSource[0].id);
    }
    // const element = document.querySelector(".patient-disorder-row-container");
    // console.log("element==>before", element);
    // if (element) {
    //   element.parentElement.classList.add("patient-disorder-parent");
    // }
    // console.log("element==>after", element);
  }, [window.location.hash]);

  const handleTabClick = (key) => {
    console.log("active keyy==>", typeof key);
    window.location.hash = `#id=${key}`;
    setActiveKey(key);
  };

  const DeleteBtn = ({ id }) => {
    const removeFile = () => {
      dispatch(
        actions.delete({
          id: id,
        })
      );
      message.success(`patiend disorder successfully deleted`);
    };

    return (
      <Popconfirm
        title="Confirm delete?"
        onConfirm={(e) => removeFile()}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined
          // onClick={(e) => props.removeFile()}
          className="remove-image"
        />
      </Popconfirm>
    );
  };

  let finalRender = [];
  dataSource.map((item, i) => {
    console.log("item==>", item);
    const actionButton = (
      <ActionBtns edit={true} id={item.id} path="patients" />
    );

    let tab = (
      <TabPane
        tab={
          <>
            <Space>
              {/* Disorder{item.id} */}
              {item.disorder_details && item.disorder_details.form_name
                ? item.disorder_details.form_name
                : item.id}{" "}
              ({dayjs(item.assessment_date).format("DD-MM-YYYY")})
              <DeleteBtn id={item.id} />
              {/* {actionButton} */}
            </Space>
          </>
        }
        key={item.id}
      >
        {item.response_json.disorder_dependents && (
          <>
            <Space style={{ marginRight: "10px" }}>
              <Tooltip title="Print Final Output">
                <Button
                  onClick={() => {
                    window.open(
                      `/print/patients/${item.patient_id}/disorder/${item.id}`,
                      "_blank"
                    );
                  }}
                  type="primary"
                  // icon={<PrinterOutlined />}
                >
                  Print Final Output
                </Button>
              </Tooltip>
            </Space>
            <Space style={{ marginRight: "10px" }}>
              <Tooltip title="Print Form">
                <Button
                  onClick={() => {
                    window.open(
                      `/print/patients/${item.patient_id}/print_form/${item.id}`,
                      "_blank"
                    );
                  }}
                  // type="primary"
                  // icon={<PrinterOutlined />}
                >
                  Print Form
                </Button>
              </Tooltip>
              <Tooltip title="Edit">
                <Link
                  to={`/u/patients/${item.patient_id}/patient_disorders/${item.id}/edit`}
                >
                  {" "}
                  <Button type="primary">Edit</Button>{" "}
                </Link>
              </Tooltip>
            </Space>
          </>
        )}
        <PatientDisorderItemDetail item={item} />
      </TabPane>
    );
    finalRender.push(tab);
  });

  return (
    <>
      {activeKey !== null && (
        <div className="patient-disorder-row-container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {activeKey}
              <Tabs
                activeKey={activeKey}
                // defaultActiveKey={dataSource.length && dataSource[0].id}
                onTabClick={handleTabClick}
              >
                {finalRender}
              </Tabs>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default PatientDisorderList;
