import React from "react";
import { Select as AntSelect } from "antd";
const Option = AntSelect.Option;

//     showSearch
//     style={{ width: 200 }}
//     placeholder="Select a person"
//     optionFilterProp="children"
//     onChange={onChange}
//     onFocus={onFocus}
//     onBlur={onBlur}
//     onSearch={onSearch}
//     filterOption={(input, o

const MrSelect = props => {
  return (
    <AntSelect style={{ minWidth: "200" }} {...props.config}>
      {props.options.map(option => {
        console.log("option", option);
        let value, label;
        if (typeof option === "object") {
          if (option.value) {
            value = option.value;
          }
          if (option.label) {
            label = option.label;
          }
        } else {
          value = label = option;
        }

        return <Option value={value}>{label}</Option>;
      })}
    </AntSelect>
  );
};
export default MrSelect;
