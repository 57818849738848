import React, { Component, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "antd";
import styled from "styled-components";
import { List, Typography, Card, Row, Col } from "antd";
import { returnPatientsForm } from "../Patients";
import _ from "lodash";
import { adhdForm } from "../../PatientDisorderForms/adhd";
import { MrRow } from "../../../components/UI/MrTable/MrTable";
import { MrColumn } from "../../../components/UI/MrTable/MrTable";

const StyledHeading = styled.span`
  /* background-color: red; */
  font-weight: bold;
  font-size: 150%;
  display: block;
  text-align: center;
  text-decoration: underline;
`;

const Stylevalue = styled.span`
  /* background-color: red; */
  /* font-size: 150%; */
  /* display: block; */
  /* float: ; */
  text-align: center !important;
  padding-right: 10px;
  /* text-align: center; */
  /* text-decoration: underline; */
`;

const getSectionFields = (props, element) => {
  const { item } = props;
  if (!item.id) return null;
  console.log("props ->> ", props);
  console.log("props.item", props.item);
  const patientDetails = props.item;
  console.log("patientDetails", patientDetails);

  let properties = element.fields.properties;

  let sectionFields = [];
  for (const key in properties) {
    if (properties.hasOwnProperty(key)) {
      console.log("key", key);
      console.log("value", properties[key].is_sub_heading);
      console.log("value", patientDetails.disorder_information[key]);
      if (properties[key].is_sub_heading === true) {
        sectionFields.push(
          <List.Item>
            <MrRow className="section-field-row">
              <MrColumn style={{ flex: 4 }} className="sub-heading">
                {properties[key].sub_heading_title}
              </MrColumn>
            </MrRow>
          </List.Item>
        );
      } else {
        sectionFields.push(
          <List.Item>
            <MrRow className="section-field-row">
              <MrColumn style={{ flex: 4 }}>{properties[key].title}</MrColumn>
              <MrColumn style={{ flex: 20 }} className="field-value">
                {" "}
                {patientDetails.disorder_information[key]}
              </MrColumn>
            </MrRow>
          </List.Item>
          // <p><span>{key}</span> - <span>{element.fields.properties[key].title}</span>: <span>{patientDetails.disorder_information[key]}</span></p>
        );
      }
    }
  }

  return sectionFields;
};

const PatientInformationComponent = (props) => {
  const { item } = props;
  if (!item.id) return null;
  console.log("props in PatientInformationComponent");
  console.log("props in PatientsInfo>> ", props);
  console.log("props.item", props.item);

  const patientDetails = props.item;

  console.log("patientDetails Patients information", patientDetails);

  let finalRender = [];
  for (const key in adhdForm) {
    if (adhdForm.hasOwnProperty(key)) {
      const element = adhdForm[key];
      console.log("key", key);
      console.log("element", element);
      finalRender.push(
        <React.Fragment key={`showSection__${key}`}>
          <Card
            title={element.title}
            className="section-card section-card-show"
          >
            {getSectionFields(props, element)}
          </Card>
        </React.Fragment>
      );
    }
  }

  return (
    <div>
      {/* <StyledHeading>
        Patient: {patientDetails.first_name.toUpperCase()} {patientDetails.middle_name.toUpperCase()}{" "}
        {patientDetails.last_name.toUpperCase()}
      </StyledHeading> */}
      <div class="section-card-show">
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 4 }}>Patient name</MrColumn>
            <MrColumn style={{ flex: 20 }} className="field-value">
              <p class="patient-name">
                {patientDetails.first_name} {patientDetails.middle_name}{" "}
                {patientDetails.last_name}
              </p>
            </MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 4 }}>Phone</MrColumn>
            <MrColumn style={{ flex: 20 }} className="field-value">
              {patientDetails.phone}
            </MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 4 }}>Email</MrColumn>
            <MrColumn style={{ flex: 20 }} className="field-value">
              {patientDetails.email}
            </MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 4 }}>Address</MrColumn>
            <MrColumn style={{ flex: 20 }} className="field-value">
              {patientDetails.address}
            </MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 4 }}>Hospital ID</MrColumn>
            <MrColumn style={{ flex: 20 }} className="field-value">
              {patientDetails.hospital_id}
            </MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 4 }}>Disorder</MrColumn>
            <MrColumn style={{ flex: 20 }} className="field-value">
              {patientDetails.disorder_information.disorder_type}
            </MrColumn>
          </MrRow>
        </List.Item>
        {/* <p class="patient-name">{patientDetails.first_name} {patientDetails.middle_name} {patientDetails.last_name}</p> */}
        {/* <p>{patientDetails.phone}</p>
        <p>{patientDetails.email}</p>
        <p>{patientDetails.address}</p>
        <p>{patientDetails.hospital_id}</p>
        <p>{patientDetails.disorder_information.disorder_type}</p> */}
      </div>

      {finalRender}
    </div>
  );
};
export default PatientInformationComponent;
