import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import {
  buildOptionsArr,
  buildSchemaObject,
} from "../../lib/utils/helperMethods";

export const strokeForm = {
  history: {
    title: "History",
    fields: {
      type: "object",
      properties: {
        uhid: {
          title: "UHID",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        gender: {
          title: "Gender",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Male", "Female", "Other"]),
          },
        },
        age_radio: {
          title: "Age greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        age_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_radio"],
          children: ({ getFieldValue, sourceValues }) => {
            // console.log("chilren, line 42");
            // console.log("sourceValues", sourceValues);
            const nameVal = getFieldValue([
              "disorder_information",
              "age_radio",
            ]);
            // console.log("nameVal", nameVal);

            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_radio === "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_options",
                    title: "Age in years",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );

              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in years" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in years" />
              //   </Form.Item>
              // );
            } else if (
              nameVal === "No" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_radio === "No")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_options",
                    title: "Age in months",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in months" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in months" />
              //   </Form.Item>
              // );
            }
            return finalRender;
            // return <React.Fragment>{finalRender}</React.Fragment>;
          },
        },

        age_at_symptom_onset: {
          title: "Age at symptom onset greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        age_at_symptom_onset_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_at_symptom_onset"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "age_at_symptom_onset",
            ]);
            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_at_symptom_onset ===
                  "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_at_symptom_onset_options",
                    title: "Age in years",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            } else if (
              nameVal === "No" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_at_symptom_onset === "No")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_at_symptom_onset_options",
                    title: "Age in months",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        age_at_presentation: {
          title: "Age at presentation(in months)",
          type: "string",
          widget: "InputNumberWidget",
          span: 6,
          setInitialValue: true,
        },

        time_period_between_onset_of_weakness_and_presentation: {
          type: "string",
          title: "Time period between Onset of weakness and presentation ",
          span: 12,
          setInitialValue: true,
        },

        time_period_between_Onset_and_nadir_of_weakness: {
          type: "string",
          title: "Time period between Onset and Nadir of weakness",
          span: 12,
          setInitialValue: true,
        },

        onset: {
          title: "Onset",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["acute", "subacute"]),
          },
        },

        course: {
          title: "Course",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["static", "progressive"]),
          },
        },

        flailness_of_limbs: {
          title: "Flailness of limbs",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        stiffness_of_limbs: {
          title: "Stiffness of limbs",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        pattern_of_weakness: {
          title: "Pattern of weakness",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr([
              "monoparesis",
              "hemiparesis",
              "paraparesis",
              "quadriparesis",
            ]),
          },
        },

        laterality: {
          title: "Laterality",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["unilateral", "bilateral", "multifocal"]),
          },
        },

        speech_disturbances: {
          title: "Speech disturbances",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        memory_disturbances: {
          title: "Memory disturbances",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        bladder_or_bowel_disturbances: {
          title: "Bladder or bowel disturbances",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        behavioural_symptoms: {
          type: "string",
          title: "Behavioural symptoms",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        behavioural_symptoms_details: {
          title: "Behavioural symptoms details",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.behavioural_symptoms"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "behavioural_symptoms",
            ]);
            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.behavioural_symptoms ===
                  "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "behavioural_symptoms_details",
                    title: "Autistic traits",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        squint: {
          type: "string",
          title: "Squint",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        restriction_of_eye_movements: {
          type: "string",
          title: "Restriction of eye movements",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_chewing: {
          type: "string",
          title: "Difficulty in chewing",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        deviation_of_angle_of_mouth: {
          type: "string",
          title: "Deviation of angle of mouth",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_swallowing: {
          type: "string",
          title: "Difficulty in swallowing",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        nasal_twang: {
          type: "string",
          title: "Nasal twang",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        nasal_regurgitation: {
          type: "string",
          title: "Nasal regurgitation",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        sensory_loss: {
          type: "string",
          title: "Sensory loss",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        pattern_of_sensory_loss: {
          title: "Pattern of sensory loss",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        involuntary_movements: {
          type: "string",
          title: "Involuntary movements",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        //// ai-----------to------------------ap doubt

        seizures: {
          type: "string",
          title: "Seizures",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Focal", "Generalised", "Unknown"]),
          },
        },

        description_of_seizure: {
          title: "Description of seizure",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.seizures"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "seizures"]);
            let finalRender = null;

            if (
              nameVal === "Focal" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.seizures === "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "description_of_seizure",
                    title: "Description of seizure",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            } else if (
              nameVal === "Generalised" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.seizures === "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "description_of_seizure",
                    title: "Description of seizure",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },


        headache: {
          type: "string",
          title: "Headache",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        vomiting: {
          type: "string",
          title: "Vomiting",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        visual_disturbances: {
          type: "string",
          title: "Visual disturbances",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        head_and_neck_trauma: {
          type: "string",
          title: "Head and neck trauma",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        unexplained_fever: {
          type: "string",
          title: "Unexplained fever",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        malaise: {
          type: "string",
          title: "Malaise",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        joint_pain: {
          type: "string",
          title: "Joint pain",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        joint_swelling: {
          type: "string",
          title: "joint swelling",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        history_of_recent_infection: {
          type: "string",
          title: "History of  recent infection",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },



        varicella_in_last_months: {
          type: "string",
          title: "Varicella in last 12 months",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        heartdisease: {
          type: "string",
          title: "H/s/o heart disease",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        progressive_pallor: {
          type: "string",
          title: "H/o progressive pallor",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        dyspnoea_on_exertion: {
          type: "string",
          title: "Dyspnoea on exertion",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        sickling_crisis: {
          type: "string",
          title: "Sickling crisis",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        reucrrent_abdominal_pain: {
          type: "string",
          title: "Reucrrent abdominal pain",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        
        plethora: {
          type: "string",
          title: "Plethora",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        recurrent_blood_transfusion: {
          type: "string",
          title: "Recurrent blood transfusion",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        bleeeding_manifestion: {
          type: "string",
          title: "Bleeeding manifestion",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        haemarthrosis: {
          type: "string",
          title: "Haemarthrosis",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        drug_ingestion: {
          title: "Comorbid conditions",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Drug ingestion ", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Drug ingestion </p>
        },

        ocp: {
          type: "string",
          title: "OCP",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        phencyclidine: {
          type: "string",
          title: "phencyclidine",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        exposure_to_toxins: {
          type: "string",
          title: "Exposure to toxins",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        head_and_neck_radiation: {
          type: "string",
          title: "Head and neck radiation",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        prior_history_of_tia: {
          type: "string",
          title: "Prior history of TIA",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        
        contact_with_tb: {
          type: "string",
          title: "Contact with TB",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        family_history: {
          title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Family history", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Family history</p>
        },


        mi_at_young_age: {
          type: "string",
          title: "MI at young age",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hematologic: {
          type: "string",
          title: "Hematologic disease",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        migraine: {
          type: "string",
          title: "Migraine",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        stroke: {
          type: "string",
          title: "Stroke",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hypertension: {
          type: "string",
          title: "Hypertension",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        heart_disease: {
          type: "string",
          title: "Heart disease",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        



        antenatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "antenatal_risk_factors", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Antenatal risk factors</p>,
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        hypothyroidism: {
          title: "Hypothyroidism",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        worsening_with_intercurrent_illness: {
          title: "Worsening with intercurrent illness",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        pih: {
          title: "Pih",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        gdm: {
          title: "Gdm",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        fever: {
          title: "Fever",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        skin_rash: {
          title: "Skin rash",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        drug_exposure: {
          title: "Drug exposure",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        recurrent_abortions: {
          title: "Recurrent abortions",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        others_antenatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 8,
          setInitialValue: true,
        },

        perinatal_history: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "perinatal_history", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Perinatal history</p>,
        },

        // Gestational age
        gestational_age: {
          title: "Gestational age",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Preterm", "Term", "Post term"]),
          },
        },

        birth_weight: {
          title: "Birth weight",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["SGA", "AGA", "LGA"]),
          },
        },

        neonatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "neonatal_risk_factors", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neonatal risk factors</p>,
        },

        birth_asphyxia: {
          title: "Birth asphyxia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hypoglycaemia: {
          title: "Hypoglycaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        sepsis: {
          title: "Sepsis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        trauma: {
          title: "Trauma",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        cogulopathy: {
          title: "Cogulopathy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        arterial_or_venous_catheterisation: {
          title: "Arterial or venous catheterisation",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        dehydration: {
          title: "Dehydration",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        polycythaemia: {
          title: "Polycythaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        others_neonatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 24,
          setInitialValue: true,
        },

     
        gdd: {
          title: "GDD",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

       

        gdd_Domain: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "GDD Domain", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>GDD Domain</p>,
        },

        gross_motor:{
          title: "Gross motor",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        fine_motor: {
          title: "Fine motor",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        socio_adaptive: {
          title: "Socio-personal",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        language: {
          title: "Language",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        vision: {
          title: "Vision",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


        hearing:{
          title: "Hearing",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },


       

      
      },
    },
  },

  general_examination: {
    title: "General Examinations",
    fields: {
      type: "object",
      properties: {
     
        neurocutaneous_markers: {
          title: "Neurocutaneous markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cyanosis: {
          title: "Cyanosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        lymphadenopathy: {
          title: "Lymphadenopathy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        skin_rashes: {
          title: "Skin rashes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        raynaud_phenomena: {
          title: "Raynaud’s phenomena",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        signs_of_ie: {
          title: "Signs of IE",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

       

        vitals: {
          title: "Vitals",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Stable','Unstable'])
          },
        },

        //anthropometry subheading....

        anthropometry: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Anthropometry", //using in show 
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Anthropometry</p>
        },
       
        weight: {
          title: "Weight",
          type: "number",
          span: 8,
          setInitialValue: true,
          className: "p-l-10",
        },
        height: {
          title: "Height",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        head_circumference: {
          title: "Head circumference",
          type: "number",
          span: 8,
          setInitialValue: true,
        },

        eyes: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "Eyes", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Eyes</p>
        },

        dislocation_of_lens: {
          title: "Dislocation of lens",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        uveitis: {
          title: "Uveitis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ectopia_lentis: {
          title: "Ectopia lentis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        pallor: {
          title: "Pallor",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        marfanoid_habitus: {
          title: "Marfanoid habitus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        signs_of_connective_tissue_disorder: {
          title: "Signs of connective tissue disorder",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        signs_of_trauma: {
          title: "Signs of trauma",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        //ends_anthropometry

      },
    },
  },

  neurologicalexamination: {
    title: "Neurological Examination",
    fields: {
      type: "object",
      properties: {

        //HMF subheading
        HMF: {
          // title: "HMF",
          is_sub_heading: true, //using in show view
          sub_heading_title: "HMF", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>HMF</p>
        },

        handedness: {
          title: "Handedness",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        memory: {
          title: "Memory",
          type: "string",
          span: 24,
          setInitialValue: true,

        },


        apraxia: {
          title: "Apraxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        mmse: {
          title: "MMSE",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        hyperactive: {
          title: "Hyperactive",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_attention: {
          title: "Poor attention",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        speech: {
          title: "Speech",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_eye_contact: {
          title: "Poor eye contact",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        // Cranial nerves deficit

        cranial_nerves_deficit: {
          title: "Cranial nerves deficit",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        facial_weakness: {
          title: "Facial weakness",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ptosis: {
          title: "Ptosis",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        strabismus: {
          title: "Strabismus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        fundus: {
          title: "Fundus",
          type: "string",
          setInitialValue: true,
          span: 12,
        },


        jaw_muscles_involvement: {
          title: "Jaw muscles involvement",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        gag_reflex: {
          title: "Gag reflex",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal diminshed', 'Exaggerated'])
          },
        },

        bulbar_weakness: {
          title: "Bulbar weakness",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tongue_movements: {
          title: "Tongue movements",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        Other_cranial_nerves: {
          title: "Other cranial nerves",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

       

        
        //Motor system (sub heading)
        motor_system: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Motor System", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Motor System</p>
        },        

        bulk: {
          title: "Bulk",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Wasting', 'Hypertrophy'])
          },
        },

        tone_normal: {
          title: "Tone normal",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Hypotonia','Hypertonia', 'Variable'])
          },
        },

        power_charting: {
          title: "Power charting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['1','2','3','4','5'])
          },
        },

        deep_tendon_reflexes: {
          title: "Deep tendon reflexes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Areflexia', 'Hyporeflexia', 'Hyperreflexia'])
          },
        },

        plantar_responses: {
          title: "Plantar responses",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Flexor','Extensor', 'No response'])
          },
        },

        superficial_abdominal_reflexes:{
          title: "Superficial abdominal reflexes",
          type: "string",
          span: 12,
          setInitialValue: true,

        },
        // motor system ends here


        //Extrapyramidal signs sub-heading
        extrapyramidal_signs: {
          // title: "Extrapyramidal signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Extrapyramidal Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Extrapyramidal Signs</p>
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_es: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Extrapyramidal signs ends (sub section)
       
        distribution_of_movement_disorder: {
          title: "Distribution of movement disorder",
          type: "string",
          setInitialValue: true,
          span:12,
        },
        //Cerebellar signs starts (sub section)
        cerebellar_signs: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Cerebellar Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cerebellar signs</p>
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        titubation: {
          title: "Titubation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_cs: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        dysmetria: {
          title: "Dysmetria",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        sensory_system: {
          title: "Sensory system",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Touch', 'Pain', 'Temperature', 'position', 'vibration', 'cortical sensation'])
          },
        },

       

        sensory_system_description: {
          title: "Sensory system description",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        autonomic_nervous_system:{
          title: " Autonomic nervous system",
          type: "string",
          setInitialValue: true,
          span: 12,
        },
        //Cerebellar signs ends (sub section)

        // Spine/cranium


        gait:{
          title: "Gait",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        spine_cranium: {
          title: "Spine/cranium",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        other_findings: {
          title: "Other findings",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        hearing_deficit: {
          title: "Hearing Deficit",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        rating_scales: {
          title: "Rating scales",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        functional_status: {
          title: "Functional status",
          type: "string",
          span: 24,
          setInitialValue: true,

        },


      },
    },
  },



  systemic_examination: {
    title: "Systemic Examination",
    fields: {
      type: "object",
      properties: {


        cardiovascular: {
          title: "Cardiovascular",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        respiratory: {
          title: "Respiratory",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        abdominal: {
          title: "Abdominal",
          type: "string",
          setInitialValue: true,
          span: 12,
        },
        // Comorbid_conditions subheading
       
       

       
      },
    },
  },

 

  investigations: {
    title: "Investigations",
    fields: {
      type: "object",
      properties: {
        //Neuroimaging sub-heading
        Neuroimaging: {
          // title: "Neuroimaging",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Neuroimaging", //using in show
          type: "number",
          span: 24,
          setInitialValue: true,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neuroimaging</p>,
        },

        Ct: {
          title: "CT",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        mri: {
          title: "MRI",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        mra: {
          title: "MRA",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        mrv: {
          title: "MRV",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        vessel_wall_imaging: {
          title: "Vessel wall imaging",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        dsa: {
          title: "DSA",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

       
        //Neuroimaging heading ends

        procoagulant_workup_less_than12: {
          title: "Procoagulant workup (acute stage<12 weeks)",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        procoagulant_workup_greater_than_12: {
          title: "Procoagulant workup (>12 weeks after onset of stroke)",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        cbc: {
          title: "CBC",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        ps_study: {
          title: "PS study",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        sickle_cell_anaemia_screening: {
          title: "Sickle cell anaemia screening",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        reticulocyte_count: {
          title: "Reticulocyte count",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        iron: {
          title: "Iron",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        tibc: {
          title: "TIBC",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        ferritin: {
          title: "Ferritin",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        vitamin_b_12: {
          title: "Vitamin B12",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        homocysteine: {
          title: "Homocysteine",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        creatinine: {
          title: "Creatinine",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        lft: {
          title: "LFT",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        lipid_profile: {
          title: "Lipid profile",
          type: "number",
          span: 12,
          setInitialValue: true,
        },




        ana: {
          title: "ANA",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },



        dsdna: {
          title: "DSDNA",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },



        esr: {
          title: "ESR",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'High'])
          },
        },



        crp: {
          title: "CRP",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'High'])
          },
        },



        blood_borne_virus_screening: {
          title: "Blood borne virus screening",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },



        complement: {
          title: "Complement",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Low'])
          },
        },



        serum_C3: {
          title: "Serum C3, C4",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Low'])
          },
        },



        C_ANCA: {
          title: "C-ANCA",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','High'])
          },
        },



        P_ANCA: {
          title: "P-ANCA",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','High'])
          },
        },



        antissa: {
          title: "AntiSSA",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','High'])
          },
        },



        Antissb: {
          title: "AntiSSB",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','High'])
          },
        },


        urine_protein_creatinine_ratio: {
          title: "Urine protein/creatinine ratio",
          type: "string",
          span: 12,
          setInitialValue: true,

        },



        anti_cardiolipin_antibody: {
          title: "Anti cardiolipin antibody",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','High'])
          },
        },



        anti_glycoprotein_antibody: {
          title: "Anti glycoprotein Ig M, Ig G antibody",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        metabolic_workup_finding: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Metabolic workup finding", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Metabolic workup finding</p>
        },


        lactate: {
          title: "Lactate",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        tms: {
          title: "TMS",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        gcms: {
          title: "GCMS",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        usgcolour_doppler_of_carotid_vessels: {
          title: "USG colour doppler of Carotid vessels",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        ecg: {
          title: "ECG",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        echo_finding: {
          title: "Echo_finding",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        hb_electrophoresis: {
          title: "Hb Electrophoresis",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        tb_work_up: {
          title: "TB work-up",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        csf_cell_count: {
          title: "CSF cell count",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        csf_protein: {
          title: "CSF protein",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        csf_sugar: {
          title: "CSF sugar",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        csf_c_s: {
          title: "CSF C/S",
          type: "string",
          span: 12,
          setInitialValue: true,

        },


        csf_lactate: {
          title: "CSF lactate",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        csf_amino_acid: {
          title: "CSF amino acid",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        csf_viral_pcr: {
          title: "CSF Viral PCR",
          type: "string",
          span: 12,
          setInitialValue: true,

        },


        csf_tb_pcr: {
          title: "CSF TB PCR",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

       csf_mgit_culture: {
          title: "CSF MGIT culture",
          type: "string",
          span: 12,
          setInitialValue: true,

        },

        other_infection_screening: {
          title: "Other infection screening (rickettsial)",
          type: "string",
          span: 12,
          setInitialValue: true,

        },


        genetic_testing: {
          title: "Genetic testing",
          type: "string",
          span: 12,
          setInitialValue: true,

        },





        other_investigations: {
          title: "Other investigations",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },

  diagnosis: {
    title: "Diagnosis",
    fields: {
      type: "object",
      properties: {
        type_of_stroke: {
          type: "string",
          title: "Type of stroke",
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Ischemic','Hemorrhagic'])
          },
        },
        vascular_involvement: {
          type: "string",
          title: "Vascular involvement",
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Arterial','Venous'])
          },
        },
        vascular_territory: {
          type: "string",
          title: "Vascular territory",
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['ACA', 'MCA', 'PCA', 'AICA', 'PICA', 'SCA', 'VA', 'BA', 'More than 1 vessel involved'])
          },
        },

        etiology: {
          title: "Etiology",
          type: "string",
          span: 12,
          setInitialValue: true,

        },
      },
    },
  },


  treatment: {
    title: "Treatment",
    fields: {
      type: "object",
      properties: {
        anti_coagulant_therapy: {
          type: "string",
          title: "Anti-coagulant therapy",
          span: 12,
          setInitialValue: true,
        },
        anti_platele_therapy: {
          type: "string",
          title: "Anti-platelet therapy",
          span: 12,
          setInitialValue: true,
        },
        recurrence_of_episodes: {
          type: "string",
          title: "Recurrence of episodes",
          span: 12,
          setInitialValue: true,
        },

        surgical_intervention: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "Surgical intervention", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Surgical intervention</p>
        },

        moya_moya: {
          type: "string",
          title: "Moya-Moya",
          span: 12,
          setInitialValue: true,
        },
        malignant_cerebral_edema: {
          type: "string",
          title: "Malignant cerebral edema",
          span: 12,
          setInitialValue: true,
        },
        physiotherapy: {
          type: "string",
          title: "Physiotherapy",
          span: 12,
          setInitialValue: true,
        },

        occupational_therapy: {
          type: "string",
          title: "Occupational therapy",
          span: 12,
          setInitialValue: true,
        },
        Speech_therapy: {
          type: "string",
          title: "Speech therapy",
          span: 12,
          setInitialValue: true,
        },
        
      },
    },
  },

  outcome_section: {
    title: "Outcome",
    fields: {
      type: "object",
      properties: {


        recovered_with_sequelae: {
          title: "Recovered with sequelae",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        recovered_without_sequelae: {
          title: "Recovered without sequelae",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        duration_of_follow_up: {
          title: "Duration of follow-up",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        


        functional_status_at_follow_up: {
          title: "Functional status at follow-up",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

      },
    },
  },
};
