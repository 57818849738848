import React, { Component, useState } from "react";
import {
  Breadcrumb,
  List,
  Card,
  Pagination,
  Modal,
  Form,
  Icon,
  Button,
  Table
} from "antd";
import styled, { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";

import {
  BrowserRouter as Router,
  withRouter,
  Route,
  Switch,
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";
import Spinner from "../../UI/Spinner/Spinner";
import { MrForm } from "mr_react_framework";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
// import { SIGHUP } from "constants";
// import { SampleComponent } from "mr_react_framework";
import _ from "lodash";
import NewLayout from "./NewLayout";

defineMessages({
  search: {
    id: "general.search",
    defaultMessage: "Search"
  }
});
const StyledButton = styled(Button)`
  background: "red";
  color: "red";
`;
const NewBasicCRUDLayout = props => {
  console.log("newbasiccrudprops", props);

  const {
    resourceName,
    singleResourceName,
    // MrForm,
    filter,
    forms,
    MrFilter,
    displayName,
    resourceForm,
    CustomListItem,
    ActionBtns,
    params,
    setParams,
    showFormVal,
    showForm,
    loading,
    item,
    handleOk,
    handleCancel,
    refresh,
    dataSource,
    pagination,
    onPageChange,
    view,
    edited,
    viewed,
    deleted,
    permission,
    currentUser,
    ListHeader,
    resourceUrl,
    parentInstance,
    hocProps,
    tableColumns,
    createBtnMessage,
    InfoComponent
  } = props;
  const intl = useIntl();

  let isRoute = true;

  if (hocProps.isRoute && props.isRoute) {
    isRoute = true;
  }
  if (!item) {
    item = {};
  }

  let toRender;
  let content = [];
  let match = useRouteMatch();
  let visible = false;
  if (loading) {
    return <Spinner />;
  }
  console.log("showFormVal", showFormVal);

  if (showFormVal === resourceName) {
    visible = true;
  }

  // const intl = {
  //   formatMessage: () => {}
  // }
  const formObj = resourceForm.form({
    intl,
    currentUser,
    permission
  });

  let createBtn = null;
  let createBtnMobile = null;
  let headerHospital = null;

  // if (currentUser.role == "hospital_admin") {
  //   headerHospital = (
  //     <h1>hospitalRole hospital_admin data_entry </h1>
  //   );
  // }

  const HospitalNameStyle = {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "150%"
  };

  if (!visible) {
    console.log("permission[singleResourceName].create", permission);
    console.log("currentUser!!!!!!", currentUser);
    if (permission[singleResourceName].create) {
      /* background: '${props => props.theme.button.background}'; */
      // color: ${props => props.theme.main.colors.primary};
      console.log("props------->", props);
      const StyledButton = styled(Button)`
        background: "red";
        color: "red";
      `;
      createBtn = (
        <StyledButton
          className="desktop-only"
          key={"createBtn"}
          onClick={() => showForm({ name: resourceName })}
          icon={"plus"}
        >
          <FormattedMessage
            id="general.create"
            defaultMessage={"Create"}
            // defaultMessage={`{text}`}
            // description="Create"
            // values={{ text: "Create" }}
          />
        </StyledButton>
      );
      createBtnMobile = (
        <StyledButton
          style={{
            position: "fixed",
            right: "30px",
            bottom: "30px",
            width: "55px",
            height: "55px",
            backgroundColor: "#398FFD",
            color: "white",
            borderRadius: "50%"
          }}
          className="mobile-only"
          key={"createBtn"}
          onClick={() => showForm({ name: resourceName })}
          icon={"plus"}
        ></StyledButton>
      );
    }

    if (currentUser.role != "superadmin") {
      console.log("currentUser.role", currentUser.role);
      headerHospital = (
        <h1 style={HospitalNameStyle}>{currentUser.hospital.title} </h1>
      );
    }

    content.push(headerHospital);
    // content.push(createBtn);
    // let createBtnmobile = null;
    // content.push(<SampleComponent />);
    if (isRoute) {
      let extraFilters = [];

      if (filter) {
        extraFilters = filter({
          ...params,
          changed: setParams,
          currentUser: currentUser
        });
      }

      if (MrFilter) {
        const Filter = MrFilter({
          name: displayName,
          singleResourceName,
          extraFilters
        });

        content.push(
          console.log("params----->",params),
          <Filter
            {...params}
            changed={setParams}
            refresh={refresh}
            createBtn={createBtn}
            // create={() => this.showForm({ name: resourceName })}
          />
        );
      }
    }
    if (_.isArray(dataSource) && tableColumns) {
      var tableArray = [];
      for (var key in dataSource) {
        tableArray.push(dataSource[key]);
      }
      console.log("permission[singleResourceName]", resourceName);
      const actionBtns = {
        title: "Actions",
        key: "actionBtns",
        render: item => (
          <ActionBtns
            // edit={permission[singleResourceName].update}
            // delete={permission[singleResourceName].delete}
            view={true}
            // edited={edited}
            viewed={viewed}
            // deleted={deleted}
            name={resourceName}
            item={item}
          />
        )
      };

      console.log("table column", tableColumns);
      const actionBtnsIndex = _.findIndex(
        tableColumns,
        column => column.key == "actionBtns"
      );

      console.log("actionBtnsIndex", actionBtnsIndex);
      if (actionBtnsIndex === -1) {
        tableColumns.push(actionBtns);
      }

      let table = <Table columns={tableColumns} dataSource={tableArray} />;
      content.push(table);
    }

    if (ListHeader) {
      content.push(ListHeader);
    }

    if (CustomListItem) {
      console.log("List rendering");
      let list = (
        <List
          key={"list"}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 1,
            lg: 1,
            xl: 1,
            xxl: 1
          }}
          dataSource={dataSource}
          edited={edited}
          deleted={deleted}
          viewed={viewed}
          renderItem={item => (
            console.log("item-------s", item),
            (
              <CustomListItem
                className={"custom-list-item"}
                item={item}
                actionBtns={
                  <ActionBtns
                    edit={permission[singleResourceName].update}
                    delete={permission[singleResourceName].delete}
                    view={permission[singleResourceName].view}
                    edited={edited}
                    viewed={viewed}
                    deleted={deleted}
                    name={resourceName}
                    item={item}
                  />
                }
                name={resourceName}
              />
            )
          )}
        />
      );
      content.push(list);

      content.push(createBtnMobile);
      
      // console.log('ye hai pagination ==>', pagination);
      content.push(
        <Pagination
          // showQuickJumper
          pageSize={pagination.pageSize}
          onChange={onPageChange}
          // current={pagination.page}
          defaultCurrent={pagination.page}
          total={pagination.totalCount}
        />
      );
    }

    if (InfoComponent) {
      content.push(<InfoComponent key="showviewlayout" item={props.item} />);
      content.push(
        
          <StyledButton
            // key={`createBtn-show-view`}
            onClick={() => props.edited({ name: resourceName, item: item })}
            icon={"edit"}
            style={{backgroundColor:"#198FFC" ,color: "white", position: "fixed", right:70, bottom: 70, borderRadius: 25,width: 50,
            height: 50,   }}
          >
          </StyledButton>
        
      );
      // content.push(
      //   <ActionBtns
      //     edit={permission[singleResourceName].update}
      //     delete={permission[singleResourceName].delete}
      //     edited={edited}
      //     deleted={deleted}
      //     name={resourceName}
      //     item={item}
      //   />
      // );
    }
  }

  let breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href="">Home</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{displayName}</Breadcrumb.Item>
    </Breadcrumb>
  );

  if (visible) {
    let addDetailsForm = (
      <Form.Item>
        <h1 style={{ textAlign: "center", fontSize: "40" }}>{displayName} </h1>
        <Button key="back" onClick={handleCancel} style={{}}>
          {/* <Icon type="left" /> */}
          Return
        </Button>
      </Form.Item>
    );
    content.push(addDetailsForm);
    const WrappedMrForm = MrForm();
    let form = (
      <WrappedMrForm
        // isModal={false}
        isSubmitBtn={true}
        // schema={resourceForm.form(intl).schema}
        // jsonForm={resourceForm.form(intl)}
        schema={formObj.schema}
        jsonForm={formObj}
        noHtml5Validate={true}
        onSubmit={form => {
          console.log("form", form);
        }}
        // fields={resourceForm.form(intl)}
        visible={visible}
        displayName={displayName}
        loading={loading}
        item={item}
        handleOk={handleOk}
        handleCancel={handleCancel}
        onError={error => {
          console.log("errors", error);
        }}
        formData={item}
      />
    );

    content.push(form);
  }

  console.log("contentrenderno", content);
  toRender = content;
  return toRender;
};
NewBasicCRUDLayout.defaultProps = {
  isRoute: true,
  item: {}
};

export default NewBasicCRUDLayout;
