import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import { buildOptionsArr, buildSchemaObject } from "../../lib/utils/helperMethods";

export const gddForm = {
  history: {
    title: "History",
    fields: {
      type: "object",
      properties: {
        
        gender: {
          title: "Gender",
          type: "string",
          widget: "RadioGroupWidget",
          span: 24,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Male','Female','Other'])
          },
        },
        age_radio: {
          title: "Age greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        age_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_radio"],
          children: ({ getFieldValue, sourceValues }) => {
            // console.log("chilren, line 42");
            // console.log("sourceValues", sourceValues);
            const nameVal = getFieldValue(["disorder_information", "age_radio"]);
            // console.log("nameVal", nameVal);

            let finalRender = null;
            
            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_options", title: "Age in years", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />

              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in years" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in years" />
              //   </Form.Item>
              // );
            } else if (nameVal === "No" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "No")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_options", title: "Age in months", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in months" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in months" />
              //   </Form.Item>
              // );
            }
            return finalRender;
            // return <React.Fragment>{finalRender}</React.Fragment>;
          },
        },
        age_at_symptom_onset: {
          title: "Age at symptom onset ",
          type: "string",
          widget: "InputNumberWidget",
          span: 6,
          setInitialValue: true
        },

         // should have two input box 
        age_at_diagnosis: {
          title: "Age at diagnosis ",
          type: "string",
          widget: "InputNumberWidget",
          span: 6,
          setInitialValue: true
        },

        typeofpredominantseizures: {
            title: "Type of predominant seizures",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Focal', 'Generalised', 'Unknown'])
            },
          },


          description_of_seizure:    {
            title: "Description of seizure",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Motor', 'Non-motor'])
            },
          },


          other_seizure_type_one: {
          title: "Other seizure type 1",
          type: "number",
          span: 24,
          setInitialValue: true,
        },

        

         other_seizure_type_two: {
          title: "Other seizure type 2",
          type: "number",
          span: 24,
          setInitialValue: true,
        },

          

          gdd_id: {
            title: "GDD/ID",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },




          gdd_domain: {
            is_sub_heading: true, //using in show view
            sub_heading_title: "GDD Domain", //using in show 
            type: "string",
            span: 24,
            className: "m-b-0 sub-heading",
            widget: (props) => <p>GDD Domain</p>
          },


          gross_motor: {
            title: "Gross motor",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },

          fine_motorr: {
            title: "Fine motor",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },

          socio_personal: {
            title: "Socio-personal",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },


          language: {
            title: "Language",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },


          vision: {
            title: "Vision",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },


          hearing: {
            title: "Hearing",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },



          adl: {
            type: "string",
            title: "ADL",
            span: 8,
            setInitialValue: true,
          },


          Scholastic_performance: {
            title: "Scholastic performance",
            type: "string",
            span: 12,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Below average', 'Average', 'Above average'])
            },
          },


          scholastic_deterioration: {
            title: "scholastic deterioration",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
                options: buildOptionsArr(['Yes','No'])
            },
          },

          disease_course: {
            title: "Disease course",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Static', 'Progressive'])
            },
          },



          


        
        antenatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "antenatal_risk_factors", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Antenatal risk factors</p>
        },


        anaemia: {
          title: "Anaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        hypothyroidism: {
          title: "Hypothyroidism",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
       
        pih: {
          title: "PIH",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        gdm: {
          title: "GDM",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        fever: {
          title: "Fever",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        skin_rash: {
          title: "Skin rash",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        drug_exposure: {
          title: "Drug exposure",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        others_antenatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 12,
          setInitialValue: true,
        },







        perinatal_history: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "perinatal_history", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Perinatal history</p>
        },






        // Gestational age
        gestational_age: {
          title: "Gestational age",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Preterm', 'Term', 'Post term'])
          },
        },

        
        birth_weight: {
          title: "Birth weight",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['SGA', 'AGA', 'LGA'])
          },
        },








        neonatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "neonatal_risk_factors", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neonatal risk factors</p>
        },







        birth_asphyxia: {
          title: "Birth asphyxia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        hypoglycaemia: {
          title: "Hypoglycaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
       
        sepsis: {
          title: "Sepsis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        trauma: {
          title: "Trauma",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cogulopathy: {
          title: "Cogulopathy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        others_neonatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 24,
          setInitialValue: true,
        },








        regression_of_milestones: {
          title: "Regression of milestones",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        behavioural_symptoms: {
          title: "Behavioural symptoms",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        // Autistic traits ------ column  AP, AQ, AR,  AS remaining



        sleep_disturbances: {
          title: "Sleep disturbances",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        No_of_antiepileptic_medications: {
          title: "No.of antiepileptic medications",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['1','2','3','More than 3'])
          },
        },

        drug_complaince: {
            title: "Drug complaince",
            type: "string",
            span: 8,
            setInitialValue: true,
            widget: "RadioGroupWidget",
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },
  
        functional_status: {
          title: "Functional status (GMFSC)",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['I', 'II', 'III', 'IV', 'V'])
          },
        },

       
        motor_disturbances: {
          title: "Motor disturbances",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        vision_deterioration: {
          title: "Vision deterioration",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        involuntary_movements: {
          title: "Involuntary movements",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        other_history_gdd: {
          title: "Other history",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        family_history: {
          title: "Family history",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        immunisation_history: {
          title: "Immunisation history",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
  
      },
    },
  },

  general_examination: {
    title: "General Examinations",
    fields: {
      type: "object",
      properties: {
     
        neurocutaneous_markers: {
          title: "Neurocutaneous markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cyanosis: {
          title: "Cyanosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        lymphadenopathy: {
          title: "Lymphadenopathy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        other_ge: {
          title: "Others",
          type: "string",
          setInitialValue: true,
          // span: 8,
        },

        vitals: {
          title: "Vitals",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Stable','Unstable'])
          },
        },

        //anthropometry subheading....

        anthropometry: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Anthropometry", //using in show 
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Anthropometry</p>
        },
       
        weight: {
          title: "Weight",
          type: "number",
          span: 8,
          setInitialValue: true,
          className: "p-l-10",
        },
        height: {
          title: "Height",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        head_circumference: {
          title: "Head circumference",
          type: "number",
          span: 8,
          setInitialValue: true,
        },

        //ends_anthropometry

      },
    },
  },


  neurologicalexamination: {
    title: "Neurological Examination",
    fields: {
      type: "object",
      properties: {

        //HMF subheading
        HMF: {
          // title: "HMF",
          is_sub_heading: true, //using in show view
          sub_heading_title: "HMF", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>HMF</p>
        },

        hyperactive: {
          title: "Hyperactive",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_attention: {
          title: "Poor attention",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        speech: {
          title: "Speech",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_eye_contact: {
          title: "Poor eye contact",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        // Cranial nerves deficit

        cranial_nerves_deficit: {
          title: "Cranial nerves deficit",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ptosis: {
          title: "Ptosis",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        strabismus: {
          title: "Strabismus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        fundus: {
          title: "Fundus",
          type: "string",
          setInitialValue: true,
          span: 12,
        },


        jaw_muscles_involvement: {
          title: "Jaw muscles involvement",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        gag_reflex: {
          title: "Gag reflex",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Diminshed', 'Exaggerated'])
          },
        },

        bulbar_weakness: {
          title: "Bulbar weakness",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tongue_movements: {
          title: "Tongue movements",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Decreased'])
          },
        },

        Other_cranial_nerves: {
          title: "Other cranial nerves",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

       

        
        //Motor system (sub heading)
        motor_system: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Motor System", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Motor System</p>
        },        

        bulk: {
          title: "Bulk",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Wasting', 'Hypertrophy'])
          },
        },

        tone_normal: {
          title: "Tone normal",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Hypotonia','Hypertonia', 'Variable'])
          },
        },

        power_charting: {
          title: "Power charting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['1','2','3','4','5'])
          },
        },

        deep_tendon_reflexes: {
          title: "Deep tendon reflexes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Areflexia', 'Hyporeflexia', 'Hyperreflexia'])
          },
        },

        plantar_responses: {
          title: "Plantar responses",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Flexor','Extensor', 'No response'])
          },
        },
        // motor system ends here


        //Extrapyramidal signs sub-heading
        extrapyramidal_signs: {
          // title: "Extrapyramidal signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Extrapyramidal Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Extrapyramidal Signs</p>
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_es: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Extrapyramidal signs ends (sub section)
       
        distribution_of_movement_disorder: {
          title: "Distribution of movement disorder",
          type: "string",
          setInitialValue: true,
          span:12,
        },
        //Cerebellar signs starts (sub section)
        cerebellar_signs: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Cerebellar Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cerebellar signs</p>
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        titubation: {
          title: "Titubation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_cs: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        dysmetria: {
          title: "Dysmetria",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        sensory_system: {
          title: "Sensory system",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Touch', 'Pain', 'Temperature', 'position', 'vibration', 'cortical sensation'])
          },
        },

       

        sensory_system_description: {
          title: "Sensory system description",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        autonomic_nervous_system:{
          title: " Autonomic nervous system",
          type: "string",
          setInitialValue: true,
          span: 12,
        },
        //Cerebellar signs ends (sub section)

        // Spine/cranium


        gait:{
          title: "Gait",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        spine_cranium: {
          title: "Spine/cranium",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        other_findings: {
          title: "Other findings",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        hearing_deficit: {
          title: "Hearing Deficit",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        rating_scales: {
          title: "Rating scales",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        functional_status: {
          title: "Functional status",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        Course: {
          title: "Course",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Improving', 'Progressive', 'Static'])
          },
        },


        worst_gcs: {
          title: "Worst GCS",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

      },
    },
  },



  investigations: {
    title: "Investigations",
    fields: {
      type: "object",
      properties: {

        //Neuroimaging sub-heading
        Neuroimaging: {
          is_sub_heading: true, 
          sub_heading_title: "Neuroimaging", 
          type: "number",
          span: 24,
          setInitialValue: true,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neuroimaging</p>
        },

        Ct: {
          title: "CT",
          type: "number",
          span: 24,
          setInitialValue: true,
        },

        MRI: {
          title: "MRI",
          type: "number",
          span: 24,
          setInitialValue: true,
        },
        //Neuroimaging heading ends

        //EEG findings sub-heading
        egg_findings: {
          is_sub_heading: true, 
          sub_heading_title: "EEG Findings",  
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>EEG Findings</p>
        },

        type_of_record: {
          title: "Type of record",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Sleep','Awake'])
          },
        },

        background: {
          title: "Background",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Abnormal'])
          },
        },

        sleep_markers: {
          title: "Sleep markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        epileptiform_discharges: {
          title: "Epileptiform discharges",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        morphology_of_epileptiform_discharges: {
          title: " Morphology of epileptiform discharges",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
        //egg finding ends


       
        
        nuclear_imaging: {
          is_sub_heading: true, 
          sub_heading_title: "Nuclear imaging", 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Nuclear imaging</p>
        },
        

        pet: {
          title: "PET",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        spect: {
          title: "SPECT",
          type: "string",
          span: 12,
          setInitialValue: true,
        },


        



        csf_inding: {
          is_sub_heading: true, 
          sub_heading_title: "CSF Finding",
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>CSF Finding</p>
        },





        cell_count: {
          title: "Cell count",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        sugar: {
          title: "Sugar",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        protein: {
          title: "Protein",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        c_s: {
          title: "C/S",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

       
       

        lactate: {
          title: "Lactate",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        amino_acids: {
          title: "Amino Acids",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        neurotransmitters: {
          title: "Neurotransmitters",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        






        metabolic_work_up: {
          is_sub_heading: true, 
          sub_heading_title: "Metabolic_work_up",
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Metabolic work-up</p>
        },





        abg: {
          title: "ABG",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        arterial_lactate: {
          title: "Arterial lactate",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        tms: {
          title: "TMS",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        urine_gcms: {
          title: "Urine GCMS",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        cdg_screening: {
          title: "CDG screening",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        Serum_amino_acid_by_hplc: {
          title: "Serum amino acid by HPLC",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
       
        blood_prolactin: {
          title: "Blood prolactin",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        serum_neurotransmitters: {
          title: "Serum neurotransmitters",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        urine_biopterin_neopterins: {
          title: "Urine biopterin, neopterins",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        hexosaminidase_assay: {
          title: "Hexosaminidase assay",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        

        tpp_ppt_enzyme_assay: {
          title: "TPP/PPT enzyme assay",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        other_metabolic_work_up: {
          title: "Other metabolic work up",
          type: "string",
          span: 12,
          setInitialValue: true,
        },


        
        // Genetic testing  starts

        genetic_testing: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Genetic testing", //using in show 
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Genetic testing</p>
        },


        karyotyping: {
          title: "Karyotyping",
          type: "string",
          span: 12,
          setInitialValue: true,
        },





        chromosomal_microarray: {
          title: "Chromosomal microarray",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        

        fish: {
          title: "FISH",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        mlpa: {
          title: "MLPA",
          type: "string",
          span: 12,
          setInitialValue: true,
        },


        clinical_exome: {
          title: "Clinical exome",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        

        mitochondrial_genome_sequencing: {
          title: "Mitochondrial genome sequencing",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        whole_exome_sequencing: {
          title: "Whole exome sequencing",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        fragile_x_syndrome_screening: {
            title: "Fragile X syndrome screening",
            type: "string",
            span: 12,
            setInitialValue: true,
          },

        
      },
    },
  },



  diagnosis: {
    title: "Diagnosis",
    fields: {
      type: "object",
      properties: {

        Syndromic_Diagnosis: {
          type: "string",
          title: "Syndromic Diagnosis",
          span: 24,
          setInitialValue: true,
        },

        etiology: {
            type: "string",
            title: "Etiology",
            span: 24,
            setInitialValue: true,
        },



        verbal_iq: {
            type: "string",
            title: "Verbal IQ",
            span: 12,
            setInitialValue: true,
        },
  
        performance_iq: {
              type: "string",
              title: "Performance IQ",
              span: 12,
              setInitialValue: true,
        },

          

        cbcl: {
            type: "string",
            title: "CBCL",
            span: 12,
            setInitialValue: true,
          },
  
        dp3_profile: {
              type: "string",
              title: "DP3 profile",
              span: 12,
              setInitialValue: true,
          },
  


        
        
      },
    },
  },


  treatment: {
    title: "Treatment",
    fields: {
      type: "object",
      properties: {

        physiotherapy: {
            type: "string",
            title: "Physiotherapy",
            span: 24,
            setInitialValue: true,
        },


        occupational_therapy: {
            type: "string",
            title: "Occupational therapy",
            span: 24,
            setInitialValue: true,
        },


        speech_therapy: {
            type: "string",
            title: "Speech therapy",
            span: 24,
            setInitialValue: true,
        },



        antiepileptic_medications: {
            title: "Antiepileptic medications",
            type: "string",
            setInitialValue: true,
            widget: "RadioGroupWidget",
            span: 8,
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },
  

        No_of_antiepileptic_medications_treatment: {
          title: "No.of antiepileptic medications",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['1','2','3','More than 3'])
          },
        },

        drug_compliance: {
          title: "Drug compliance",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        drugs_for_movement_disorder: {
            type: "string",
            title: "Drugs for movement disorder",
            span: 12,
            setInitialValue: true,
        },

        drugs_for_spasticity: {
            type: "string",
            title: "Drugs for spasticity",
            span: 12,
            setInitialValue: true,
        },

        surgical_procedures: {
            type: "string",
            title: "Surgical procedures",
            span: 12,
            setInitialValue: true,
        },

        behavioural_therapy: {
            type: "string",
            title: "Behavioural therapy",
            span: 12,
            setInitialValue: true,
        },

        Others_treatment: {
            type: "string",
            title: "Others",
            span: 24,
            setInitialValue: true,
        },


     
      },
    },
  },

  outcome_section: {
    title: "Outcome",
    fields: {
      type: "object",
      properties: {
        duration_of_follow_up: {
          type: "string",
          title: "Duration of follow-up",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },



  



};