import { createSelector } from "reselect";

const disordersState = (state, props) => {
  return state.disorders.get("disorders");
};

const requestDisorderState = (state, props) => {
  return state.request.get("disorders");
};

export const requestDisorderSelector = () =>
  createSelector(
    [requestDisorderState],
    (requestDisorders) => requestDisorders
  );

export const disordersSelector = () =>
  createSelector([disordersState], (disorders) => disorders);

const loadingState = (state, props) => {
  return state.disorders.get("loading");
};

export const loadingSelector = () =>
  createSelector([loadingState], (loading) => loading);

const errorState = (state, props) => {
  return state.disorders.get("error") !== null;
};

export const errorSelector = () =>
  createSelector([errorState], (error) => error);
