import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import { buildOptionsArr, buildSchemaObject } from "../../lib/utils/helperMethods";

export const cerebralForm = {
  history: {
    title: "History",
    fields: {
      type: "object",
      properties: {
        
        gender: {
          title: "Gender",
          type: "string",
          widget: "RadioGroupWidget",
          span: 24,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Male','Female','Other'])
          },
        },
        age_radio: {
            title: "Age greater than 5 years?",
            type: "string",
            widget: "RadioGroupWidget",
            span: 6,
            setInitialValue: true,
            widgetConfig: {
              options: buildOptionsArr(['Yes','No'])
            },
          },
          age_options: {
            title: "Age",
            type: "string",
            span: 6,
            setInitialValue: true,
            className: "m-b-0",
            dependsOn: ["disorder_information.age_radio"],
            children: ({ getFieldValue, sourceValues }) => {
              // console.log("chilren, line 42");
              // console.log("sourceValues", sourceValues);
              const nameVal = getFieldValue(["disorder_information", "age_radio"]);
              // console.log("nameVal", nameVal);
  
              let finalRender = null;
              
              if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "Yes")) {
                finalRender = <FormRenderer
                  formObj={buildSchemaObject({key: "age_options", title: "Age in years", inputType: "string", widget: "InputNumberWidget"})}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
  
                // FormItem wasn't working
                // finalRender = (
                //   <Form.Item label="Age in years" name={["disorder_information", "age_options"]} >
                //     <Input placeholder="Age in years" />
                //   </Form.Item>
                // );
              } else if (nameVal === "No" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "No")) {
                finalRender = <FormRenderer
                  formObj={buildSchemaObject({key: "age_options", title: "Age in months", inputType: "string", widget: "InputNumberWidget"})}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
                // FormItem wasn't working
                // finalRender = (
                //   <Form.Item label="Age in months" name={["disorder_information", "age_options"]} >
                //     <Input placeholder="Age in months" />
                //   </Form.Item>
                // );
              }
              return finalRender;
              // return <React.Fragment>{finalRender}</React.Fragment>;
            },
          },
        age_at_symptom_onset: {
          title: "Age at symptom onset (in months)",
          type: "string",
          widget: "InputNumberWidget",
          span: 8,
          setInitialValue: true
        },
        age_at_diagnosis: {
          title: "Age at diagnosis (in months)",
          type: "string",
          widget: "InputNumberWidget",
          span: 8,
          setInitialValue: true
        },
        age_at_first_resentation_to_medical_facility: {
            title: "Age at first presentation to medical facility",
            type: "string",
            widget: "InputNumberWidget",
            span: 8,
            setInitialValue: true
          },

        
        antenatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "antenatal_risk_factors", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Antenatal risk factors</p>
        },


        anaemia: {
          title: "Anaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        hypothyroidism: {
          title: "Hypothyroidism",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
       
        pih: {
          title: "Pih",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        gdm: {
          title: "Gdm",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        fever: {
          title: "Fever",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        skin_rash: {
          title: "Skin rash",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        drug_exposure: {
          title: "Drug exposure",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        others_antenatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 8,
          setInitialValue: true,
        },


        perinatal_history: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "perinatal_history", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Perinatal history</p>
        },

        // Gestational age
        gestational_age: {
          title: "Gestational age",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Preterm', 'Term', 'Post term'])
          },
        },

        
        birth_weight: {
          title: "Birth weight",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['SGA', 'AGA', 'LGA'])
          },
        },


        neonatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "neonatal_risk_factors", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neonatal risk factors</p>
        },

        birth_asphyxia: {
          title: "Birth asphyxia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        hypoglycaemia: {
          title: "Hypoglycaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
       
        sepsis: {
          title: "Sepsis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        trauma: {
          title: "Trauma",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cogulopathy: {
          title: "Cogulopathy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        others_neonatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 24,
          setInitialValue: true,
        },



        // Neonatal risk factors ends



        possible_timing_of_insult: {
          title: "Possible timing of insult",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Prenatal','Natal','Post natal'])
          },
        },


        seizures: {
          title: "Seizures",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        type_of_seizures: {
          type: "string",
          title: " Type of seizures",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Focal', 'Generalised', 'Unknown'])
          },
        },

        description_of_seizure: {
          title: "Description of seizure",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.type_of_seizures"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "type_of_seizures"]);
            let finalRender = null;
            
            if (nameVal === "Focal" || (!nameVal && sourceValues.id && sourceValues.disorder_information.type_of_seizures === "Focal")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "description_of_seizure", title: "Description of seizure", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            }else if (nameVal === "Generalised" || (!nameVal && sourceValues.id && sourceValues.disorder_information.type_of_seizures === "Generalised")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "description_of_seizure", title: "Description of seizure", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            }
            return finalRender;
          },
        },


        other_seizure: {
          type: "string",
          title: "Other seizure ",
          span: 12,
          setInitialValue: true,
        },

        stiffness_of_limbs: {
          title: "Stiffness of limbs",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        // involuntary movements

        involuntary_movements: {
          title: "Involuntary movements",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        type_of_movement_disorder : {
          title: "Type of movement disorder",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Dystonia', 'Chorea', 'Athetosis', 'Stereotypies', 'Tremors', 'Others'])
          },
        },

        type_of_movement_disorder_description: {
          title: "Type of movement disorder description ",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.type_of_movement_disorder"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "type_of_movement_disorder"]);
            let finalRender = null;
            
            if (nameVal === "Others" || (!nameVal && sourceValues.id && sourceValues.disorder_information.type_of_movement_disorder === "Others")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "type_of_movement_disorder_description", title: "Others", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
            }
            return finalRender;
          },
        },

        



        gdd_id: {
          title: "GDD/ID",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        gross_motor: {
          title: "Gross motor",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        fine_motor: {
          title: "Fine motor",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        socio_personal: {
          title: "Socio-personal",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        language: {
          type: "string",
          title: "Language",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        vision: {
          type: "string",
          title: "Vision",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        hearing: {
          type: "string",
          title: "Hearing",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        behavioural_symptoms: {
          type: "string",
          title: "Behavioural symptoms",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        behavioural_symptoms_details: {
          title: "Behavioural symptoms details",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.behavioural_symptoms"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "behavioural_symptoms"]);
            let finalRender = null;
            
            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.behavioural_symptoms === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "behavioural_symptoms_details", title: "Autistic traits", inputType: "string"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />

              
            }
            return finalRender;
          },
        },



        feeding_difficulty : {
          type: "string",
          title: "Feeding Difficulty ",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        recurrent_respiratory_tract_infection: {
          type: "string",
          title: "Recurrent respiratory tract infection",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        sleep_disturbances: {
          type: "string",
          title: "Sleep disturbances",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        excessive_irritability: {
          type: "string",
          title: "Excessive irritability",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        failure_to_thrive: {
          type: "string",
          title: "Failure to thrive",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        constipation: {
          type: "string",
          title: "Constipation",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        family_history: {
          type: "string",
          title: "Family history",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        functional_status: {
          type: "string",
          title: "Functional status (GMFCS)",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['I','II','III','IV','V'])
          },
        },


        adls: {
          type: "string",
          title: "ADLs",
          span: 24,
          setInitialValue: true,
        },


        other_history: {
          type: "string",
          title: "Other history",
          span: 24,
          setInitialValue: true,
        },
  
      },
    },
  },


  general_examination: {
    title: "General Examinations",
    fields: {
      type: "object",
      properties: {
     
        neurocutaneous_markers: {
          title: "Neurocutaneous markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cyanosis: {
          title: "Cyanosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        lymphadenopathy: {
          title: "Lymphadenopathy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
         span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        dental_abnormalities: {
          title: "Dental abnormalities",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        decubitus_Ulcers: {
          title: "Decubitus Ulcers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        other_ge: {
          title: "Others",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        vitals: {
          title: "Vitals",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Stable','Unstable'])
          },
        },

        //anthropometry subheading....

        anthropometry: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Anthropometry", //using in show 
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Anthropometry</p>
        },
       
        weight: {
          title: "Weight",
          type: "number",
          span: 8,
          setInitialValue: true,
          className: "p-l-10",
        },
        height: {
          title: "Height",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        head_circumference: {
          title: "Head circumference",
          type: "number",
          span: 8,
          setInitialValue: true,
        },

        //ends_anthropometry

      },
    },
  },

  

  neurologicalexamination: {
    title: "Neurological Examination",
    fields: {
      type: "object",
      properties: {

        //HMF subheading
        HMF: {
          // title: "HMF",
          is_sub_heading: true, //using in show view
          sub_heading_title: "HMF", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>HMF</p>
        },

        hyperactive: {
          title: "Hyperactive",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_attention: {
          title: "Poor attention",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        speech: {
          title: "Speech",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_eye_contact: {
          title: "Poor eye contact",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        // Cranial nerves deficit

        cranial_nerves_deficit: {
          title: "Cranial nerves deficit",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        nystagmus: {
          title: "Nystagmus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ptosis: {
          title: "Ptosis",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        strabismus: {
          title: "Strabismus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        fundus: {
          title: "Fundus",
          type: "string",
          setInitialValue: true,
          span: 12,
        },


        jaw_muscles_involvement: {
          title: "Jaw muscles involvement",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        gag_reflex: {
          title: "Gag reflex",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Diminshed', 'Exaggerated'])
          },
        },

        bulbar_weakness: {
          title: "Bulbar weakness",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tongue_movements: {
          title: "Tongue movements",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Decreased'])
          },
        },

        Other_cranial_nerves: {
          title: "Other cranial nerves",
          type: "string",
          setInitialValue: true,
          span: 12,
        },


        
        //Motor system (sub heading)
        motor_system: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Motor System", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Motor System</p>
        },        

        bulk: {
          title: "Bulk",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Wasting', 'Hypertrophy'])
          },
        },

        tone_normal: {
          title: "Tone normal",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Hypotonia','Hypertonia', 'Variable'])
          },
        },

        hypertonia: {
          title: "Hypertonia (Modified Ashworth grade)",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['I', 'II', 'III', 'IV', 'V'])
          },
        },

        power_charting: {
          title: "Power charting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['1','2','3','4','5'])
          },
        },

        deep_tendon_reflexes: {
          title: "Deep tendon reflexes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Areflexia', 'Hyporeflexia', 'Hyperreflexia'])
          },
        },

        plantar_responses: {
          title: "Plantar responses",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Flexor','Extensor', 'No response'])
          },
        },
        // motor system ends here


        //Extrapyramidal signs sub-heading
        extrapyramidal_signs: {
          // title: "Extrapyramidal signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Extrapyramidal Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Extrapyramidal Signs</p>
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_es: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Extrapyramidal signs ends (sub section)

        //Cerebellar signs starts (sub section)
        cerebellar_signs: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Cerebellar Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cerebellar signs</p>
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        titubation: {
          title: "Titubation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_cs: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        dysmetria: {
          title: "Dysmetria",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Cerebellar signs ends (sub section)

        // Spine/cranium
        spine_cranium: {
          title: "Spine/cranium",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        other_findings: {
          title: "Other findings",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        hearing_deficit: {
          title: "Hearing Deficit",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        orthopedic: {
          // title: "Neuroimaging",
          is_sub_heading: true, //using in show view
          sub_heading_title: "orthopedic", //using in show 
          type: "number",
          span: 24,
          setInitialValue: true,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Orthopedic</p>
        },


        hip_dislocation: {
          title: "Hip dislocation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        contractures: {
          title: "Contractures",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        scoliosis: {
          title: "Scoliosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        kyhosis: {
          title: "Kyhosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
      },
    },
  },

  diagnosis: {
    title: "Diagnosis",
    fields: {
      type: "object",
      properties: {


        hea: {
          type: "string",
          title: "Hea",
          span: 24,
          setInitialValue: true,
        },

        etiology: {
          type: "string",
          title: "Etiology",
          span: 24,
          setInitialValue: true,
        },

        dq_iq: {
          type: "string",
          title: "DQ/IQ",
          span: 24,
          setInitialValue: true,
        },
     
      },
    },
  },



  investigations: {
    title: "Investigations",
    fields: {
      type: "object",
      properties: {



        egg_findings: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "EEG Findings", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>EEG Findings</p>
        },

        type_of_record: {
          title: "Type of record",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Sleep','Awake'])
          },
        },

        background: {
          title: "Background",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Abnormal'])
          },
        },

        sleep_markers: {
          title: "Sleep markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        epileptiform_discharges: {
          title: "Epileptiform discharges",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        morphology_of_epileptiform_discharges: {
          title: " Morphology of epileptiform discharges",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        //Neuroimaging sub-heading
        Neuroimaging: {
          // title: "Neuroimaging",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Neuroimaging", //using in show 
          type: "number",
          span: 24,
          setInitialValue: true,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neuroimaging</p>
        },

        Ct: {
          title: "CT",
          type: "number",
          span: 24,
          setInitialValue: true,
        },

        MRI: {
          title: "MRI",
          type: "number",
          span: 24,
          setInitialValue: true,
        },
        //Neuroimaging heading ends


        hearing_assessment: {
          title: "Hearing assessment",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['BERA','FFA','OAE','PTA'])
          },
        },


        hearing_impairment: {
          title: "Hearing impairment",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['VEP' , 'ERG'])
          },
        },



        vision_impairment: {
          title: "Vision impairment",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        dental_assessment: {
          title: "Dental assessment",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        orthopedic_assessment: {
          title: "Orthopedic assessment",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        gerd_assessment: {
          title: "GERD assessment",
          type: "string",
          span: 12,
          setInitialValue: true,
        },


        // Genetic testing subheading
        genetic_testing: {
          // title: "Genetic testing",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Genetic testing", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Genetic testing</p>
        },

        karyotype: {
          title: "Karyotype",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        Chromosomal_microarray: {
          title: "Chromosomal microarray",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        fish: {
          title: "FISH",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        mlpa: {
          title: "MLPA",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        Clinical_exome: {
          title: "Clinical exome",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        whole_exome: {
          title: "Whole exome sequencing",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        mitochondrial_genome_sequencing: {
          title: "Mitochondrial genome sequencing",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        others: {
          title: "Others",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

       
       
      },
    },
  },


  treatment: {
    title: "Treatment",
    fields: {
      type: "object",
      properties: {

        physiotherapy: {
          type: "string",
          title: "Physiotherapy",
          span: 12,
          setInitialValue: true,
        },
        occupational_therapy: {
          type: "string",
          title: "Occupational therapy",
          span: 12,
          setInitialValue: true,
        },



        speech_therapy: {
          type: "string",
          title: "Speech therapy",
          span: 12,
          setInitialValue: true,
        },



        antiepileptic_medications: {
          title: "Antiepileptic medications",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        number_of_antiepileptic_medications: {
          title: "Number of antiepileptic medications",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['1','2','3', 'more than 3'])
          },
        },


        drug_complaince: {
          title: "Drug complaince",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        





        drugs_for_movement_disorder: {
          type: "string",
          title: "Drugs for movement disorder",
          span: 12,
          setInitialValue: true,
        },


          

        drugs_for_spasticity: {
          type: "string",
          title: "Drugs for spasticity",
          span: 12,
          setInitialValue: true,
        },
        surgical_procedures: {
          type: "string",
          title: "Surgical procedures",
          span: 12,
          setInitialValue: true,
        },



        behavioural_therapy: {
          type: "string",
          title: "Behavioural therapy",
          span: 12,
          setInitialValue: true,
        },

        Others_treatment: {
          type: "string",
          title: "Others",
          span: 24,
          setInitialValue: true,
        },
      
     
      },
    },
  },

  outcome_section: {
    title: "Outcome",
    fields: {
      type: "object",
      properties: {

        duration_of_follow_up: {
          type: "string",
          title: "Duration of follow-up",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },



  



};