import React, { Component } from "react";
import PropTypes from "prop-types";
import { MrShowView } from "mr_react_framework";
import commonSelectors from "../../../App/commonSelectors";
import { actions } from "../redux";
import { firstRoutesSelector, firstRoutesShowSelector } from "./selector";
import PatientInformationComponent from "./PatientInformation";
import { returnPatientsForm } from "../Patients";
// import PatientsShowLayout from "./NoUsePatientsShowLayout";
import NewBasicCRUDLayout from "../../../components/Layouts/BasicCRUDLayout/NewBasicCRUDLayout";

export const PatientShowView = MrShowView({
  selectorsObj: {
    ...commonSelectors,
    patients: firstRoutesSelector,
    patient: firstRoutesShowSelector,
  },
  // layout: PatientsShowLayout,
  // layout: NewBasicCRUDLayout,
  resourceName: "patients",
  singleResourceName: "patient",
  displayName: "Patient",
  resourceUrl: "/patients/:id",
  actions,
  forms: [{ name: "patients", form: returnPatientsForm }],
  actionNames: ["create", "update", "delete", "show"],
  infoComponent: PatientInformationComponent,
  // ChildComponent: (props) => <span>ChildComponent</span>,
});
console.log("PatientInformationComponent", PatientInformationComponent);
// console.log("PatientInformationComponent 2", PatientInformationComponent);

export default PatientShowView;
