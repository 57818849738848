
import React, { Component } from 'react'
import { actions } from '../redux';
import { MrConnectField } from 'mr_react_framework';
import { hospitalsSelector, loadingSelector, errorSelector } from "../HospitalSelector";

const initialState = {
    value: null,
};

const SelectHospital = MrConnectField({
    initialState,
    itemName: 'hospital',
    config: {
        placeholder: "Select Hospital",
        label: "Select Hospital",
        elementType: "select"
    },
    fetch: true,
    actions,
    selectorsObj: {
        loading: loadingSelector,
        error: errorSelector,
        hospitals: hospitalsSelector,
    },
    elementType: 'select',
    options: 'props.hospitals',
});

export default SelectHospital;