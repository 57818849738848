import React from "react";
import {  Draggable } from "react-smooth-dnd";

const DraggableWrapper = ({isDraggable,key,children}) => {
  if (isDraggable) {
    return (
      <Draggable key={key}>{children}</Draggable>
    );
  }
  return (
    <div key={key}>{children}</div>
    );
};

export default DraggableWrapper;
