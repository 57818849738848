export const validateJsonValue = (value) => {
  console.log("value in validate==>", value);
  const validation = {
    success: true,
    messages: [],
  };
  let allHaveTitle = true;
  value.length !== 0 &&
    value.map((current) => {
      if (!current.title) {
        allHaveTitle = false;
      } else {
        current.subSectionsList.map((sub) => {
          if (sub.hasOwnProperty("sub_heading_title")) {
            if (!sub.sub_heading_title) {
              allHaveTitle = false;
            }
          } else {
            if (!sub.title) {
              allHaveTitle = false;
            } else if (!sub.span) {
              allHaveTitle = false;
            } else if (!sub.widget) {
              allHaveTitle = false;
            }
          }
          sub.options &&
            sub.options.length !== 0 &&
            sub.options.map((option) => {
              if (!option.title) {
                allHaveTitle = false;
              }
            });
        });
      }
    });
  if (!allHaveTitle) {
    validation.messages.push("All fields are mandatory.");
    validation.success = false;
  }
  return validation;
};







export const validateSectionValue = (value) => {

  console.log("value in section validate==>", value);
  const validation = {
    success: true,
    messages: [],
  };
  let allHaveTitle = true;
  if (!value.title) {
        allHaveTitle = false;
  } else {
        value.subSectionsList.map((sub) => {
          if (sub.hasOwnProperty("sub_heading_title")) {
            if (!sub.sub_heading_title) {
              allHaveTitle = false;
            }
          } else {
            if (!sub.title) {
              allHaveTitle = false;
            } else if (!sub.span) {
              allHaveTitle = false;
            } else if (!sub.widget) {
              allHaveTitle = false;
            }
          }
          sub.options &&
            sub.options.length !== 0 &&
            sub.options.map((option) => {
              if (!option.title) {
                allHaveTitle = false;
              }
            });
        });
  } 


  return allHaveTitle;
};



export const validateItemValue = (value) => {

          let allHaveTitle = true;

          if (value.hasOwnProperty("is_sub_heading")) {
            console.log("commonnn",value.sub_heading_title);
            if (!value.sub_heading_title) {
              allHaveTitle = false;
            }
          } 
          else {
            if (!value.title) {
              allHaveTitle = false;
            } else if (!value.span) {
              allHaveTitle = false;
            } else if (!value.widget) {
              allHaveTitle = false;
            }
          }

          value.options &&
            value.options.length !== 0 &&
            value.options.map((option) => {
              if (!option.title) {
                allHaveTitle = false;
              }
            });
     
  return allHaveTitle;
};
