import React from "react";
import { Button, Dropdown, Input } from "antd";
import MrSelect from "../../../components/UI/MrSelect/MrSelect";
import { MrInput } from "mr_react_framework";
import { MrConnectField } from "mr_react_framework";
// import OrderStatus from "../../Settings/OrderStatus/OrderStatus";
import { MrFilter } from "mr_react_framework";
import SelectHospital from "../../Hospital/SelectHospital/SelectHospital";
import SelectDisorder from "../../Disorders/SelectDisorder/SelectDisorder";

// import Edit from "instructure-qi/lib/components/essay/Edit";

// props
// changed = setParams
const PatientsFilter = (props) => {
  let toRender;
  // debugger;
  // const Filter = MrFilter({ name: "AppConstants", ...props });
  console.log("props in Leadfilter patients", props);
  let extraFilters = [];

  // extraFilters.push(
  //   <MrInput
  //     key="by_disorder_name"
  //     elementType="select"
  //     options={[
  //       { id: "adhd", name: "ADHD" },
  //       { id: "asd", name: "ASD" },
  //       { id: "demyelinating", name: "DEMYELINATING" },
  //       { id: "cp", name: "CP" },
  //       { id: "epilepsy", name: "EPILEPSY" },
  //       { id: "encephalitis", name: "ENCEPHALITIS" },
  //       { id: "gdd", name: "GDD" },
  //       { id: "movement_disorder", name: "MOVEMENT DISORDER" },
  //       { id: "ld", name: "LD" },
  //       { id: "neurodegenerative", name: "NEURODEGENERATIVE" },
  //       { id: "neuromuscular", name: "NEUROMUSCULAR" },
  //       { id: "stroke", name: "STROKE" },
  //       { id: "neuropedicon", name: "NEUROPEDICON" },
  //     ]}
  //     valueKey="id"
  //     textKey="name"
  //     // extraOptions={{clearOptions: true}}
  //     placeholder="Filter by Disorder"
  //     // defaultValue={{props.by_disorder_name}}
  //     defaultValue={props.by_disorder_name}
  //     changed={props.changed}
  //     fieldInfo={{ key: "by_disorder_name" }}
  //   />
  // );

  // extraFilters.push(
  //   <SelectHospital
  //     key="by_hospital_id"
  //     elementType="select"
  //     valueKey='id'
  //     textKey='title'
  //     extraOptions={{clearOptions: true}}
  //     placeholder="Filter by Hospital"
  //     defaultValue={props.by_hospital_id}
  //     changed={props.changed}
  //     fieldInfo={{ key: "by_hospital_id" }}
  //   />
  // );
  // extraFilters.push(
  //   <SelectAll
  //     key="by_hospital_id"
  //     elementType="select"
  //     valueKey='id'
  //     textKey='title'
  //     placeholder="Filter all"
  //     defaultValue={props.by_hospital_id}
  //     changed={props.changed}
  //     fieldInfo={{ key: "by_hospital_id" }}
  //   />
  // );

  return extraFilters;
};
export default PatientsFilter;
