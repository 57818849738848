import React from "react";
import { Layout } from "antd";
import classNames from "classnames";
import styled from "styled-components";

import styles from "./BaseLayout.scss";

const { Header, Content, Footer } = Layout;
const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;

const StyledContent = styled(Content)`
  padding: 32px 0 24px 0px;
  flex: 1 1;
`;

const StyledFooter = styled(Footer)`
  margin: 48px 0 24px 0;
  padding: 0 16px;
  text-align: center;
  display: block;
  ::before,
  ::after {
    box-sizing: border-box;
  }
`;
const BaseLayout = (props) => {
  console.log("BaseLayout");
  let content = [];
  content.push(<Header key={"header"} className="base-header"></Header>);
  content.push(<StyledContent key={"content"}>{props.children}</StyledContent>);
  // content.push(<StyledFooter key={"footer"}></StyledFooter>);
  return (
    <StyledLayout className={classNames("layout")}>{content}</StyledLayout>
  );
};
export default BaseLayout;
