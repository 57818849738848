import React from "react";
import { List, Card, Table } from "antd";
import styled from 'styled-components';

const OrgListItem = props => {
    const { item } = props
    
    return (     
         <List.Item >
        </List.Item>  
    );
};
export default OrgListItem;