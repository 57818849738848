import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import {
  buildOptionsArr,
  buildSchemaObject,
} from "../../lib/utils/helperMethods";

export const neurodegenerativeForm = {
  history: {
    title: "History",
    fields: {
      type: "object",
      properties: {
        uhid: {
          title: "UHID",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        gender: {
          title: "Gender",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Male", "Female", "Other"]),
          },
        },
        age_radio: {
          title: "Age greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        age_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_radio"],
          children: ({ getFieldValue, sourceValues }) => {
            // console.log("chilren, line 42");
            // console.log("sourceValues", sourceValues);
            const nameVal = getFieldValue([
              "disorder_information",
              "age_radio",
            ]);
            // console.log("nameVal", nameVal);

            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_radio === "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_options",
                    title: "Age in years",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );

              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in years" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in years" />
              //   </Form.Item>
              // );
            } else if (
              nameVal === "No" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_radio === "No")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_options",
                    title: "Age in months",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in months" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in months" />
              //   </Form.Item>
              // );
            }
            return finalRender;
            // return <React.Fragment>{finalRender}</React.Fragment>;
          },
        },

        age_at_symptom_onset: {
          title: "Age at symptom onset greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        age_at_symptom_onset_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_at_symptom_onset"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "age_at_symptom_onset",
            ]);
            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_at_symptom_onset ===
                  "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_at_symptom_onset_options",
                    title: "Age in years",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            } else if (
              nameVal === "No" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_at_symptom_onset === "No")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_at_symptom_onset_options",
                    title: "Age in months",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        presenting_symptoms: {
          type: "string",
          default: "",
          title: "Presenting symptoms",
          span: 12,
          setInitialValue: true,
        },

        onset: {
          title: "Onset",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Acute", "Sub acute", "Chronic"]),
          },
        },

        antenatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "antenatal_risk_factors", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Antenatal risk factors</p>,
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        hypothyroidism: {
          title: "Hypothyroidism",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        pih: {
          title: "Pih",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        gdm: {
          title: "Gdm",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        fever: {
          title: "Fever",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        skin_rash: {
          title: "Skin rash",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        drug_exposure: {
          title: "Drug exposure",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        others_antenatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 8,
          setInitialValue: true,
        },

        // Perinatal history

        perinatal_history: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "perinatal_history", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Perinatal history</p>,
        },

        gestational_age: {
          title: "Gestational age",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Preterm", "Term", "Post term"]),
          },
        },

        birth_weight: {
          title: "Birth weight",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["SGA", "AGA", "LGA"]),
          },
        },

        // Neonatal risk factors
        neonatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "neonatal_risk_factors", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neonatal risk factors</p>,
        },

        birth_asphyxia: {
          title: "Birth asphyxia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hypoglycaemia: {
          title: "Hypoglycaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        sepsis: {
          title: "Sepsis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        trauma: {
          title: "Trauma",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        cogulopathy: {
          title: "Cogulopathy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        others_neonatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 12,
          setInitialValue: true,
        },

        // Neonatal risk factors ends
        Developmental_delay: {
          title: "Developmental delay (pre-morbid)",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        gdd_id: {
          title: "GDD/ID",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        // Developmental Domain subheading
        developmental_domain: {
          type: "string",
          span: 24,
          // setInitialValue: true,
          is_sub_heading: true, //using in show view
          sub_heading_title: "Developmental domain", //using in show view
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Developmental domain</p>,
        },

        gross_motor: {
          title: "Gross motor",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        fine_motor: {
          title: "Fine motor",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        socio_personal: {
          title: "Socio-personal",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        language: {
          type: "string",
          title: "Language",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        vision: {
          type: "string",
          title: "Vision",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hearing: {
          type: "string",
          title: "Hearing",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        // End development domain

        regression_of_milestones: {
          title: "Regression of milestones",
          type: "string",
          span: 4,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        type_of_movement_disorder: {
          title: "Type of movement disorder",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Hypokinetic", "Hyperkinetic"]),
          },
        },

        dystonia: {
          type: "string",
          title: "Dystonia",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        chorea: {
          type: "string",
          title: "Chorea",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        athetosis: {
          type: "string",
          title: "Athetosis",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        tremors: {
          type: "string",
          title: "Tremors",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        stereotypies: {
          type: "string",
          title: "Stereotypies",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        ballismus: {
          type: "string",
          title: "Ballismus",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        tics: {
          type: "string",
          title: "Tics",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        slowing_of_activities: {
          type: "string",
          title: "Slowing of activities",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        decreased_facial_expression: {
          type: "string",
          title: "Decreased facial expression",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        any_setting_for_conversion: {
          type: "string",
          title: "Any setting for conversion",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        precipitating_factor: {
          type: "string",
          title: "Precipitating Factor",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        precipitating_factor_details: {
          title: "Precipitating Factor details",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.precipitating_factor"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "precipitating_factor",
            ]);
            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.precipitating_factor ===
                  "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "precipitating_factor_details",
                    title: "Details",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        distribution: {
          is_sub_heading: true,
          sub_heading_title: "Distribution",
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Distribution</p>,
        },

        segmental: {
          type: "string",
          title: "Segmental",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        focal: {
          type: "string",
          title: "Focal",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        multifocal: {
          type: "string",
          title: "Multifocal",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        generalised: {
          type: "string",
          title: "Generalised",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        hemidystonia: {
          type: "string",
          title: "Hemidystonia",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        others_distribution: {
          type: "string",
          title: "Others",
          span: 12,
          setInitialValue: true,
        },

        stiffness_of_limbs: {
          type: "string",
          title: "Stiffness of limbs",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        wasting_of_muscles: {
          type: "string",
          title: "Wasting of muscles",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        diurnal_variation: {
          type: "string",
          title: "Diurnal variation",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        aggravating_factors: {
          type: "string",
          title: "Aggravating factors",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        relieving_factors: {
          type: "string",
          title: "Relieving factors",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        cognitive_decline: {
          type: "string",
          title: "Cognitive decline",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        deterioration_of_speech: {
          type: "string",
          title: "Deterioration of speech",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_chewing: {
          type: "string",
          title: "Difficulty in chewing",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_swallowing: {
          type: "string",
          title: "Difficulty in swallowing",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        nasal_regurgitation: {
          type: "string",
          title: "Nasal regurgitation",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        unsteadiness_of_gait: {
          type: "string",
          title: "Unsteadiness of gait",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        truncal_unsteadiness: {
          type: "string",
          title: "Truncal unsteadiness",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        difficulty_in_buttoning_shirts: {
          type: "string",
          title: "Difficulty in buttoning or unbuttoning shirts",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        deterioration_of_hand_writing: {
          type: "string",
          title: "Deterioration of handwriting",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        roving_eye_movements: {
          type: "string",
          title: "Roving eye movements",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        sensory_disturbances: {
          type: "string",
          title: "Sensory disturbancess",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        history_of_preceding_fever: {
          type: "string",
          title: "History of preceding fever",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        night_blindness: {
          type: "string",
          title: "Night-blindness",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        recurrent_respiratory_infections: {
          type: "string",
          title: "Recurrent respiratory infections",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        skin_rash_in_past: {
          type: "string",
          title: "Skin rash in past",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        trauma_2: {
          type: "string",
          title: "Trauma",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        altered_sensorium: {
          type: "string",
          title: "Altered sensorium",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        seizures: {
          type: "string",
          title: "Seizures",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Focal", "Generalised", "Unknown"]),
          },
        },

        description_of_seizure: {
          title: "Description of seizure",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.seizures"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue(["disorder_information", "seizures"]);
            let finalRender = null;

            if (
              nameVal === "Focal" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.seizures === "Focal")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "description_of_seizure",
                    title: "Description of seizure",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            } else if (
              nameVal === "Generalised" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.seizures === "Generalised")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "description_of_seizure",
                    title: "Description of seizure",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        behavioural_symptoms: {
          type: "string",
          title: "Behavioural symptoms",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        behavioural_symptoms_details: {
          title: "Behavioural symptoms details",
          type: "string",
          span: 12,
          setInitialValue: true,
          // className: "m-b-0",
          dependsOn: ["disorder_information.behavioural_symptoms"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "behavioural_symptoms",
            ]);
            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.behavioural_symptoms ===
                  "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "behavioural_symptoms_details",
                    title: "Autistic traits",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        sleep_disturbances: {
          type: "string",
          title: "Sleep disturbances",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        jaundice: {
          type: "string",
          title: "Jaundice",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        family_history: {
          type: "string",
          title: "Family history",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        other_history: {
          title: "Other history",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "DatePickerWidget",
        },
      },
    },
  },

  general_examination: {
    title: "General Examinations",
    fields: {
      type: "object",
      properties: {
        neurocutaneous_markers: {
          title: "Neurocutaneous markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        cyanosis: {
          title: "Cyanosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        kf_ring: {
          title: "KF ring",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        cataract: {
          title: "Cataract",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        progeroid_facies: {
          title: "Progeroid facies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        photosensitivity: {
          title: "Photosensitivity",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        telangiectasia: {
          title: "Telangiectasia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        signs_of_liver_cell_failure: {
          title: "Signs of liver cell failure",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        rickets: {
          title: "Rickets",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        other_ge: {
          title: "Others",
          type: "string",
          setInitialValue: true,
          // span: 8,
        },

        vitals: {
          title: "Vitals",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Stable", "Unstable"]),
          },
        },

        //anthropometry subheading....

        anthropometry: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Anthropometry", //using in show
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Anthropometry</p>,
        },

        weight: {
          title: "Weight",
          type: "number",
          span: 8,
          setInitialValue: true,
          className: "p-l-10",
        },
        height: {
          title: "Height",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        head_circumference: {
          title: "Head circumference",
          type: "number",
          span: 8,
          setInitialValue: true,
        },

        //ends_anthropometry
      },
    },
  },

  neurologicalexamination: {
    title: "Neurological Examination",
    fields: {
      type: "object",
      properties: {
        //HMF subheading
        HMF: {
          // title: "HMF",
          is_sub_heading: true, //using in show view
          sub_heading_title: "HMF", //using in show
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>HMF</p>,
        },

        hyperactive: {
          title: "Hyperactive",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        poor_attention: {
          title: "Poor attention",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        speech: {
          title: "Speech",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        poor_eye_contact: {
          title: "Poor eye contact",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        // Cranial nerves deficit

        cranial_nerves_deficit: {
          title: "Cranial nerves deficit",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        ptosis: {
          title: "Ptosis",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        strabismus: {
          title: "Strabismus",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        fundus: {
          title: "Fundus",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        jaw_muscles_involvement: {
          title: "Jaw muscles involvement",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        gag_reflex: {
          title: "Gag reflex",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Normal diminshed", "Exaggerated"]),
          },
        },

        bulbar_weakness: {
          title: "Bulbar weakness",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        tongue_movements: {
          title: "Tongue movements",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        Other_cranial_nerves: {
          title: "Other cranial nerves",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        //Motor system (sub heading)
        motor_system: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Motor System", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Motor System</p>,
        },

        bulk: {
          title: "Bulk",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Normal", "Wasting", "Hypertrophy"]),
          },
        },

        tone_normal: {
          title: "Tone normal",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Hypotonia", "Hypertonia", "Variable"]),
          },
        },

        power_charting: {
          title: "Power charting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["1", "2", "3", "4", "5"]),
          },
        },

        deep_tendon_reflexes: {
          title: "Deep tendon reflexes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr([
              "Normal",
              "Areflexia",
              "Hyporeflexia",
              "Hyperreflexia",
            ]),
          },
        },

        plantar_responses: {
          title: "Plantar responses",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Flexor", "Extensor", "No response"]),
          },
        },
        // motor system ends here

        //Extrapyramidal signs sub-heading
        extrapyramidal_signs: {
          // title: "Extrapyramidal signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Extrapyramidal Signs", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Extrapyramidal Signs</p>,
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        tremor_es: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        //Extrapyramidal signs ends (sub section)

        distribution_of_movement_disorder: {
          title: "Distribution of movement disorder",
          type: "string",
          setInitialValue: true,
          span: 12,
        },
        //Cerebellar signs starts (sub section)
        cerebellar_signs: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Cerebellar Signs", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cerebellar signs</p>,
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        titubation: {
          title: "Titubation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        tremor_cs: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        dysmetria: {
          title: "Dysmetria",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        sensory_system: {
          title: "Sensory system",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr([
              "Touch",
              "Pain",
              "Temperature",
              "position",
              "vibration",
              "cortical sensation",
            ]),
          },
        },

        sensory_system_description: {
          title: "Sensory system description",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        autonomic_nervous_system: {
          title: " Autonomic nervous system",
          type: "string",
          setInitialValue: true,
          span: 12,
        },
        //Cerebellar signs ends (sub section)

        // Spine/cranium

        gait: {
          title: "Gait",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        spine_cranium: {
          title: "Spine/cranium",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        other_findings: {
          title: "Other findings",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        hearing_deficit: {
          title: "Hearing Deficit",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        rating_scales: {
          title: "Rating scales",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        functional_status: {
          title: "Functional status",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },

  systemic_examination: {
    title: "Systemic Examination",
    fields: {
      type: "object",
      properties: {
        cardiac: {
          title: "Cardiac",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        respiratory: {
          title: "Respiratory",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        abdominal: {
          title: "Abdominal",
          type: "string",
          setInitialValue: true,
          span: 12,
        },
        // Comorbid_conditions subheading
      },
    },
  },

  investigations: {
    title: "Investigations",
    fields: {
      type: "object",
      properties: {
        cbc: {
          title: "CBC",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        serum_albumin: {
          title: "Serum albumin",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal"]),
          },
        },

        lipid_profile: {
          title: "Lipid profile",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        serumafp: {
          title: "Serum AFP",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Elevated", "Normal"]),
          },
        },

        liver_enzymes: {
          title: "Liver enzymes",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Elevated", "Normal"]),
          },
        },
        uric_acid: {
          title: "Uric acid",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal", "High"]),
          },
        },
        serum_prolactin: {
          title: "Serum prolactin",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["High", "Normal"]),
          },
        },
        neurotransmitters: {
          title: "Neurotransmitters",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal", "High"]),
          },
        },

        bood_lactate: {
          title: "Bood lactate",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["High", "Normal"]),
          },
        },
        ammonia: {
          title: "Ammonia",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal", "High"]),
          },
        },
        vitamin_e: {
          title: "Vitamin E",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal"]),
          },
        },
        vitamin_b12: {
          title: "Vitamin B12",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal"]),
          },
        },
        copper: {
          title: "Copper",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal", "High"]),
          },
        },

        Ceruloplasmin: {
          title: "Ceruloplasmin",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal"]),
          },
        },

        blood_borne_virus_screening: {
          title: "Blood borne virus screening",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "DatePickerWidget",
        },
        cdg_screening: {
          title: "CDG screening",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "DatePickerWidget",
        },

        hours_urine_copper: {
          title: "24 hours urine copper",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Normal", "High"]),
          },
        },

        serum_amino_acid: {
          title: "Serum amino acid",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        urine_amino_acid: {
          title: "Urine amino acid",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        urine_gcms: {
          title: "Urine GCMS",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        csf_sugar: {
          title: "CSF sugar",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal"]),
          },
        },

        csf_lactate: {
          title: "CSF lactate",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["High", "Normal"]),
          },
        },

        csf_protein: {
          title: "CSF protein",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["High", "Normal"]),
          },
        },

        csf_viral_markers: {
          title: "CSF viral markers",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Positive", "Negative"]),
          },
        },

        csf_neurotransmitters: {
          title: "CSF neurotransmitters",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal", "High"]),
          },
        },

        csg_and_serum_antimeasles_antibodies: {
          title: "CSF and serum antimeasles antibodies",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(["Low", "Normal", "High"]),
          },
        },

        ecg: {
          title: "ECG",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        echo: {
          title: "ECHO",
          type: "string",
          setInitialValue: true,
          span: 12,
        },

        //EEG findings sub-heading
        egg_findings: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "EEG Findings", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>EEG Findings</p>,
        },

        type_of_record: {
          title: "Type of record",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Sleep", "Awake"]),
          },
        },

        background: {
          title: "Background",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Normal", "Abnormal"]),
          },
        },

        sleep_markers: {
          title: "Sleep markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        epileptiform_discharges: {
          title: "Epileptiform discharges",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        morphology_of_epileptiform_discharges: {
          title: " Morphology of epileptiform discharges",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
        //egg finding ends

        ncs: {
          title: "NCS",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        vep: {
          title: "VEP",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        bera: {
          title: "BERA",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        ssep_tibial: {
          title: "SSEP tibial",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        muscle_biopsy: {
          title: "Muscle biopsy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        skin_biopsy: {
          title: "Skin biopsy",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        genetic_tests: {
          title: "Genetic tests",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr([
              "Clinical exome",
              "Whole exome",
              "Microarray",
              "Mitochondrial genome sequencing",
              "Others",
            ]),
          },
        },
        genetic_tests_reports: {
          title: "Genetic tests reports",
          type: "string",
          span: 12,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.genetic_tests"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "genetic_tests",
            ]);

            let finalRender = null;

            if (
              nameVal === "Others" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_radio === "Others")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "genetic_tests_reports",
                    title: "Genetic tests report",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        mri_brain: {
          title: "MRI brain",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        enzyme_assay: {
          title: "Enzyme assay",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        other_investigations: {
          title: "Other investigations",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },

  diagnosis: {
    title: "Diagnosis",
    fields: {
      type: "object",
      properties: {
        diagnosis: {
          title: "Diagnosis",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        etiology: {
          title: "Etiology",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },

  treatment: {
    title: "Treatment",
    fields: {
      type: "object",
      properties: {
        antiviral_herapy: {
          title: "Anti-viral Therapy (duration) ",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        antiepiletic_medications: {
          title: "Antiepiletic medications",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        movement_disorder_drugs: {
          title: "Movement disorder drugs",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        specific_diet: {
          title: "Specific diet",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        chelation_therapy: {
          title: "Chelation therapy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        enzyme_replacement_therapy: {
          title: "Enzyme replacement therapy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        physiotherapy: {
          title: "Physiotherapy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        speech_therapy: {
          title: "Speech therapy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        occupational_therapy: {
          title: "Occupational therapy",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },

  outcome: {
    title: "Outcome",
    fields: {
      type: "object",
      properties: {
        mrs: {
          title: "MRS",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        duration_of_follow_up: {
          title: "Duration of follow-up",
          type: "string",
          span: 12,
          setInitialValue: true,
        },

        functional_status_at_follow_up: {
          title: "Functional status at follow-up",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },
};
