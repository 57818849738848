import { createSelector } from "reselect";

const patientsState = (state, props) => {
  return state.patients.get("patients");
};

export const patientsSelector = () =>
  createSelector([patientsState], (patients) => patients);

const patientDisorderState = (state, props) => {
  return state.patientDisorders.get("patient_disorders");
};

export const patientDisorderSelector = () =>
  createSelector(
    [patientDisorderState],
    (patientDisorders) => patientDisorders
  );

const requestDisorderState = (state, props) => {
  return state.request.get("disorders");
};

export const requestDisorderSelector = () =>
  createSelector(
    [requestDisorderState],
    (requestDisorders) => requestDisorders
  );
