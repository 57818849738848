// import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import createHistory from "history/createBrowserHistory";
import { IntlProvider, addLocaleData } from "react-intl";
import App from "./App/App";
import { watchLogin, reducer as loginReducer } from "./views/Auth/Login/redux";
import {
  watchAppConstants,
  reducer as appConstantsReducer
} from "./views/AppConstants/redux";

import { watchUsers, reducer as usersReducer } from "./views/Accounts/redux";

import { watchHospitals, reducer as hospitalsReducer } from "./views/Hospital/redux";
import { watchPatients, reducer as patientsReducer } from "./views/Patients/redux";
import { watchPatientDisorders, reducer as patientDisordersReducer } from "./views/Patients/PatientDisorder/redux";
import { watchDisorders, reducer as disordersReducer } from "./views/Disorders/redux";
import { watchAdhds, reducer as adhdsReducer } from "./views/Adhd/redux";
import {reducer as requestReducer, watchRequests} from './App/genericRedux';



const history = createHistory();
const rootReducer = combineReducers({
  // prefrences: prefrencesReducer,
  // users: usersReducer,
  // leads: leadsReducer,
  // lead: leadReducer,
  // call: callReducer,
  // actions: actionsReducer,
  // logs: logsReducer,
  // contacts: contactsReducer,
  // items: itemsReducer,
  // auth: authReducer,
  login: loginReducer,
  appConstants: appConstantsReducer,
  hospitals: hospitalsReducer,
  adhds: adhdsReducer,
  users: usersReducer,
  patients: patientsReducer,
  disorders: disordersReducer,
	request: requestReducer,
  patientDisorders: patientDisordersReducer

});

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  // composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(watchLogin);
sagaMiddleware.run(watchAppConstants);
sagaMiddleware.run(watchHospitals);
sagaMiddleware.run(watchAdhds);
sagaMiddleware.run(watchUsers);
sagaMiddleware.run(watchPatients);
sagaMiddleware.run(watchPatientDisorders);
sagaMiddleware.run(watchDisorders);
sagaMiddleware.run(watchRequests);


// sagaMiddleware.run(watchLeads);

const render = Component => {
  return ReactDOM.render(
    <Router>
      <Provider store={store}>
        <Component />
      </Provider>
    </Router>,
    document.getElementById("root")
  );
};

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
