import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Space,
  Radio,
  Checkbox,
  Input,
  Upload,
  message,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { findIndex } from "lodash";
import { MrRow, MrColumn } from "../../components/UI/MrTable/MrTable";
import "./Disorder.scss";
import InputWithTags from "../../components/UI/Input/InputWithTags";
import DisorderItemSections from "../Disorders/DisorderItemSections";

const DisorderItemDetail = (props) => {
  // const disorderData = props.item;
  console.log("disorder item detail==>", props);
  const [headerData, setHeaderData] = useState([]);
  const [disorderData, setDisorderData] = useState([]);
  useEffect(() => {
    setHeaderData(props.item);
    const data = props.item.form_json.disorders;
    data.map((item) => {
      item.subSectionsList.map((innerItem) => {
        // if (!innerItem.is_sub_heading) {
        //   if (innerItem.dependsOnIndex) {
        //     innerItem.visible = false;
        //   } else {
        //     innerItem.visible = true;
        //   }
        // }
        if (innerItem.dependsOnIndex) {
          innerItem.visible = false;
        } else {
          innerItem.visible = true;
        }
      });
    });
    setDisorderData(data);
  }, []);
  const onChange = (value, parentId, childId, widget) => {
    const stateObj = [...disorderData];
    stateObj[parentId]["subSectionsList"][childId].options.map(
      (option) => (option.checked = false)
    );
    if (widget === "CheckboxGroupWidget") {
      stateObj[parentId]["subSectionsList"][childId].options.map(
        (checkboxItem) => {
          value.map((item) => {
            if (item === checkboxItem.title) {
              checkboxItem.checked = true;
            }
          });
        }
      );
    } else {
      var current = findIndex(
        stateObj[parentId]["subSectionsList"][childId]["options"],
        (item) => item.title == value
      );
      stateObj[parentId]["subSectionsList"][childId]["options"][
        current
      ].checked = true;
    }

    stateObj[parentId]["subSectionsList"].map((item) => {
      // if (!item.is_sub_heading) {
      //   if (item.dependsOnIndex) {
      //     const dependsOnArrayIndex = findIndex(
      //       stateObj[parentId]["subSectionsList"],
      //       (filterItem) => filterItem.uniqueId == item.dependsOnIndex
      //     );

      //     stateObj[parentId]["subSectionsList"][dependsOnArrayIndex][
      //       "options"
      //     ].map((option) => {
      //       if (
      //         option.title.toLowerCase() === item.dependsOnValue.toLowerCase()
      //       ) {
      //         if (option.checked) {
      //           item.visible = true;
      //         } else {
      //           item.visible = false;
      //         }
      //       } else {
      //         // item.visible = false;
      //       }
      //     });
      //   }
      // }
      if (item.dependsOnIndex) {
        const dependsOnArrayIndex = findIndex(
          stateObj[parentId]["subSectionsList"],
          (filterItem) => filterItem.uniqueId == item.dependsOnIndex
        );
        const dependantValue =
          stateObj[parentId]["subSectionsList"][dependsOnArrayIndex].visible;

        console.log("visible checking==>", dependantValue, item);

        if (!dependantValue) {
          item.visible = false;
          if (
            stateObj[parentId]["subSectionsList"][dependsOnArrayIndex]
              .widget === "RadioGroupWidget" ||
            stateObj[parentId]["subSectionsList"][dependsOnArrayIndex]
              .widget === "CheckboxGroupWidget"
          ) {
            stateObj[parentId]["subSectionsList"][dependsOnArrayIndex][
              "options"
            ].map((option) => {
              option.checked = false;
            });
          }
        } else {
          stateObj[parentId]["subSectionsList"][dependsOnArrayIndex][
            "options"
          ].map((option) => {
            if (
              option.title.toLowerCase() === item.dependsOnValue.toLowerCase()
            ) {
              if (option.checked) {
                item.visible = true;
              } else {
                item.visible = false;
              }
            } else {
              // item.visible = false;
            }
          });
        }
      }
    });
    // stateObj[parentId]["subSectionsList"].map((item) => {
    //   !item.is_sub_heading && item.dependsOnIndex !== ""
    //     ? (item.visible = false)
    //     : (item.visible = true);
    // });
    // stateObj[parentId]["subSectionsList"].visible = true;
    setDisorderData(stateObj);
  };
  return (
    <>
      {/* {JSON.stringify(disorderData)} */}
      <div className="section-card-show">
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 4 }}>ID </MrColumn>
          <MrColumn style={{ flex: 4 }} className="field-value">
            <p className="disorder-id">{headerData.id}</p>
          </MrColumn>
          <MrColumn style={{ flex: 6 }}>Name</MrColumn>
          <MrColumn style={{ flex: 18 }} className="field-value">
            <p
              className="disorder-name"
              // style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {headerData.form_name}
              <span className="tooltiptext">{headerData.form_name}</span>
            </p>
          </MrColumn>
        </MrRow>

        {/* <MrRow className="section-field-row">
          <MrColumn style={{ flex: 8 }}>Disorder name</MrColumn>
          <MrColumn style={{ flex: 16 }} className="field-value">
            <p class="disorder-name">{headerData.name}</p>
          </MrColumn>
        </MrRow> */}
        {/* <MrRow className="section-field-row">
          <MrColumn style={{ flex: 12 }}>Symptoms</MrColumn>
          <MrColumn style={{ flex: 12 }} className="field-value">
            <p class="disorder-remarks">{headerData.remarks}</p>
          </MrColumn>
        </MrRow>
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 12 }}>Abbreviation</MrColumn>
          <MrColumn style={{ flex: 12 }} className="field-value">
            <p class="disorder-remarks">{headerData.remarks}</p>
          </MrColumn>
        </MrRow>
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 12 }}>Remarks</MrColumn>
          <MrColumn style={{ flex: 12 }} className="field-value">
            <p class="disorder-remarks">{headerData.remarks}</p>
          </MrColumn>
        </MrRow> */}
      </div>

      <div>
        <DisorderItemSections disorderData={props} onChange={onChange} />
      </div>

      {/* <div>
        {disorderData.length !== 0 &&
          disorderData.map((disorder, index) => (
            <Card
              type="inner"
              title={disorder.title}
              key={disorder.id}
              className="main-card"
            >
              <Row gutter={16}>
                {disorder.subSectionsList.length !== 0 &&
                  disorder.subSectionsList.map((disorderDetail, nestedIndex) =>
                    disorderDetail.hasOwnProperty("is_sub_heading") ? (
                      <Col span={disorderDetail.span} className="heading">
                        <Space>
                          <p>{disorderDetail.sub_heading_title}</p>
                        </Space>
                      </Col>
                    ) : (
                      <>
                        {disorderDetail.visible && (
                          <Col
                            span={disorderDetail.span}
                            className="subsection"
                          >
                            <Space className="subsection-title">
                              {disorderDetail.title}
                            </Space>
                            <br />
                            {disorderDetail.widget === "RadioGroupWidget" &&
                              disorderDetail.options.length !== 0 && (
                                <Radio.Group
                                  onChange={(value) =>
                                    onChange(
                                      value.target.value,
                                      index,
                                      nestedIndex
                                    )
                                  }
                                >
                                  {disorderDetail.options.map((option) => (
                                    <Space
                                      className="options"
                                      style={{ flex: 1 }}
                                    >
                                      <Radio value={option.title}>
                                        {option.title}
                                      </Radio>
                                    </Space>
                                  ))}
                                </Radio.Group>
                              )}
                            {disorderDetail.widget === "CheckboxGroupWidget" &&
                              disorderDetail.options.length !== 0 && (
                                <Checkbox.Group>
                                  {disorderDetail.options.map((option) => (
                                    <Space
                                      className="options"
                                      style={{ flex: 1 }}
                                    >
                                      <Checkbox value={option.title}>
                                        {option.title}
                                      </Checkbox>
                                    </Space>
                                  ))}
                                </Checkbox.Group>
                              )}
                            {disorderDetail.visible &&
                              disorderDetail.widget === "string" && (
                                <Input
                                  placeholder="Text"
                                  placeholder={disorderDetail.title}
                                />
                              )}
                            {disorderDetail.widget === "InputWithTags" && (
                              <InputWithTags
                                placeholderValue={disorderDetail.title}
                              />
                            )}
                            {disorderDetail.widget === "UploadWidget" && (
                              <Dragger {...uploadProps}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                  Click or drag file to this area to upload
                                </p>
                                <p className="ant-upload-hint">
                                  Support for a single or bulk upload. Strictly
                                  prohibit from uploading company data or other
                                  band files
                                </p>
                              </Dragger>
                            )}
                          </Col>
                        )}
                      </>
                    )
                  )}
              </Row>
            </Card>
          ))}
      </div> */}
    </>
  );
};
export default DisorderItemDetail;
