import React, { Component } from 'react'
import { actions } from '../redux'
import { MrConnectField } from "mr_react_framework";
import { rolesAppConstantsSelector, loadingSelector, errorSelector } from "../selector";

const initialState = {
    value: null,
};

const SelectSettings = MrConnectField({
    initialState,
    itemName: 'roles_setting',
    config: {
        placeholder: 'Hospital Settings',
        // label: 'Select Disorder',
        // textKey: 'name',
        // valueKey: 'text_value',
        // elementType: 'input',
        // default: true
    },
    fetch: true,
    actions,
    selectorsObj: {
        loading: loadingSelector,
        error: errorSelector,
        roles_settings: rolesAppConstantsSelector,
    },
    elementType: 'select',
    options: 'props.roles_settings',
});

export default SelectSettings;