import React from "react";

import { MrCrudView } from "mr_react_framework";
import {
  appConstantsSelector,
  loadingSelector,
  errorSelector,
  paginationSelector
} from "./selector";
import { actions } from "./redux";
import { currentUserSelector, orgSelector } from "../Auth/Login/selector";
import AppConstantListItem from "./AppConstantListItem/AppConstantListItem";
import AppConstantFilter from "./AppConstantFilter/AppConstantFilter";
import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import SelectOrg from "../../components/AppSpecific/SelectOrg/SelectOrg";
import commonSelectors from "../../App/commonSelectors";

// import MrReduxCrud from '../../lib/utils/MrReduxCrud/MrReduxCrud';
// const keyName = (<FormattedMessage
//   id="appConstantsForm.keyName"
//   defaultMessage="French Key Name"
// />);

defineMessages({
  keyName: {
    id: "appConstantsForm.keyName",
    defaultMessage: "Key Name"
  },
  desc: {
    id: "appConstantsForm.desc",
    defaultMessage: "Desc"
  }
});

const returnAppConstantsForm = intl => {
  // const keyName = intl.formatMessage({
  //   id: "appConstantsForm.keyName"
  // });
  // const desc = intl.formatMessage({
  //   id: "appConstantsForm.desc"
  // });
  console.log("intl", intl);
  let appConstantsForm = {
    schema: {
      type: "object",
      properties: {
        key: {
          type: "string",
          default: "Key",
          // label: keyName,
          span: 12,
          // placeholder: keyName
          // required: true
        },

        key_type: {
          type: "string",
          span: 12,
          default: "Key Type"
        },
        files: {
          type: "array",
          buttonText: "Upload files",
          icon: "upload",
          items: {
            type: "string",
            format: "data-url"
          }
        },
        dateTime: {
          type: "string",
          format: "date-time"
        },
        date: {
          type: "string",
          format: "date"
        },
        altDateTime: {
          type: "string",
          format: "date-time"
        },
        arrayType: {
          type: "array",
          minItems: 1,
          items: {
            type: "object",
            properties: {
              item1: {
                type: "string"
              }
              // item2: {
              //   type: "string"
              // }
            }
          }
        },
        desc: {
          type: "string",
          default: "desc"
        },
        text_value: {
          type: "string"
          // default: "Text Value"
        },
        value_type: {
          type: "string",
          span: 12,
          default: "Value Type"
        },
        // orgs: {
        //   span: 12,
        //   defaultValue: 1,
        //   controlName: "org_id",
        //   valueKey: "id",
        //   textKey: "name",
        //   isFormItem: true,
        //   multiple: true,
        //   elementType: "select",
        //   mrComponent: SelectOrg,
        //   group: 1,
        //   label: "Org"
        // }
      }
    },
    uiSchema: {
      orgs: {
        // "ui:field": "selectOrg"
        "ui:field": "mrComponent"
        // props => {
        //   console.log("uiwidget props", props);
        //   SelectOrg
        // }
      },
      altDateTime: {
        "ui:widget": "alt-datetime"
      },
      text_value: {
        "ui:widget": "JsonWidget"
      }
    },
    fields: {
      // selectOrg: props => {
      //   console.log("selectOrg props", props);
      //   return <SelectOrg {...props.schema} changed={props.onChange} />;
      // }
    }

    // nested_test: {
    //   elementType: "nested",
    //   fields: {
    //     key: {
    //       defaultValue: "Hello1",
    //       elementType: "input",
    //       group: 1,
    //       label: keyName,
    //       validationRules: [{ required: true }],
    //       placeholder: keyName,

    //       // prefix: {
    //       //   type: "element",
    //       //   element: <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
    //       // }
    //     }
    //   }
    // },
  };
  return appConstantsForm;
};
// const AppConstants = () => {
//   const intl = useIntl();
//   const keyName = intl.formatMessage({
//     id: "appConstantsForm.keyName",
//     defaultMessage: "Key Name"
//   });
// };

const AppConstants = MrCrudView({
  resourceName: "appConstants",
  // resourceGroupName: "app_constants",
  singleResourceName: "app_constant",
  displayName: "App Constants",
  resourceUrl: "/app_constants",
  // layout: BlankLayout,
  filter: AppConstantFilter,
  listItem: AppConstantListItem,
  forms: [{ name: "appConstants", form: returnAppConstantsForm }],
  selectorsObj: {
    ...commonSelectors
  },
  isRoute: true,
  
  // selectorsObj: {
  //   loading: loadingSelector,
  //   error: errorSelector,
  //   appConstants: appConstantsSelector,
  //   // users: usersSelector(),
  //   currentUser: currentUserSelector,
  //   pagination: paginationSelector,
  //   org: orgSelector
  // },
  actions,
  actionNames: ["fetch", "create", "update", "delete"],
  firstTimeFetchParams: { with_users: true }
});
// return appConstants;

export default AppConstants;
