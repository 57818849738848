import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import update from "immutability-helper";
import { axiosInstance } from "../api/apiModule";
import {message} from "antd";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "request",
  message,
  actionNames: ["HOSPITALS", "PATIENTS", "DISORDERS"]
});


const initialState = fromJS({
  // segments: [],
  org: [],
  hospitals: [],
  patients: [],
  disorders: [],
  error: null,
  loading: false
});
export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState);
export const watchRequests = reduxCrud.generateWatchSaga();
export default reduxCrud;