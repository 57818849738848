import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux";
import { actions as patientDisorderActions } from "./redux";
import { patientDisorderSelector, patientsSelector } from "./selector";
import PatientDisorderItemDetail from "./PatientDisorderItemDetail";
import DisorderItemView from "./DisorderItemView";

const PrintForm = (props) => {
  const { match } = props;

  let patientId = match.params && match.params.id;
  let patientDisorderId = match.params && match.params.patient_disorder_id;

  let formDisorderId = patientDisorderId.match(/\,/g);

  if (formDisorderId !== null) {
    var array = JSON.parse("[" + patientDisorderId + "]");
    // formId = [array];
    patientDisorderId = [array];
    console.log("formdisorderid==>", patientDisorderId);
  } else {
    patientDisorderId = patientDisorderId;
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (patientId) {
      dispatch(actions.show({ id: patientId }));
      dispatch(patientDisorderActions.show({ id: patientDisorderId }));
    }
    setTimeout(() => {
      window.print();
    }, 5000);
  }, []);
  const disorders = useSelector(patientDisorderSelector());
  const patients = useSelector(patientsSelector());

  return (
    <>
      {formDisorderId !== null ? (
        <DisorderItemView
          formData={
            disorders[0] && disorders[0].response_json.disorder_dependents
          }
          disorderArr={patientDisorderId[0]}
          print={true}
        />
      ) : (
        disorders.length !== 0 && (
          <PatientDisorderItemDetail
            item={disorders}
            print={true}
            patient={patients}
          />
        )
      )}
    </>
  );
};

export default PrintForm;
