import { defineMessages } from "react-intl";

const common = defineMessages({
  edit: {id: "common.edit", default: "Edit"},
  create: {id: "common.create", default: "Create"},
  delete: {id: "common.delete", default: "delete"},
  search: {id: "common.search", default: "search"},
  refresh: {id: "common.refresh", default: "refresh"},
  logout: {id: "common.logout", default: "logout"},
});
const modules = defineMessages({
  app_constants: { id: "module.app_constants", default: "app_constants" },
  orgs: { id: "module.orgs", default: "Create" },
  programmes: { id: "module.programmes", default: "programmes" },
  boards: { id: "module.boards", default: "Boards" },
  experiences: { id: "module.experiences", default: "Experiences" },
  groups: { id: "module.groups", default: "groups" },
  users: { id: "module.users", default: "users" },
  org_programmes: { id: "module.org_programmes", default: "org_programmes" },
  integrations: { id: "module.integrations", default: "integrations" },
});
const commonMessages = {
  ...common,
  ...modules,
};

export default commonMessages;