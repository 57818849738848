import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import {
  buildOptionsArr,
  buildSchemaObject,
} from "../../lib/utils/helperMethods";

export const demyelinatingForm = {
  history: {
    title: "History",
    fields: {
      type: "object",
      
      properties: {
        gender: {
          title: "Gender",
          type: "string",
          widget: "RadioGroupWidget",
          span: 24,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Male", "Female", "Other"]),
          },
        },
        age_radio: {
          title: "Age greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },
        age_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_radio"],
          children: ({ getFieldValue, sourceValues }) => {
            // console.log("chilren, line 42");
            // console.log("sourceValues", sourceValues);
            const nameVal = getFieldValue([
              "disorder_information",
              "age_radio",
            ]);
            // console.log("nameVal", nameVal);

            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_radio === "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_options",
                    title: "Age in years",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );

              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in years" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in years" />
              //   </Form.Item>
              // );
            } else if (
              nameVal === "No" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information.age_radio === "No")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "age_options",
                    title: "Age in months",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in months" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in months" />
              //   </Form.Item>
              // );
            }
            return finalRender;
            // return <React.Fragment>{finalRender}</React.Fragment>;
          },
        },

        onset: {
          title: "Onset",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Acute", "Sub-acute", "Chronic"]),
          },
        },

        fever: {
          title: "Fever",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        prodrome_illness: {
          title: "Prodrome illness",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        headache: {
          title: "Headache",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        raised_ipc: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Raised ICP", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Raised ICP</p>,
        },

        vomiting: {
          title: "Vomiting",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        blurring_of_vision: {
          title: "Blurring of vision",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        diplopia: {
          title: "Diplopia",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        altered_sensorium: {
          title: "Altered sensorium",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        seizures: {
          title: "Seizures",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        seizure_semiology: {
          title: "Seizure semiology",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Generalised", "Focal", "Unknown"]),
          },
        },

        course: {
          title: "Course",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Monophasic", "Relapsing"]),
          },
        },

        vision_loss: {
          title: "Vision loss",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        motor_weakness: {
          title: "Motor weakness",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        pattern_of_weakness: {
          title: "Pattern of weakness",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr([
              "Monoparesis",
              "Hemiparesis",
              "Paraparesis",
              "Quadriparesis",
            ]),
          },
        },

        involuntary_movements: {
          title: "Involuntary movements",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        involuntary_movements_types: {
          title: "Involuntary movements types",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr([
              "Dystonia",
              "Chorea",
              "Athetosis",
              "Tremor",
              "Others",
            ]),
          },
        },
        involuntary_movements_types_discription: {
          title: "Involuntary movements types discription",
          type: "string",
          span: 12,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.involuntary_movements_types"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "involuntary_movements_types",
            ]);
            let finalRender = null;

            if (
              nameVal === "Others" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information
                  .involuntary_movements_types === "Others")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "involuntary_movements_types_discription",
                    title: "Others",
                    inputType: "string",
                    widget: "InputNumberWidget",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
        },

        bladder_involvement: {
          title: "Bladder involvement",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        bowel_involvement: {
          title: "Bowel involvement",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        sensory_loss: {
          title: "sensory loss",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        what_sensory_loss: {
          title: "What sensory loss",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr([
              "Touch",
              "Pain",
              "Temperature",
              "Position, Vibration, Cortical sensation",
            ]),
          },
        },

        recent_immunization: {
          title: "Recent immunization",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        dog_bite: {
          title: "Dog bite",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        history_of_recent_travel: {
          title: "History of recent travel",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        history_of_viral_ecephalits_epidemics: {
          title: "History of viral ecephalits epidemics",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        head_trauma: {
          title: "Head trauma",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        contact_with_patients: {
          title: "Contact with TB patients",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        history_to_suggest_immunosuppressed_state: {
          title: "History to suggest immunosuppressed state",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
				},
				

				cranial_nerve_dysfunction: {
          title: "Cranial nerve dysfunction",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
        },
				cranial_nerve_dysfunction_discription: {
          title: " Discription",
          type: "string",
          span: 12,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.cranial_nerve_dysfunction"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "cranial_nerve_dysfunction",
            ]);
            let finalRender = null;

            if (
              nameVal === "Yes" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information
                  .cranial_nerve_dysfunction === "Yes")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "cranial_nerve_dysfunction_discription",
                    title: "Others",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
				},
				
				motor_disturbances: {
          title: "Motor disturbances",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},
				

				history_to_suggest_movement_disorder: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "History to suggest movement disorder", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>History to suggest movement disorder</p>
				},
				
				dystonia: {
          title: "Dystonia",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},

				tremor: {
          title: "Tremor",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},


				tics: {
          title: "Tics",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},


				chorea: {
          title: "Chorea",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},


				athetosis: {
          title: "Athetosis",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},

				stereotypies: {
          title: "Stereotypies",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},

				ataxia: {
          title: "Ataxia",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},

				bladder_bowel_involvement: {
          title: "Bladder/bowel involvement",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},

				sensory_disturbances: {
          title: "Sensory disturbances",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},

				past_history_of_similar_illness: {
          title: "Past history of similar illness",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},

				premorbid_development: {
          title: "Premorbid development",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(['Normal', 'delayed']),
          },
				},

				exposure_to_toxins_drugs: {
          title: "Exposure to toxins/drugs",
          type: "string",
          widget: "RadioGroupWidget",
          span: 12,
          setInitialValue: true,
          widgetConfig: {
						options: buildOptionsArr(["Yes", "No"]),
          },
				},




        other_history: {
          type: "string",
          title: "Other history",
          span: 24,
          setInitialValue: true,
        },

        gcs: {
          type: "GCS",
          title: "Other history",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },

  general_examination: {
    title: "General Examinations",
    fields: {
      type: "object",
      properties: {
     
        neurocutaneous_markers: {
          title: "Neurocutaneous markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cyanosis: {
          title: "Cyanosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        lymphadenopathy: {
          title: "Lymphadenopathy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        other_ge: {
          title: "Others",
          type: "string",
          setInitialValue: true,
          span: 24,
        },

        vitals: {
          title: "Vitals",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Stable','Unstable'])
          },
        },

        //anthropometry subheading....

        anthropometry: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Anthropometry", //using in show 
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Anthropometry</p>
        },
       
        weight: {
          title: "Weight",
          type: "number",
          span: 8,
          setInitialValue: true,
          className: "p-l-10",
        },
        height: {
          title: "Height",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        head_circumference: {
          title: "Head circumference",
          type: "number",
          span: 8,
          setInitialValue: true,
        },

        //ends_anthropometry

      },
    },
  },

  neurologicalexamination: {
    title: "Neurological Examination",
    fields: {
      type: "object",
      properties: {

        //HMF subheading
        HMF: {
          // title: "HMF",
          is_sub_heading: true, //using in show view
          sub_heading_title: "HMF", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>HMF</p>
        },

        hyperactive: {
          title: "Hyperactive",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_attention: {
          title: "Poor attention",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        speech: {
          title: "Speech",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_eye_contact: {
          title: "Poor eye contact",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        // Cranial nerves deficit

        cranial_nerves_deficit: {
          title: "Cranial nerves deficit",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        
        //Motor system (sub heading)
        motor_system: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Motor System", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Motor System</p>
        },        

        bulk: {
          title: "Bulk",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Wasting', 'Hypertrophy'])
          },
        },

        tone_normal: {
          title: "Tone normal",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Hypotonia','Hypertonia', 'Variable'])
          },
        },

        power_charting: {
          title: "Power charting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['1','2','3','4','5'])
          },
        },

        deep_tendon_reflexes: {
          title: "Deep tendon reflexes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Areflexia', 'Hyporeflexia', 'Hyperreflexia'])
          },
        },

        plantar_responses: {
          title: "Plantar responses",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Flexor','Extensor', 'No response'])
          },
        },
        // motor system ends here


        //Extrapyramidal signs sub-heading
        extrapyramidal_signs: {
          // title: "Extrapyramidal signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Extrapyramidal Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Extrapyramidal Signs</p>
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_es: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Extrapyramidal signs ends (sub section)

        //Cerebellar signs starts (sub section)
        cerebellar_signs: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Cerebellar Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cerebellar signs</p>
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        titubation: {
          title: "Titubation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_cs: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        dysmetria: {
          title: "Dysmetria",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Cerebellar signs ends (sub section)

        // Spine/cranium
        spine_cranium: {
          title: "Spine/cranium",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        other_findings: {
          title: "Other findings",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        hearing_deficit: {
          title: "Hearing Deficit",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        meningeal_signs: {
          title: "Meningeal signs",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        spinal_dysraphism: {
          title: "Spinal dysraphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        neural_tube_defects: {
          title: "Neural tube defects",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        other_examination_findings: {
          type: "string",
          title: "Other examination findings",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },

  

  investigations: {
    title: "Investigations",
    fields: {
      type: "object",
      properties: {

        cbc_inv: {
          type: "string",
          span: 24,
          // setInitialValue: true,
          is_sub_heading: true, //using in show view
          sub_heading_title: "CBC", //using in show view
          className: "m-b-0 sub-heading",
          widget: (props) => <p>CBC</p>
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        polycythaemia: {
          title: "Polycythaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        leucopenia: {
          title: "Leucopenia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        leucocytosis: {
          title: "Leucocytosis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        thrombocytopenia: {
          title: "Thrombocytopenia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        thrombocytosis: {
          title: "Thrombocytosis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


      

        lft_inv: {
          type: "string",
          span: 24,
          // setInitialValue: true,
          is_sub_heading: true, //using in show view
          sub_heading_title: "LFT", //using in show view
          className: "m-b-0 sub-heading",
          widget: (props) => <p>LFT</p>
        },


        elevated_liver_enzymes: {
          title: "Elevated liver enzymes",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        hypoalbuminemia: {
          title: "Hypoalbuminemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        
        rft_inv: {
          type: "string",
          span: 24,
          // setInitialValue: true,
          is_sub_heading: true, //using in show view
          sub_heading_title: "RFT", //using in show view
          className: "m-b-0 sub-heading",
          widget: (props) => <p>RFT</p>
        },

        creatinine: {
          title: "Creatinine",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Elevated', 'Normal'])
          },
        },


        dengue_serology: {
          title: "Dengue serology",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },


        dengue_ns1_antigen: {
          title: "Dengue NS1 antigen",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },


        ricketssial_work_up: {
          title: "Ricketssial work-up",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

        ricketssial_work_up_discription: {
          title: " Discription",
          type: "string",
          span: 12,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.ricketssial_work_up"],
          children: ({ getFieldValue, sourceValues }) => {
            const nameVal = getFieldValue([
              "disorder_information",
              "ricketssial_work_up",
            ]);
            let finalRender = null;

            if (
              nameVal === "Positive" ||
              (!nameVal &&
                sourceValues.id &&
                sourceValues.disorder_information
                  .ricketssial_work_up === "Positive")
            ) {
              finalRender = (
                <FormRenderer
                  formObj={buildSchemaObject({
                    key: "ricketssial_work_up_discription",
                    title: "Others",
                    inputType: "string",
                  })}
                  propertyKey={"disorder_information"}
                  sourceValues={sourceValues}
                />
              );
            }
            return finalRender;
          },
				},


        hiv_screening: {
          title: "HIV screening",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },


        cxr: {
          title: "CXR",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Abnormal'])
          },
        },


        mantoux_test: {
          title: "Mantoux test",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },




        egg_findings: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "EEG Findings", //using in show
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>EEG Findings</p>,
        },

        state: {
          title: "State",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Sleep", "Awake"]),
          },
        },

        background_sleep_markers: {
          title: "Background/sleep markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Normal", "Abnormal"]),
          },
        },

       

        epileptiform_discharges: {
          title: "Epileptiform discharges",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        pleds: {
          title: "PLEDS",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        ncse: {
          title: "NCSE",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(["Yes", "No"]),
          },
        },

        

        //Neuroimaging sub-heading
        Neuroimaging: {
          // title: "Neuroimaging",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Neuroimaging", //using in show
          type: "number",
          span: 24,
          setInitialValue: true,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neuroimaging</p>,
        },

        Ct_brain:{
          title: "CT Brain",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Plain', 'Contrast']),
          },
        },

        mri_brain: {
          title: "MRI Brain",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Plain', 'Contrast']),
          },
        },
        ct_findings: {
          title: "CT findings",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        mri_findings: {
          title: "MRI findings",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        mri_spine: {
          title: "MRI spine",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
         //Neuroimaging heading ends
        csf_finding: {
          // title: "Neuroimaging",
          is_sub_heading: true, //using in show view
          sub_heading_title: "CSF Finding", //using in show
          type: "number",
          span: 24,
          setInitialValue: true,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>CSF Finding</p>,
        },

        cell_count: {
          title: "Cell count",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        sugar: {
          title: "Sugar",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        protein: {
          title: "Protein",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        C_S: {
          title: "C/S",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        viral_pcr: {
          title: "viral PCR",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        tb_pcr: {
          title: "TB PCR",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        mgit_c_s: {
          title: "MGIT C/S",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        fungal_c_s: {
          title: "Fungal C/S",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        ocb: {
          title: "OCB",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        latex_agglutination: {
          title: "Latex agglutination",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        ig_g_index: {
          title: "Ig G index",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },

       

        ae_panel: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "AE panel", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>AE panel</p>
        },



        csf_ae: {
          title: "CSF",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Positive', 'Negative'])
          },
        },


        type_of_antibodies: {
          title: "Type of antibodies",
          type: "number",
          span: 24,
          setInitialValue: true,
        },


        immunodeficiency_work_up: {
          title: "Immunodeficiency work-up",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Primary', 'Secondary'])
          },
        },

       

        vasculitis_screening: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "vasculitis screening", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Vasculitis screening</p>
        },

        esr: {
          title: "ESR",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        crp: {
          title: "CRP",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        ana: {
          title: "ANA",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        dsdna: {
          title: "DSDNA",
          type: "number",
          span: 12,
          setInitialValue: true,
        },


        thyroid_antibodies: {
          title: "Thyroid antibodies",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        ace: {
          title: "ACE",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        anti_ssa : {
          title: "Anti SSA ",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        anti_ssb: {
          title: "Anti SSB",
          type: "number",
          span: 12,
          setInitialValue: true,
        },


       c_anca: {
          title: "C-ANCA",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        p_anca: {
          title: "P-ANCA",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        urine_microscopy: {
          title: "Urine microscopy",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        nmos_screening: {
          title: "NMOSD screening",
          type: "number",
          span: 12,
          setInitialValue: true,
        },


        ncs: {
          title: "NCS",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        vep_vep: {
          title: "VEP",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        bera_bera: {
          title: "BERA",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        ssep_tibial: {
          title: "SSEP tibial",
          type: "number",
          span: 12,
          setInitialValue: true,
        },

        oct: {
          title: "OCT",
          type: "number",
          span: 12,
          setInitialValue: true,
        },
        
        others: {
          title: "Others",
          type: "number",
          span: 24,
          setInitialValue: true,
        },

       
      },
    },
  },

  diagnosis: {
    title: "Diagnosis",
    fields: {
      type: "object",
      properties: {
        final_diagnosis: {
          type: "string",
          title: "Final diagnosis",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },
  management: {
    title: "Management",
    fields: {
      type: "object",
      properties: {



        requirement_of_mechanical_ventilation: {
          title: "Requirement of mechanical ventilation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        ivig: {
          title: "IVIG",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        ivmp: {
          title: "IVMP",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        plasmapheresis: {
          title: "Plasmapheresis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        rituximab: {
          title: "Rituximab",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        azathioprine: {
          title: "Azathioprine",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        cyclophosphamied: {
          title: "Cyclophosphamied",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        mycophenolate: {
          title: "Mycophenolate",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        Other_drugs: {
          type: "string",
          title: "Other drugs",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },

  outcome_section: {
    title: "Outcome",
    fields: {
      type: "object",
      properties: {
        duration_of_follow_up: {
          type: "string",
          title: "Duration of follow-up (In months)",
          span: 12,
          setInitialValue: true,
        },

        cognitive_outcome: {
          title: "Cognitive outcome",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Normal', 'Learning disbilities', 'Behavioural symptoms'])
          },
        },

        mrs: {
          type: "string",
          title: "MRS",
          span: 12,
          setInitialValue: true,
        },

        vision_deficits: {
          title: "Vision deficits",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        hearing_defiicits: {
          title: "Hearing defiicits",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        sleep_problems: {
          title: "Sleep problems",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        behavioural_symptoms_outcome: {
          title: "Behavioural symptoms",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        epilepsy: {
          title: "Epilepsy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        bladder_bowel_outcome: {
          type: "string",
          title: "Bladder/bowel outcome",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },
};
