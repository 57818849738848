import React, { useEffect } from "react";
import { Layout } from "antd";
import classNames from "classnames";
import styled from "styled-components";

// import styles from "./AuthLayout.scss";
// import BaseLayout from "../BaseLayout/BaseLayout";
import LogoImage from "../../../assets/images/logo.png";
import { useRouteMatch, useLocation, Redirect } from "react-router-dom";
// import Spinner from "components/UI/Spinner/Spinner";

const styledContentTop = {};

const PrintLayout = (props) => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const { isAuthenticated, tokenPresent } = props;

  console.log("PrintLayout props", props);
  console.log("PrintLayout this.props.location", location);
  console.log("isAuthenticated, tokenPresent", isAuthenticated, tokenPresent);
  const homePath = "/";

  return (
    <div>
      {/* Print Layout */}
      <div>{props.children}</div>
    </div>
  );
};
export default PrintLayout;
