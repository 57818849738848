import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import { buildOptionsArr, buildSchemaObject } from "../../lib/utils/helperMethods";

export const asdForm = {
  history: {
    title: "History",
    fields: {
      type: "object",
      properties: {
        
        gender: {
          title: "Gender",
          type: "string",
          widget: "RadioGroupWidget",
          span: 24,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Male','Female','Other'])
          },
        },
        age_radio: {
          title: "Age greater than 5 years?",
          type: "string",
          widget: "RadioGroupWidget",
          span: 6,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        age_options: {
          title: "Age",
          type: "string",
          span: 6,
          setInitialValue: true,
          className: "m-b-0",
          dependsOn: ["disorder_information.age_radio"],
          children: ({ getFieldValue, sourceValues }) => {
            // console.log("chilren, line 42");
            // console.log("sourceValues", sourceValues);
            const nameVal = getFieldValue(["disorder_information", "age_radio"]);
            // console.log("nameVal", nameVal);

            let finalRender = null;
            
            if (nameVal === "Yes" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "Yes")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_options", title: "Age in years", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />

              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in years" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in years" />
              //   </Form.Item>
              // );
            } else if (nameVal === "No" || (!nameVal && sourceValues.id && sourceValues.disorder_information.age_radio === "No")) {
              finalRender = <FormRenderer
                formObj={buildSchemaObject({key: "age_options", title: "Age in months", inputType: "string", widget: "InputNumberWidget"})}
                propertyKey={"disorder_information"}
                sourceValues={sourceValues}
              />
              // FormItem wasn't working
              // finalRender = (
              //   <Form.Item label="Age in months" name={["disorder_information", "age_options"]} >
              //     <Input placeholder="Age in months" />
              //   </Form.Item>
              // );
            }
            return finalRender;
            // return <React.Fragment>{finalRender}</React.Fragment>;
          },
        },
        age_at_symptom_onset: {
          title: "Age at symptom onset (in months)",
          type: "string",
          widget: "InputNumberWidget",
          span: 6,
          setInitialValue: true
        },
        age_at_diagnosis: {
          title: "Age at diagnosis (in months)",
          type: "string",
          widget: "InputNumberWidget",
          span: 6,
          setInitialValue: true
        },

        
        antenatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "antenatal_risk_factors", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Antenatal risk factors</p>
        },


        anaemia: {
          title: "Anaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        hypothyroidism: {
          title: "Hypothyroidism",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        worsening_with_intercurrent_illness: {
          title: "Worsening with intercurrent illness",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        pih: {
          title: "Pih",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        gdm: {
          title: "Gdm",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        fever: {
          title: "Fever",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        skin_rash: {
          title: "Skin rash",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        drug_exposure: {
          title: "Drug exposure",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        others_antenatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 8,
          setInitialValue: true,
        },


        perinatal_history: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "perinatal_history", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Perinatal history</p>
        },

        // Gestational age
        gestational_age: {
          title: "Gestational age",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Preterm', 'Term', 'Post term'])
          },
        },

        
        birth_weight: {
          title: "Birth weight",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['SGA', 'AGA', 'LGA'])
          },
        },


        neonatal_risk_factors: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "neonatal_risk_factors", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neonatal risk factors</p>
        },

        birth_asphyxia: {
          title: "Birth asphyxia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        hypoglycaemia: {
          title: "Hypoglycaemia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
       
        sepsis: {
          title: "Sepsis",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        trauma: {
          title: "Trauma",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cogulopathy: {
          title: "Cogulopathy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        others_neonatal_risk_factors: {
          type: "string",
          title: "Others",
          span: 24,
          setInitialValue: true,
        },

        family_history: {
          title: "Family history",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        epilepsy: {
          title: "Epilepsy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        seizure_semiology: {
          title: "Seizure semiology",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Generalised', 'Focal', 'Unknown'])
          },
        },


        cerebral_palsy: {
          title: "Cerebral palsy",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        development_history: {
          is_sub_heading: true, //using in show view
          sub_heading_title: "development_history", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Development history</p>
        },

        gross_motor: {
          type: "string",
          title: "Gross motor",
          span: 12,
          setInitialValue: true,
        },

        fine_motor: {
          type: "string",
          title: "Fine motor",
          span: 12,
          setInitialValue: true,
        },

        socio_adaptive : {
          type: "string",
          title: "Socio-adaptive",
          span: 12,
          setInitialValue: true,
        },

        language: {
          type: "string",
          title: "Language",
          span: 12,
          setInitialValue: true,
        },

        vision: {
          type: "string",
          title: "Vision",
          span: 12,
          setInitialValue: true,
        },

        hearing: {
          type: "string",
          title: "Hearing",
          span: 12,
          setInitialValue: true,
        },





        gdd: {
          title: "GDD",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        dq_iq: {
          title: "DQ/IQ",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        autistic_regression: {
          title: "Autistic regression",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        extrapyramidal_symptoms: {
          title: "Extrapyramidal symptoms",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },



        stereotypies: {
          title: "Stereotypies",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        hyperactivity: {
          title: "Hyperactivity",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        agression: {
          title: "Agression",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        feeding_problems: {
          title: "Feeding problems",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cardiac_arrhrythmias: {
          title: "Cardiac arrhrythmias",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        respiratory_dysrhythmia: {
          title: "Respiratory dysrhythmia",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        sleep_abnormalities: {
          title: "Sleep abnormalities",
          type: "string",
          span: 8,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        other_history: {
          type: "string",
          title: "Other history",
          span: 12,
          setInitialValue: true,
        },
  
      },
    },
  },

  general_examination: {
    title: "General Examinations",
    fields: {
      type: "object",
      properties: {
     
        neurocutaneous_markers: {
          title: "Neurocutaneous markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        facial_dysmorphism: {
          title: "Facial dysmorphism",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        anaemia: {
          title: "Anaemia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        jaundice: {
          title: "Jaundice",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        cyanosis: {
          title: "Cyanosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        lymphadenopathy: {
          title: "Lymphadenopathy",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          // span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        other_ge: {
          title: "Others",
          type: "string",
          setInitialValue: true,
          // span: 8,
        },

        vitals: {
          title: "Vitals",
          type: "string",
          span: 24,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Stable','Unstable'])
          },
        },

        //anthropometry subheading....

        anthropometry: {
          type: "string",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Anthropometry", //using in show 
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Anthropometry</p>
        },
       
        weight: {
          title: "Weight",
          type: "number",
          span: 8,
          setInitialValue: true,
          className: "p-l-10",
        },
        height: {
          title: "Height",
          type: "number",
          span: 8,
          setInitialValue: true,
        },
        head_circumference: {
          title: "Head circumference",
          type: "number",
          span: 8,
          setInitialValue: true,
        },

        //ends_anthropometry

      },
    },
  },

  neurologicalexamination: {
    title: "Neurological Examination",
    fields: {
      type: "object",
      properties: {

        //HMF subheading
        HMF: {
          // title: "HMF",
          is_sub_heading: true, //using in show view
          sub_heading_title: "HMF", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>HMF</p>
        },

        hyperactive: {
          title: "Hyperactive",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_attention: {
          title: "Poor attention",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        speech: {
          title: "Speech",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        poor_eye_contact: {
          title: "Poor eye contact",
          type: "string",
          span: 6,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        // Cranial nerves deficit

        cranial_nerves_deficit: {
          title: "Cranial nerves deficit",
          type: "string",
          span: 24,
          setInitialValue: true,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        
        //Motor system (sub heading)
        motor_system: {
          // title: "Motor system",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Motor System", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Motor System</p>
        },        

        bulk: {
          title: "Bulk",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Wasting', 'Hypertrophy'])
          },
        },

        tone_normal: {
          title: "Tone normal",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Hypotonia','Hypertonia', 'Variable'])
          },
        },

        power_charting: {
          title: "Power charting",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['1','2','3','4','5'])
          },
        },

        deep_tendon_reflexes: {
          title: "Deep tendon reflexes",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Areflexia', 'Hyporeflexia', 'Hyperreflexia'])
          },
        },

        plantar_responses: {
          title: "Plantar responses",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Flexor','Extensor', 'No response'])
          },
        },
        // motor system ends here


        //Extrapyramidal signs sub-heading
        extrapyramidal_signs: {
          // title: "Extrapyramidal signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Extrapyramidal Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Extrapyramidal Signs</p>
        },

        dystonia: {
          title: "Dystonia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_es: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tics: {
          title: "Tics",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        chorea: {
          title: "Chorea",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        athetosis: {
          title: "Athetosis",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        stereotypies: {
          title: "Stereotypies",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Extrapyramidal signs ends (sub section)

        //Cerebellar signs starts (sub section)
        cerebellar_signs: {
          // title: "Cerebellar signs",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Cerebellar Signs", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Cerebellar signs</p>
        },

        nystagmus: {
          title: "Nystagmus",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        titubation: {
          title: "Titubation",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        tremor_cs: {
          title: "Tremor",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        ataxia: {
          title: "Ataxia",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        dysmetria: {
          title: "Dysmetria",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 8,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
        //Cerebellar signs ends (sub section)

        // Spine/cranium
        spine_cranium: {
          title: "Spine/cranium",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        other_findings: {
          title: "Other findings",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        vision_assessment: {
          title: "Vision assessment",
          type: "string",
          span: 24,
          setInitialValue: true,

        },

        hearing_deficit: {
          title: "Hearing Deficit",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },
      },
    },
  },

  etiology_section: {
    title: "Etiology",
    fields: {
      type: "object",
      properties: {

        etiology: {
          type: "string",
          title: "Etiology",
          span: 24,
          setInitialValue: true,
        },
     
      },
    },
  },

  asd_severity_section: {
    title: "ASD severity",
    fields: {
      type: "object",
      properties: {

        asd_severity: {
          type: "string",
          title: "ASD severity",
          span: 24,
          setInitialValue: true,
        },
     
      },
    },
  },

  investigations: {
    title: "Investigations",
    fields: {
      type: "object",
      properties: {

        //Neuroimaging sub-heading
        Neuroimaging: {
          // title: "Neuroimaging",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Neuroimaging", //using in show 
          type: "number",
          span: 24,
          setInitialValue: true,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Neuroimaging</p>
        },

        Ct: {
          title: "CT",
          type: "number",
          span: 24,
          setInitialValue: true,
        },

        MRI: {
          title: "MRI",
          type: "number",
          span: 24,
          setInitialValue: true,
        },
        //Neuroimaging heading ends

        //EEG findings sub-heading
        egg_findings: {
          // title: "EEG findings",
          is_sub_heading: true, //using in show view
          sub_heading_title: "EEG Findings", //using in show 
          type: "number",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>EEG Findings</p>
        },

        type_of_record: {
          title: "Type of record",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Sleep','Awake'])
          },
        },

        background: {
          title: "Background",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Normal','Abnormal'])
          },
        },

        sleep_markers: {
          title: "Sleep markers",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },


        epileptiform_discharges: {
          title: "Epileptiform discharges",
          type: "string",
          setInitialValue: true,
          widget: "RadioGroupWidget",
          span: 6,
          widgetConfig: {
            options: buildOptionsArr(['Yes','No'])
          },
        },

        morphology_of_epileptiform_discharges: {
          title: " Morphology of epileptiform discharges",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
        //egg finding ends


        metabolic_screening_radio: {
          title: "Metabolic screening",
          type: "string",
          setInitialValue: true,
          span: 6,
          widget: "RadioGroupWidget",
          widgetConfig: {
            options: buildOptionsArr(['Abnormal','Normal'])
          },
        },

        // metabolic_screening_abnormal: {
        //   title: "Metabolic screening - Abnormal",
        //   type: "string",
        //   span: 18,
        //   className: "m-b-0",
        //   dependsOn: ["disorder_information.metabolic_screening_radio"],
        //   children: ({ getFieldValue, sourceValues }) => {
        //     console.log("chilren, line 42");
        //     const nameVal = getFieldValue(["disorder_information", "metabolic_screening_radio"]);
        //     let finalRender = null;
            
        //     if (nameVal === "Abnormal" || (!nameVal && sourceValues.id && sourceValues.disorder_information.metabolic_screening_radio === "Abnormal")) {
        //       finalRender = <FormRenderer
        //         formObj={buildSchemaObject({key: "metabolic_screening_abnormal", title: "Please elaborate", inputType: "string"})}
        //         propertyKey={"disorder_information"}
        //         sourceValues={sourceValues}
        //       />
        //     }
        //     return finalRender;
        //   },
        // },

        heavy_metal_screening: {
          title: "Heavy metal screening",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        // Genetic testing subheading
        genetic_testing: {
          // title: "Genetic testing",
          is_sub_heading: true, //using in show view
          sub_heading_title: "Genetic testing", //using in show 
          type: "string",
          span: 24,
          className: "m-b-0 sub-heading",
          widget: (props) => <p>Genetic testing</p>
        },

        karyotype: {
          title: "Karyotype",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        Chromosomal_microarray: {
          title: "Chromosomal microarray",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        Clinical_exome: {
          title: "Clinical exome",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        whole_exome: {
          title: "Whole exome",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        mitochondrial_genome_sequencing: {
          title: "Mitochondrial genome sequencing",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        others_gt: {
          title: "Others",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
        // Genetic testing  ends

        psg_finding: {
          title: "PSG finding",
          type: "string",
          span: 24,
          setInitialValue: true,
        },

        other_investigations: {
          title: "Other investigations",
          type: "string",
          span: 24,
          setInitialValue: true,
        },
      },
    },
  },


  treatment: {
    title: "Treatment",
    fields: {
      type: "object",
      properties: {

        type_of_intervention: {
          type: "string",
          title: "Type of intervention",
          span: 12,
          setInitialValue: true,
        },
        Medications: {
          type: "string",
          title: "Medications",
          span: 12,
          setInitialValue: true,
        },
        Others_treatment: {
          type: "string",
          title: "Others",
          span: 12,
          setInitialValue: true,
        },
      
     
      },
    },
  },

  outcome_section: {
    title: "Outcome",
    fields: {
      type: "object",
      properties: {

        Outcome: {
          type: "string",
          title: "Outcome",
          span: 12,
          setInitialValue: true,
        },
      },
    },
  },



  



};