import React from "react";
import { MrCrudView, MrSelect } from "mr_react_framework";
import "./Patients.scss";
import { PatientsList, PatientColumns } from "./PatientsListItems/PatientsList";
// import HeaderLayout from "../../components/Layouts/HeaderLayout/HeaderLayout";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import PatientsFilter from "./PatientsFilter/PatientsFilter";
import PatientList from "./PatientList/PatientList";
import PatientItemDetail from "./PatientItemDetail/PatientItemDetail";
import { actions } from "./redux";
// import PatientsHeader from "./PatientsHeader";
import commonSelectors from "../../App/commonSelectors";
import SelectHospital from "../Hospital/SelectHospital/SelectHospital";
import { Select, Row, Col, Form, Input, Radio, Card, message } from "antd";
import { FormRenderer } from "mr_react_framework";
import {
  buildCountriesOptionsArr,
  checkMob,
} from "../../lib/utils/helperMethods";

import { adhdForm } from "../PatientDisorderForms/adhd";
import { asdForm } from "../PatientDisorderForms/asd";
import { encephalitisForm } from "../PatientDisorderForms/encephalitis";
import { movementDisorderForm } from "../PatientDisorderForms/movementDisorder";
import { neurodegenerativeForm } from "../PatientDisorderForms/neurodegenerative";
import { cerebralForm } from "../PatientDisorderForms/cerebralPalsy";
import { neuromuscularForm } from "../PatientDisorderForms/neuromuscular";
import { epilepsyForm } from "../PatientDisorderForms/epilepsy";
import { gddForm } from "../PatientDisorderForms/gdd";
import { ldForm } from "../PatientDisorderForms/ld";
import { strokeForm } from "../PatientDisorderForms/stroke";
import { demyelinatingForm } from "../PatientDisorderForms/demyelinating";
import { uniqWith, defaultsDeep, merge } from "lodash";
import PatientsCreateMobile from "./PatientsCreateMobile.js";
import CustomPatientForm from "./CustomPatientForm.js";

//
import {
  buildOptionsArr,
  buildSchemaObject,
} from "../../lib/utils/helperMethods";
import SelectDisorder from "../Disorders/SelectDisorder/SelectDisorder";
import InputWithTags from "../../components/UI/Input/InputWithTags";
import { actions as genericActions } from "../../App/genericRedux";
import SelectUpload from "../../components/SelectUpload/SelectUpload";

// import MrSelect from "../../components/UI/MrSelect/MrSelect";

const disorderFormsObj = {
  adhdForm: adhdForm,
  asdForm: asdForm,
  encephalitisForm: encephalitisForm,
  movementDisorderForm: movementDisorderForm,
  neurodegenerativeForm: neurodegenerativeForm,
  cerebralForm: cerebralForm,
  neuromuscularForm: neuromuscularForm,
  epilepsyForm: epilepsyForm,
  gddForm: gddForm,
  ldForm: ldForm,
  strokeForm: strokeForm,
  demyelinatingForm: demyelinatingForm,
};

defineMessages({
  patient_search: {
    id: "module.patient",
    defaultMessage: "Patient",
  },
});

const SelectDisorderType = MrSelect({
  config: {
    processData: (data, props) => {
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "ADHD", value: "adhdForm" },
        { label: "ASD", value: "asdForm" },
        { label: "CEREBRAL PALSY", value: "cerebralForm" },
        { label: "DEMYELINATING", value: "demyelinatingForm" },
        { label: "ENCEPHALITIS", value: "encephalitisForm" },
        { label: "EPILEPSY", value: "epilepsyForm" },
        { label: "GLOBAL DEVELOPMENTAL DELAY", value: "gddForm" },
        { label: "LEARNING DISABILITY", value: "ldForm" },
        { label: "MOVEMENT DISORDER", value: "movementDisorderForm" },
        { label: "NEURODEGENERATIVE", value: "neurodegenerativeForm" },
        { label: "NEUROMUSCULAR", value: "neuromuscularForm" },
        { label: "STROKE", value: "strokeForm" },
      ],
      showSearch: false,
      // filterOption: true,
      optionFilterProp: "label",
      placeholder: "Value",
      // style: { width: "120px" },
      allowClear: true,
    },
  },
});

const SelectDisorderType2 = MrSelect({
  actions: genericActions,
  resourceName: "disorders",
  config: {
    url: "/disorders",
    // params: { key: "value" },
    processData: (data, props) => {
      console.log("data==>", data);
      return data.map((item) => {
        return { label: item.name, value: item.id };
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Select school",
      // mode: "multiple",
      style: { width: "250px" },
      allowClear: true,
    },
  },
});

const SelectCountry = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: buildCountriesOptionsArr(),
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Country",
      // style: { width: "100%" },
      allowClear: true,
    },
  },
});

export const returnDisorderInfoForm = (props) => {
  const { intl, currentUser, permission } = props;
  let patientsForm = {
    schema: {
      type: "object",
      properties: {
        disorder_information: {
          title: "Disorder Type(Multi Select)",
          type: "object",
          properties: {
            // disorder_type2: {
            //   title: "Disorder2",
            //   type: "string",
            //   placeholder: "Disorder Type2",
            //   widget: SelectDisorderType2,
            //   widgetConfig: {
            //     mode: "multiple"
            //   },
            //   span: 8,
            // },
            disorder_type: {
              title: "Disorder",
              type: "string",
              placeholder: "Disorder Type",
              widget: SelectDisorderType,
              widgetConfig: {
                mode: "multiple",
              },
              span: 24,
            },
            disorder_dependents: {
              type: "string",
              className: "m-b-0",
              dependsOn: ["disorder_information.disorder_type"],
              setInitialValue: true,
              children: ({ getFieldValue, sourceValues }) => {
                const disorderArr = getFieldValue([
                  "disorder_information",
                  "disorder_type",
                ]);
                let finalRender = [];
                const selectedDisorderList = [];
                if (disorderArr && disorderArr.length !== 0) {
                  for (var i = 0; i < disorderArr.length; i++) {
                    var current = disorderArr[i];
                    var formsObjCurrent = disorderFormsObj[current];
                    selectedDisorderList.push(formsObjCurrent);
                  }
                }
                var finalObj = {};

                for (var i = 0; i < selectedDisorderList.length; i++) {
                  var current = selectedDisorderList[i];
                  finalObj = merge(finalObj, current);
                }

                for (const key in finalObj) {
                  if (finalObj.hasOwnProperty(key)) {
                    const element = finalObj[key];
                    console.log("finalObj==>", element);
                    finalRender.push(
                      <React.Fragment key={`formSection__${key}`}>
                        <Card title={element.title} className="section-card">
                          {/* <h3 class="section-title">{element.title}</h3> */}
                          <Row gutter={[16, 16]} className="section-row m-0">
                            <FormRenderer
                              formObj={element.fields}
                              propertyKey={"disorder_information"}
                              sourceValues={sourceValues}
                            />
                          </Row>
                        </Card>
                      </React.Fragment>
                    );
                  }
                }
                return finalRender;
              },
            },
          },
        },
      },
    },
  };
  return patientsForm;
};

export const returnPatientsFormMobileView = (props) => {
  console.log("Ganesh props ", props);
  const { intl, currentUser, permission } = props;
  let patientsFormMobileView = {
    schema: [
      {
        type: "object",
        properties: {
          uhid: {
            title: "UHID",
            type: "number",
            span: 24,
            setInitialValue: true,
          },
        },
      },
      {
        type: "object",
        properties: {
          first_name: {
            type: "string",
            // rules: [
            //   {
            //     required: true,
            //     message: "Please enter your first name",
            //   },
            // ],
            title: "First Name",
            default: "",
            span: 24,
          },
        },
      },
      // {
      //   type: "object",
      //   properties: {
      //     middle_name: {
      //       type: "string",
      //       default: "",
      //       title: "Middle Name",
      //       span: 24,
      //     },

      //   },
      // },
      {
        type: "object",
        properties: {
          last_name: {
            type: "string",
            default: "",
            title: "Last Name",
            span: 24,
            visible: "",
          },
        },
      },
      {
        type: "object",
        properties: {
          gender: {
            title: "Gender",
            type: "string",
            widget: "RadioGroupWidget",
            span: 24,
            setInitialValue: true,
            widgetConfig: {
              options: buildOptionsArr(["Male", "Female", "Other"]),
            },
          },
        },
      },
      // {
      //   type: "object",
      //   properties: {
      //     phone: {
      //       type: "string",
      //       default: "",
      //       title: "Phone",
      //       span: 24,
      //     },
      //   },
      // },
      // {
      //   type: "object",
      //   properties: {
      //     dob: {
      //       title: "Date Of Birth",
      //       type: "string",
      //       format: "date-time",
      //       span: 24,
      //       setInitialValue: true,
      //       widget: "DatePickerWidget",
      //       widgetConfig: {
      //         style: {
      //           width: "250px",
      //         },
      //       },
      //     },
      //   },
      // },
      {
        type: "object",
        properties: {
          email: {
            type: "string",
            default: "",
            title: "Email",
            span: 24,
            visible: "",
          },
        },
      },
      // {
      //   type: "object",
      //   properties: {
      //     address: {
      //       type: "string",
      //       default: "",
      //       title: "Address",
      //       span: 24,
      //       visible: "",
      //     },
      //   },
      // },
      // {
      //   type: "object",
      //   properties: {
      //     aadhar_card: {
      //       title: "Aadhar Number",
      //       type: "string",
      //       span: 24,
      //       setInitialValue: true,
      //     },
      //   },
      // },
      // {
      //   type: "object",
      //   properties: {
      //     ethnic_background: {
      //       title: "Ethnic Background",
      //       type: "string",
      //       span: 24,
      //       setInitialValue: true,
      //       widget: InputWithTags,
      //     },
      //   },
      // },
      {
        type: "object",
        properties: {
          upload_consent_form: {
            title: "Upload Consent Form",
            type: "string",
            span: 24,
            widget: "UploadWidget",
            widgetConfig: {
              listType: "picture-card",
              // type: "base64",
              dragger: {
                text: "Click or drag file to this area to upload",
                hint: "Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files",
              },
              buttonText: "Upload files",
              // listType: "picture-card",
            },
          },
        },
      },
      // {
      //   type: "object",
      //   properties: {
      //     upload_files: {
      //       title: "Upload Files",
      //       type: "string",
      //       span: 24,
      //       widget: "UploadWidget",
      //       widgetConfig: {
      //         listType: "picture-card",
      //         // type: "base64",
      //         dragger: {
      //           text: "Click or drag file to this area to upload",
      //           hint:
      //             "Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files",
      //         },
      //         buttonText: "Upload files",
      //         // listType: "picture-card",
      //       },
      //     },
      //   },
      // },
    ],
  };
  console.log("patientsFormmobileviewcard", patientsFormMobileView);
  return patientsFormMobileView;
};

export const returnPatientsForm = (props) => {
  console.log("shikhar props", props);
  const { intl, currentUser, permission } = props;
  let patientsForm = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps  ==>", opts);
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        // auto gen on BE
        uuid: {
          title: "Registry ID [Auto-generated]",
          placeholder: "Registry ID",
          type: "number",
          span: 24,
          setInitialValue: true,
          widgetConfig: {
            disabled: true,
          },
          // rules: [{ required: true, message: "Required" }],
        },
        uhid: {
          title: "UHID",
          type: "number",
          span: 24,
          setInitialValue: true,
          rules: [{ required: true, message: "Required" }],
        },
        first_name: {
          type: "string",
          rules: [
            {
              required: true,
              message: "Please enter your first name",
            },
          ],
          title: "First Name",
          default: "",
          span: 8,
        },
        middle_name: {
          type: "string",
          default: "",
          title: "Middle Name",
          span: 8,
        },
        last_name: {
          type: "string",
          default: "",
          title: "Last Name",
          span: 8,
          visible: "",
        },
        gender: {
          title: "Gender",
          type: "string",
          widget: "RadioGroupWidget",
          span: 8,
          setInitialValue: true,
          widgetConfig: {
            options: buildOptionsArr(["Male", "Female", "Other"]),
          },
          rules: [{ required: true, message: "Required" }],
        },
        date_of_birth: {
          title: "Date Of Birth",
          type: "string",
          format: "date-time",
          span: 9,
          setInitialValue: true,
          widget: "DatePickerWidget",
          widgetConfig: {
            style: {
              width: "250px",
            },
          },
          rules: [{ required: true, message: "Required" }],
        },
        phone: {
          type: "string",
          default: "",
          title: "Phone 1",
          span: 8,
          rules: [
            {
              required: true,
              message: "Required",
            },
          ],
        },
        phone_2: {
          type: "string",
          default: "",
          title: "Phone 2",
          span: 8,
        },

        email: {
          type: "string",
          default: "",
          title: "Email",
          span: 8,
          visible: "",
        },
        address: {
          type: "string",
          default: "",
          title: "Address",
          span: 8,
          visible: "",
        },
        state: {
          type: "string",
          default: "",
          title: "State",
          span: 8,
          visible: "",
        },
        country: {
          type: "string",
          title: "Country",
          span: 8,
          // default: "India",
          widget: SelectCountry,
          rules: [{ required: true, message: "Required" }],
        },
        ethnic_background: {
          title: "Ethnic Background",
          type: "string",
          span: 12,
          setInitialValue: true,
        },
        custom_fields: {
          type: "object",
          properties: {
            addiction: {
              title: "Addiction (pan drug etc)",
              type: "string",
              span: 12,
            },
            father_occupation: {
              span: 12,
              title: "Father's occupation",
              type: "string",
              widget: "CheckboxGroupWidget",
              widgetConfig: {
                options: buildOptionsArr([
                  "Legislators,senior officials and managers",
                  "Professionals",
                  "Technicians and Associate Professionals",
                  "Clerks",
                  "Skilled workers and shop and market sales workers",
                  "Skilled agricultural and fishery workers",
                  "craft and related trade workers",
                  "plant and machine operators and assemblers",
                  "elementary occupation",
                  "unemployed",
                ]),
              },
            },
            mother_occupation: {
              span: 12,
              title: "Mother's occupation",
              type: "string",
              widget: "CheckboxGroupWidget",
              widgetConfig: {
                options: buildOptionsArr([
                  "Legislators,senior officials and managers",
                  "Professionals",
                  "Technicians and Associate Professionals",
                  "Clerks",
                  "Skilled workers and shop and market sales workers",
                  "Skilled agricultural and fishery workers",
                  "craft and related trade workers",
                  "plant and machine operators and assemblers",
                  "elementary occupation",
                  "unemployed",
                ]),
              },
            },
            father_education: {
              span: 12,
              title: "Father's Education",
              type: "string",
              widget: "CheckboxGroupWidget",
              widgetConfig: {
                options: buildOptionsArr([
                  "Professional degree",
                  "Graduate",
                  "Intermediate/diploma",
                  "High School",
                  "Middle School",
                  "Primary School",
                  "Illiterate",
                ]),
              },
            },
            mother_education: {
              span: 12,
              title: "Mother's Education",
              type: "string",
              widget: "CheckboxGroupWidget",
              widgetConfig: {
                options: buildOptionsArr([
                  "Professional degree",
                  "Graduate",
                  "Intermediate/diploma",
                  "High School",
                  "Middle School",
                  "Primary School",
                  "Illiterate",
                ]),
              },
            },
          },
        },
        socioeconomic_status: {
          title: "Socio Economic Status (WAMI Score)",
          type: "string",
          span: 12,
          setInitialValue: true,
          widget: "CheckboxGroupWidget",
          widgetConfig: {
            options: buildOptionsArr([
              "2000 and above",
              "1000-1999",
              "750-999",
              "500-749",
              "300-499",
              "101-299",
              "Less than 100",
            ]),
          },
          // widget: InputWithTags
        },
        national_id: {
          title: "National ID number",
          type: "string",
          span: 24,
          setInitialValue: true,
          rules: [{ required: true, message: "Required" }],
        },
        attachments_json: {
          type: "object",
          properties: {
            national_id: {
              title: "Upload National ID",
              type: "string",
              span: 24,
              widget: SelectUpload,
              setInitialValue: true,
              rules: [{ required: true, message: "Required" }], // TODO: not working
            },

            consent_forms: {
              title: "Upload Consent Forms",
              type: "string",
              span: 24,
              widget: SelectUpload,
            },
            photos: {
              title: "Upload Photos",
              type: "string",
              span: 24,
              widget: SelectUpload,
            },
            videos: {
              title: "Upload Videos",
              type: "string",
              span: 24,
              widget: SelectUpload,
            },
            reports: {
              title: "Upload Reports",
              type: "string",
              span: 24,
              widget: SelectUpload,
            },
            extra_files: {
              title: "Upload Extra Files",
              type: "string",
              span: 24,
              widget: SelectUpload,
              // widget: "UploadWidget",
              // widgetConfig: {
              //   listType: "picture-card",
              //   // type: "base64",
              //   dragger: {
              //     text: "Click or drag file to this area to upload",
              //     hint: "Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files",
              //   },
              //   buttonText: "Upload files",
              //   // listType: "picture-card",
              // },
            },
          },
        },

        // patient_images: {
        //   type: "string",
        //   span: 8,
        //   default: [],
        //   title: "Patient Photo",
        //   listType: "picture-card",
        //   // icon: "upload",
        //   widget: "UploadWidget",
        //   widgetConfig: {
        //     listType: "picture-card",
        //     // type: "base64",
        //     dragger: {
        //       text: "Click or drag file to this area to upload",
        //       hint:
        //         "Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files",
        //     },
        //     buttonText: "Upload files",
        //     // listType: "picture-card",
        //   },
        //   items: {
        //     type: "object",
        //     properties: {
        //       value: {
        //         type: "string",
        //         format: "data-url",
        //       },
        //     },
        //   },
        // },
        hospital_id: {
          //replace with select and show hospital name
          type: "string",
          title: "Hospital",
          span: 0,
          widgetConfig: {
            disabled: currentUser.role === "superadmin" ? false : true,
          },
          className: "hidden",
        },
        // disorder_information: {
        //   title: "Disorder Type(Multi Select)",
        //   type: "object",
        //   properties: {
        //     disorder_type: {
        //       title: "Disorder",
        //       type: "string",
        //       placeholder: "Disorder Type",
        //       widget: SelectDisorderType,
        //       widgetConfig: {
        //         mode: "multiple"
        //       },
        //       span: 8,
        //     },
        //     disorder_dependents: {
        //       type: "string",
        //       className: "m-b-0",
        //       dependsOn: ["disorder_information.disorder_type"],
        //       setInitialValue: true,
        //       children: ({ getFieldValue, sourceValues }) => {
        //         const disorderArr = getFieldValue([
        //           "disorder_information",
        //           "disorder_type"
        //         ]);
        //         let finalRender = [];
        //         const selectedDisorderList = [];
        //         if (disorderArr && disorderArr.length !== 0) {
        //           for (var i = 0; i < disorderArr.length; i++) {
        //             var current = disorderArr[i];
        //             var formsObjCurrent = disorderFormsObj[current];
        //             selectedDisorderList.push(formsObjCurrent);
        //           }
        //         }
        //         // var finalObj;
        //         var finalObj = {};
        //         // if (selectedDisorderList.length !== 0) {
        //         //   finalObj = selectedDisorderList.reduce((accumulator, currentValue) => {
        //         //     var mergedObj = defaultsDeep(accumulator, currentValue);
        //         //     return mergedObj;
        //         //   });

        //         // }
        //         for (var i = 0; i < selectedDisorderList.length; i++) {
        //           var current = selectedDisorderList[i];
        //           finalObj = merge(finalObj, current);
        //         }

        //         for (const key in finalObj) {
        //           if (finalObj.hasOwnProperty(key)) {
        //             const element = finalObj[key];
        //             finalRender.push(
        //               <React.Fragment key={`formSection__${key}`}>
        //                 <Card title={element.title} className="section-card">
        //                   {/* <h3 class="section-title">{element.title}</h3> */}
        //                   <Row gutter={[16, 16]} className="section-row m-0">
        //                     <FormRenderer
        //                       formObj={element.fields}
        //                       propertyKey={"disorder_information"}
        //                       sourceValues={sourceValues}
        //                     />
        //                   </Row>
        //                 </Card>
        //               </React.Fragment>
        //             );
        //           }
        //         }

        //         // if (selectedDisorderList.length > 1) {
        //         //   const reducer = (accumulator, currentValue) => {
        //         //     for (const key in accumulator) {
        //         //       if (accumulator.hasOwnProperty(key)) {
        //         //         const element = accumulator[key] && accumulator[key].fields.properties;
        //         //         const element1 = currentValue[key] && currentValue[key].fields.properties;
        //         //         console.log('nameVal==>', Object.assign(element, element1));
        //         //       }
        //         //     }

        //         //   };
        //         //   console.log('nameVal==>', selectedDisorderList.reduce(reducer));
        //         // }
        //         // return null;
        //         return finalRender;
        //       }
        //     },
        //   },

        // },

        // disorder_information: {
        //   title: "Disorder",
        //   type: "object",
        //   // required: ["disorder_type"],
        //   properties: {
        //     disorder_type: {
        //       title: "Disorder",
        //       type: "string",
        //       placeholder: "Disorder Type",
        //       widget: "SelectWidget",
        //       widgetConfig: {
        //         options: buildOptionsArr(["ADHD","ASD","CEREBRAL PALSY","DEMYELINATING","ENCEPHALITIS","EPILEPSY","GLOBAL DEVELOPMENTAL DELAY",  "LEARNING DISABILITY" ,"MOVEMENT DISORDER", "NEURODEGENERATIVE", "NEUROMUSCULAR", "STROKE"])
        //       },
        //       span: 8,
        //     },
        //     disorder_dependents: {
        //       type: "string",
        //       className: "m-b-0",
        //       dependsOn: ["disorder_information.disorder_type"],
        //       setInitialValue: true,
        //       children: ({ getFieldValue, sourceValues }) => {
        //         console.log("chilren, line 42");
        //         console.log("sourceValues", sourceValues);
        //         const nameVal = getFieldValue([
        //           "disorder_information",
        //           "disorder_type",
        //         ]);
        //         let finalRender = [];
        //         if (nameVal === "ADHD") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in adhdForm) {
        //             if (adhdForm.hasOwnProperty(key)) {
        //               const element = adhdForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }else if (nameVal === "ASD") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in asdForm) {
        //             if (asdForm.hasOwnProperty(key)) {
        //               const element = asdForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }else if (nameVal === "ENCEPHALITIS") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in encephalitisForm) {
        //             if (encephalitisForm.hasOwnProperty(key)) {
        //               const element = encephalitisForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }else if (nameVal === "MOVEMENT DISORDER") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in movementDisorderForm) {
        //             if (movementDisorderForm.hasOwnProperty(key)) {
        //               const element = movementDisorderForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }else if (nameVal === "NEURODEGENERATIVE") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in neurodegenerativeForm) {
        //             if (neurodegenerativeForm.hasOwnProperty(key)) {
        //               const element = neurodegenerativeForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }else if (nameVal === "CEREBRAL PALSY") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in cerebralForm) {
        //             if (cerebralForm.hasOwnProperty(key)) {
        //               const element = cerebralForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }

        //         else if (nameVal === "NEUROMUSCULAR") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in neuromuscularForm) {
        //             if (neuromuscularForm.hasOwnProperty(key)) {
        //               const element = neuromuscularForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }
        //         else if (nameVal === "EPILEPSY") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in epilepsyForm) {
        //             if (epilepsyForm.hasOwnProperty(key)) {
        //               const element = epilepsyForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }
        //         else if (nameVal === "GLOBAL DEVELOPMENTAL DELAY") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in gddForm) {
        //             if (gddForm.hasOwnProperty(key)) {
        //               const element = gddForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }
        //         else if (nameVal === "LEARNING DISABILITY") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in ldForm) {
        //             if (ldForm.hasOwnProperty(key)) {
        //               const element = ldForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }
        //         else if (nameVal === "STROKE") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in strokeForm) {
        //             if (strokeForm.hasOwnProperty(key)) {
        //               const element = strokeForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }
        //         else if (nameVal === "DEMYELINATING") {
        //           // finalRender = <Form.Item label="Middle Name" name={["nested1"]} initialValue={"Nested 1 hello typed"}>
        //           //   <Input placeholder="Middle Name"/>
        //           // </Form.Item>

        //           for (const key in demyelinatingForm) {
        //             if (demyelinatingForm.hasOwnProperty(key)) {
        //               const element = demyelinatingForm[key];
        //               finalRender.push(
        //                 <React.Fragment key={`formSection__${key}`}>
        //                   <Card title={element.title} className="section-card">
        //                     {/* <h3 class="section-title">{element.title}</h3> */}
        //                     <Row gutter={[16, 16]} className="section-row m-0">
        //                       <FormRenderer
        //                         formObj={element.fields}
        //                         propertyKey={"disorder_information"}
        //                         sourceValues={sourceValues}
        //                       />
        //                     </Row>
        //                   </Card>

        //                   {/* <Row gutter={[20, 20]}>
        //                     <Col span={12}>column</Col>
        //                     <Col span={12}>column</Col>
        //                   </Row> */}
        //                 </React.Fragment>
        //               );
        //             }
        //           }
        //         }

        //         return <React.Fragment>{finalRender}</React.Fragment>;
        //       },
        //     },
        //   },
        // },
      },
    },
    uiSchema: {},
  };

  return patientsForm;
};

const Patients = MrCrudView({
  resourceName: "patients",
  singleResourceName: "patient",
  displayName: "Create/Edit Patient",
  resourceUrl: "/patients",
  // layout: NewBasicCRUDLayout,
  isRoute: true,
  // formLayout: NewBasicFormLayout,
  // tableColumns: PatientColumns,
  filter: PatientsFilter,
  // listItem: PatientsList,
  // returnPatientsFormMobileView
  forms: [
    {
      name: "patients",
      form: checkMob() ? returnPatientsFormMobileView : returnPatientsForm,
      config: {
        isModal: true,
        title: { show: true, value: "patient record" },
        modalConfig: { width: "1000px", wrapClassName: "patient-modal" },
      },
    },
    {
      name: "disorderInfo",
      form: returnDisorderInfoForm,
      config: { isModal: false },
    },
  ],
  selectorsObj: {
    ...commonSelectors,
  },
  actions,
  // listHeader: <PatientsHeader />,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: PatientsList,
      // use if we want cards
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: PatientItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
    },
    core: {
      setQueryParams: false,
    },
    list: {
      // use if we want custom table
      show: true,
      type: "table/list/custom",
      widget: PatientList,
      forceReload: true,
    },
  },
});

export default Patients;
