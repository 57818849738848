import React from "react";
import PropTypes from "prop-types";
import { List, Card, Table } from "antd";
import ActionBtns from "../../../components/ActionBtns/ActionBtns";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import "../Accounts.scss";
import { currentUserSelector } from "../../Auth/Login/selector";

const AccountList = (props) => {
  const { dataSource } = props;
  const currentUser = useSelector(currentUserSelector());
  console.log("groups list props accounts===>", currentUser);

  const tableDataSource = [];
  for (let index = 0; index < dataSource.length; index++) {
    let objToPush = {};
    objToPush.id = dataSource[index].id;
    // objToPush.name = dataSource[index].title
    objToPush.name = (
      <Link to={`/u/users/${dataSource[index].id}`}>
        {" "}
        {dataSource[index].id} - {dataSource[index].first_name}{" "}
        {dataSource[index].last_name}
      </Link>
    );

    objToPush.email = dataSource[index].email;
    objToPush.gender = dataSource[index].gender;
    objToPush.role = dataSource[index].role;
    objToPush.phone = dataSource[index].phone;
    objToPush.actions = (
      <ActionBtns
        edit={true}
        delete={currentUser.permission.user.delete}
        deleted={props.deleted}
        id={dataSource[index].id}
        path="users"
      />
    );
    tableDataSource.push(objToPush);
  }

  const columns = [
    {
      title: "Contact name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const { path, url } = useRouteMatch();
  console.log("exp list item url", url);
  return (
    <Table dataSource={tableDataSource} columns={columns} pagination={false} />
  );
};
AccountList.defaultProps = {};

AccountList.propTypes = {};

export default AccountList;
