import React from "react";
import { List, Card } from "antd";
import styled from "styled-components";

const AppConstantListItem = props => {
  console.log("props", props);
  const { item } = props;
  const ActionBtns = props.actionBtns;
  console.log("props.actionBtns", props.actionBtns);
  // const StyledActionBtns = styled(ActionBtns)`
  //   background: black;
  //   color: yellow;
  // `;

  return (
    <List.Item>
      <Card title={item.key}>
        {props.actionBtns}
        {item.value_type} {item.text_value}
      </Card>
    </List.Item>
  );
};
export default AppConstantListItem;
