import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./redux";
import { disordersSelector } from "./selector";
import DisorderItemView from "../Patients/PatientDisorder/DisorderItemView";
import { has } from "lodash";
import { mergeDisorders } from "../../lib/utils/helperMethods";

const PrintForm = (props) => {
  const { match } = props;

  let disorderId = match.params && match.params.disorder_id;
  const dispatch = useDispatch();
  useEffect(() => {
    if (disorderId) {
      dispatch(actions.show({ id: disorderId }));
    }
    setTimeout(() => {
      window.print();
    }, 5000);
  }, []);
  const disorders = useSelector(disordersSelector());
  disorders.map((item) => {
    item.form_json.disorders.map((innerItem) => {
      innerItem.subSectionsList.length !== 0 &&
        innerItem.subSectionsList.map((disorderInnerItem) => {
          const exists = has(disorderInnerItem, "visible");
          if (!exists) {
            disorderInnerItem.visible = true;
          }
        });
    });
  });
  let mergedForm =
    disorders &&
    disorders[0] &&
    mergeDisorders(
      [
        disorders[0],
        disorders[0].form_type === "add_on"
          ? disorders[0].master_form_data
          : {},
      ],
      [disorders[0].id],
      [disorders[0].disorder_type],
      disorders[0].form_type === "add_on" ? true : false
    );

  console.log(
    "disorder data==>",
    // disorders[0] && [disorders[0], disorders[0].master_form_data],
    // disorders[0] && [disorders[0].id],
    // disorders[0] && [disorders[0].disorder_type]
    disorders && disorders
  );

  return (
    <>
      {disorders && (
        <DisorderItemView
          formData={disorders[0] && mergedForm}
          disorderArr={disorderId}
          print={true}
        />
      )}
    </>
  );
};

export default PrintForm;
