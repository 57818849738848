import React, { Component } from "react";
import {
  BrowserRouter as Router,
  withRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

// import logo from "../assets/images";
import "./App.scss";

import WrappedNormalLoginForm from "../views/Auth/Login/Login";
import {
  isAuthenticatedSelector,
  tokenPresentSelector,
  currentUserSelector,
} from "../views/Auth/Login/selector";
import { actions as loginActions } from "../views/Auth/Login/redux";
import { getRoutes } from "../routes/routes";

import AppLayout from "../components/Layouts/AppLayout/AppLayout";
// import MrApp from "../lib/utils/MrApp/MrApp";
import PatientShowView from "../views/Patients/ShowView/PatientShowView";
import { MrApp } from "mr_react_framework";
import AuthLayout from "../components/Layouts/AuthLayout/AuthLayout";

import AppConstants from "../views/AppConstants/AppConstants";
import Login from "../views/Auth/Login/Login";
import Orgs from "../views/Orgs/Orgs";

import Hospitals from "../views/Hospital/Hospital";
import Accounts from "../views/Accounts/Accounts";
import Patients from "../views/Patients/Patients";
import Disorders from "../views/Disorders/Disorders";
import Adhds from "../views/Adhd/Adhd";
// import SOP from '../views/SOP/SOP';

// import { IntlProvider } from "react-intl";
import { IntlProvider, defineMessages } from "react-intl";

import commonMessages from "./../translations/commonMessages";
import messages_en from "./../translations/locales/en.json";

import commonSelectors from "./commonSelectors";

import { Space } from "antd";
import {
  SettingOutlined,
  PlusCircleOutlined,
  UserOutlined,
  HeartOutlined,
  InfoCircleOutlined
  // SettingOutlined,
  // UnorderedListOutlined,
} from "@ant-design/icons";

import LogoImageHor from "../assets/images/nr-logo-hor.png";
import LogoImageCollapsed from "../assets/images/nr-logo-collapsed.png";
import PrintLayout from "../components/Layouts/PrintLayout/PrintLayout";
import DisorderFinalOutputPrint from "../views/Patients/PatientDisorder/DisorderFinalOutputPrint";
import DisorderFormPrint from "../views/Patients/PatientDisorder/DisorderFormPrint";
import DisorderFormOutputPrint from "../views/Patients/PatientDisorder/DisorderFinalOutputPrint";
import DisorderPrint from "../views/Disorders/DisorderPrint";

import { displayRole } from "../lib/utils/helperMethods";
import { checkMob } from "../lib/utils/helperMethods";

const localeConfig = {
  en: {
    label: "English",
    messages: messages_en,
  },
};

const App = MrApp({
  name: "National Registry",
  // selectorObj: {
  //   isAuthenticatedSelector,
  //   tokenPresentSelector,
  //   currentUserSelector
  // },
  config: {
    preHeader: {
      show: false,
      // component: AppNotice,
    },
    header: {
      siderToggleIcon: {
        show: true,
      },
      language: {
        show: true,
      },
      userDropdown: {
        display: "name",
        show: true,
      },
      extraItems: (props) => {
        let finalRender = [];
        finalRender.push(
          <Space className="header-user-info">
            <span class="user-role">
              {displayRole(props.currentUser.role)} at
            </span>
            <span>{props.currentUser.hospital.title}</span>
          </Space>
        );
        // finalRender.push(<span>{props.currentUser.role}</span>);
        return <React.Fragment>{finalRender}</React.Fragment>;

        // return <span>{props.currentUser.hospital.title}</span>
      },
    },
    sider: {
      logo: {
        expanded: <img style={{ width: "100%" }} src={LogoImageHor} />,
        collapsed: <img style={{ width: "100%" }} src={LogoImageCollapsed} />,
      },
      // collapsed: checkMob(),
      collapsed: false,
      className: "appLeftSider",
      menuOptions: {
        theme: "light",
      },
    },
  },
  selectorsObj: {
    ...commonSelectors,
    isAuthenticated: isAuthenticatedSelector,
    tokenPresent: tokenPresentSelector,
  },
  loginActions,
  localeConfig,
  LoginComponent: Login,
  routes: (props) => {
    console.log("routes props", props);
    const { currentUser, permission } = props;
    var menu = [];

    const org = {};
    // if (permission.patient && permission.patient.list) {
      menu.push({
        component: Patients,
        url: "/patients",
        title: "Patients",
        resourceName: "patients",
        singleResourceName: "patient",
        icon: <HeartOutlined />,
        whoCanAccess: ["superadmin"],
        // isSubMenu: false,
        // children: [
        // {
        //   component: PatientShowView,
        //   url: "/patients/:id",
        //   // addToMenu: true,
        //   title: "First Show",
        //   resourceName: "patients",
        //   singleResourceName: "patient",
        //   icon: "setting",
        //   whoCanAccess: ["superadmin"]
        // }
        // ]
      });
    // }

    if (permission.user && permission.user.list) {
      menu.push({
        component: Accounts,
        url: "/users",
        title: "Accounts",
        resourceName: "users",
        singleResourceName: "user",
        icon: <UserOutlined />,
        whoCanAccess: ["superadmin"],
      });
    }

    if (permission.hospital && permission.hospital.list) {
      menu.push({
        component: Hospitals,
        url: "/hospitals",
        title: "Hospital List",
        resourceName: "hospitals",
        singleResourceName: "hospital",
        icon: <PlusCircleOutlined />,
        whoCanAccess: ["superadmin"],
      });
    }

    if (permission.disorder && permission.disorder.list) {
      menu.push({
        component: Disorders,
        url: "/disorders",
        title: "Disorder Forms",
        resourceName: "disorders",
        singleResourceName: "disorder",
        icon: <SettingOutlined />,
      });
    }

    if (permission.app_constant && permission.app_constant.list) {
    menu.push({
      component: AppConstants,
      url: "/app_constants",
      title: "App Constants",
      resourceName: "app_constants",
      singleResourceName: "app_constant",
      icon: <SettingOutlined />,
      // whoCanAccess: ["superadmin"],
    });
    }
    // menu.push({
    //   component: SOP,
    //   url: "/help",
    //   title: "SOP",
    //   // resourceName: "users",
    //   // singleResourceName: "user",
    //   icon: <InfoCircleOutlined />,
    // });

    const layoutWiseRoutes = [
      {
        isPrivate: true,
        // path: "/u",
        path: "/u",
        // layout: "", //defaults to AppLayout
        routes: menu,
      },
      {
        isPrivate: false,
        path: "/auth",
        layout: AuthLayout,
        // layout: (props) => {
        //   return <div>My Basic Auth Layout<div>{props.children}</div> </div>
        // },
        routes: [
          {
            component: Login,
            url: "/login",
          },
        ],
      },
      {
        isPrivate: true,
        path: "/print",
        layout: PrintLayout,
        // layout: (props) => {
        //     return <div>My Basic print Layout<div>{props.children}</div> </div>
        //   },
        routes: [
          {
            component: DisorderFinalOutputPrint,
            url: "/patients/:id/disorder/:patient_disorder_id",
            title: "Print Form",
          },
          {
            component: DisorderFormPrint,
            url: "/patients/:id/print_form/:form_id",
            title: "Print Form",
          },
          {
            component: DisorderPrint,
            url: "/disorders/:disorder_id",
            title: "Print Disorder Form",
          },
          
          // {
          //   component: DisorderFormOutputPrint,
          //   url: "/patients/:id/formDisorder/:form_id",
          //   title: "Print Form",
          // },
        ],
      },
    ];

    return layoutWiseRoutes;
  },
});

export default App;
