import React from "react";
import PropTypes from "prop-types";
import { List, Card, Table } from "antd";
import ActionBtns from "../../../components/ActionBtns/ActionBtns";
import { Link, useRouteMatch } from "react-router-dom";
import Mrcard from "../../../components/UI/MrTable/Mrcard";
import { checkMob } from "../../../lib/utils/helperMethods";
import PatientListCard from "../PatientList/PatientListCard";
import "../Patients.scss";
import dayjs from "dayjs";

const PatientList = (props) => {
  const { dataSource, permission } = props;
  console.log("groups list props ===>", props);
  const tableDataSource = [];
  for (let index = 0; index < dataSource.length; index++) {
    let objToPush = {};
    objToPush.id = dataSource[index].id;
    // objToPush.uuid = dataSource[index].uuid;

    objToPush.uuid = (
      <Link to={`/u/patients/${dataSource[index].id}/patient_disorders`}>
        {" "}
        {dataSource[index].uuid}
      </Link>
    );
    objToPush.name = (
      <Link to={`/u/patients/${dataSource[index].id}/patient_disorders`}>
        {" "}
        {/* {dataSource[index].id} -  */}
        {dataSource[index].first_name} {dataSource[index].last_name}
      </Link>
    );

    // if(dataSource[index].disorder_information.age_options !== undefined){
    //   let suffix = ""
    //   if(dataSource[index].disorder_information.age_radio == "yes")
    //     suffix = "years"
    //   else
    //     suffix = "months"
    //   objToPush.age = dataSource[index].disorder_information.age_options + " " + suffix;
    // }

    objToPush.age = dataSource[index].age;
    objToPush.gender = dataSource[index].gender;
    // objToPush.disorder = dataSource[index].disorder_information.disorder_type
    objToPush.updated_at = dayjs(dataSource[index].updated_at).format(
      "DD-MM-YYYY"
    );
    objToPush.created_at = dayjs(dataSource[index].created_at).format(
      // "DD-MM-YYYY h:mm:ss"
      "DD-MM-YYYY"
    );
    objToPush.actions = (
      <ActionBtns
        edit={true}
        delete={permission.patient.delete}
        deleted={props.deleted}
        id={dataSource[index].id}
        path="patients"
        item={dataSource[index]}
      />
    );
    tableDataSource.push(objToPush);
  }

  console.log("tableDataSource", tableDataSource);

  const dataObject = {
    Name: dataSource.name,
    Age: dataSource.age,
    hospital_id: dataSource.hospital_id,
    // email: item.remarks,
    // disorder_information:
    //  ActionBtns: props.actionBtns,
  };

  const columns = [
    {
      title: "Registry ID",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    // {
    //   title: "Disorder",
    //   dataIndex: "disorder",
    //   key: "disorder",
    // },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const { path, url } = useRouteMatch();
  console.log("exp list item url", url);
  return (
    <div>
      {checkMob() ? (
        <PatientListCard
          dataObject={tableDataSource}
          actionBtns={props.actionBtns}
        />
      ) : (
        <Table
          dataSource={tableDataSource}
          columns={columns}
          pagination={false}
        />
      )}
    </div>
  );
};
PatientList.defaultProps = {};

PatientList.propTypes = {};

export default PatientList;
