import React from "react";
import { MrCrudView, MrSelect } from "mr_react_framework";
import {
  AccountsList,
  AccountsColumns,
} from "../Accounts/AccountsListItems/AccountsList";
import AccountsFilter from "./AccountsFilter/AccountsFilter";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { actions } from "./redux";
import AccountsHeader from "./AccountsHeader";
import commonSelectors from "../../App/commonSelectors";
import NewBasicCRUDLayout from "../../components/Layouts/BasicCRUDLayout/NewBasicCRUDLayout";
import { Input } from "antd";
import AccountItemDetail from "../Accounts/AccountItemDetail/AccountItemDetail";
import AccountList from "../Accounts/AccountList/AccountList";
import { actions as genericActions } from "../../App/genericRedux";

const SelectHospital = MrSelect({
  actions: genericActions,
  resourceName: "hospitals",
  config: {
    url: "/hospitals",
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.title, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Select Hospital",
      // mode: "multiple",
      style: { width: "250px" },
      allowClear: true,
      // value: [13],
    },
  },
});

defineMessages({
  keyName: {
    id: "hospitalForm.keyName",
    defaultMessage: "Key Name",
  },
  desc: {
    id: "hospitalForm.desc",
    defaultMessage: "Desc",
  },
});

const getRoleOptions = (props = {}) => {
  let { currentUser } = props;
  const roleOptions = [
    {
      label: "Hospital Admin",
      value: "hospital_admin",
    },
    {
      label: "Data Entry",
      value: "data_entry",
    },
  ];

  if (currentUser.role === "superadmin") {
    roleOptions.push({
      label: "Superadmin",
      value: "superadmin",
    });
  }
  console.log("roleOptions", roleOptions);
  return roleOptions;
};

const returnAccountsForm = (props = {}) => {
  const { intl, currentUser, permission } = props;
  console.log("propsaccounts", props);

  let accountsForm = {
    schema: {
      type: "object",
      properties: {
        first_name: {
          rules: [
            {
              required: true,
              message: "Please enter your first name",
            },
          ],
          span: 8,
          type: "string",
          default: "",
          elementType: "input",
          title: "First Name",
        },
        middle_name: {
          type: "string",
          span: 8,
          default: "",
          elementType: "input",
          title: "Middle Name",
        },
        last_name: {
          type: "string",
          span: 8,
          default: "",
          elementType: "input",
          title: "Last Name",
        },
        phone: {
          type: "string",
          title: "Contact no.",
          span: 8,
          default: "",
          rules: [
            {
              pattern: /\d/g,
              // len: 10,
              message: "Only digits allowed",
            },
            {
              // pattern: /\d/g,
              len: 10,
              message: "Must be 10 digits",
            },
          ],
        },

        email: {
          title: "Email",
          type: "string",
          span: 8,
          default: "",
          // elementType: "input",
          // format: "email"
          rules: [
            {
              required: true,
              message: "Please enter an email address",
            },
          ],
        },
        password: {
          title: "Password",
          type: "string",
          span: 8,
          default: "rockpaper",
          // elementType: "input",
          // format: "email"
          widget: Input.Password,
          rules: [
            {
              required: true,
              message: "Please set a password",
            },
          ],
        },

        gender: {
          type: "string",
          title: "Gender",
          // default: "Male",
          placeholder: "Select Gender",
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: [
              {
                label: "Male",
                value: "Male",
              },
              {
                label: "Female",
                value: "Female",
              },
              {
                label: "Other",
                value: "Other",
              },
            ],
          },
        },

        role: {
          title: "Role",
          type: "string",
          placeholder: "Select Role",
          widget: "RadioGroupWidget",
          span: 24,
          widgetConfig: {
            options: getRoleOptions(props),
            // hidden: true, // doesn't work
            // className: "hidden" // works
          },
        },
        hospital_id: {
          //replace with select and show hospital name
          span: 8,
          widget: SelectHospital,
          type: "number",
          title: "Hospital",
          widgetConfig: {
            disabled: currentUser.role === "superadmin" ? false : true,
          },
          default:
            currentUser.role != "superadmin" ? currentUser.hospital_id : "",
        },
      },
    },
    uiSchema: {},
    email: {
      "ui:widget": "email",
    },
    select: {
      "ui:widget": "select",
      "ui:options": {
        multiple: true,
      },
    },
  };

  return accountsForm;
};

const Accounts = MrCrudView({
  resourceName: "users",
  singleResourceName: "user",
  displayName: "Create/Edit Account",
  resourceUrl: "/users",
  // layout: NewBasicCRUDLayout,
  isRoute: true,
  // / layout: BlankLayout,
  // tableColumns: AccountsColumns,
  // listHeader: <AccountsHeader />,
  filter: AccountsFilter,
  // listItem: AccountsList,
  forms: [
    {
      name: "users",
      form: returnAccountsForm,
      config: {
        isModal: true,
        modalConfig: { width: "1000px", wrapClassName: "patient-modal" },
      },
    },
  ],
  selectorsObj: {
    ...commonSelectors,
  },
  actions,
  actionNames: ["fetch", "create", "update", "delete"],
  config: {
    itemPreview: {
      widget: AccountList,
      // use if we want cards
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: AccountItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
    },
    core: {
      setQueryParams: false,
    },
    list: {
      // use if we want custom table
      show: true,
      type: "table/list/custom",
      widget: AccountList,
      forceReload: true,
    },
  },
  // firstTimeFetchParams: { with_users: true }
});

export default Accounts;
