// const stringManipulator = (arr, splitter, operation) => {
//   array.forEach(currentItem => {});
//   arr.map((u, i) => {});
// };
import deepmerge from "deepmerge";
import { filter, remove } from "lodash";
import debounce from "lodash.debounce";
import queryString from "query-string";
import { countries } from "./countries";

export const buildCountriesOptionsArr = () => {
  let options = countries.countries;
  let optionsArr = [];
  options.map((value, index) => {
    optionsArr.push({ label: value.name, value: value.code });
  });
  return optionsArr;
};

export const returnQueryString = (obj) => {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
// export const customDebounce = function (a, b, c) {
//   var d, e;
//   return function () {
//     function h() {
//       (d = null), c || (e = a.apply(f, g));
//     }
//     var f = this,
//       g = arguments;
//     return (
//       clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
//     );
//   };
// };

export const debounceEventHandler = (...args) => {
  const debounced = debounce(...args);
  return function (e) {
    e.persist();
    return debounced(e);
  };
};
export const formatJsonApiData = (response) => {
  // console.log("response", response);
  if (response.hasOwnProperty("data")) {
    response = response.data;
  }
  let finalObj = {
    ...response.attributes,
  };
  // console.log("finalObj1", finalObj);

  if (response.hasOwnProperty("relationships")) {
    let relationshipsObj = {};
    for (const key in response.relationships) {
      if (response.relationships.hasOwnProperty(key)) {
        const element = response.relationships[key];
        if (element.data) {
          let tempObj = formatJsonApiData(element.data);
          console.log("tempObj", tempObj);
          // debugger;
          const { type, ...finalElemData } = element.data;
          relationshipsObj[key] = { ...finalElemData };
        }
      }
    }

    finalObj = {
      ...finalObj,
      ...relationshipsObj,
    };
  }

  // console.log("finalObj", finalObj);
  return finalObj;
};

export const checkMob = () => {
  console.log("check mobile");
  let check = false;
  (function (a) {
    if (
      // /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  // console.log("checking mob", check)
  return check;
};

export const setQueryParams = (options) => {
  let { url, history, location, newParams, removeParams } = options;
  const queryParams = {
    ...queryString.parse(location.search),
    ...newParams,
  };
  if (removeParams) {
    removeParams.forEach((element) => {
      if (queryParams.hasOwnProperty(element)) {
        delete queryParams[element];
      }
    });
  }
  // let { queryParamsWithRemovedParams, ...removeParams } = queryParams;

  // let finalQueryParams = queryString.stringify(queryParamsWithRemovedParams);
  let finalQueryParams = queryString.stringify(queryParams);
  console.log("url", url);
  history.push(`${url}?${finalQueryParams}`);
  // history.push({ pathname: url, search: `?${finalQueryParams}` });
  console.log("finalQueryParams", finalQueryParams);
};
export const returnFinalValue = (event, controlName) => {
  let finalValue;
  if (controlName === "object") {
    finalValue = event;
  } else if (typeof event == "string") {
    finalValue = event;
  } else if (Array.isArray(event)) {
    finalValue = event;
  } else {
    if (event.hasOwnProperty("target")) {
      finalValue = event.target.value;
    } else {
      finalValue = event;
    }
  }
  return finalValue;
};
export const getQueryParams = (options) => {
  let { location } = options;
  let finalQueryParams = queryString.parse(location.search);
  return finalQueryParams;
};

export const displayRole = (role) => {
  if (role === "superadmin") {
    return "Superadmin";
  } else if (role === "hospital_admin") {
    return "Hospital Admin";
  } else if (role === "data_entry") {
    return "Data Entry Operator";
  }
};

export const buildOptionsArr = (options) => {
  let optionsArr = [];
  options.map((value, index) => {
    optionsArr.push({ label: value, value: value });
  });
  // let optionsArr = [
  //   {
  //     label: "Yes",
  //     value: "Yes",
  //   },
  //   {
  //     label: "No",
  //     value: "No",
  //   },
  // ]
  return optionsArr;
};

export const buildSchemaObject = (attrs) => {
  // attrs = {key: "age_options", title: "Age in years", inputType: "string", radioOptions: "['Yes', 'No'], widget: 'InputNumberWidget'"}
  let obj = {
    type: "object",
    properties: {},
  };
  obj.properties[attrs.key] = {
    type: "string",
    title: attrs.title,
    setInitialValue: true,
    span: attrs.span,
    className: "conditional-child-input",
    widget: attrs.widget,
  };
  if (attrs.inputType === "radio") {
    obj.properties.widgetConfig.options = buildOptionsArr(attrs.radioOptions);
  }
  return obj;
};

export const setToLS = (key, value) => {
  localStorage.setItem(key, value);
};

export const getFromLS = (key) => {
  return localStorage.getItem(key);
};
export const deleteFromLS = (key) => {
  return localStorage.removeItem(key);
};

export const getUniqueId = (len = 16) => {
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let objectId = "";
  let i = 0;
  while (i < len) {
    objectId += possible.charAt(Math.floor(Math.random() * possible.length));
    i++;
  }
  return objectId;
};

export const enterUniqueId = (obj) => {
  obj.map((item) => {
    item["uniqueId"] = getUniqueId();
    item.subSectionsList.length !== 0 &&
      item.subSectionsList.map((subItem) => {
        subItem["uniqueId"] = getUniqueId();
        subItem.options &&
          subItem.options.length !== 0 &&
          subItem.options.map((itemOption) => {
            itemOption["uniqueId"] = getUniqueId();
          });
      });
  });
  console.log("uniqueID---->", JSON.stringify(obj));
  return obj;
};

export const getIndexofObject = (obj, compareObj) => {
  let indexNumber = 0;
  let findIndex = 0;
  obj.map((item) => {
    item.subSectionsList.length !== 0 &&
      item.subSectionsList.map((subItem) => {
        if (subItem === compareObj) {
          return (findIndex = indexNumber);
        }
      });
    indexNumber++;
  });
  return findIndex;
};

export const mergeDisorders = (
  disorders,
  disorderArr,
  disorderNames,
  useMasterForm
) => {
  if (useMasterForm === undefined || useMasterForm === true) {
    console.log("disorderArr==>", disorders);
    const masterFormId =
      disorders.length !== 0 &&
      disorders.filter(
        (item) =>
          // Need to change this after BE deploy - had to remove item.name
          item.disorder_type === "master_form" || item.name === "master_form"
      );
    if (masterFormId[0]) {
      disorderArr = [masterFormId[0].id, ...disorderArr];
    }
  } else {
    var index = disorderArr.indexOf(13);
    if (index > -1) {
      //if found
      disorderArr.splice(index, 1);
    }
  }
  var finalDisorders = [];
  disorders &&
    disorderNames &&
    disorders.map((item, index) => {
      disorderNames &&
        disorderNames.map((disorderName, i) => {
          // Need to change this after BE deploy - had to remove item.name
          if (disorderName === item.disorder_type || item.name) {
            finalDisorders.push(item.id);
          }
        });
    });
  console.log("disorder name!!==>", finalDisorders, disorderArr);

  const selectedDisorderList = [];
  if (disorders) {
    if (disorderArr && disorderArr.length !== 0) {
      for (var i = 0; i < disorderArr.length; i++) {
        let current = disorderArr[i];
        const finalData =
          disorders.length && disorders.length !== 0
            ? filter(disorders, (item) => item.id === current)
            : [];
        selectedDisorderList.push(finalData[0]);
      }
    }
    const finalFormJson = [];
    selectedDisorderList.map((item) => {
      let currentDisorder = item && item.form_json.disorders;
      // finalFormJson.push(currentDisorder);

      currentDisorder &&
        currentDisorder.map((innerItem) => {
          finalFormJson.push(innerItem);
        });
    });

    var finalList = [];
    if (disorderArr && disorderArr.length > 1) {
      for (let item = 0; item < finalFormJson.length; item++) {
        let current = finalFormJson[item];
        // remainingList = remove(finalFormJson, (r) => {
        //   return r.id == item;
        // });

        var remainingList = remove(finalFormJson, (f) => {
          return f.title.toLowerCase() === current.title.toLowerCase();
        });
        // if (remainingList.length !== 0) {
        remainingList.map((finalItem, index) => {
          if (index !== 0) {
            current = deepmerge(current, finalItem);
          }
        });
        // } else {
        //   finalList.push(current);
        // }
        finalList.push(current);
      }
    }
    finalFormJson.map((rest) => {
      finalList.push(rest);
    });

    finalList &&
      finalList.map((item) => {
        item.subSectionsList.map((innerItem) => {
          // if (!innerItem.is_sub_heading) {
          //   if (innerItem.dependsOnIndex) {
          //     innerItem.visible = false;
          //   } else {
          //     innerItem.visible = true;
          //   }
          // }
          if (innerItem.dependsOnIndex) {
            innerItem.visible = false;
          } else {
            innerItem.visible = true;
          }
        });
      });
    return finalList;
    // props.onChange(finalList);
  }
};
