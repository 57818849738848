import React from "react";
import { Select } from "antd";

const InputWithTags = ({ placeholderValue }) => {
  return (
    <>
      <Select
        mode="tags"
        // value={value && value}
        style={{ width: "100%" }}
        placeholder={placeholderValue}
      ></Select>
    </>
  );
};

export default InputWithTags;
