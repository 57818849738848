
import React, { Component } from 'react'
import { actions } from '../redux'
import { MrConnectField } from 'mr_react_framework'
import { disordersSelector, loadingSelector, errorSelector } from "../selector";

const initialState = {
    value: null,
};

const SelectDisorder = MrConnectField({
    initialState,
    itemName: 'disorder',
    config: {
        valueKey: 'id',
        textKey: 'name',
        placeholder: 'Select Disorder',
        label: 'Select Disorder',
        elementType: 'select',
    },
    fetch: true,
    actions,
    selectorsObj: {
        loading: loadingSelector,
        error: errorSelector,
        disorders: disordersSelector,
    },
    elementType: 'select',
    options: 'props.disorders',
});

export default SelectDisorder;