import { createSelector } from "reselect";

const isAuthenticatedState = (state, props) => {
  return state.login.get("token") !== null;
  // return localStorage.getItem.get("token") !== null;
};

export const isAuthenticatedSelector = () =>
  createSelector(
    [isAuthenticatedState],
    isAuthenticated => {
      console.log("isAuthenticated", isAuthenticated);
      let token = localStorage.getItem("token");
      console.log("token", token);
      let tokenPresent = false;
      if (token !== null) {
        tokenPresent = true;
      }
      return isAuthenticated;
      // return isAuthenticated;
    }
  );

const tokenPresentState = (state, props) => {
  let token = localStorage.getItem("token");

  return token ? true : false;
};

export const tokenPresentSelector = () =>
  createSelector(
    [tokenPresentState],
    tokenPresent => tokenPresent
  );

const loadingState = (state, props) => {
  return state.login.get("loading");
};

export const loadingSelector = () =>
  createSelector(
    [loadingState],
    loading => loading
  );

const errorState = (state, props) => {
  return state.login.get("error");
};

export const errorSelector = () =>
  createSelector(
    [errorState],
    error => error
  );

const currentUserState = (state, props) => {
  return state.login.get("currentUser");
};

export const currentUserSelector = () =>
  createSelector(
    [currentUserState],
    currentUser => {
      // if (currentUser.permission && currentUser.permission.settings) {
      //   return {
      //     ...currentUser,
      //     permission: {
      //       ...currentUser.permission,
      //       settings: JSON.parse(currentUser.permission.settings),
      //     },
      //   };
      // } else {
      //   return currentUser;
      // }
      currentUser.org = currentUser.hospital;
      return currentUser;
    }
  );

export const permissionsSelector = () =>
  createSelector(
    [currentUserState],
    currentUser => {
      let permissions = {
        ...currentUser.permission
      };
      return permissions;
    }
  );

const orgState = (state, props) => {
  return state.login.get("currentUser").hospital;
};

export const orgSelector = () =>
  createSelector(
    [orgState],
    org => org
  );
