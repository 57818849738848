import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Space,
  Typography,
  Button,
  Upload,
  message,
  Empty,
  Image,
  Popconfirm,
  Affix,
} from "antd";
import { find, isArray, filter, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { actions } from "./redux";
import MediaShow from "../../../components/UI/MediaShow/MediaShow";
import { useRouteMatch } from "react-router-dom";

const { Title } = Typography;

const token = localStorage.getItem("token");

const PatientDisorderItemDetail = (props) => {
  const dispatch = useDispatch();
  const { item, print, patient } = props;
  const [spanValue, setSpanValue] = useState(false);
  const [data, setData] = useState([]);
  const [uploadData, setUploadData] = useState({});
  const { path, url } = useRouteMatch();

  console.log("inside patient disorder item detail", props);

  useEffect(() => {
    isArray(item) ? setData(item[0]) : setData(item);
  }, [item]);
  useEffect(() => {
    if (
      item.attachments_json &&
      item.attachments_json.scanned_document &&
      item.attachments_json.scanned_document.files &&
      item.attachments_json.scanned_document.files.length > 0
    ) {
      setSpanValue(true);
      setUploadData(item.attachments_json.scanned_document.files[0]);
    }
  }, []);
  console.log("datadata", uploadData);
  const uploadProps = {
    name: "file",
    multiple: true,
    headers: { token: token },
    showUploadList: false,
    action:
      (process.env.REACT_APP_API_URL || "/backend/api/v1/") + "attachments",
    onChange(info) {
      const { status } = info.file;
      console.log("info==>", info.fileList.length);
      info.fileList.length !== 0 && setSpanValue(true);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(
          "status in patientdisorderitem detail==>",
          info.file.response
        );
        setUploadData(info.file.response);
        const newAttachmentsJson = {
          ...item.attachments_json,
          scanned_document: {
            files: [info.file.response],
          },
        };
        dispatch(
          actions.update({
            id: item.id,
            attachments_json: newAttachmentsJson,
          })
        );
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const removeFile = () => {
    const newResponseJson = {
      ...item.response_json,
      scanned_document: { files: [] },
    };
    dispatch(
      actions.update({
        id: item.id,
        response_json: newResponseJson,
      })
    );
    message.success(`file uploaded deleted.`);
  };

  const gettingCorrect = (array, type) => {
    if (type === "CheckboxGroupWidget") {
      const newItems = [];
      array.map((item) => {
        item.checked && newItems.push(item.title);
      });
      return newItems.join(",");
    }
    const correct = array.length !== 0 && find(array, (item) => item.checked);
    return correct ? correct.title : "";
  };
  const pdfToRender = (
    <>
      <Button
      // className= {!fullScreenModal  ? "pdf-full-screen-button" : "pdf-full-screen-exit-button" }
      // onClick={() => setFullScreenModal(!fullScreenModal)}
      // icon={
      //   fullScreenModal ? <FullscreenExitOutlined /> : <FullscreenOutlined />
      // }
      ></Button>
      <iframe
        src={`/pdfjs/web/viewer.html?file=${uploadData.url}`}
        width="100%"
        height="500px"
      ></iframe>
    </>
  );
  const patientData = patient && patient[0];

  return (
    <>
      {print && patientData && (
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
            marginBottom: "20px",
          }}
        >
          <Card
            title={`Patient name: - ${patientData.first_name}`}
            size="small"
          >
            <Title level={5}>Hospital ID:- {patientData.hospital_id}</Title>
            <Title level={5}>Patient ID:- {patientData.patient_id}</Title>
          </Card>
        </Space>
      )}
      {data.response_json && data.response_json.disorder_dependents ? (
        <>
          {!print && isEmpty(uploadData) && (
            <Space>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Space>
          )}
          <Row gutter={[16, 16]}>
            <Col span={spanValue ? 12 : 24}>
              {data &&
                data.response_json &&
                data.response_json.disorder_dependents &&
                isArray(data.response_json.disorder_dependents) &&
                data.response_json.disorder_dependents.map(
                  (singleItem, index) => (
                    <Card title={singleItem.title} type="inner">
                      {singleItem.subSectionsList &&
                        singleItem.subSectionsList.map(
                          (innerItem, innerIndex) =>
                            innerItem.widget === "string" ? (
                              <Row gutter={[16, 16]}>
                                <Col span={6}>
                                  <Space>
                                    <Title level={5}>{innerItem.title}</Title>
                                  </Space>
                                </Col>
                                <Col span={18}>
                                  <Space>
                                    {innerItem.response
                                      ? innerItem.response
                                      : "-"}
                                  </Space>
                                </Col>
                              </Row>
                            ) : innerItem.widget === "CheckboxGroupWidget" ||
                              innerItem.widget === "RadioGroupWidget" ? (
                              <>
                                <Row gutter={[16, 16]}>
                                  <Col span={6}>
                                    <Space>
                                      <Title level={5}>{innerItem.title}</Title>
                                    </Space>
                                  </Col>
                                  <Col span={18}>
                                    <Space>
                                      {gettingCorrect(
                                        innerItem.options,
                                        "CheckboxGroupWidget"
                                      ) || "-"}
                                    </Space>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              (innerItem.widget === "UploadWidget" ||
                                innerItem.widget === "PedigreeChart") && (
                                <>
                                  <Row gutter={[16, 16]}>
                                    <Col span={6}>
                                      <Space>
                                        <Title level={5}>
                                          {innerItem.title}
                                        </Title>
                                      </Space>
                                    </Col>
                                    <Col span={18}>
                                      <Space>
                                        {innerItem.fileObject &&
                                        isArray(innerItem.fileObject.files) ? (
                                          innerItem.fileObject &&
                                          innerItem.fileObject.files.map(
                                            (image) => (
                                              <Image
                                                src={image.url}
                                                width={200}
                                                alt={image.name}
                                              />
                                            )
                                          )
                                        ) : isArray(innerItem.fileObject) ? (
                                          innerItem.fileObject &&
                                          innerItem.fileObject.map((image) => (
                                            <Image
                                              src={image.url}
                                              width={200}
                                              alt={image.name}
                                            />
                                          ))
                                        ) : (
                                          <Image
                                            src={
                                              innerItem.fileObject &&
                                              innerItem.fileObject.url
                                            }
                                            width={200}
                                            alt={
                                              innerItem.fileObject &&
                                              innerItem.fileObject.name
                                            }
                                          />
                                        )}
                                      </Space>
                                    </Col>
                                  </Row>
                                </>
                              )
                            )
                        )}
                    </Card>
                  )
                )}
            </Col>
            <Col span={spanValue ? 12 : 0}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {/* {uploadData && <MediaShow file={uploadData} />} */}
                  {uploadData && (
                    <Affix
                      offsetTop={30}
                      onChange={(affixed) => console.log("affixed", affixed)}
                    >
                      {/* No delete here - only in edit */}
                      <div className="scanned-document-container">
                        {/* <Popconfirm
                          title="Confirm delete?"
                          onConfirm={(e) => {
                            setSpanValue(false);
                            setUploadData({});
                            removeFile();
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined />
                        </Popconfirm> */}
                        <MediaShow file={uploadData} />
                      </div>
                    </Affix>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col span={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default PatientDisorderItemDetail;
