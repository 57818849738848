import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Card,
  Col,
  Row,
  Space,
  Radio,
  Checkbox,
  Input,
  Steps,
  Button,
  message,
  Upload,
  PageHeader,
  Popover,
  Carousel,
  Select,
  InputNumber,
  Anchor,
} from "antd";
import { findIndex } from "lodash";
import { InboxOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./Disorder.scss";
import InputWithTags from "../../components/UI/Input/InputWithTags";
import { checkMob } from "../../lib/utils/helperMethods";
import SelectUpload from "../../components/SelectUpload/SelectUpload";
import { update } from "immutable";

const { Link } = Anchor;
const { Option } = Select;
const { Step } = Steps;

let checkmobile = checkMob();

console.log("checkmobile", checkmobile);

const { Dragger } = Upload;
const uploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // mutable ref to store current callback

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

const DisorderItemSections = (props) => {
  const myRef = useRef(null);

  const [current, setCurrent] = useState(0);
  const [subsectionCount, setsubsectionCount] = useState(0);
  const [questionsHeadingCount, setQuestionsHeadingCount] = useState(0);
  // const [currentLocation, setCurrentLocation] = useState({
  //   x: 50,
  //   y: 50,
  // });
  const [touchStartLocation, setTouchStartLocation] = useState({
    x: null,
    y: null,
  });

  const [leftScroll, setLeftScroll] = useStateCallback(0);

  // const [subHeadingList, setSubHeadingList] = useState([]);
  // const [subHeadingList, setSubHeadingList] = React.useState({});

  console.log("myRefisnullis", myRef);

  // const executeScroll = () => myRef.current.scrollIntoView();

  // useMountEffect(executeScroll);
  console.log("DisorderItemSections props only", props);
  const onChange = props.onChange;
  const disorderData = props.disorderData;
  console.log("DisorderItemSections=======>", props.disorderData);
  console.log("disorderData=======>", disorderData);

  // console.log(
  //   "props in DisorderItemSections",
  //   disorderData.item.form_json.disorders || disorderData[0].form_json.disorders
  // );

  let finalData = null;
  if (disorderData.length >= 0) {
    finalData = disorderData[0].form_json.disorders;
  } else {
    finalData = disorderData.item.form_json.disorders;
  }

  console.log("finalData", finalData);

  let disorderItem = finalData[current];
  if (disorderItem) {
    disorderItem[`index`] = current;
  }
  let getSubSection =
    disorderItem &&
    disorderItem.subSectionsList.map((subSection, mobIndex) => {
      subSection[`mobIndex`] = mobIndex;
      return subSection;
    });

  console.log("disorder getSubSection", getSubSection);

  useEffect(() => {
    console.log("useEffect_");
  }, [current]);

  let subHeadingArray = [];
  let questionsObj = {
    label: " ",
    questions: [],
  };

  getSubSection &&
    getSubSection.map((section, i) => {
      console.log("mapsection", i, section);
      if (
        section.hasOwnProperty("title") &&
        !section.hasOwnProperty("sub_heading_title")
      ) {
        // section[index] = i;
        questionsObj.questions.push(section);
      } else if (section.hasOwnProperty("sub_heading_title")) {
        subHeadingArray = [...subHeadingArray, { ...questionsObj }];
        questionsObj.label = section.sub_heading_title;
        questionsObj.questions = [];
      }
      if (getSubSection.length === i + 1) {
        subHeadingArray = [...subHeadingArray, { ...questionsObj }];
        questionsObj.label = section.sub_heading_title;
        questionsObj.questions = [];
      }
    });

  console.log("subHeadingArrayhere", subHeadingArray);
  // setSubHeadingList([...subHeadingArray]);

  // if (
  //   subHeadingArray[subsectionCount].label === " " &&
  //   subHeadingArray[subsectionCount].questions.length == 0
  // ) {
  //   let removedSubHeadingArray = subHeadingArray.shift();
  //   subHeadingArray = [...subHeadingArray]
  //   // console.log("subHeadingArraynew", subHeadingArray.shift())
  //   // console.log("removedSubHeadingArray", removedSubHeadingArray)
  // }

  console.log("subHeadingArray only11111", subHeadingArray);
  console.log("subHeadingArray subsectionCount", subsectionCount);

  console.log(
    "subHeadingArray[subsectionCount]",
    subHeadingArray[subsectionCount]
  );

  let getquestions;
  if (
    // subHeadingArray[subsectionCount] &&
    subHeadingArray[subsectionCount].questions.length !== 0
  ) {
    getquestions = subHeadingArray[subsectionCount].questions;
    console.log("getquestions new", getquestions);
  } else if (
    // subHeadingArray[subsectionCount] &&
    subHeadingArray[subsectionCount].questions.length == 0
  ) {
    getquestions = subHeadingArray[subsectionCount].questions;
    console.log("getquestions when question is 0", getquestions);
  }

  // const updateScrollValue = (value) => {
  //   myRef.current.scrollLeft += value;
  //   console.log("value==>", value);
  //   console.log("leftScroll", myRef.current.scrollLeft);
  // };

  // const scroll = (scrollOffset) => {
  //   const newScrollValue = leftScroll + scrollOffset;
  //   console.log("scrollOffset", newScrollValue);
  //   setLeftScroll(
  //     (prev) => prev + scrollOffset,
  //     (s) => updateScrollValue(s)
  //   );
  // };

  const scroll = (scrollOffset) => {
    console.log("myRef.current.scrollLeft", myRef);
    myRef.current.scrollLeft += scrollOffset;
    console.log("myRef.current.scrollLeft", myRef.current.scrollLeft);
  };

  console.log("LeftScroll", leftScroll);

  const next = () => {
    setCurrent(current + 1);
    setsubsectionCount(0);
    setQuestionsHeadingCount(0);
    // scrollTo(scrollLeft)
  };

  const prev = () => {
    setCurrent(current - 1);
    setsubsectionCount(0);
    setQuestionsHeadingCount(0);
  };

  const subSectionNext = () => {
    setsubsectionCount(subsectionCount + 1);
    setQuestionsHeadingCount(0);
  };

  const subSectionPrev = () => {
    console.log("subSectionPrev is called");
    setsubsectionCount(subsectionCount - 1);
    // console.log("getquestions.length prev section", getquestions.length)
    // setQuestionsHeadingCount(getquestions.length);
    subSectionPrevQuestion();
  };

  const subSectionPrevQuestion = () => {
    console.log("getquestions.length prev section", getquestions);
    setQuestionsHeadingCount(0);
  };

  const scrollTo = (el) => {
    const elLeft = el.offsetLeft + el.offsetWidth;
    const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;
    // check if element not in view
    if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
      el.parentNode.scrollLeft = elLeft - elParentLeft;
    } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
      el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft;
    }
  };

  const nextQuestion = () => {
    console.log("next, questionsHeadingCount ", questionsHeadingCount);
    console.log(
      "clicking on the next question==>",
      document.querySelectorAll(".ant-steps-item-active")[1].nextElementSibling
    );

    const currentElement = document.querySelectorAll(
      ".ant-steps-item-active"
    )[1].nextElementSibling.nextElementSibling;

    if (currentElement) {
      scrollTo(currentElement);
    }

    // console.log("scroll 20==>", getquestions.length)
    if (getquestions.length > 0) {
      setQuestionsHeadingCount(questionsHeadingCount + 1);
      // scroll(-20);
    } else {
      subSectionNext();
    }
  };

  const prevQuestion = () => {
    console.log(
      "previous, questionsHeadingCount before ",
      questionsHeadingCount
    );
    if (questionsHeadingCount > 0) {
      setQuestionsHeadingCount(questionsHeadingCount - 1);
      console.log("previous, questionsHeadingCount ", questionsHeadingCount);
    }
  };
  console.log("previous, questionsHeadingCount after ", questionsHeadingCount);

  // if (questionsHeadingCount === 0) {
  //   console.log("go to prevQuestionsection")
  //   subSectionPrev();
  // } else

  // const prevQuestion = () => {
  //   if (
  //     subHeadingArray[subsectionCount].label === " " &&
  //     subHeadingArray[subsectionCount].questions.length == 0
  //   ) {
  //     // setsubsectionCount(subsectionCount + 1);
  //     // getquestions = subHeadingArray[subsectionCount].questions;
  //     // console.log("subHeadingArray[subsectionCount]this")
  //   } else {
  //     setQuestionsHeadingCount(questionsHeadingCount - 1);
  //   }
  // };

  const nextSection = () => {
    if (finalData[current] <= finalData) {
      setCurrent(current + 1);
      setsubsectionCount(0);
      setQuestionsHeadingCount(0);
    } else {
      console.log("subsection complete");
    }
  };

  const currentQuestion = (questionid) => {
    console.log("question id clicked on here", questionid);
    setQuestionsHeadingCount(questionid);
  };

  const currentHeading = (id) => {
    console.log("disorder id clicked here currnetheading", id);
    setCurrent(id);
    setsubsectionCount(0);
    setQuestionsHeadingCount(0);
  };

  const nextquestionSection = () => {
    // myRef.current.scrollIntoView()
    if (current === finalData.length - 1) {
      message.success("Processing complete!");
    } else if (subsectionCount == subHeadingArray.length - 1) {
      nextSection();
    } else if (subHeadingArray[subsectionCount] <= subHeadingArray) {
      setsubsectionCount(subsectionCount + 1);
      setQuestionsHeadingCount(0);
    } else {
      console.log("subsection complete");
    }
  };

  const previousSection = () => {
    if (subsectionCount === 0 && questionsHeadingCount === 0) {
      prev();
    } else if (questionsHeadingCount === 0) {
      console.log("go to prevQuestionsection");
      subSectionPrev();
    }
  };

  // const previousSection = () => {
  //   // subSectionPrev();
  //   if (subsectionCount == 0) {
  //     setCurrent(current - 1);
  //     // setsubsectionCount(subHeadingArray.length - 1);
  //   } else {
  //     setsubsectionCount(subsectionCount - 1);
  //     // setQuestionsHeadingCount(getquestions.length - 1);
  //   }
  // };

  const handleTouchStart = (e) => {
    e.persist();
    const firstTouchEvent = e.touches[0];
    const location = {
      x: firstTouchEvent.clientX,
      y: firstTouchEvent.clientY,
    };
    setTouchStartLocation(location);
  };

  const handleTouchEnd = (e) => {
    e.persist();
    const firstTouchEvent = e.changedTouches[0];
    const location = {
      x: firstTouchEvent.clientX,
      y: firstTouchEvent.clientY,
    };
    const differences = {
      x: touchStartLocation.x - location.x,
      y: touchStartLocation.y - location.y,
    };

    if (differences.y < 0 && differences.y > -65 && differences.x > 0) {
      // console.log("insideifvlockNEXTquestionsHeadingCount", questionsHeadingCount)
      console.log(
        "insideifvlockNEXTsubHeadingArray.length",
        subHeadingArray.length
      );
      console.log("insideifvlockNEXTsubHeadingArray", subHeadingArray);
      if (
        current === finalData.length - 1 &&
        subsectionCount == subHeadingArray.length - 1
      ) {
        message.success("Processing complete!");
      } else if (questionsHeadingCount < getquestions.length - 1) {
        setQuestionsHeadingCount(questionsHeadingCount + 1);
      } else if (subsectionCount < subHeadingArray.length - 1) {
        setsubsectionCount(subsectionCount + 1);
        setQuestionsHeadingCount(0);
      } else if (subsectionCount == subHeadingArray.length - 1) {
        nextSection();
      }
    } else if (differences.y > 0 && differences.y <= 65 && differences.x < 0) {
      // if (subsectionCount == 0) {
      //   console.log(
      //     "subHeadingArray[subsectionCount]",
      //     subHeadingArray[subsectionCount]
      //   );
      //   setCurrent(current - 1);

      //   // setsubsectionCount(subHeadingArray.length - 1);
      // } else

      if (current === 0 && subsectionCount == 0 && questionsHeadingCount == 0) {
        console.log("first question don't move back");
      } else if (
        questionsHeadingCount >= 1 &&
        questionsHeadingCount <= getquestions.length - 1
      ) {
        prevQuestion();
      } else if (questionsHeadingCount === 0 && subsectionCount > 0) {
        console.log("helading 0 move to previous section");
        // setCurrent(current - 1)
        setsubsectionCount(subsectionCount - 1);
      } else if (subsectionCount == 0 && questionsHeadingCount == 0) {
        console.log("first question don't move back");
        setCurrent(current - 1);
      }
    }
  };

  let sectionRender = (
    <div>
      {disorderData.item.form_json.disorders.length !== 0 &&
        disorderData.item.form_json.disorders.map((disorder, index) => (
          <Card
            type="inner"
            title={disorder.title}
            key={disorder.id}
            className="main-card"
          >
            <Row gutter={16}>
              {disorder.subSectionsList.length !== 0 &&
                disorder.subSectionsList.map((disorderDetail, nestedIndex) =>
                  disorderDetail.hasOwnProperty("is_sub_heading") ? (
                    <Col span={disorderDetail.span} className="heading">
                      {disorderDetail.visible && (
                        <Space>
                          <p>{disorderDetail.sub_heading_title}</p>
                        </Space>
                      )}
                    </Col>
                  ) : (
                    <>
                      {disorderDetail.visible && (
                        <Col span={disorderDetail.span} className="subsection">
                          <b>
                            #{nestedIndex + 1} - {disorderDetail.uniqueId} -{" "}
                          </b>
                          <Space className="subsection-title">
                            {disorderDetail.title}
                          </Space>
                          <br />
                          {disorderDetail.widget === "RadioGroupWidget" &&
                            disorderDetail.options.length !== 0 && (
                              <Radio.Group
                                onChange={(value) =>
                                  onChange(
                                    value.target.value,
                                    index,
                                    nestedIndex
                                  )
                                }
                              >
                                {disorderDetail.options.map((option) => (
                                  <Space
                                    className="options"
                                    style={{ flex: 1 }}
                                  >
                                    <Radio value={option.title}>
                                      {option.title}
                                    </Radio>
                                  </Space>
                                ))}
                              </Radio.Group>
                            )}
                          {disorderDetail.widget === "CheckboxGroupWidget" &&
                            disorderDetail.options.length !== 0 && (
                              <Checkbox.Group
                                onChange={(value) =>
                                  onChange(
                                    value,
                                    index,
                                    nestedIndex,
                                    disorderDetail.widget
                                  )
                                }
                              >
                                {disorderDetail.options.map((option) => (
                                  <Space
                                    className="options"
                                    style={{ flex: 1 }}
                                  >
                                    <Checkbox value={option.title}>
                                      {option.title}
                                    </Checkbox>
                                  </Space>
                                ))}
                              </Checkbox.Group>
                            )}
                          {disorderDetail.visible &&
                            disorderDetail.widget === "string" && (
                              <Input
                                placeholder={disorderDetail.title}
                              />
                            )}
                          {disorderDetail.visible &&
                            disorderDetail.widget === "number" && (
                              <>
                                {disorderDetail.minMaxEnabled ? (
                                  <InputNumber
                                    placeholder={`Choose between ${disorderDetail.min} to ${disorderDetail.max}`}
                                    min={disorderDetail.min}
                                    max={disorderDetail.max}
                                    style={{ width: "200px" }}
                                  />
                                ) : (
                                  <InputNumber
                                    placeholder={disorderDetail.title}
                                  />
                                )}
                              </>
                            )}
                          {disorderDetail.widget === "InputWithTags" && (
                            <InputWithTags
                              placeholderValue={disorderDetail.title}
                            />
                          )}
                          {disorderDetail.widget === "UploadWidget" && (
                            <Dragger {...uploadProps}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                              <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly
                                prohibit from uploading company data or other
                                band files
                              </p>
                            </Dragger>
                          )}
                          {disorderDetail.widget === "SelectWidget" &&
                            disorderDetail.options.length !== 0 && (
                              <>
                                {JSON.stringify(disorderDetail.options)}
                                <Select
                                  style={{ width: 120 }}
                                  placeholder="Select"
                                >
                                  {disorderDetail.options.map((option) => (
                                    <Option
                                      className="options"
                                      value={option.title}
                                    >
                                      {option.title}
                                    </Option>
                                  ))}
                                </Select>
                              </>
                            )}
                          {disorderDetail.widget === "MinMaxWidget" && (
                            <>
                              <InputNumber
                                placeholder={`Choose between ${disorderDetail.min} to ${disorderDetail.max}`}
                                min={disorderDetail.min}
                                max={disorderDetail.max}
                                style={{ width: "200px" }}
                              />
                            </>
                          )}
                          {disorderDetail.widget === "PedigreeChart" && (
                            <>
                              <Row gutter={[16, 16]}>
                                <Col span={12}>
                                  <b>
                                    {" "}
                                    You can create a Pedigree Chart on this
                                    website and can upload it here
                                  </b>
                                  :
                                  <br />
                                  <Button type="link">
                                    <a
                                      href="https://creately.com"
                                      target="_blank"
                                    >
                                      https://creately.com
                                    </a>
                                  </Button>
                                </Col>
                                <Col span={12}>
                                  <SelectUpload
                                    className="disorder-upload"
                                    onChange={(file) => {
                                      // onFileChange(
                                      //   file,
                                      //   disorderDetail.widget,
                                      //   index,
                                      //   nestedIndex
                                      // );
                                    }}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                        </Col>
                      )}
                    </>
                  )
                )}
            </Row>
          </Card>
        ))}
    </div>
  );
  let previousBtnDisplay;
  if (current == 0 && subsectionCount == 0) {
    previousBtnDisplay = false;
  } else {
    previousBtnDisplay = true;
  }
  console.log("previousBtnDisplay", previousBtnDisplay);

  let mobileRender = (
    <>
      <div className="mobile-steps">
        <Steps
          progressDot
          current={current}
          size="large"
          className="steps_current"
        >
          {finalData.map(
            (disorder, nestedIndex) => (
              console.log("disorderinmap", disorder),
              (
                <Step
                  key={disorder.id}
                  onClick={() => currentHeading(disorder.id)}
                />
              )
            )
          )}
        </Steps>
      </div>

      <div className="steps-content">
        <React.Fragment>
          <h1 className="sectionHeading">
            {finalData[current] && finalData[current].title}
            <span className="sectiontooltiptext">
              {finalData[current] && finalData[current].title}
            </span>
          </h1>
        </React.Fragment>
        {/* <Steps
          current={subsectionCount}
          progressDot
          labelPlacement="vertical"
          style={{ flex: "left" }}
        >
          {subHeadingArray.map(
            (heading, i) => (
              console.log("heading", heading), (<Step key={i} />)
            )
          )}
        </Steps> */}

        <div className="steps-content">
          <React.Fragment>
            <div
              style={{
                textAlign: "center",
                padding: 7,
                fontSize: 16,
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              {subHeadingArray[subsectionCount] &&
              subHeadingArray[subsectionCount].label == " " &&
              subHeadingArray[subsectionCount].questions.length !== 0
                ? "GENERAL DETAILS"
                : subHeadingArray[subsectionCount] &&
                  subHeadingArray[subsectionCount].label}
            </div>
          </React.Fragment>

          <div ref={myRef}>
            <Steps
              current={questionsHeadingCount}
              progressDot
              style={{
                position: "relative",
                top: "25px",
                left: "12px",
                zIndex: 10,
                // overflowX: "auto",
                overflowY: "hidden",
              }}
            >
              {subHeadingArray[subsectionCount] &&
                subHeadingArray[subsectionCount].questions.map(
                  (question, i) => (
                    console.log("question", question),
                    console.log("question i", i),
                    (
                      <Step
                        key={question.id}
                        onClick={() => currentQuestion(i)}
                      />
                    )
                  )
                )}
            </Steps>
          </div>

          <React.Fragment>
            <div className="">
              {console.log("getquestions[questionsHeadingCount]", getquestions)}
              {console.log("[questionsHeadingCount]", questionsHeadingCount)}

              <div
                className="steps-content"
                style={{
                  // bottom: 0,
                  // padding: 20,
                  // width: 360,
                  // margin: "auto",
                  // position: "relative",
                  // backgroundColor: "#a83025",
                  // height: 550,
                  // float: "left",
                  // margin: 3,
                  // padding: 3,
                  // overflow: "auto",
                  backgroundColor: "#f5f5f5",
                  height: "580px",
                  width: 350,
                  position: "relative",
                  padding: 10,
                }}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
              >
                <div
                  style={{
                    bottom: -25,
                    height: "90%",
                    width: "23",
                    margin: "auto",
                    marginTop: "0",
                    marginBottom: 0,
                    padding: 10,
                    position: "relative",
                    backgroundColor: "#f5f5f5",
                    margin: 3,
                    overflow: "auto",
                  }}
                >
                  {getquestions && getquestions.length > 0 && (
                    <Col
                      span={24}
                      className="subsection"
                      style={{
                        textAlign: "center",
                        marginBottom: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Space className="subsection-title">
                        {getquestions[questionsHeadingCount].title}
                      </Space>
                      <br />
                      {/* {getquestions[questionsHeadingCount].widget ===
                      "RadioGroupWidget" &&
                      getquestions[questionsHeadingCount].options.length !==
                      0 &&
                      getquestions[questionsHeadingCount].options.map(
                        (option) => (
                          <Space className="options" style={{ flex: 1 }}>
                            <Radio.Group>
                              <Radio value={option.title}>{option.title}</Radio>
                            </Radio.Group>
                          </Space>
                        )
                      )} */}
                      {getquestions[questionsHeadingCount].widget ===
                        "RadioGroupWidget" &&
                        getquestions[questionsHeadingCount].options.length !==
                          0 && (
                          <Radio.Group
                            buttonStyle="solid"
                            onChange={(value) =>
                              onChange(
                                value.target.value,
                                finalData[current].index,
                                getquestions[questionsHeadingCount].mobIndex
                              )
                            }
                          >
                            {getquestions[questionsHeadingCount].options.map(
                              (option) => (
                                <div className="radio_options">
                                  <Space
                                    className="radio_options"
                                    style={
                                      {
                                        // float: "left",
                                        // display: "block",
                                        // clear: "right",
                                        // borderStyle: "solid",
                                        // borderWidth: "thin",
                                        // width: "100%",
                                        // color: "#2196F3",
                                        // margin: 5,
                                        // padding: 5,
                                        // backgroundClip: "padding-box",
                                        // textAlign: "left",
                                      }
                                    }
                                  >
                                    <Radio
                                      value={option.title}
                                      buttonStyle="solid"
                                    >
                                      <span className="radio_title">
                                        {option.title}
                                      </span>
                                    </Radio>
                                  </Space>
                                </div>
                              )
                            )}
                          </Radio.Group>
                        )}
                      {getquestions[questionsHeadingCount].visible &&
                        getquestions[questionsHeadingCount].widget ===
                          "string" && (
                          <Input
                            placeholder={
                              getquestions[questionsHeadingCount].title
                            }
                          />
                        )}
                      {getquestions[questionsHeadingCount].widget ===
                        "CheckboxGroupWidget" &&
                        getquestions[questionsHeadingCount].options.length !==
                          0 &&
                        getquestions[questionsHeadingCount].options.map(
                          (option) => (
                            <div className="checkbox_options">
                              <Space
                                style={
                                  {
                                    // // float: "left",
                                    // display: "block",
                                    // // clear: "right",
                                    // // clear: "left",
                                    // // borderStyle: "solid",
                                    // // borderWidth: "thin",
                                    // width: "100%",
                                    // color: "#2196F3",
                                    // margin: 5,
                                    // padding: 10,
                                    // // backgroundClip: "padding-box",
                                    // textAlign: "left",
                                  }
                                }
                              >
                                {/* <Checkbox.Group style={{ color: "red" }}> */}
                                <Checkbox value={option.title}>
                                  <span className="checkbox_title">
                                    {option.title}
                                  </span>
                                </Checkbox>
                                {/* </Checkbox.Group> */}
                              </Space>
                            </div>
                          )
                        )}

                      {/* {getquestions[questionsHeadingCount].widget ===
                      "string" && (
                        <Input
                          placeholder="Text"
                          placeholder={getquestions[questionsHeadingCount].title}
                        />
                      )} */}

                      {getquestions[questionsHeadingCount].widget ===
                        "InputWithTags" && (
                        <InputWithTags
                          placeholderValue={
                            getquestions[questionsHeadingCount].title
                          }
                        />
                      )}

                      {getquestions[questionsHeadingCount].widget ===
                        "UploadWidget" && (
                        <Dragger {...uploadProps}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly
                            prohibit from uploading company data or other band
                            files
                          </p>
                        </Dragger>
                      )}
                    </Col>
                  )}
                  {/* </SwiperSlide> */}
                </div>
                <div className="steps-action">
                  {questionsHeadingCount > 0 && (
                    <Button
                      onClick={() => prevQuestion()}
                      style={{
                        position: "absolute",
                        bottom: "4px",
                        left: "5px",
                        zIndex: 1,
                        margin: "0 8px"
                      }}
                    >
                      Previous
                    </Button>
                  )}
                  {/* questionsHeadingCount < getquestions.length - 1 */}
                  {console.log(
                    "questionsHeadingCount next",
                    questionsHeadingCount,
                    getquestions
                  )}
                  {questionsHeadingCount <
                    (getquestions && getquestions.length - 1) && (
                    <Button
                      type="primary"
                      onClick={() => nextQuestion()}
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        left: "285px",
                        zIndex: 1,
                      }}
                    >
                      Next
                    </Button>
                  )}
                  {/* (getquestions && getquestions.length - 1) && ( */}
                  {/* || (getquestions.length === 0) */}
                  {(questionsHeadingCount === getquestions.length - 1 ||
                    getquestions.length === 0) && (
                    <Button
                      type="primary"
                      onClick={() => nextquestionSection()}
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        left: "285px",
                        zIndex: 1,
                      }}
                    >
                      Done
                    </Button>
                  )}

                  {previousBtnDisplay && questionsHeadingCount === 0 && (
                    <Button
                      onClick={() => previousSection()}
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        left: "4px",
                        zIndex: 1,
                      }}
                    >
                      Previous!
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
          {/* <div className="steps-action">
            {subsectionCount > 0 && (
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => subSectionPrev()}
              >
                Previous
              </Button>
            )}

            {subsectionCount < subHeadingArray.length - 1 && (
              <Button type="primary" onClick={() => subSectionNext()}>
                Next
              </Button>
            )}
            {subsectionCount === subHeadingArray.length - 1 && (
              <Button type="primary" onClick={() => nextSection()}>
                Done
              </Button>
            )}
          </div> */}
        </div>
      </div>

      {/*  next buttons for sections for sections */}

      {/* <div className="steps-action">
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}

        {current < finalData.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === finalData.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )}
      </div> */}
    </>
  );

  return <>{checkmobile ? mobileRender : sectionRender}</>;
};

export default DisorderItemSections;

// {getquestions[questionsHeadingCount].widget ===
//   "RadioGroupWidget" &&
//   getquestions[questionsHeadingCount].options.length !==
//   0 && (
//     <Radio.Group onChange={(value) =>
//       onChange(
//         value.target.value,
//         // index,
//         nestedIndex
//       )
//     }>
//       {getquestions[questionsHeadingCount].options.map(
//         (option) => (

//           <Space className="options" style={{ flex: 1 }}>
//             <Radio value={option.title}>{option.title}</Radio>
//           </Space>
//         ))}
//     </Radio.Group>
//   )}
