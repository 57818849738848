import React, { Component, useState, useEffect } from "react";
import { Breadcrumb, List, Card, Pagination, Modal, Button } from "antd";
import styled, { ThemeProvider } from "styled-components";
import "./BasicCRUDLayout";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { SampleComponent } from "mr_react_framework";
defineMessages({
  search: {
    id: "general.search",
    defaultMessage: "Search"
  }
});
const BasicCRUDLayout = props => {
  const {
    resourceName,
    singleResourceName,
    MrForm,
    filter,
    forms,
    MrFilter,
    displayName,
    resourceForm,
    CustomListItem,
    ActionBtns,
    params,
    setParams,
    showFormVal,
    showForm,
    loading,
    item,
    handleOk,
    handleCancel,
    refresh,
    dataSource,
    pagination,
    onPageChange,
    edited,
    deleted,
    permission,
    ListHeader
  } = props;
  let toRender;
  let content = [];

  let visible = false;

  console.log("resourceName, showFormVal", resourceName, showFormVal);
  if (showFormVal === resourceName) {
    visible = true;
  }
  const WrappedMrForm = MrForm();
  // const WrappedMrForm = this.mrForm;
  let extraFilters = [];

  if (filter) {
    extraFilters = filter({
      ...params,
      changed: setParams
    });
  }

  const Filter = MrFilter({
    name: displayName,
    singleResourceName,
    extraFilters
  });
  const intl = useIntl();
  let form = (
    // <WrappedMrForm
    //   isModal
    //   fields={resourceForm.form(intl)}
    //   visible={visible}
    //   displayName={displayName}
    //   loading={loading}
    //   item={item}
    //   handleOk={handleOk}
    //   handleCancel={handleCancel}
    // />
    <WrappedMrForm
      isModal
      schema={resourceForm.form(intl).schema}
      jsonForm={resourceForm.form(intl)}
      noHtml5Validate={true}
      onSubmit={form => {
        console.log("form", form);
      }}
      // fields={resourceForm.form(intl)}
      visible={visible}
      displayName={displayName}
      loading={loading}
      item={item}
      handleOk={handleOk}
      handleCancel={handleCancel}
      loading={loading}
      onError={error => {
        console.log("errors", error);
      }}
      formData={item}
    />
  );

  content.push(form);
  let createBtn = null;
  console.log("permission", permission);
  console.log("singleResourceName", singleResourceName);
  if (permission[singleResourceName].create) {
    const StyledButton = styled(Button)`
      background: ${props => props.theme.button.background};
      color: ${props => props.theme.main.colors.primary};
    `;
    createBtn = (
      <StyledButton
        key={"createBtn"}
        onClick={() => showForm({ name: resourceName })}
        icon={"plus"}
      >
        <FormattedMessage
          id="general.create"
          defaultMessage="Create"
          // defaultMessage={`{text}`}
          // description="Create"
          // values={{ text: "Create" }}
        />
      </StyledButton>
    );
    // content.push(createBtn);
  }
  content.push(<SampleComponent />);
  content.push(
    <Filter
      {...params}
      changed={setParams}
      refresh={refresh}
      createBtn={createBtn}
      // create={() => this.showForm({ name: resourceName })}
    />
  );
  let list = (
    <List
      key={"list"}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 1,
        lg: 1,
        xl: 1,
        xxl: 1
      }}
      dataSource={dataSource}
      renderItem={item => (
        <CustomListItem
          className={"custom-list-item"}
          item={item}
          actionBtns={
            <ActionBtns
              edit={permission[singleResourceName].update}
              delete={permission[singleResourceName].delete}
              edited={edited}
              deleted={deleted}
              name={resourceName}
              item={item}
            />
          }
          name={resourceName}
        />
      )}
    />
  );
  console.log("permission", permission);
  content.push(ListHeader);
  content.push(list);
  console.log("pagination", pagination);
  // content.push(pagination.totalPages);

  content.push(
    <Pagination
      // showQuickJumper
      total={pagination.totalCount}
      pageSize={pagination.pageSize}
      // showTotal={total => `Total ${total} items`}
      onChange={onPageChange}
      current={pagination.page}
      // defaultCurrent={params.page}
    />
  );
  let breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href="">Home</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{displayName}</Breadcrumb.Item>
    </Breadcrumb>
  );
  toRender = content;
  return toRender;
};

export default BasicCRUDLayout;
