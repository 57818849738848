import styled from "styled-components";

export const MrRow = styled.div`
  /* list-style-type: none; */
  margin: 0;
  padding: 0;
  /* border: 1px dotted red;  */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  flex-wrap: wrap;

  
  /* @media (max-width: 1300px) {
    flex: column;
    background-color: #DCDCDC;
    overflow: hidden;
  } */
`;

export const MrColumn = styled.div`
  /* border: 1px solid green;  */
  margin: 0.5em;
  padding: 0.5em;
  width: 10%;
  overflow-wrap: break-word;
  text-align: left;
  /* background-color: red; */
  /* @media (max-width: 1300px) {
    height: 30px;
    width: 30px;
    text-align: center;
    word-wrap: break-word;
    max-width:700px;
    min-width:700px;
    box-sizing: border-box;
    display: block;
    background-color: #F0F2F5;

  } */
`;

export const MrRowHeader = styled.div`
  list-style-type: none;
  /* margin: -5; */
  
  /* padding: 0; */
  background-color: #f0f2f5;
  width: 100%;
  height: 60px;
  /* border: 1px dotted red;  */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: left;
  

  /* @media (max-width: 1300px) {
    width:1px;
    height: 1px;
    margin: 10px;
    box-sizing: border-box;
    flex: column;
    background-color: #DCDCDC;

  } */
`;

export const MrColumnHeader = styled.div`
  /* border: 1px solid green;  */
  margin: 0.5em;
  padding: 0.5em;
  width: 10%;
  text-align: left;
  font-size: 16px;
  font-style: bold;
  /* @media (max-width: 1300px) {
    height: 1px;
    width: 1px;
  } */
    
     
  /* display: block; */
  /* background-color: yellow; */
  /* flex-direction: column; */
  /* flex-wrap: wrap; */
  /* background-color: blue; */

  /* border: 1px dotted black; */
  /* clip: rect(0 0 0 0); */
  /* height: 3px; */
  /* margin: 5px; */
  /* overflow: auto; 
     */
  
`;

// export const MrLogout = styled.button`
// float: right;
// margin: 20px;
// height:40px;
// background-color: red;
// `;
