import {
  currentUserSelector,
  permissionsSelector,
  orgSelector,
} from "../views/Auth/Login/selector";

export default {
  currentUser: currentUserSelector,
  permission: permissionsSelector,
  org: orgSelector,
};
