import React from "react";
import styled from "styled-components";
import { Form, Icon, Button, Modal } from "antd";

const BasicFormLayout = props => {
  let formItems = [];
  let {
    styleProps,
    loading,
    visible,
    isModal,
    displayName,
    handleOk,
    handleCancel,
    handleSubmit,
    item
  } = props;
  // if (props.hasOwnProperty("buildFields")) {
  //   formItems = props.buildFields(this.state.fields);
  // } else {
  // }
  formItems = props.buildFields(props.fields);
  let submitBtnStyle = {};
  const SubmitButton = styled(Button)`
    width: ${props => props.width};
  `;

  if (styleProps && styleProps.submitBtn) {
    submitBtnStyle = { ...styleProps.submitBtn };
  }
  let submitBtn = (
    <Form.Item>
      <SubmitButton type="primary" htmlType="submit" {...submitBtnStyle}>
        Log in 
        {/* <Icon type="login" /> */}
      </SubmitButton>
    </Form.Item>
  );
  const formLayout = {
    layout: "horizontal"
  };
  if (!isModal) {
    formItems.push(submitBtn);
  }
  // display form on Modal support
  let finalForm = (
    <Form {...formLayout} onSubmit={handleSubmit}>
      {formItems}
    </Form>
  );
  if (isModal) {
    finalForm = (
      <Modal
        key={"modal"}
        visible={visible}
        title={displayName}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ]}
      >
        {finalForm}
      </Modal>
    );
  }

  return finalForm;
};

export default BasicFormLayout;
