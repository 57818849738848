import React from "react";
import { List, Card, AutoComplete } from "antd";
import { MrColumn } from "../../../components/UI/MrTable/MrTable";




const PatientsListCard = (props) => {
    console.log("props_dataObject_patients_in_card", props);

    let keysArr = Object.keys(props.dataObject);
    console.log("keysArr---->", keysArr);
    let patientsFormMobileView = "there"

    return (
        <div>

            {props.dataObject.map((dataItem, index) => {
                return (
                    <Card style={{ margin: "auto", width: 340, position: "relative" }}>
                        {dataItem.name}
                        <MrColumn style={{ position: "absolute", left: 290, top: 5 }}> {dataItem.actions} </MrColumn>

                    </Card>
                )
            })}
        </div>
    );
};

export default PatientsListCard;
