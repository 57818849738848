import { createSelector } from "reselect";
import lodash from "lodash";

const loadingState = (state, props) => {
  state.patients.get("loading");
};
export const loadingSelector = () =>
  createSelector([loadingState], loading => loading);

const errorState = (state, props) => {
  state.patients.get("error");
};
export const errorSelector = () => 
  createSelector([errorState], error => error);

const firstRoutesState = (state, props) => {
  return state.patients.get("patients");
};
export const firstRoutesSelector = () =>
  createSelector([firstRoutesState], patients => patients);

const firstRoutesShowState = (state, props) => {
  console.log("showview state", state, props);
  const patients = state.patients.get("patients");
  if (patients.size === 0) {
    return {};
  } else {
    const id = parseInt(props.match.params.id);
    const idx = lodash.findIndex(patients, {   
      id: id
    });
    console.log("iddddd",idx);
    if (idx !== -1) {
      return patients[idx];
    }
    return {};
    
  }
};
export const firstRoutesShowSelector = () =>
  createSelector([firstRoutesShowState], patients => patients);

const paginationState = (state, props) => {
  const pageObj = {};
  pageObj.page = state.patients.get("page");
  pageObj.totalPages = state.patients.get("total_pages");
  return pageObj;
};
export const paginationSelector = () =>
  createSelector([paginationState], pageObj => pageObj);