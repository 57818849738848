import React, { Component } from "react";
import BaseLayout from "../BaseLayout/BaseLayout";
import {
  Layout,
  Menu,
  Icon,
  Breadcrumb,
  List,
  Card,
  Button,
  Select,
  Collapse,
  Dropdown,
} from "antd";

// import {MrLogout} from "../../UI/MrTable/MrTable"
import aiimslogo from "../../../assets/images/aiimslogo.jpg";
import "./AppLayout.scss";
import styled from "styled-components";
import { connect } from "react-redux";
import MrMenu from "../../../lib/utils/MrMenu/MrMenu";
import MrInput from "../../UI/MrInput/MrInput";
import SideDrawer from "../../SideDrawer/SideDrawer";
import BackDrawer from "../../SideDrawer/BackDrop";
// import BasicViewLayout from "../../../lib/utils/MrForm/BasicFormLayout/BasicViewLayout";
import { useIntl } from "react-intl";
import { isAbsolute } from "path";
const { SubMenu } = Menu;
const { Panel } = Collapse;
const { Header, Sider, Content } = Layout;
const AppLayoutHeader = styled(Header)`
  // margin-left: 200;
  // background: "#fff";
  // padding: 0;
  // position: fixed;
  // width: 100%;
`;
const AppLayoutSider = styled(Sider)`
  // flex: 0 0 256px;
  // max-width: 256px;
  // min-width: 256px;
  // width: 256px;
`;

const AppLayoutSiderMobile = styled(Sider)`
  /* width: 500px; */
`;
const AppMainLayout = styled(Layout)`
  min-height: 100vh;
  // padding-left: 80px;
`;
const AppLayoutContent = styled(Content)`
  // margin-top: 64px;
  // padding: 0 50px;
`;
const InfoContainer = styled.div`
  // margin-top: 64px;
  // padding: 0 50px;
  font-size: 20px;
  font-weight: bold;
  padding: 20px 30px 0;
`;
const FiltersContainer = styled.div`
  // margin-top: 64px;
  // padding: 0 50px;
`;
const PaginationContainer = styled.div`
  // margin-top: 64px;
  // padding: 0 50px;
`;
const ListContainer = styled.div`
  // margin-top: 64px;
  // padding: 0 50px;
`;

class AppLayout extends Component {
  state = {
    collapsed: true,
    active: false,
    SideDrawerOpen: false,
  };

  // toggle for trigger  and (menu fold and menu unfold)
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({
      active: !currentState,
    });
  };

  // for hamburgr menu
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { SideDrawerOpen: !prevState.SideDrawerOpen };
    });
  };

  // for hamburgr menu
  backdropClickHandler = () => {
    this.setState({ SideDrawerOpen: false });
  };

  render() {
    console.log("applayout component");
    let FinalMenu = MrMenu();
    let sideDrawer;
    let backDrop;
    console.log("LOCALESssss", this.props.locales);
    console.log("applayout");

    //sidedrawer for hamburger menu
    if (this.state.SideDrawerOpen) {
      sideDrawer = (
        <SideDrawer>
          <div style={{ backgroundColor: "#00152A", color: "white" }}>
            <div>
              <img
                src={aiimslogo}
                alt="logo"
                style={{ marginLeft: 15, marginTop: 20, width: 50, height: 50 }}
              />
            </div>

            <FinalMenu items={this.props.menu} />

            <Menu mode="inline" theme="dark">
              <SubMenu
                key="sub1"
                title={
                  <span>
                    {/* <Icon type="pie-chart" /> */}
                    <span>Change Language</span>
                  </span>
                }
              >
                {this.props.locales.map((locale, i) => {
                  return (
                    <Menu.Item
                      key={locale.value}
                      onClick={() => this.props.changeLanguage(locale.value)}
                    >
                      {locale.label}
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            </Menu>

            {/* logout button shifted to hamburger menu */}
            {/* <Icon
              className="mobile-only"
              type="logout"
              onClick={() => this.props.logout()}
              style={{
                width: "60px",
                padding: "1px",
                marginTop: "10px",
                color: "white"
              }}
            /> */}
            <span>logout</span>
          </div>

          {/* </AppLayoutSider> */}

          {/* <FinalMenu items={this.props.menu} /> */}
        </SideDrawer>
      );
      backDrop = <BackDrawer click={this.backdropClickHandler} />;
    }

    let list = (
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        dataSource={this.props.listData}
        renderItem={(item) => <div />}
      />
    );

    let toRender;
    console.log("this.props.localesas", this.props.locales);
    toRender = (
      <Layout id="app-layout">
        {/* AppLayoutSider menubar open here */}
        <AppLayoutSider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          className="desktop-only"
        >
          <div>
            <img
              src={aiimslogo}
              alt="logo"
              style={{ marginLeft: 15, marginTop: 20, width: 50, height: 50 }}
            />
          </div>

          <FinalMenu items={this.props.menu} />

          {/* <Icon
            className="mobile-only"
            type="logout"
            onClick={() => this.props.logout()}
            style={{
              width: "60px",
              padding: "1px",
              margin: "10px",
              color: "white"
            }}
          /> */}
        </AppLayoutSider>

        {/* sideDrawer and backbrop for hamburger menu */}
        {sideDrawer}
        {backDrop}
        <AppMainLayout>
          <AppLayoutHeader style={{ background: "#fff", padding: 0 }}>
            <Button
              type="primary"
              onClick={this.drawerToggleClickHandler}
              style={{ marginBottom: 16, marginLeft: 15 }}
              className="mobile-only"
            >
              {/* <Icon type={this.state.collapsed ? "menu-unfold" : "menu-fold"} /> */}
            </Button>

            {/* <Icon
              className="trigger"
              type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
              onClick={this.toggle}
            /> */}

            <MrInput
              className="desktop-only"
              key="lang"
              elementType="select"
              options={this.props.locales}
              valueKey="value"
              textKey="label"
              mobile-only
              placeholder="Change Language"
              defaultValue={this.props.currentLocale}
              changed={this.props.changeLanguage}
              // fieldInfo={{ key: "locale" }}
              style={{ width: 200, padding: "20px" }}
            />

            <Button
              className="desktop-only"
              icon={"logout"}
              onClick={() => this.props.logout()}
              style={{ float: "right", margin: "20px" }}
            >
              Logout
            </Button>
          </AppLayoutHeader>
          <InfoContainer>{this.props.breadcrumb}</InfoContainer>
          <AppLayoutContent
            style={{
              margin: "24px 16px",
              padding: 24,
              background: "#fff",
              minHeight: 280,
            }}
          >
            {this.props.children}
          </AppLayoutContent>
        </AppMainLayout>
      </Layout>
    );
    return toRender;
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    return {
      //  bar: getBarState(state, props)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitHandler: (email) => {
      // dispatch(actions.login(email));
    },
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(AppLayout);
