import React, { memo, useEffect, useState ,Suspense,lazy} from "react";
import { Collapse } from "antd";
import { DragOutlined } from "@ant-design/icons";
import { Container, Draggable } from "react-smooth-dnd";
//import SingleCard from "./SingleCard";
import { validateJsonValue,validateSectionValue } from "../validateJsonValue";
import DraggableWrapper from "./DraggableWrapper"
import "../CustomInput.scss";
import { keyBy } from "lodash";





const SingleCard = lazy(() => import("./SingleCard"));



const { Panel } = Collapse;

const CardsList = ({
  data,
  dragToggle,
  addButtonStateChange,
  onOuterDrop,
  onDragStart,
  onDrop,
  onDragOver,
  onDragEnter,
  onDragEnd,
  inputsInnerWrapperRef,
  dragInnerItem,
  dropInnerItem,
  allowDropInnerItem,
  leaveDropInner,
  onInputChange,
  deleteSection,
  renderSelectedItem,
  addSubSection,
  addOption,
  addCondition,
}) => {


  const [activateKey, setActivateKey] = useState(['0']);



  // const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const cleanClasses = () => {
      document.body.className = '';
    };
    document.addEventListener("touchend", cleanClasses, false);
    return () => {
      document.removeEventListener("touchend", cleanClasses, false);
    };
  }, []);


  useEffect(() => {
    if(dragToggle==true){
      setActivateKey(null)
    }
  }, [dragToggle]);

  const handleCollapseOnChange = (index) =>{
    if(activateKey != index){
      setActivateKey(index)
    }
  }

console.log("what i have do ==>",data);

  return (
    <>
     <Container onDrop={onOuterDrop} behaviour={dragToggle?"move":"copy"} key={"form-container"} >
        {data.map((item, index) => {

      const borderInline =  !validateSectionValue(item) ? "1px solid red":"1px solid #d9d9d9"; //

        return (
           <DraggableWrapper key={item.uniqueId} isDraggable={dragToggle}>
           <Collapse  style={{ marginBottom: "20px" }} onChange={(index)=> handleCollapseOnChange(index)}  destroyInactivePanel={true}  accordion activeKey={activateKey}  key={"collapse-"+item.uniqueId}> 
          <Panel
            header={item.title || `Enter Section title`}
            key={"panel-"+item.uniqueId}
            extra={dragToggle && <DragOutlined />}
            style={{border:borderInline}}
          >
            <Suspense fallback={<h1>Still Loading…</h1>}>
            <SingleCard
              data={item}
              index={index}
              dragToggle={dragToggle}
              onInputChange={onInputChange}
              addButtonStateChange={addButtonStateChange}
              onDragStart={onDragStart}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onDragEnd={onDragEnd}
              onDragEnter={onDragEnter}
              inputsInnerWrapperRef={inputsInnerWrapperRef}
              dragInnerItem={dragInnerItem}
              dropInnerItem={dropInnerItem}
              allowDropInnerItem={allowDropInnerItem}
              leaveDropInner={leaveDropInner}
              deleteSection={deleteSection}
              renderSelectedItem={renderSelectedItem}
              addOption={addOption}
              addSubSection={addSubSection}
              addCondition={addCondition}
            />
            </Suspense>
          </Panel>
          </Collapse>
          </DraggableWrapper>
        )}
        
        )}
      </Container>

      <div>
        {!validateJsonValue(data).success && (
          <span className="custom-error-message with-bold">
            All fields are Required
          </span>
        )}
      </div>
    </>
  );
};

const areEqual = (prevProps, currentProps) => {
  console.log("prevProps==> card list", prevProps, "currentProps==>", currentProps);
  return prevProps.data === currentProps.data && currentProps.dragToggle === prevProps.dragToggle ?true : false;
};

export default CardsList;
