import { fromJS } from "immutable";
import { message } from "antd";
import { delay } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { makeAxiosInstance } from "../../../api/apiModule";

export const axiosInstance = makeAxiosInstance();
const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "login",
  message,
  actionNames: ["CREATE_GOOGLE", "CREATE", "LOGOUT", "CHECK_AUTH_STATE"],
  resourceUrl: "sessions"
});

const initialState = fromJS({
  token: null,
  userId: null,
  error: null,
  loading: false,
  authRedirectPath: "/",
  currentUser: {},
  preferences: {},
  permission: {},
  org: {}
});
const successFunc = (state, action) => {
  // console.log("action data", action);
  console.log("action", action);
  let payload = action.payload;
  // let currentUser = payload.data.user;
  let currentUser = formatJsonApiData(payload.data.user.data);
  if (payload.data.user.included) {
    currentUser['hospital'] = formatJsonApiData(payload.data.user.included[0]);
  }
  console.log("currentUser and payload", currentUser, payload);
  let newPermission, newPreference;
  // try {
  //   newPermission = {
  //     ...JSON.parse(currentUser.permission),
  //   };
  // } catch (error) {
  //   console.log( "error", error );
  //   newPermission;
  // }
  // newPermission = {
  //   ...JSON.parse(currentUser.permission.settings)
  // };
  // currentUser.permission = newPermission;
  // newPreference = {
  //   ...JSON.parse(currentUser.preference.settings)
  // };
  // currentUser.preference = newPreference;
  localStorage.setItem("token", payload.data.token);
  return (
    state
      .set("currentUser", currentUser)
      .set("token", payload.data.token)
      .set("permission", currentUser.permission)
      // .set("userId", payload.data.user.id)
      .set("hospital", currentUser.hospital)
      // .set("company", payload.data.company)
      .set("loading", false)
  );
};
const logoutFunc = (state, action) => {
  localStorage.removeItem("token");
  axiosInstance.resetInstance()
  return (
    state
    .set("token", null)
    .set("userId", null)
    .set("currentUser", {})
    .set("permission", {})
    .set("hospital", {})
    .set("error", null)
    .set("loading", false)
  );
};

export function* checkAuthStateSaga(action) {
  const token = localStorage.getItem("token");
  if (!token) {
    yield put(actions.logout());
  } else {
    let url = "get_user.json";
    try {
      yield put(actions.createStart());
      yield put({
        type: "LOGIN_CREATE_START",
      });
      // const response = yield axiosInstance.get(url);
      const response = yield axiosInstance.instance.get(url);
      console.log("response", response);
      let formattedUserData = formatJsonApiData(response.data.user.data);
      let unformattedUserData = response.data.user;
      let payload = {
        data: {
          user: {
            ...unformattedUserData,
            token: token
          },
          // org: unformattedUserData.org,
          token: token
        }
      };

      yield put(actions.createSuccess(payload));
    } catch (error) {
      // yield put(actions.logout());
      // yield put(actions.createFail());
      yield put({
        type: "LOGIN_CREATE_FAIL",
      });
    }
  }
}

export function* logoutLoginSaga(action) {
  try {
    yield localStorage.removeItem("token");
    yield put(actions.logoutSuccess());
  } catch (e) {
    console.log("e", e);
    yield put(actions.logoutFail());
  }
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  CREATE_LOGIN_SUCCESS: successFunc,
  CREATE_GOOGLE_LOGIN_SUCCESS: successFunc,
  LOGOUT_LOGIN_SUCCESS: logoutFunc
});

export const watchLogin = reduxCrud.generateWatchSaga({
  baseUrl: "sessions",
  CHECK_AUTH_STATE_LOGIN: checkAuthStateSaga,
  LOGOUT_LOGIN: logoutLoginSaga
});

export default reduxCrud;
