import React, { useState } from "react";
import { MrCrudView, MrSelect } from "mr_react_framework";
import {
  DisordersList,
  DisordersColumns,
} from "./DisordersListItems/DisordersList";
import DisorderHeader from "./DisorderHeader";
import NewBasicCRUDLayout from "../../components/Layouts/BasicCRUDLayout/NewBasicCRUDLayout";
import commonSelectors from "../../App/commonSelectors";
import DisorderItemDetail from "./DisorderItemDetail";
import CustomInput from "./CustomInput";
import { ChromePicker } from "react-color";
import { BgColorsOutlined } from "@ant-design/icons";

import { actions } from "./redux";
import { validateJsonValue } from "./validateJsonValue";
import { Button } from "antd";

const SelectDisorderType = MrSelect({
  config: {
    processData: (data, props) => {
      console.log("select dropdown==>", data, props);
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "Master Form", value: "master_form" },
        { label: "ADHD", value: "adhd_form" },
        { label: "ASD", value: "asd_form" },
        { label: "CEREBRAL PALSY", value: "cerebral_form" },
        { label: "DEMYELINATING", value: "demyelinating_form" },
        { label: "ENCEPHALITIS", value: "encephalitis_form" },
        { label: "EPILEPSY", value: "epilepsy_form" },
        { label: "GLOBAL DEVELOPMENTAL DELAY", value: "gdd_form" },
        { label: "LEARNING DISABILITY", value: "ldForm" },
        { label: "MOVEMENT DISORDER", value: "movement_disorder_form" },
        { label: "NEURODEGENERATIVE", value: "neurodegenerative_form" },
        { label: "NEUROMUSCULAR", value: "neuromuscular_form" },
        { label: "STROKE", value: "stroke_form" },
      ],
      showSearch: false,
      // filterOption: true,
      optionFilterProp: "label",
      placeholder: "Value",
      // style: { width: "120px" },
      allowClear: true,
    },
  },
});

const SelectFormType = MrSelect({
  config: {
    processData: (data, props) => {
      console.log("select dropdown==>", data, props);
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "Master", value: "master" },
        { label: "Add on", value: "add_on" },
        { label: "Follow up", value: "follow_up" },
      ],
      showSearch: false,
      // filterOption: true,
      optionFilterProp: "label",
      placeholder: "Value",
      // style: { width: "120px" },
      allowClear: true,
    },
  },
});

const returnForm = () => {
  let form = {
    // processFormData: (dataProps, opts) => {
    //   console.log("dataProps", dataProps);
    //   console.log("opts", opts);
    //   // process and set values and then return changed values
    //   opts.values["custom_field"] = "custom_value";

    //   if(opts.values.children){
    //     let newChildren = opts.values.children.map((item) => {
    //       return {
    //         ...item,
    //         starts_on: moment(item.starts_on, 'YYYY-MM-DD'),
    //         ends_on: moment(item.ends_on, 'YYYY-MM-DD'),
    //       }
    //     });

    //     console.log("newChildren", newChildren);
    //     opts.values.children_attributes = newChildren;
    //   }

    //   return opts.values;
    // },
    schema: {
      type: "object",
      properties: {
        name: {
          type: "string",
          title: "Academic year name",
          // default: "2020-2021"
          span: 8,
        },
        // label: {
        //   type: "string",
        //   placeholder: "Academic year label",
        //   // default: "2020-2021"
        // },
        children: {
          type: "array",
          addButtonTitle: "+ Add term",
          span: 20,
          min: 1,
          max: 4,
          default: [{ name: "Term 1" }],
          items: {
            type: "object",
            properties: {
              name: {
                type: "string",
                title: "Term name",
                // default: "Term 1"
                span: 8,
              },
              // label: {
              //   type: "string",
              //   placeholder: "Term label",
              //   // default: "Term 1"
              // },
              starts_on: {
                title: "Starts on",
                type: "string",
                span: 6,
                widget: "DatePickerWidget",
              },
              ends_on: {
                title: "Ends on",
                type: "string",
                span: 6,
                widget: "DatePickerWidget",
              },
              // itemArray: {
              //   type: "array",
              //   items: {
              //     type: "object",
              //     properties: {
              //       itemArrayItem1: {
              //         type: "string",
              //         placeholder: "itemArrayItem1"
              //       },
              //       itemArrayItem2: {
              //         type: "string",
              //         placeholder: "itemArrayItem2"
              //       },
              //     }
              //   }
              // }
            },
          },
        },
      },
    },
    uiSchema: {},
  };
  return form;
};

const ColorPicker = (props) => {
  console.log("color picker==>", props);
  const [color, setColor] = useState(props.value || "");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <>
      <Button icon={<BgColorsOutlined />} style={{backgroundColor: color}} onClick={handleClick}>
      </Button>
      {displayColorPicker && (
        <div
          style={{
            position: "absolute",
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={handleClose}
          />
          <ChromePicker
            color={color}
            onChangeComplete={(color) => {
              setColor(color.hex);
              props.onChange(color.hex);
            }}
          />
        </div>
      )}
    </>
  );
};

const returnDisorderForm = () => {
  let disorderForm = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts==>", opts);
      // process and set values and then return changed values
      return opts.values;
    },
    schema: {
      type: "object",
      required: [],
      properties: {
        // Disorder name
        form_name: {
          type: "string",
          default: "",
          title: "Disorder form name",
          span: 8,
          rules: [
            {
              required: true,
              message: "Please select",
            },
          ],
        },
        form_code: {
          type: "string",
          default: "",
          title: "Form code",
          span: 4,
          rules: [
            {
              required: true,
              message: "Please select",
            },
          ],
        },
        form_type: {
          type: "string",
          span: 3,
          title: "Form type",
          widget: SelectFormType, // master, add_on, follow_up
          rules: [
            {
              required: true,
              message: "Please select",
            },
          ],
        },
        // disorder forms names - actually disorder_type. TODO: change this on FE everywhere - changed on BE @nitin
        disorder_type: {
          type: "string",
          span: 6,
          title: "Disorder type",
          widget: SelectDisorderType,
          rules: [
            {
              required: true,
              message: "Please select",
            },
          ],
        },
        form_color: { // TODO: change this on FE everywhere - changed on BE @nitin to form_color. remarks field should be used separately for comments/description if needed
          type: "string",
          default: "",
          title: "Color",
          span: 2,
          widget: ColorPicker,
        },
        form_json: {
          type: "string",
          span: 24,
          title: "Disorder Fields",
          widget: CustomInput,
          trigger: "onSubmit",
          formItemConfig: { noStyle: true },
          rules: [
            {
              validator(rule, value) {
                const valid = validateJsonValue(value.disorders);
                if (!valid.success) {
                  return Promise.reject(valid.messages);
                } else {
                  return Promise.resolve();
                }
              },
            },
          ],
        },
      },
    },
  };

  return disorderForm;
};
const Disorders = MrCrudView({
  resourceName: "disorders",
  singleResourceName: "disorder",
  displayName: "Add Disorders Details",
  resourceUrl: "/disorders",
  // layout: NewBasicCRUDLayout,
  isRoute: true,
  // formLayout: NewBasicFormLayout,
  // tableColumns: PatientColumns,
  // filter: PatientsFilter,
  // listHeader: <DisorderHeader />,
  // listItem: DisordersList,
  forms: [{ name: "disorders", form: returnDisorderForm }],
  selectorsObj: {
    ...commonSelectors,
  },
  actions,
  // listHeader: <PatientsHeader />,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      // widget: DisordersList
      // use if we want cards
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: DisorderItemDetail,
    },
    pagination: {
      show: false,
    },
    filter: {
      show: true,
    },
    core: {
      setQueryParams: false,
    },
    list: {
      // use if we want custom table
      show: true,
      type: "table/list/custom",
      widget: DisordersList,
      forceReload: true,
    },
  },
});

export default Disorders;
