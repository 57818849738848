import React, { useState, memo } from "react";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { Tabs, Button, Modal, Collapse, Space, Tooltip } from "antd";
import { List, Typography, Card, Row, Col } from "antd";
import _ from "lodash";
import { adhdForm } from "../../PatientDisorderForms/adhd";
import { MrRow } from "../../../components/UI/MrTable/MrTable";
import { MrColumn } from "../../../components/UI/MrTable/MrTable";
import PatientDisorders from "../PatientDisorder/PatientDisorders";
import MediaShow from "../../../components/UI/MediaShow/MediaShow";
import ViewUploadsModal from "./ViewUploadsModal";
import dayjs from "dayjs";

const { TabPane } = Tabs;

const getSectionFields = (props, element) => {
  const { item } = props;
  if (!item.id) return null;
  console.log(
    "props getSectionFields ---------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
    props
  );
  console.log("props.item", props.item);
  const patientDetails = props.item;
  console.log("patientDetails", patientDetails, element.fields.properties);
  console.log("element getsectionField", element.fields.properties);

  let properties = element.fields.properties;
  console.log("properties", properties);
  let sectionFields = [];
  for (const key in properties) {
    if (properties.hasOwnProperty(key)) {
      console.log("key in get section Field", key);
      console.log("value in get section field", properties[key].is_sub_heading);
      // console.log("value", patientDetails.disorder_information[key]);
      if (properties[key].is_sub_heading === true) {
        sectionFields.push(
          <List.Item>
            <MrRow className="section-field-row">
              <MrColumn style={{ flex: 4 }} className="sub-heading">
                {properties[key].sub_heading_title}
              </MrColumn>
            </MrRow>
          </List.Item>
        );
      } else {
        sectionFields.push(
          <List.Item>
            <MrRow className="section-field-row">
              <MrColumn style={{ flex: 4 }}>{properties[key].title}</MrColumn>
              <MrColumn style={{ flex: 20 }} className="field-value">
                {" "}
                {patientDetails.disorder_information &&
                  patientDetails.disorder_information[key]}
              </MrColumn>
            </MrRow>
          </List.Item>
          // <p><span>{key}</span> - <span>{element.fields.properties[key].title}</span>: <span>{patientDetails.disorder_information[key]}</span></p>
        );
      }
    }
  }

  return sectionFields;
};

const PatientItemDetail = (props) => {
  const { path, url, params } = useRouteMatch();
  console.log("path in patient item detail==>", path);
  const { item } = props;
  if (!item.id) return null;
  console.log("props patients==>", props);
  console.log("props.item", props.item);
  const patientDetails = props.item;
  console.log("patientDetails", patientDetails);
  const { Panel } = Collapse;

  let finalRender = [];

  console.log("finalRender1", finalRender);
  for (const key in adhdForm) {
    if (adhdForm.hasOwnProperty(key)) {
      const element = adhdForm[key];
      console.log("key patientItemDetails", key);
      console.log("element patientItemDetails", element);
      finalRender.push(
        <React.Fragment key={`showSection__${key}`}>
          <Card
            title={element.title}
            className="section-card section-card-show"
          >
            {getSectionFields(props, element)}
          </Card>
        </React.Fragment>
      );
    }
  }
  console.log("finalRender2", item.id);

  return (
    <>
      <Row>
        <Collapse defaultActiveKey={["0"]} style={{ width: "100%" }}>
          <Panel
            header={`${patientDetails.first_name} ${patientDetails.middle_name} ${patientDetails.last_name} - ${patientDetails.uuid}`}
            key="1"
            extra={
              <>
                <Space>
                  <Tooltip title="Edit patient">
                    <Link to={`/u/patients/${item.id}/edit`}>
                      <Button type="link">Edit</Button>
                    </Link>
                  </Tooltip>
                  <ViewUploadsModal uploads={item.attachments_json} />
                </Space>
              </>
            }
          >
            <p>{patientDetails.uuid}</p>
            <p>National ID:- {patientDetails.national_id}</p>
            <p>Gender:- {patientDetails.gender}</p>
            <p>
              Date of birth:-{" "}
              {dayjs(patientDetails.date_of_birth).format(
                "DD-MM-YYYY, hh:mm a"
              )}
            </p>
            <p>Phone:- {patientDetails.phone}</p>
            <p>Country:- {patientDetails.country}</p>
          </Panel>
        </Collapse>
      </Row>
      <Row className="m-t-10">
        <Route
          // path={`${path}`}
          path={`${path}/patient_disorders`}
          render={(routeProps) => (
            <PatientDisorders
              className="patient-disorder"
              params={{ by_patient_id: item.id }}
              createUpdateParams={{
                patient_id: item.id,
                // termable_type: "org_programme",
              }}
              isRoute={false}
              {...routeProps}
              config={{
                filter: {
                  show: true,
                  config: {
                    create: {
                      show: true,
                    },
                    edit: {
                      show: true,
                    },
                    search: { show: false },
                  },
                },
                pagination: { show: false },
              }}
            />
          )}
          // render={() => <div>Home</div>}
        />
      </Row>
    </>
  );

  return (
    <div>
      {/* <Row className="disorder-form-show">
        <Col span={24}>
          {props.renderForm({
            name: "disorderInfo",
            config: { isModal: false, visible: true, width: "1000" },
            modal: { width: "900px" },
            // resource: item //use this for edit
          })}
        </Col>
      </Row> */}
      {/* <StyledHeading>
        Patient: {patientDetails.first_name.toUpperCase()} {patientDetails.middle_name.toUpperCase()}{" "}
        {patientDetails.last_name.toUpperCase()}
      </StyledHeading> */}
      <div class="section-card-show">
        {/* <List.Item> */}
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 6 }}>Registry ID</MrColumn>
          <MrColumn style={{ flex: 18 }} className="field-value">
            {patientDetails.uuid}
          </MrColumn>
        </MrRow>
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 6 }}>UHID</MrColumn>
          <MrColumn style={{ flex: 18 }} className="field-value">
            {patientDetails.uhid}
          </MrColumn>
        </MrRow>
        {/* </List.Item> */}
        {/* <List.Item> */}
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 6 }}>Patient name</MrColumn>
          <MrColumn style={{ flex: 18 }} className="field-value">
            {/* <p class="patient-name"> */}
            {patientDetails.first_name} {patientDetails.middle_name}{" "}
            {patientDetails.last_name}
            {/* </p> */}
          </MrColumn>
        </MrRow>
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 6 }}>Age</MrColumn>
          <MrColumn style={{ flex: 18 }} className="field-value">
            {patientDetails.age}
          </MrColumn>
        </MrRow>
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 6 }}>Gender</MrColumn>
          <MrColumn style={{ flex: 18 }} className="field-value">
            {patientDetails.gender}
          </MrColumn>
        </MrRow>
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 6 }}>Phone</MrColumn>
          <MrColumn style={{ flex: 18 }} className="field-value">
            {patientDetails.phone}
          </MrColumn>
        </MrRow>
        <MrRow className="section-field-row">
          <MrColumn style={{ flex: 6 }}>Country</MrColumn>
          <MrColumn style={{ flex: 18 }} className="field-value">
            {patientDetails.country}
          </MrColumn>
        </MrRow>
        {/* </List.Item> */}
        {/* <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 8 }}>Phone</MrColumn>
            <MrColumn style={{ flex: 16 }} className="field-value">
              {patientDetails.phone}
            </MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 8 }}>Email</MrColumn>
            <MrColumn style={{ flex: 16 }} className="field-value">
              {patientDetails.email}
            </MrColumn>
          </MrRow>
        </List.Item>
        <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 8 }}>Address</MrColumn>
            <MrColumn style={{ flex: 16 }} className="field-value">
              {patientDetails.address}
            </MrColumn>
          </MrRow>
        </List.Item> */}

        {/* <List.Item>
          <MrRow className="section-field-row">
            <MrColumn style={{ flex: 4 }}>Disorder</MrColumn>
            <MrColumn style={{ flex: 20 }} className="field-value">
              {patientDetails.disorder_information &&
                patientDetails.disorder_information.disorder_type}
            </MrColumn>
          </MrRow>
        </List.Item> */}
        {/* <p class="patient-name">{patientDetails.first_name} {patientDetails.middle_name} {patientDetails.last_name}</p> */}
        {/* <p>{patientDetails.phone}</p>
        <p>{patientDetails.email}</p>
        <p>{patientDetails.address}</p>
        <p>{patientDetails.hospital_id}</p>
        <p>{patientDetails.disorder_information.disorder_type}</p> */}
      </div>

      <Route
        // path={`${path}`}
        path={`${path}/patient_disorders`}
        render={(routeProps) => (
          <PatientDisorders
            className="patient-disorder"
            params={{ by_patient_id: item.id }}
            createUpdateParams={{
              patient_id: item.id,
              // termable_type: "org_programme",
            }}
            isRoute={false}
            {...routeProps}
            config={{
              filter: {
                show: true,
                config: {
                  create: {
                    show: true,
                  },
                  edit: {
                    show: true,
                  },
                  search: { show: false },
                },
              },
              pagination: { show: false },
            }}
          />
        )}
        // render={() => <div>Home</div>}
      />

      {/* <PatientDisorders /> */}
      {/* <Button
        type="primary"
        onClick={() => {
          setShowForm(true);
          showModal();
        }}
      >
        Add Disorder Information
      </Button>
      <Modal
        title="Add Disorder"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel} */}
      {/* > */}
      {/* {showDisorderForm && ( */}

      {/* )} */}
      {/* </Modal> */}

      {/* {finalRender} */}
    </div>
  );
};

PatientItemDetail.defaultProps = {};

PatientItemDetail.propTypes = {};

export default memo(PatientItemDetail);
