import React from "react";
import PropTypes from "prop-types";
import { List, Card, Table } from "antd";
import ActionBtns from "../../../components/ActionBtns/ActionBtns";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import "../Hospitals.scss";
import { currentUserSelector } from "../../Auth/Login/selector";

const HospitalList = (props) => {
  const { dataSource } = props;
  const currentUser = useSelector(currentUserSelector());

  console.log("hospital list props hospital===>", currentUser);
  const tableDataSource = [];
  for (let index = 0; index < dataSource.length; index++) {
    let objToPush = {};
    objToPush.id = dataSource[index].id;
    // objToPush.name = dataSource[index].title
    objToPush.hospital_name = (
      <Link to={`/u/hospitals/${dataSource[index].id}`}>
        {" "}
        {dataSource[index].id} - {dataSource[index].title}{" "}
      </Link>
    );

    objToPush.contact_name = dataSource[index].contact_name;
    objToPush.contact_email = dataSource[index].contact_email;
    objToPush.contact_no = dataSource[index].contact_no;
    objToPush.city = dataSource[index].city;
    objToPush.state = dataSource[index].state;
    objToPush.actions = (
      <ActionBtns
        edit={true}
        delete={currentUser.permission.hospital.delete}
        deleted={props.deleted}
        id={dataSource[index].id}
        path="hospitals"
      />
    );
    tableDataSource.push(objToPush);
  }

  const columns = [
    {
      title: "Hospital Name",
      dataIndex: "hospital_name",
      key: "hospital name",
    },
    {
      title: "Contact Name",
      dataIndex: "contact_name",
      key: "contact_name",
    },
    {
      title: "Email",
      dataIndex: "contact_email",
      key: "contact_email",
    },
    {
      title: "Contact No.",
      dataIndex: "contact_no",
      key: "contact_no",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const { path, url } = useRouteMatch();
  console.log("exp list item url", url);
  return (
    <Table dataSource={tableDataSource} columns={columns} pagination={false} />
  );
};
HospitalList.defaultProps = {};

HospitalList.propTypes = {};

export default HospitalList;
