import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import update from "immutability-helper";
import { makeAxiosInstance } from "../../api/apiModule";

export const axiosInstance = makeAxiosInstance();
const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "adhd",
  actionNames: ["FETCH", "CREATE", "UPDATE", "DELETE"]
});

const initialState = fromJS({
  adhds: [],
  page: 1,
  total_pages: 1,
  // action: {},
  error: null,
  loading: false
});

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState);
export const watchAdhds = reduxCrud.generateWatchSaga({});

export default reduxCrud;
