import { applyMiddleware, combineReducers, createStore, } from "redux";
// import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { ReactWrapper, } from "enzyme";
// import { routerStateReducer, } from "redux-router";

/* Sets up basic variables to be used by integration tests
 * Params:
 *   reducers: should be an object with all the reducers your page uses
 *   initialRouterState: an optional object to set as the initial state for the router
 * Returns:
 *   an object with the following attributes:
 *     store: the reducer store which contains the main dispatcher and the state
 *     dispatchSpy: a jest spy function to be used on assertions of dispatch action calls
 */
export const setupIntegrationTest = (reducers, initialRouterState = {}) => {
  // creating the router's reducer
  // function routerReducer(state = initialRouterState, action) {
  //   // override the initial state of the router so it can be used in test.
  //   return routerStateReducer(state, action);
  // }

  // creating a jest mock function to serve as a dispatch spy for asserting dispatch actions if needed
  const dispatchSpy = jest.fn(() => ({}));
  const reducerSpy = (state, action) => dispatchSpy(action);
  // applying thunk middleware to the the store
  // const emptyStore = applyMiddleware(thunk)(createStore);
  const sagaMiddleware = createSagaMiddleware();
  const emptyStore = applyMiddleware(sagaMiddleware)(createStore);

  const combinedReducers = combineReducers({
    reducerSpy,
    // router: routerReducer,
    ...reducers,
  });
  // creating the store
  const store = emptyStore(combinedReducers);
  // const store = createStore(combinedReducers, applyMiddleware(sagaMiddleware));

  return { store, dispatchSpy, };
};
// backup
// export const setupIntegrationTest = (reducers, initialRouterState = {}) => {
//   // creating the router's reducer
//   function routerReducer(state = initialRouterState, action) {
//     // override the initial state of the router so it can be used in test.
//     return routerStateReducer(state, action);
//   }

//   // creating a jest mock function to serve as a dispatch spy for asserting dispatch actions if needed
//   const dispatchSpy = jest.fn(() => ({}));
//   const reducerSpy = (state, action) => dispatchSpy(action);
//   // applying thunk middleware to the the store
//   const emptyStore = applyMiddleware(thunk)(createStore);
//   const combinedReducers = combineReducers({
//     reducerSpy,
//     router: routerReducer,
//     ...reducers,
//   });
//   // creating the store
//   const store = emptyStore(combinedReducers);

//   return { store, dispatchSpy, };
// };

class LocalStorageMock {
  constructor() {
    this.store = {};
  }

  clear() {
    this.store = {};
  }

  getItem(key) {
    return this.store[key] || null;
  }

  setItem(key, value) {
    if (value) {
      this.store[key] = value.toString();
    }
  }

  removeItem(key) {
    delete this.store[key];
  }
}

export const setLocalStorage = () => {
  global.localStorage = new LocalStorageMock();
  // global.localStorage = {
  //   getItem: function(key) {
  //     return this[key];
  //   },
  //   setItem: function(key, value) {
  //     this[key] = value;
  //   },
  //   removeItem: function(key) {
  //     delete this[key];
  //   },
  // };
};
// export const localStorage = new LocalStorageMock();
// var localStorageMock = (function() {
//   var store = {};
//   return {
//     getItem: function(key) {
//       return store[key];
//     },
//     setItem: function(key, value) {
//       store[key] = value.toString();
//     },
//     clear: function() {
//       store = {};
//     },
//     removeItem: function(key) {
//       delete store[key];
//     },
//   };
// })();
// Object.defineProperty(window, "localStorage", { value: localStorageMock, });
export const setLocalStorage2 = () => {
  var localStorageMock = (function () {
    var store = {};
    return {
      getItem: function (key) {
        return store[key];
      },
      setItem: function (key, value) {
        store[key] = value.toString();
      },
      clear: function () {
        store = {};
      },
      removeItem: function (key) {
        delete store[key];
      },
    };
  })();
  Object.defineProperty(window, "localStorage", { value: localStorageMock, });
};

export const flushAllPromises = () =>
  new Promise(resolve => setImmediate(resolve));
